// import library components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//  import custom components
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import DatePickerComponent from "../../../../../../Components/Core/DatePicker/DatePicker";

// import utils
import countriesData from "../../../../../../utils/data/countries.json";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";

// import redux components
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import moment from "moment";
import { formatDateString } from "../../../../../../utils/helpers/dateHandlers";

type Props = {};

const FugitiveEmission = (Props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = React.useState<any>({
    name: "",
    location: "",
    facility_article: "",
    organisation_unit: "",
    source_type: "",
    leak_rate: "",
    provider: "",
    quantity: "",
    ashrae_refrigerant_code: "",
    installation_date: "",
    operation: "",
    disposed_date: "",
    recovered_date: "",
  });
  const [selectedEqTypeObj, setSelectedEqTypeObj] = useState({
    installation_leak_rate: 0,
    operating_leak_rate: 0,
    disposal_leak_rate: 0,
    recovery_leak_rate: 0,
  });

  useEffect(() => {
    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        facility_article: "",
        organisation_unit: "",
        source_type: "",
        leak_rate: "",
        provider: "",
        quantity: "",
        ashrae_refrigerant_code: "",
        installation_date: "",
        operation: "",
        disposed_date: "",
        recovered_date: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string | number, key: string): void => {
    let leakRate = 0;
    if (key === "source_type") {
      setSelectedEqTypeObj(
        dropdownContentList?.data?.equipment_type.values?.filter(
          (el: any) => el.name === value
        )[0]
      );
    } else if (key === "installation_date") {
      value = formatDateString(`${value}`);
      leakRate = selectedEqTypeObj?.installation_leak_rate;
    } else if (key === "operating_date") {
      value = formatDateString(`${value}`);
      leakRate = selectedEqTypeObj?.operating_leak_rate;
    } else if (key === "disposed_date") {
      value = formatDateString(`${value}`);
      leakRate = selectedEqTypeObj?.disposal_leak_rate;
    } else if (key === "recovered_date") {
      value = formatDateString(`${value}`);
      leakRate = selectedEqTypeObj?.recovery_leak_rate;
    }
    setData((prevValue: any) => {
      return {
        ...prevValue,
        leak_rate: leakRate,
        [key]: value,
      };
    });
  };

  const submitHandler = () => {
    let payload;

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: "fugitive_emission", // mention source
        action: "edit", // delete, edit
        scope_type: "S1", // mention the scopes here
        // pk: state?.formData?.pk,
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: "fugitive_emission", // mention source
        action: "add", // delete, edit
        scope_type: "S1", // mention the scopes here
        data: data,
      };
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      navigate("/data-collection/ghg-emission/tables/Fugitive Emission", {
        state: {
          scope: "S1",
          scopePath: "fugitive_emission",
        },
      });
      toastifyAsync(res);
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              onChangeHandler={(input: string) => handleChanges(input, "name")}
              value={data.name}
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                // names must be equal
                return 0;
              })}
              width="46%"
              heightInput="5.2rem"
              header="Location"
              searchIncluded
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "location")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.facility_type?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Facility Type"
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "facility_article")
              }
              addButtonIncluded
              headerStyles={{ fontSize: "1.4rem" }}
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "facility_article");
              }}
              defaultOption={[
                { name: state?.formData?.facility_article ?? "" },
              ]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Organisational unit"
              addButtonIncluded
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <div className="section-header">Source</div>
          <br />
          <div className="questions-container">
            <Dropdown
              options={dropDownData?.equipment_type?.values?.map(
                (item: any) => ({ name: item.name })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Equipment Type"
              addButtonIncluded
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "source_type")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.source_type ?? "" }]}
            />
            <TextInput
              header="Leak Rate"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              value={data.leak_rate}
              headerStyles={{ fontSize: "1.4rem" }}
              placeholder="Auto Filled"
              disabled={true}
            />
            <Dropdown
              options={dropDownData?.provider?.values?.map((item: string) => ({
                name: item,
              }))}
              width="46%"
              heightInput="5.2rem"
              header="Provider"
              addButtonIncluded
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "provider")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.provider ?? "" }]}
            />
            <TextInput
              header="Quantity"
              width="46%"
              numeric={true}
              height="5.2rem"
              theme="theme-gray"
              onChangeHandler={(input: string) =>
                handleChanges(+input, "quantity")
              }
              value={data.quantity}
              headerStyles={{ fontSize: "1.4rem" }}
             
            />
            <Dropdown
              options={dropDownData?.ashrae_code?.values?.map(
                (item: string) => ({
                  name: item,
                })
              )}
              width="46%"
              heightInput="5.2rem"
              header="ASHRAE Refrigerant Code"
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "ashrae_refrigerant_code")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[
                { name: state?.formData?.ashrae_refrigerant_code ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
        <div className="section-container-consumption">
          <div className="section-header">Article Type</div>
          <br />
          <div className="questions-container">
            <DatePickerComponent
              header="Installation Date"
              width="46%"
              selectedDateCallback={(selectedDate: string) =>
                handleChanges(String(selectedDate), "installation_date")
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.installation_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
            <DatePickerComponent
              header="Operating date"
              width="46%"
              selectedDateCallback={(selectedDate: string) =>
                handleChanges(String(selectedDate), "operating_date")
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.operating_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
            <DatePickerComponent
              header="Disposed Date"
              width="46%"
              selectedDateCallback={(selectedDate: string) =>
                handleChanges(String(selectedDate), "disposed_date")
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.disposed_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
            <DatePickerComponent
              header="Recovered Date"
              width="46%"
              selectedDateCallback={(selectedDate: string) =>
                handleChanges(String(selectedDate), "recovered_date")
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.recovered_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default FugitiveEmission;
