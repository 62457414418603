import React from "react";
import { useState, useEffect } from "react";
import { Graphs } from "../../../../../Components/graphs";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const AttributedEmission = ({ receivedFinancedEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.attributed_emission_by_sector
    ) {
      let tempKeys =
        receivedFinancedEmissionData.attributed_emission_by_sector?.map(
          (el: any) => el.sector
        );
      let tempValues =
        receivedFinancedEmissionData.attributed_emission_by_sector?.map(
          (el: any) => el.financed_emission
        );
      setValues(tempValues);
      setLabels(tempKeys);
    }
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Attributed Emission</>}
      xAxisLabel=""
      yAxisLabel="Emission in MtCO2e"
      labels={labels}
      chartData={[
        {
          label: "",
          values: values,
        },
      ]}
      legendsDisabled={true}
    />
  );
};

export default AttributedEmission;
