import { createSlice } from "@reduxjs/toolkit";

const initialState: { [key: string]: any } = {
    index: null
}

const dataCollectionProgress = createSlice({
    name: 'investee/dataCollectionProgress',
    initialState: initialState,
    reducers: {
        updateDataCollectionProgressAction(state, { payload }) {
            return {
                ...state,
                index: payload.index
            }
        }
    }
})



export const { updateDataCollectionProgressAction } = dataCollectionProgress.actions;

export const dataCollectionProgressReducer = dataCollectionProgress.reducer