//@ import library components
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useId, useLayoutEffect, useState } from "react";

//@ import styles file
import "./App.scss";
import "./defaultStyles.scss";

//@ import custom components
import Layout from "./Pages/Layout/Layout";

//@ utils
import {
  routeWithoutLayoutInvestor,
  routeWithLayoutInvestor,
  typeRoutesConfig,
  routeWithLayoutInvestee,
  routeWithoutLayoutInvestee,
} from "./Routes/routeConfig";
import Loader from "./Components/Core/Loader/Loader.component";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./Redux/store";
import { getAuthTokenVerified } from "./Redux/Slices/Utils/TokenVerification/TokenVerification.slice";
import UnAuthorized from "./Components/Utils/UnauthorisedPage/UnAuthorized";
import useRunOnlyOnce from "./Hooks/useRunOnlyOnce";

//@ interfaces
export interface IAccountingProps {}

const App = ({}: IAccountingProps) => {
  const [cookieVerify, setCookieVerify] = useState<boolean>(true);
  const [hasAccess, setHasAccess] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const routObj = {
    routeWithoutLayout: routeWithoutLayoutInvestee,
    routeWithLayout: routeWithLayoutInvestee,
  };

  const [routes, setRoutes] = useState<{
    routeWithoutLayout: typeRoutesConfig[];
    routeWithLayout: typeRoutesConfig[];
  }>(routObj);

  //^ have a useEffect to update the routes for the user
  // useEffect(() => {setRoutes()},[])

  function getCookie(): string | undefined {
    const value: string = `; ${document.cookie}`;
    const parts: string[] = value.split(`; user_token=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
    return undefined;
  }

  const randomId1 = useId();

  const checkAuthUponLoad = () => {
    if (!localStorage.user_token) {
      const cookie = getCookie();
      if (cookie) {
        dispatch(
          getAuthTokenVerified({ module: "accounting", user_token: cookie })
        ).then((res: any) => {
          const { payload } = res;

          if (res?.error) {
            // send the user back to marketplace
            setHasAccess(false);
            return;
          }

          if (payload.moduleAccess) {
            if (payload.role === "investee") {
              setRoutes({
                routeWithoutLayout: routeWithoutLayoutInvestee,
                routeWithLayout: routeWithLayoutInvestee,
              });
            } else {
              setRoutes({
                routeWithoutLayout: routeWithoutLayoutInvestor,
                routeWithLayout: routeWithLayoutInvestor,
              });
            }
            localStorage.user_token = cookie;
            localStorage.user_data = JSON.stringify(payload);
            document.cookie = `user_token=${localStorage.getItem(
              "user_token"
            )}; expires=${new Date()}; domain=.${
              process.env.REACT_APP_DOMAIN_URL
            }; path=/`;
            navigate("/");
            setHasAccess(true);
            setCookieVerify(false);

            // log the status into console
            const style =
              "color:#0C81A8; font-size:28px; font-weight: bold; -webkit-text-stroke: 0.5px white;";
            console.log("%c User Authentication function executed", style);
          } else {
            // send the user back to marketplace
            // redirect()
            setHasAccess(false);
            setCookieVerify(false);
          }
        });
      } else {
        // send the user back to marketplace
        setCookieVerify(false);
        setHasAccess(false);
        // redirect()
      }
    } else if (localStorage.user_token) {
      const cookie = getCookie();

      if (cookie) {
        localStorage.user_token = cookie;
        document.cookie = `user_token=${localStorage.getItem(
          "user_token"
        )}; expires=${new Date()}; domain=.${
          process.env.REACT_APP_DOMAIN_URL
        }; path=/`;
      }

      //$ new code
      dispatch(
        getAuthTokenVerified({
          module: "accounting",
          user_token: localStorage.user_token,
        })
      ).then((res: any) => {
        const { payload } = res;

        if (res?.error) {
          // send the user back to marketplace
          setHasAccess(false);
          return;
        }

        if (payload.moduleAccess) {
          if (payload.role === "investee") {
            setRoutes({
              routeWithoutLayout: routeWithoutLayoutInvestee,
              routeWithLayout: routeWithLayoutInvestee,
            });
          } else {
            setRoutes({
              routeWithoutLayout: routeWithoutLayoutInvestor,
              routeWithLayout: routeWithLayoutInvestor,
            });
          }
          localStorage.user_data = JSON.stringify(payload);
          setHasAccess(true);
          setCookieVerify(false);

          // log the status into console
          const style =
            "color:#0C81A8; font-size:28px; font-weight: bold; -webkit-text-stroke: 0.5px white;";
          console.log("%c User Authentication function executed", style);
          return;
        } else {
          // send the user back to marketplace
          // redirect()
          setHasAccess(false);
          setCookieVerify(false);
          return;
        }
      });
    }
  };

  useEffect(() => {
    checkAuthUponLoad();
  }, []);

  return (
    <div className="App">
      {cookieVerify ? (
        <Loader hardSetLoader={true} />
      ) : hasAccess ? (
        <Routes>
          {routes.routeWithoutLayout.map((element) => {
            return (
              <Route
                key={element.path}
                path={element.path}
                element={element.component}
              />
            );
          })}

          <Route path="/" element={<Layout />}>
            {routes.routeWithLayout.map((component) => {
              return (
                <Route
                  key={component.path}
                  path={component.path && component.path}
                  index={component.index}
                  element={component.component}
                />
              );
            })}
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <UnAuthorized />
      )}
    </div>
  );
};

export default App;
