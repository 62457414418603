import React, { useEffect, useState } from "react";
import Comparison from "./Comparison";
import "./Benchmarking.scss";
import BenchmarkingHeader from "./BenchMarkingHeader";
import { useSelector } from "react-redux";
import { selectBenchmarkingComparedDataResponse } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingComparedData/getBenchmarkingCompared.selector";

type receivedObj = {};

const AddGraph = () => {
  const benchmarkingComparedData = useSelector(
    selectBenchmarkingComparedDataResponse
  );

  const [
    receivedBenchmarkingComparedData,
    setReceivedBenchmarkingComparedData,
  ] = useState<receivedObj>([]);

  useEffect(() => {
    setReceivedBenchmarkingComparedData(benchmarkingComparedData);
  }, [benchmarkingComparedData]);

  return (
    <>
      <BenchmarkingHeader backBtnEnabled={true} />
      <div className="benchmarking-body-container">
        <Comparison
          mode={"add"}
          receivedBenchmarkingComparedData={receivedBenchmarkingComparedData}
        />
      </div>
    </>
  );
};

export default AddGraph;
