// import libarary components
import { AsyncThunk, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../../Api";

// import initial state
import { initialStateApi } from "../../../../utils";

type payloadType = {
  [key: string]: any;
};

export const postInvestorTargetFormAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "investee/postInvestorTargetFormAction",
    async (payload, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.postInvestorTargetForm(),
          api.http.post(payload)
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.post(payload)
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const postInvestorTargetForm = createSlice({
  name: "postInvestorTargetForm",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postInvestorTargetFormAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postInvestorTargetFormAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(postInvestorTargetFormAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.payload;
        state.isError = true;
      });
  },
});

export default postInvestorTargetForm.reducer;
