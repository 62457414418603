// import library components
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

// import styles file
import "./TargetDetails.scss";

// import custom components
import { scienceBasedTarget } from "../../../../assets/images/images";
import { ReactComponent as Chevron } from "../../../../assets/icons/arrowLeft.svg";
import { Graphs } from "../../../../Components/graphs";

// import redux components
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getTargetDetailsAction } from "../../../../Redux/Slices/InvesteePlatform/getTargetDetails/getTargetDetails.slice";
import { selectTargetDetailsResponse } from "../../../../Redux/Slices/InvesteePlatform/getTargetDetails/getTargetDetails.selector";
import { getInvestorTargetDetailsAction } from "../../../../Redux/Slices/dashboard/targetSetting/investorTargetDetails/getInvestorTargetDetails.slice";
import { selectInvestorTargetDetailsResponse } from "../../../../Redux/Slices/dashboard/targetSetting/investorTargetDetails/getInvestorTargetDetails.selector";
import TableEnhanced from "../../../../Components/Core/TableEnhanced/TableEnhanced";

type Props = {};

const TargetDetails = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const apiResponse = useSelector(selectTargetDetailsResponse);
  const investorapiResponse = useSelector(selectInvestorTargetDetailsResponse);
  const {
    state: { sk },
  } = useLocation();

  const location = useLocation();
  const isInvestor = location.pathname?.includes("/investor/");

  const [targetPageResponse, setTargetPageResponse] = useState<{
    [key: string]: any;
  } | null>(null);
  const [activeScope, setActiveScope] = useState("SCOPE 1");

  useEffect(() => {
    //@ get the id from target homepage and and pass here in the api call
    isInvestor
      ? dispatch(getInvestorTargetDetailsAction({ targetScope: "ALL", id: sk }))
      : dispatch(getTargetDetailsAction({ targetScope: "ALL", id: sk }));
  }, []);

  useEffect(() => {
    if (isInvestor) {
      setActiveScope(investorapiResponse?.data?.target_type);
      setTargetPageResponse(investorapiResponse?.data);
    } else {
      setActiveScope(apiResponse?.data?.target_type);
      setTargetPageResponse(apiResponse?.data);
    }
  }, [apiResponse, investorapiResponse]);

  return (
    <div
      id="TargetDetailsComponent"
      className="target-details-container noScrollBar"
    >
      <div className="header-row-1">
        <div className="section-name">
          <Chevron
            className="chevron-icon"
            onClick={() => window.history.go(-1)}
          />
          Target trajectory
        </div>
        <div className="poweredby-details">
          <div className="label">Powered by:</div>
          <div className="logo">
            <img
              alt="powered by science based targets"
              src={scienceBasedTarget}
            />
          </div>
        </div>
      </div>

      <div className="header-row-2">
        <div className="tile">
          <div className="value">
            {targetPageResponse?.quick_stats?.attributed_emission}
            <span className="unit">Mtco2e</span>
          </div>
          <div className="label">
            <span className="pointer" />
            Attributed Emission
          </div>
        </div>

        <div className="tile">
          <div className="value">
            {targetPageResponse?.quick_stats?.required_reduction}
            <span className="unit">Mtco2e</span>
          </div>
          <div className="label">
            <span className="pointer" />
            Required Reduction
          </div>
        </div>

        <div className="tile">
          <div className="value">
            {targetPageResponse?.quick_stats?.target_year}
            <span className="unit">year</span>
          </div>
          <div className="label">
            <span className="pointer" />
            Net-zero Target Year
          </div>
        </div>
      </div>

      <div className="graph-container">
        {targetPageResponse && (
          <Graphs.LineGraph
            theme="custom"
            themeColors={["#005F76", "#FF2222", "#0CB6FF", "#9DCA5C"]}
            chartData={[
              targetPageResponse?.graph_stats?.historical_data?.map(
                (item: number[]) => item[1]
              ),
              [
                ...targetPageResponse?.graph_stats?.historical_data?.map(
                  (item: number[]) => item[1]
                ),
                ...targetPageResponse?.graph_stats?.current_trajectory?.map(
                  (item: number[]) => item[1]
                ),
              ],
              [
                ...targetPageResponse?.graph_stats?.historical_data?.map(
                  (item: number[]) => item[1]
                ),
                ...targetPageResponse?.graph_stats?.your_target?.map(
                  (item: number[]) => item[1]
                ),
              ],
              [
                ...targetPageResponse?.graph_stats?.historical_data?.map(
                  (item: number[]) => item[1]
                ),
                ...targetPageResponse?.graph_stats?.sbti_target?.map(
                  (item: number[]) => item[1]
                ),
              ],
            ]}
            borderDashArray={[[], [10, 5]]}
            // labels={['2021', '2022', '2023', '2024', '2025', '2026']}
            labels={[
              ...targetPageResponse?.graph_stats?.historical_data?.map(
                (item: number[]) => item[0]
              ),
              ...targetPageResponse?.graph_stats?.sbti_target?.map(
                (item: number[]) => item[0]
              ),
              "2060",
            ]}
            header="Overall Transition Journey "
            legends={[
              "Historical Emission",
              "Your Current Trajectory",
              "Your Target",
              "SBTI Target",
            ]}
            legendsPosition="bottom"
            xAxisLabel=""
            yAxisLabel="Mtco2e"
            maintainAspectRatio={false}
            pointsRadius={2}
          />
        )}
      </div>
      {isInvestor && activeScope === "FINANCED EMISSION" && (
        <div className="tile-table">
          <TableEnhanced tableHeader={headerData} tableData={tableBody} />
        </div>
      )}
    </div>
  );
};

export default TargetDetails;

const headerData: {
  label: string;
  key: string | null;
  option?: "checkbox" | "menu";
  width?: string;
}[] = [
  {
    label: "Investee Name",
    key: "investee_name",
  },
  {
    label: "2021",
    key: "2021",
  },
  {
    label: "2022",
    key: "2021",
  },
  {
    label: "2023",
    key: "2021",
  },
  {
    label: "2024",
    key: "2021",
  },
  {
    label: "2025",
    key: "2021",
  },
];

const tableBody = [
  {
    investee_name: "string",
    2020: "string",
    2021: "string",
    2022: "string",
  },
];
