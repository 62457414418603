import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "../../../../utils";

type payloadType = {};

export const getFinancedEmissionOverviewData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getFinancedEmissionOverviewData",
    async ({}, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getFinancedEmissionOverviewData(),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const financedEmissionOverviewData = createSlice({
  name: "financedEmissionOverviewData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFinancedEmissionOverviewData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getFinancedEmissionOverviewData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getFinancedEmissionOverviewData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default financedEmissionOverviewData.reducer;
