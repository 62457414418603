// import library components
import api from '../../../../Api';
import { createSlice, createAsyncThunk, AsyncThunk, } from '@reduxjs/toolkit';

// iniitial state
import { initialStateApi } from '../../../utils';

//  payload type definition
type payloadType = {
    investee_id: string;
    search_key?: string;
    limit: number;
    next_token?: string
}

export const getOnboardedInvesteeList: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'getOnboardedInvesteeList',
        async ({ investee_id, limit, search_key, next_token }, { rejectWithValue }) => {
            //investor_id, limit, search_key, next_token
            try {
                const response = await fetch(
                    api.config.getOnboardedInvesteelist(investee_id, limit, search_key, next_token),
                    api.http.get()
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const onboardedInvesteeList = createSlice({
    name: 'onboardedInvesteeList',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getOnboardedInvesteeList.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getOnboardedInvesteeList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getOnboardedInvesteeList.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    }
})

export default onboardedInvesteeList.reducer;