// import library components
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// improt styles file
import "./Layout.scss";

// improt custom components
import Header from "./Header/Header";
import SidebarInvestor from "./SidebarV2/SidebarInvestor";
import SidebarInvestee from "./SidebarV2/SidebarInvestee";
import Loader from "../../Components/Core/Loader/Loader.component";

type Props = {};

const Layout = (props: Props): JSX.Element => {
  //$ set the user type upon authentication
  const [userType, setUserType] = useState<"investor" | "investee">("investor");

  const user_data = JSON.parse(localStorage.user_data ?? "{}");
  useEffect(() => {
    if (user_data.role === "investee") {
      setUserType("investee");
    } else {
      setUserType("investor");
    }
  }, [user_data]);

  return (
    <div className="layout-component">
      <Loader />
      <Toaster position="top-right" reverseOrder={true} />
      {/* sidebar section */}
      <div className="sidebar-container">
        {userType === "investor" ? <SidebarInvestor /> : <SidebarInvestee />}
      </div>

      {/* header and profile section */}
      <div className="header-mainview-container">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
