import { Children, useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import RadioInput from "../../../../../../Components/Core/RadioInput/RadioInput";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";

type Props = {};

const PurchasedGoodsServices = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const activityArray = [
    "Supplier Specific",
    "Hybrid Approach",
    "Average Data Method",
    "Spend Based",
  ];
  const [selectedActivity, setSelectedActivity] = useState<number>(0);

  const metricsArray = [
    "Allocation by Mass",
    "Allocation by Volume",
    "Allocation by Energy",
    "Chemical content",
    "Number of units",
    "Market Value",
  ];
  const [metrics, setMetrics] = useState<number>(0);
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<{ [key: string]: any }>({
    name: "",
    location: "",
    supplier_name: "",
    organisation_unit: "",
    method: "supplier_specific",
    product_cradle_to_gate_emissions: "",
    produced: "",
    purchased: "",
    supplier_company_emission: "",
    emissions_factor_for_product_tco2e_per_kg: "",
    vehicle_type: "",
    distance_travelled: "",
    supplier_waste: "",
    waste_treatment_method: "",
    quantity_of_product_purchased: "",
    product_type: "",
  });

  useEffect(() => {
    activityArray.forEach((el, index) => {
      if (el.toLocaleLowerCase() === state?.formData?.method) {
        setSelectedActivity(index);
      }
    });

    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        supplier_name: "",
        organisation_unit: "",
        method: "supplier_specific",
        product_cradle_to_gate_emissions: "",
        produced: "",
        purchased: "",
        supplier_company_emission: "",
        emissions_factor_for_product_tco2e_per_kg: "",
        vehicle_type: "",
        distance_travelled: "",
        supplier_waste: "",
        waste_treatment_method: "",
        quantity_of_product_purchased: "",
        product_type: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3U", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    if (state.scopePath === "purchased_goods_&_services") {
      payload["emission_source"] = "purchased_goods_services";
    }
    if (payload.method === "supplier_specific") {
      delete payload.hybrid_approach;
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(
          `/data-collection/ghg-emission/tables/Purchased Goods & Services`,
          {
            state: {
              scope: "S3U",
              scopePath: "purchased_goods_services",
            },
          }
        );
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              heightInput="5.2rem"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({
                  name: item,
                })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
        <div className="section-container-source">
          <>
            <div className="radio-questions-container">
              <div className="header">Select Method</div>

              <div className="radio-container">
                {Children.toArray(
                  activityArray.map((item: string, index: number) => (
                    <RadioInput
                      label={item}
                      onClickHandler={() => {
                        handleChanges(
                          item.toLowerCase().split(" ").join("_"),
                          "method"
                        );
                        setSelectedActivity(index);
                      }}
                      checked={index === selectedActivity}
                    />
                  ))
                )}
              </div>
            </div>
            <hr className="horizontal-row" />
          </>

          {selectedActivity === 0 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Product Cradle to gate Emissions"
                  width="46%"
                  height="5.2rem"
                  theme="theme-gray"
                  value={data.product_cradle_to_gate_emissions}
                  onChangeHandler={(value) =>
                    handleChanges(value, "product_cradle_to_gate_emissions")
                  }
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Quantity of product puchased"
                  width="46%"
                  height="5.2rem"
                  theme="theme-gray"
                  value={data.quantity_of_product_purchased}
                  onChangeHandler={(value) =>
                    handleChanges(value, "quantity_of_product_purchased")
                  }
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}

          {selectedActivity === 1 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Allocated Emission</div>

                  <div className="radio-container">
                    {Children.toArray(
                      metricsArray.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "mass"
                            );
                            setMetrics(index);
                          }}
                          checked={index === metrics}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {metrics === 0 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total mass of products produced"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Mass of products purchased"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Emission Factor for Product (tco2e/kg)"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.emissions_factor_for_product_tco2e_per_kg}
                      onChangeHandler={(value) =>
                        handleChanges(
                          value,
                          "emissions_factor_for_product_tco2e_per_kg"
                        )
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      heightInput="5.2rem"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />
                    <TextInput
                      type="number"
                      header="Distance Travelled (Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      heightInput="5.2rem"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.waste_treatment_method ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {metrics === 1 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total Volume of products produced"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Volume of products purchased"
                      width="46%"
                      height="5.2rem"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Product Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "product_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.product_type ?? "" },
                      ]}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />

                    <TextInput
                      type="number"
                      header="Distance Travelled (Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.waste_treatment_method ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {metrics === 2 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total Energy or Heat Content Produced"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Energy or Heat Content of Products Purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Product Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "product_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.product_type ?? "" },
                      ]}
                    />
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />
                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Distance Travelled (Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.waste_treatment_method ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {metrics === 3 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total Chemical Content Produced"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Total Chemical Content Purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Product Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "product_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.product_type ?? "" },
                      ]}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />

                    <TextInput
                      type="number"
                      header="Distance Travelled(Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste Type"
                      width="46%"
                      theme="theme-gray"
                      value={data.waste_type}
                      onChangeHandler={(value) =>
                        handleChanges(value, "waste_type")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {metrics === 4 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total Number of Units Produced"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="umber of Units Purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Product Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "product_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.product_type ?? "" },
                      ]}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />

                    <TextInput
                      type="number"
                      header="Distance Travelled(Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste Type"
                      width="46%"
                      theme="theme-gray"
                      value={data.waste_type}
                      onChangeHandler={(value) =>
                        handleChanges(value, "waste_type")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.waste_treatment_method ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {metrics === 5 && (
                <>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Market Value of product purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "produced")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Market Value of Product Purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Supplier Company Emission"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_company_emission}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_company_emission")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Product Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "product_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.product_type ?? "" },
                      ]}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Vehicle Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "vehicle_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.vehicle_type ?? "" },
                      ]}
                    />

                    <TextInput
                      type="number"
                      header="Distance Travelled(Mph)"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste of Supplier(kg)"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_waste}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_waste")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      type="number"
                      header="Waste Type"
                      width="46%"
                      theme="theme-gray"
                      value={data.waste_type}
                      onChangeHandler={(value) =>
                        handleChanges(value, "waste_type")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Waste Treatment Method"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "waste_treatment_method"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.waste_treatment_method ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Quantity of products purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.quantity_of_product_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "quantity_of_product_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}

          {selectedActivity === 2 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Emission Factor for Product (tco2e/kg)"
                  width="46%"
                  theme="theme-gray"
                  value={data.emission_factor}
                  onChangeHandler={(value) =>
                    handleChanges(
                      value,
                      "emissions_factor_for_product_tco2e_per_kg"
                    )
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Quantity of products purchased"
                  width="46%"
                  theme="theme-gray"
                  value={data.quantity_of_product_purchased}
                  onChangeHandler={(value) =>
                    handleChanges(value, "quantity_of_product_purchased")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}

          {selectedActivity === 3 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Emission Factor for Product (tco2e/$)"
                  width="46%"
                  theme="theme-gray"
                  value={data.emissions_factor_for_product_tco2e_per_kg}
                  onChangeHandler={(value) =>
                    handleChanges(
                      value,
                      "emissions_factor_for_product_tco2e_per_kg"
                    )
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  header="Value of Goods purchased"
                  width="46%"
                  theme="theme-gray"
                  value={data.quantity_of_product_purchased}
                  onChangeHandler={(value) =>
                    handleChanges(value, "quantity_of_product_purchased")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default PurchasedGoodsServices;
