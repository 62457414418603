import React from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import DatePickerComponent from "../../../../../../Components/Core/DatePicker/DatePicker";
import countriesData from "../../../../../../utils/data/countries.json";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import { useState, useEffect } from "react";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";

type Props = {};

const IndustrialProcess = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);

  const [dropDownData, setDropDownData] = useState<any>();
  const [data, setData] = React.useState<any>({
    name: "",
    location: "",
    facility_article: "",
    organisation_unit: "",
    source_type: "",
    leak_rate: "",
    provider: "",
    quantity: "",
    ashrae_refrigerant_code: "",
    installation_date_start_date: "",
    operation: "",
    disposed_end_date: "",
    recovered: "",
  });
  const [selectedEqTypeObj, setSelectedEqTypeObj] = useState({
    installation_leak_rate: 0,
    operating_leak_rate: 0,
    disposal_leak_rate: 0,
    recovery_leak_rate: 0,
  });

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  const handleChanges = (value: string, key: string): void => {
    let leakRate = 0;
    if (key === "source_type") {
      setSelectedEqTypeObj(
        dropdownContentList?.data?.equipment_type.values?.filter(
          (el: any) => el.name === value
        )[0]
      );
    } else if (key === "installation_date") {
      leakRate = selectedEqTypeObj?.installation_leak_rate;
    } else if (key === "operating_date") {
      leakRate = selectedEqTypeObj?.operating_leak_rate;
    } else if (key === "disposed") {
      leakRate = selectedEqTypeObj?.disposal_leak_rate;
    } else if (key === "recovered") {
      leakRate = selectedEqTypeObj?.recovery_leak_rate;
    }
    setData((prevValue: any) => {
      return {
        ...prevValue,
        leak_rate: leakRate,
        [key]: value,
      };
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })}
              width="46%"
              header="Location"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={dropDownData?.facility_type?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Facility Type"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "facility_article")
              }
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "facility_article");
              }}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Organisational unit"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              selectedCallBack={(selectedOption: any) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <div className="section-header">Source</div>
          <br />
          <div className="questions-container">
            <Dropdown
              options={[{ name: "one" }, { name: "two" }, { name: "three" }]}
              width="46%"
              header="Industrial Process Type"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={[{ name: "one" }, { name: "two" }, { name: "three" }]}
              width="46%"
              header="Materials"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={[{ name: "one" }, { name: "two" }, { name: "three" }]}
              width="46%"
              header="Quantity Unit"
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <TextInput
              header="Quantity"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              value={data.quantity}
              headerStyles={{ fontSize: "1.4rem" }}
              placeholder="quantity"
              disabled={false}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
        <div className="section-container-consumption">
          <div className="section-header">Fuel Consumption</div>
          <br />
          <div className="questions-container">
            <DatePickerComponent
              header="Start Date"
              width="46%"
              inputStyles={{ height: "5.5rem" }}
            />
            <DatePickerComponent
              header="End Date"
              width="46%"
              inputStyles={{ height: "5.5rem" }}
            />
          </div>
        </div>
      </div>

      <Button label="Save" className="primary-button save-btn" />
    </div>
  );
};

export default IndustrialProcess;
