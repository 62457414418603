import { useState, useEffect } from "react";
import { Graphs } from "../../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionPerFacilities = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.emission_per_facilities
    ) {
      let tempLabels = receivedGhgEmissionData.emission_per_facilities?.map(
        (el: any) => el.facility_name
      );
      let tempValues = receivedGhgEmissionData.emission_per_facilities?.map(
        (el: any) => el.total_emission_per_facility
      );
      setValues(tempValues);
      setLabels(tempLabels);
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Emission per Facilities</>}
      xAxisLabel=""
      yAxisLabel="Emissions (MtCO2e)"
      labels={labels}
      chartData={[
        {
          label: "Emission",
          values: values,
        },
      ]}
      maintainAspectRatio={true}
      xAxisLabelVisibility={false}
      datalabels={{
        datalabels: {
          display: true,
          anchor: "end",
          color: "white",
          borderRadius: 14,
          backgroundColor: (context: any) => {
            // Dynamically set the label color based on the bar's background color
            const backgroundColor = context.dataset.backgroundColor;
            return backgroundColor;
          },
          formatter: (value: any) => {
            return "    ";
          },
        },
      }}
      borderWidth={10}
      barThickness={25}
      // extraAxes={{
      //   y1: {
      //     stacked: false,
      //     position: "right",
      //     border: {},
      //     ticks: {
      //       color: "#7B91B0",
      //     },
      //     title: {
      //       display: true,
      //       color: "#7B91B0",
      //       text: "Energy (KWh)",
      //     },
      //     grid: {
      //       drawOnChartArea: false,
      //     },
      //   },
      // }}
      xGridVisibility={false}
      legendsPosition="bottom"
    />
  );
};

export default EmissionPerFacilities;
