// import library components
import React, { useEffect, useState } from "react";

// improt styles file
import "./ToggleSwitch.scss";

// type definitions
type Props = {
  switchState?: string[];
  onSwitchChange?: (item: any) => void;
  btnTheme?: keyof typeof buttonTheme;
  selectedValue?: number;
  buttonClassnameExtra?: string;
  toggleSwitchClassnameExtra?: string;
  buttonActiveClassnameExtra?: string;
};

type styleType = {
  backgroundColor: string;
};

type btnTheme = {
  gray: styleType;
};

const buttonTheme = {
  gray: {
    backgroundColor: "#F5F5F5",
  },
  light: {
    backgroundColor: "#FFF",
  },
};

const ToggleSwitch = ({
  switchState,
  onSwitchChange,
  btnTheme = "light",
  selectedValue = 0,
  buttonClassnameExtra = "",
  toggleSwitchClassnameExtra = "",
  buttonActiveClassnameExtra = "",
}: Props) => {
  const [defaultState, setDefaultState] = useState(
    switchState || ["on", "off"]
  );
  const [selectedState, setSelectedState] = useState<number>(
    selectedValue || 0
  );

  useEffect(() => {
    setSelectedState(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setDefaultState(switchState ?? ["on", "off"]);
  }, [switchState]);

  const toggleSwitchHandler = (item: any) => {
    // setSelectedState(() => item)
    // have a callback to uplift the state to the parentcomponent below
    onSwitchChange && onSwitchChange(item);
  };

  return (
    <div
      className={`toggle-switch-component ${toggleSwitchClassnameExtra}`}
      style={{ backgroundColor: buttonTheme[btnTheme].backgroundColor }}
    >
      {defaultState.map((item, index) => {
        return (
          <button
            style={{ backgroundColor: buttonTheme[btnTheme].backgroundColor }}
            key={item}
            type="button"
            className={`toggle-switch-button ${
              index === selectedState
                ? `active ${buttonActiveClassnameExtra}`
                : ""
            } ${buttonClassnameExtra}`}
            onClick={() => toggleSwitchHandler(index)}
          >
            {item}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleSwitch;
