// import library components
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import custom components
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import DatePickerComponent from "../../../../../../Components/Core/DatePicker/DatePicker";

// import redux components
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";

// import utils component
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import countriesData from "../../../../../../utils/data/countries.json";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import moment from "moment";
import { formatDateString } from "../../../../../../utils/helpers/dateHandlers";

type Props = {};

const MobileCombustion = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);

  const [data, setData] = useState<any>({
    name: "",
    location: "",
    facility_article: "",
    organisation_unit: "",
    vehicle_type: "",
    distance: "",
    distance_unit: "",
    fuel_type: "",
    fuel_quantity_unit: "",
    fuel_quantity: "",
    consumption_period_start_date: "",
    consumption_period_end_date: "",
  });
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);

  useEffect(() => {
    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        facility_article: "",
        organisation_unit: "",
        source_type: "",
        leak_rate: "",
        provider: "",
        quantity: "",
        ashrae_refrigerant_code: "",
        installation_date: "",
        operation: "",
        disposed_date: "",
        recovered_date: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string | number, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload;

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: state.scope, // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(`/data-collection/ghg-emission/tables/Mobile Combustion`, {
          state: {
            scope: "S1",
            scopePath: "mobile_combustion",
          },
        });
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              onChangeHandler={(input) => handleChanges(input, "name")}
              value={data.name}
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              heightInput="5.2rem"
              header="Location"
              searchIncluded
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.facility_type?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Facility Type"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "facility_article")
              }
              addButtonIncluded
              searchIncluded
              headerStyles={{ fontSize: "1.4rem" }}
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "facility_article");
              }}
              defaultOption={[
                { name: state?.formData?.facility_article ?? "" },
              ]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
        <div className="section-container-source">
          <div className="section-header">Source</div>
          <br />
          <div className="questions-container">
            <Dropdown
              options={dropDownData?.fuel_type?.values?.map((item: any) => ({
                name: item?.fuel_type,
              }))}
              width="46%"
              heightInput="5.2rem"
              header="Fuel Type"
              selectedCallBack={(selectedOption) => {
                let selectedFuelType = dropDownData.fuel_type?.values?.filter(
                  (el: any) => el.fuel_type === selectedOption[0].name
                );
                setVehicleTypeOptions(selectedFuelType[0]?.vehicle_type ?? []);
                handleChanges(selectedOption[0].name, "fuel_type");
              }}
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.fuel_type ?? "" }]}
            />
            <Dropdown
              options={vehicleTypeOptions?.map((el) => ({ name: el })) ?? []}
              width="46%"
              heightInput="5.2rem"
              header="Vehicle Type"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "vehicle_type")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.vehicle_type ?? "" }]}
            />
            <TextInput
              header="Fuel quantity"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              value={data.fuel_quantity}
              headerStyles={{ fontSize: "1.4rem" }}
              placeholder="Fuel quantity"
              disabled={false}
              onChangeHandler={(input) =>
                handleChanges(+input, "fuel_quantity")
              }
            />
            <Dropdown
              options={[{ name: "Litres" }, { name: "Gallons" }]}
              width="46%"
              heightInput="5.2rem"
              header="Unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "fuel_quantity_unit")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[
                { name: state?.formData?.fuel_quantity_unit ?? "" },
              ]}
            />
            <TextInput
              header="Distance"
              width="46%"
              height="5.2rem"
              theme="theme-gray"
              value={data.distance}
              headerStyles={{ fontSize: "1.4rem" }}
              placeholder="Distance"
              disabled={false}
              onChangeHandler={(input) => handleChanges(+input, "distance")}
            />
            <Dropdown
              options={[{ name: "Kilometers" }, { name: "Miles" }]}
              width="46%"
              heightInput="5.2rem"
              header="Distance Unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "distance_unit")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.distance_unit ?? "" }]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
        <div className="section-container-consumption">
          <div className="section-header">Consumption Period</div>
          <br />
          <div className="questions-container">
            <DatePickerComponent
              header="Start Date"
              width="46%"
              selectedDateCallback={(date: string) => {
                handleChanges(
                  formatDateString(date),
                  "consumption_period_start_date"
                );
              }}
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.consumption_period_start_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
            <DatePickerComponent
              header="End Date"
              width="46%"
              selectedDateCallback={(date: string) => {
                handleChanges(
                  formatDateString(date),
                  "consumption_period_end_date"
                );
              }}
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.consumption_period_end_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default MobileCombustion;
