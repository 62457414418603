import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../../Components/graphs";
import DropDown from "../../../../../Components/Core/DropDown/DropDown";

type ghgEmissionDataObj = {
  [key: string]: any;
};

type Props = {
  radioBtnArr?: any[];
  receivedGhgEmissionData?: ghgEmissionDataObj;
};

const Facilities = ({ radioBtnArr, receivedGhgEmissionData }: Props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    const activeXaxis = radioBtnArr?.filter((el: any) => el.checked)[0].text;
    if (activeXaxis === "Facility Type") {
      let labelsArr = receivedGhgEmissionData?.emission_per_facilities.map(
        (el: any) => el.facility_name
      );
      let valuesArr = receivedGhgEmissionData?.emission_per_facilities.map(
        (el: any) => el.total_emission_per_facility
      );
      setLabels(labelsArr);
      setValues(valuesArr);
    } else if (activeXaxis === "Organization Unit") {
      let labelsArr =
        receivedGhgEmissionData?.emission_by_organisation_unit.map(
          (el: any) => el.organisation_unit
        );
      let valuesArr =
        receivedGhgEmissionData?.emission_by_organisation_unit.map(
          (el: any) => el.total_emission_co2e
        );
      setLabels(labelsArr);
      setValues(valuesArr);
    } else {
      setLabels([
        "2020",
        "2021",
        "2022",
        "2023",
        "2020",
        "2021",
        "2022",
        "2023",
        "2020",
        "2021",
        "2022",
        "2023",
      ]);
    }
  }, [radioBtnArr, receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      header={
        <div className="card-header-container">
          <div>Facilities</div>
          <div>
            <DropDown
              optionsList={["2023", "2022"]}
              dropDownContainerClassName="dropdown-container"
            />
          </div>
        </div>
      }
      legendsPosition="bottom"
      xAxisLabel="Year"
      yAxisLabel="Emission in MtCO2e"
      labels={labels}
      chartData={[
        {
          label: "Energy",
          values: values,
        },
      ]}
      datalabels={{
        datalabels: {
          display: true,
          anchor: "end",
          color: "white",
          borderRadius: 14,
          backgroundColor: "#9DCA5C",
          formatter: (value: any) => {
            return "    ";
          },
        },
      }}
      xAxisLabelVisibility={false}
      xGridVisibility={false}
      yAxisVisibility={false}
      borderWidth={10}
      barThickness={25}
      barColor="rgba(157, 202, 92, 0.40)"
    />
  );
};

export default Facilities;
