// import library components
import React, { useEffect, useState } from "react";

// improt styles file
import "./GhgEmission.scss";
import ToggleSwitch from "../../../Components/Core/ToggleSwitch/ToggleSwitch";
import Scope1 from "./Scope1/Scope1-Component";
import Scope2 from "./Scope2/Scope2-Component";
import Scope3 from "./Scope3/Scope3-Component";
import AnalyticsComponent from "./Analytics/Analytics-Component";
import { ReactComponent as GreenhouseGasProtocol } from "../../../assets/icons/greenhouseGasProtocol.svg";

// improt custom components

//type definition for props
type Props = {};

const GhgEmission = (props: Props) => {
  const switchTabs = ["Scope 1", "Scope 2", "Scope 3", "Analytics"];
  const [selectedScope, setSelectedScope] = useState(0);

  return (
    <div className="dashboard-ghgemission-container-component noScrollBar">
      <ToggleSwitch
        selectedValue={selectedScope}
        switchState={switchTabs}
        onSwitchChange={setSelectedScope}
      />
      <div className="content-header">
        <div className="headline">
          <div>GHG Emission {switchTabs[selectedScope]}</div>
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <GreenhouseGasProtocol />
            </div>
          </div>
        </div>
      </div>

      {selectedScope === 0 && <Scope1 />}
      {selectedScope === 1 && <Scope2 />}
      {selectedScope === 2 && <Scope3 />}
      {selectedScope === 3 && <AnalyticsComponent />}
    </div>
  );
};

export default GhgEmission;
