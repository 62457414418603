// import lobrary components
import { useState, useEffect } from "react";

// import custom components
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import DatePickerComponent from "../../../../../../Components/Core/DatePicker/DatePicker";
import ToggleSwitch from "../../../../../../Components/Core/ToggleSwitch/ToggleSwitch";

// import redux components
import { useLocation, useNavigate } from "react-router-dom";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";

// import utils
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import countriesData from "../../../../../../utils/data/countries.json";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import { formatDateString } from "../../../../../../utils/helpers/dateHandlers";
import moment from "moment";
import "./PurchasedElectricity.scss";

type Props = {};

const PurchasedElectricity = (props: Props) => {
  const [selectedForm, setSelectedForm] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    location: "",
    facility_article: "",
    organisation_unit: "",
    source_based_on: "Location",
    location_based_grid_name: "",
    quantity_purchased: "",
    location_based_unit: "",
    market_based_supplier_name: "",
    market_based_source: "",
    consumption_period_start_date: "",
    consumption_period_end_date: "",
  });

  useEffect(() => {
    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        facility_article: "",
        organisation_unit: "",
        source_based_on: "Location",
        location_based_grid_name: "",
        quantity_purchased: "",
        location_based_unit: "",
        market_based_supplier_name: "",
        market_based_source: "",
        consumption_period_start_date: "",
        consumption_period_end_date: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string | number, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload;

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S2", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(`/data-collection/ghg-emission/tables/Purchased Electricity`, {
          state: {
            scope: "S2",
            scopePath: "purchased_electricity",
          },
        });
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              theme="theme-gray"
              value={data.name}
              headerStyles={{ fontSize: "1.4rem" }}
              height="5.2rem"
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              heightInput="5.2rem"
              header="Location"
              searchIncluded
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.facility_type?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Facility Type"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "facility_article")
              }
              headerStyles={{ fontSize: "1.4rem" }}
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "facility_type");
              }}
              addButtonIncluded
              defaultOption={[
                { name: state?.formData?.facility_article ?? "" },
              ]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              heightInput="5.2rem"
              header="Organisational unit"
              headerStyles={{ fontSize: "1.4rem" }}
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <div className="section-header">
            <span>Source</span>

            <div className="toggle">
              <ToggleSwitch
                selectedValue={selectedForm}
                onSwitchChange={(index) => {
                  let toggleSwitchArr = ["Location", "Market"];
                  setData((prevData: any) => {
                    prevData["source_based_on"] = toggleSwitchArr[index];
                    return prevData;
                  });
                  setSelectedForm(index);
                }}
                switchState={["Location based", "Market based"]}
                btnTheme="gray"
              />
            </div>
          </div>
          <br />

          {selectedForm === 0 ? (
            // location based
            <div className="questions-container">
              <Dropdown
                options={dropDownData?.grid_name?.values?.map(
                  (item: string) => ({ name: item })
                )}
                width="46%"
                heightInput="5.2rem"
                header="Grid name"
                selectedCallBack={(selectedOption) =>
                  handleChanges(
                    selectedOption[0].name,
                    "location_based_grid_name"
                  )
                }
                defaultOption={[
                  { name: state?.formData?.location_based_grid_name ?? "" },
                ]}
              />
              <TextInput
                header="Quantity purchased (Kwh)"
                width="46%"
                height="5.2rem"
                theme="theme-gray"
                onChangeHandler={(input) =>
                  handleChanges(+input, "quantity_purchased")
                }
                value={data.quantity_purchased}
                headerStyles={{ fontSize: "1.4rem" }}
              />
            </div>
          ) : (
            // market based
            <div className="questions-container">
              <Dropdown
                options={dropDownData?.supplier_name?.values?.map(
                  (item: string) => ({ name: item })
                )}
                width="46%"
                heightInput="5.2rem"
                header="Supplier name"
                selectedCallBack={(selectedOption) =>
                  handleChanges(
                    selectedOption[0].name,
                    "market_based_supplier_name"
                  )
                }
                addButtonIncluded
                addClickHandler={(enteredOption) => {
                  updatDropdownListHandler(enteredOption, "supplier_name");
                }}
                headerStyles={{ fontSize: "1.4rem" }}
                defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
              />
              <TextInput
                header="Quantity purchased (Kwh)"
                width="46%"
                height="5.2rem"
                theme="theme-gray"
                onChangeHandler={(input) =>
                  handleChanges(input, "quantity_purchased")
                }
                value={data.quantity_purchased}
                headerStyles={{ fontSize: "1.4rem" }}
              />
              <TextInput
                header="Energy source"
                width="46%"
                height="5.2rem"
                theme="theme-gray"
                onChangeHandler={(input) =>
                  handleChanges(input, "market_based_source")
                }
                value={data.market_based_source}
                headerStyles={{ fontSize: "1.4rem" }}
              />
            </div>
          )}

          <hr className="horizontal-row" />
        </div>
        <div className="section-container-consumption">
          <div className="section-header">Consumption Period</div>
          <br />
          <div className="questions-container">
            <DatePickerComponent
              header="Start Date"
              width="49%"
              selectedDateCallback={(selectedDate) =>
                handleChanges(
                  formatDateString(selectedDate),
                  "consumption_period_start_date"
                )
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.consumption_period_start_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
            <DatePickerComponent
              header="End Date"
              width="49%"
              selectedDateCallback={(selectedDate) =>
                handleChanges(
                  formatDateString(selectedDate),
                  "consumption_period_end_date"
                )
              }
              inputStyles={{ height: "5.5rem" }}
              value={moment(
                state?.formData?.consumption_period_end_date ?? "23/03/23",
                "DD/MM/YY"
              ).format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default PurchasedElectricity;
