// import library components
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// import styles file
import "./DataCollectionForms.scss";

// import custom components
import MainContentWrapper from "../../../../../Components/Core/MainContent/ContentWrapper";
import { ReactComponent as Arrow } from "../../../../../assets/icons/backArrow.svg";
import FugitiveEmission from "../Scope1/FugitiveEmission/FugitiveEmission";
import MobileCombustion from "../Scope1/Mobilecombustion/MobileCombustion";
import StationaryCombustion from "../Scope1/StationaryCombustion/StationaryCombustion";
import PurchasedCooling from "../Scope2/PurchasedCooling/PurchasedCooling";
import IndustrialProcess from "../Scope1/IndustrialProcess/IndustrialProcess";
import PurchasedElectricity from "../Scope2/PurchasedElectricity/PurchasedElectricity";
import PurchasedHeat from "../Scope2/PurchasedHeat/PurchasedHeat";
import PurchasedSteam from "../Scope2/PurchasedSteam/PurchasedSteam";
import TransportationDistribution from "../Scope3/TransportationDistribution/TransportationDistribution";
import PurchasedGoodsServices from "../Scope3/PurchasedGoodsServices/PurchasedGoodsServices";
import FuelEnergy from "../Scope3/FuelEnergy/FuelEnergy";
import BusinessTravel from "../Scope3/BusinessTravel/BusinessTravel";
import EmployeeCommuting from "../Scope3/EmployeeCommuting/EmployeeCommuting";
import WasteGenerated from "../Scope3/WasteGenerated/WasteGenerated";
import ProcessingSoldProducts from "../Scope3/ProcessingSoldProducts/ProcessingSoldProducts";
import UseofSoldProducts from "../Scope3/UseofSoldProducts/UseofSoldProducts";
import EndofLifeTreatment from "../Scope3/EndofLifeTreatment/EndofLifeTreatment";
import Franchises from "../Scope3/Franchises/Franchises";
import LeasedAssets from "../Scope3/LeasedAssets/LeasedAssets";

type Props = {};

const DataCollectionForms = (props: Props) => {
  const navigate = useNavigate();

  const { formSection } = useParams();

  const backNavigationHandler = (): void => {
    window.history.go(-1);
  };

  return (
    <>
      <div className="datacollection-forms-container-header">
        <div className="form-name">
          <Arrow onClick={backNavigationHandler} />
          {formSection}
        </div>
      </div>
      <MainContentWrapper customClassName="datacollection-card-container">
        <>
          {formSection === "Fugitive Emission" && <FugitiveEmission />}
          {formSection === "Mobile Combustion" && <MobileCombustion />}
          {formSection === "Stationary Combustion" && <StationaryCombustion />}
          {formSection === "Industrial Process" && <IndustrialProcess />}
          {formSection === "Purchased Cooling" && <PurchasedCooling />}
          {formSection === "Purchased Electricity" && <PurchasedElectricity />}
          {formSection === "Purchased Heat" && <PurchasedHeat />}
          {formSection === "Purchased Steam" && <PurchasedSteam />}
          {formSection === "Transportation & Distribution" && (
            <TransportationDistribution />
          )}
          {formSection === "Purchased Goods & Services" && (
            <PurchasedGoodsServices />
          )}
          {formSection === "Capital Goods" && <PurchasedGoodsServices />}
          {formSection === "Fuel & Energy Related" && <FuelEnergy />}
          {formSection === "Business Travel" && <BusinessTravel />}
          {formSection === "Employee commuting" && <EmployeeCommuting />}
          {formSection === "Waste Generated In Operations" && (
            <WasteGenerated />
          )}
          {formSection === "Processing Of Sold Products" && (
            <ProcessingSoldProducts />
          )}
          {formSection === "Use Of Sold Products" && <UseofSoldProducts />}
          {formSection === "End of life treatment of sold products" && (
            <EndofLifeTreatment />
          )}
          {formSection === "Franchises" && <Franchises />}
          {formSection === "Leased Assets" && <LeasedAssets />}
        </>
      </MainContentWrapper>
    </>
  );
};

export default DataCollectionForms;
