export function stringToSnakeCase(receivedString: string): string {
  if (receivedString) {
    return receivedString.toLowerCase().replace(" ", "_");
  }

  return "";
}

export function snakeToPascalCase(receivedString: string): string {
  if (receivedString) {
    return receivedString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return "";
}
