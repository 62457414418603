import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LogarithmicScale,
} from "chart.js";
import { colors } from "../../../DesignSystem/Colors/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReactNode } from "react";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LogarithmicScale
);

type chartData = {
  label: string;
  values: number[];
};

type Props = {
  labels: string[];
  xAxisLabel?: string;
  yAxisLabel?: string;
  header?: ReactNode;
  orientationHorizontal?: boolean;
  chartData: chartData[];
  legendsDisabled?: boolean;
  maintainAspectRatio?: boolean;
  yAxisLabelVisibility?: boolean;
  xAxisLabelVisibility?: boolean;
  extraAxes?: {};
  xGridVisibility?: boolean;
  yGridVisibility?: boolean;
  datalabels?: {};
  barThickness?: number;
  borderWidth?: number;
  barColor?: string;
  yAxisVisibility?: boolean;
  legendsPosition?: "top" | "bottom" | "left" | "right";
};

const BarGraph = ({
  labels,
  header,
  orientationHorizontal,
  chartData,
  xAxisLabel,
  yAxisLabel,
  legendsDisabled = false,
  maintainAspectRatio = true,
  yAxisLabelVisibility = true,
  xAxisLabelVisibility = true,
  extraAxes = {},
  xGridVisibility = true,
  yGridVisibility = true,
  datalabels = { datalabels: { display: false } },
  barThickness = 25,
  borderWidth = 0,
  barColor = "",
  yAxisVisibility = true,
  legendsPosition = "top",
}: Props) => {
  const memoisedChartData = React.useMemo(() => {
    let newarray = [];

    for (let index = 0; index < chartData.length; index++) {
      const element = chartData[index];
      newarray.push({
        label: element.label,
        data: element.values,
        backgroundColor:
          barColor.length === 0
            ? colors.primaryColors[index] ?? "red"
            : barColor,
        borderRadius: 5,
        barThickness: barThickness,
        borderColor: "rgba(0,0,0,0)",
        borderWidth: borderWidth, // To create spacing between bars
      });
    }
    return newarray;
  }, [chartData]);

  return (
    <>
      <div
        className="chart-header"
        style={{ margin: "1.2rem", fontWeight: 500, fontSize: "1.6rem" }}
      >
        {header}
      </div>
      <Bar
        data={{
          labels: labels,
          datasets: memoisedChartData,
        }}
        plugins={[ChartDataLabels]}
        options={{
          maintainAspectRatio: maintainAspectRatio,
          indexAxis: orientationHorizontal ? "y" : "x",
          scales: {
            x: {
              stacked: false,
              title: {
                display: xAxisLabelVisibility,
                color: "#7B91B0",
                text: xAxisLabel,
              },
              ticks: {
                color: "#7B91B0",
              },
              grid: {
                display: xGridVisibility,
              },
            },
            y: {
              // type: "logarithmic",
              border: {
                display: yAxisVisibility,
              },
              stacked: false,
              title: {
                display: yAxisLabelVisibility,
                color: "#7B91B0",
                text: yAxisLabel,
              },
              ticks: {
                color: "#7B91B0",
                // minRotation: 20,
              },
              grid: {
                display: yGridVisibility,
              },
            },
            ...extraAxes,
          },
          responsive: true,
          plugins: {
            ...datalabels,
            legend: {
              display: !legendsDisabled,
              position: legendsPosition,
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                useBorderRadius: true,
                // borderRadius: 0
              },
            },
            title: {
              display: false,
              text: "Chart.js Bar Chart",
            },
          },
          elements: {
            bar: {
              // inflateAmount: -1,
              borderColor: "#fff",
              borderWidth: 1,
              borderSkipped: "start",
            },
          },
          layout: {
            padding: {
              top: 17,
            },
          },
        }}
      />
    </>
  );
};

export default BarGraph;
