// improt library components
import React, { ChangeEvent, useEffect, useState } from "react";

// import custom components
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus-blue.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit-icon2.svg";
import DialogueModal from "../../../../../Components/Core/DialogueModal/DialogueModal";

import TextInput from "../../../../../Components/Core/TextInput/TextInput";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { updateAssetClassAction } from "../../../../../Redux/Slices/financedEmission/updateAssetClass/updateAssetClass.slice";
import { toastifyAsync } from "../../../../../utils/notifications/toastifyAsync";

type subAssetClassType = {
  sub_asset_class: string;
  total_sub_asset_amount: number;
};

type assetObject1Type = {
  aum_assetclass: string;
  aum_dollars: number | string;
  sub_asset: subAssetClassType[];
};

type Props = {
  fieldData?: any;
  deleteAssetClass?: (index: number) => void;
  position: number;
  onFocusOutHandler?: (object: assetObject1Type, index: number) => void;
  setSelectedAssetClass?: (index: any) => void;
  setDeleteModal?: (index: boolean) => void;
  setEditAssetClassModalVisibility?: (index: boolean) => void;
  setAssetClassName?: (index: any) => void;
  setAumValue?: (index: any) => void;
};

const InputFieldAssetClass = ({
  fieldData,
  position,
  setSelectedAssetClass,
  setDeleteModal,
  setEditAssetClassModalVisibility,
  setAssetClassName,
  setAumValue,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [subAssetModalShow, setSubAssetModalShow] = useState(false);
  const [assetClassDetails, setAssetClassDetails] = useState(fieldData);
  const [subAssetClassDetails, setSubAssetClassDetails] = useState(
    fieldData.sub_asset
  );
  const [subAssetClassData, setSubAssetClassData] =
    useState<subAssetClassType>(defaultSubAssetData);

  const onSubAssetSubmitClick = () => {
    let subAssetClassObj = {} as any;

    [...subAssetClassDetails, subAssetClassData].forEach(
      (el) => (subAssetClassObj[el.sub_asset_class] = el.total_sub_asset_amount)
    );

    dispatch(
      updateAssetClassAction({
        action: "EDIT",
        pk: fieldData?.pk,
        sk: fieldData?.sk,
        aum_assetclass: fieldData.aum_assetclass,
        aum_dollars: fieldData.aum_dollars,
        sub_asset_class: subAssetClassObj,
      })
    )
      .then((res) => {
        if (!res.payload.is_error) {
          setSubAssetClassDetails(() => [
            ...subAssetClassDetails,
            subAssetClassData,
          ]);
        }
        toastifyAsync(res);
      })
      .catch((err) => {})
      .finally(() => {});

    // resetting the states
    setSubAssetClassData(defaultSubAssetData);
    setSubAssetModalShow(false);
  };

  const deleteClickHandler = () => {
    setDeleteModal?.(true);
    setSelectedAssetClass?.(position);
  };

  const editClickHandler = () => {
    setEditAssetClassModalVisibility?.(true);
    setSelectedAssetClass?.(position);
    setAssetClassName?.(assetClassDetails?.aum_assetclass);
    setAumValue?.(assetClassDetails?.aum_dollars);
  };

  return (
    <div className="form-field">
      <div className="asset-class-edit-btn" onClick={editClickHandler}>
        <EditIcon />
      </div>
      <div className="delete-btn" onClick={deleteClickHandler}>
        <DeleteIcon className="delete-icon" />
      </div>
      <div className="form-container">
        <div className="input-container">
          <div className="input-label">Asset class</div>
          <div className="input-field">{assetClassDetails?.aum_assetclass}</div>
        </div>
        <div className="input-container">
          <div className="input-label">Total AUM of the Asset Class ($M)</div>
          <div className="input-field">{assetClassDetails?.aum_dollars}</div>
        </div>
      </div>
      <div className="sub-asset-container">
        <div className="sub-asset-header">
          Sub-Asset Classes and there respective AUM
        </div>
        <div className="input-like-field">
          {subAssetClassDetails &&
            subAssetClassDetails?.map((subAsset: any, xandex: number) => {
              return (
                <div
                  key={subAsset.sub_asset_class + xandex}
                  className="placeholder-with-data data-tile "
                >
                  <div className="tag">{subAsset?.sub_asset_class}:</div>
                  <div>${subAsset?.total_sub_asset_amount} M</div>
                </div>
              );
            })}
          <div
            className="placeholder-add-item data-tile "
            onClick={() => setSubAssetModalShow(true)}
          >
            <PlusIcon /> Add sub-asset class
          </div>
        </div>
      </div>
      {subAssetModalShow && (
        <DialogueModal
          callback={setSubAssetModalShow}
          header="Add Sub Asset Class"
          onSubmit={onSubAssetSubmitClick}
          customClassname="assetClassModel"
        >
          <div className="modal-dialogue-body">
            <TextInput
              header="Name of the Sub-Asset Class"
              placeholder="Enter the Sub-asset class name"
              value={subAssetClassData.sub_asset_class}
              onChangeHandler={(input) =>
                setSubAssetClassData((prev) => ({
                  ...prev,
                  sub_asset_class: input,
                }))
              }
            />
            <br />
            <TextInput
              type="number"
              header="Total AUM of the Sub-Asset Class ($M)"
              placeholder="Enter the AUM of Sub-asset class"
              value={subAssetClassData.total_sub_asset_amount}
              onChangeHandler={(input) =>
                setSubAssetClassData((prev) => ({
                  ...prev,
                  total_sub_asset_amount: Number(input),
                }))
              }
            />
          </div>
        </DialogueModal>
      )}
    </div>
  );
};

export default InputFieldAssetClass;

const defaultSubAssetData = {
  sub_asset_class: "",
  total_sub_asset_amount: 0,
};
