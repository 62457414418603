// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../../store";

// create base selector for reducer
const selectInvesteeDashboardInvesteeStore = (state: RootState) => state.investeeHomepageData;

export const selectInvesteeDashboardInvesteeResponse = createSelector(
    [selectInvesteeDashboardInvesteeStore],
    (hompageData) => hompageData.response
);
