export const getTableArr = (url?: string, extraColumns?: any) => {


  let tableArr: {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu" | "popup";
    customStyles?: boolean;
    popupContent?: any;
  }[] = [];

  if (url === "/data-collection/ghg-emission/tables/Fugitive%20Emission") {
    tableArr = [
      {
        label: " ",
        key: "",
        option: "checkbox",
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Location",
        key: "location",
      },
      {
        label: "Facility Type",
        key: "facility_article",
      },
      {
        label: "Organisation Unit",
        key: "organisation_unit",
      },
      {
        label:"Equipment Type",
        key:""
      },
      {
        label:"Leak Rate",
        key:"leak_rate",
      },
      {
        label: "Provider",
        key: "provider",
      },
      {
        label: "Quantity",
        key: "quantity",
      },
      {
        label: "Ashrae refrigerant code",
        key: "ashrae_refrigerant_code",
      },
      {
        label: "Installation date",
        key: "installation_date",
      },
      {
        label:"Operation",
        key:"operation",
      },
      {
        label:"Disposed",
        key:"disposal_emission",
      },
      {
        label:"Recovered",
        key:"recovered"
      },

      { ...extraColumns },
    ];
  } else if(url === "/data-collection/ghg-emission/tables/Mobile%20Combustion") {
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Fuel Type",
          key:"fuel_type"
        },
        {
          label:"Vehicle Type",
          key:"vehicle_type",
        },
        {
          label: "Fuel Quantity",
          key: "fuel_quantity",
        },
        {
          label: "Quantity Unit",
          key: "fuel_quantity_unit",
        },
        {
          label: "Distance",
          key: "distance",
        },
        {
          label: "Distance Unit",
          key: "distance_unit",
        },
        {
          label:"Start Day",
          key:"consumption_period_start_date",
        },
        {
          label:"End Day",
          key:"disposal_emissionconsumption_period_end_date",
        },
  
        { ...extraColumns },
      ];

  }else if(url==="/data-collection/ghg-emission/tables/Stationary%20Combustion"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Facility Type",
          key: "facility_article",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Fuel Type",
          key:"fuel_type"
        },
        {
          label:"Fuel Quantity",
          key:"fuel_quantity",
        },
        {
            label: "Quantity Unit",
            key: "fuel_quantity_unit",
          },
          {
            label:"Start Day",
            key:"consumption_period_start_date",
          },
          {
            label:"End Day",
            key:"disposal_emissionconsumption_period_end_date",
          },
  
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Purchased%20Cooling"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Facility Type",
          key: "facility_article",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Based On",
          key:""
        },
        {
          label:"Grid Name",
          key:"",
        },
        {
            label:"Quality Purchase",
            key:"",
        },
        {
          label: "Unit",
          key: "",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Source",
          key: "",
        },
        
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Purchased%20Electricity"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Facility Type",
          key: "facility_article",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Based On",
          key:""
        },
        {
          label:"Grid Name",
          key:"",
        },
        {
            label:"Quality Purchase",
            key:"",
        },
        {
          label: "Unit",
          key: "",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Source",
          key: "",
        },
        
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Purchased%20Heat"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Facility Type",
          key: "facility_article",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Based On",
          key:""
        },
        {
          label:"Grid Name",
          key:"",
        },
        {
            label:"Quality Purchase",
            key:"",
        },
        {
          label: "Unit",
          key: "",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Source",
          key: "",
        },
        
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Purchased%20Steam"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Facility Type",
          key: "facility_article",
        },
        {
          label: "Organisation Unit",
          key: "organisation_unit",
        },
        {
          label:"Based On",
          key:""
        },
        {
          label:"Grid Name",
          key:"",
        },
        {
            label:"Quality Purchase",
            key:"",
        },
        {
          label: "Unit",
          key: "",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Source",
          key: "",
        },
        
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Purchased%20Goods%20&%20Services"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Method",
          key: " ",
        },
        {
          label:"Product Cradle to Gate Emissions",
          key:" "
        },
        {
          label:"Quantity of Product Purchased",
          key:" ",
        },
       
  
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Capital%20Goods"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label: "Method",
          key: " ",
        },
        {
        label:"Product Cradle to Gate Emission",
        key:"",
        },
        {
          label:"Quality of Product Purchased",
          key:" "
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Fuel%20&%20Energy%20Related"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Activity Type",
          key:"",
        },
        {
          label: "Method",
          key: " ",
        },
        {
        label:"Lifecycle Emission Factor(Country Wise)",
        key:"",
        },
        {
          label:"Supplier Fuel Emission Factor",
          key:" ",
        },
        {
          label:"Fuel Consumed",
          key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Waste%20Generated%20In%20Operations"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Method Type",
          key:"",
        },
        {
          label: "Allocation Emission",
          key: " ",
        },
        {
        label:"Activity Data",
        key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Transportation%20&%20Distribution"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Activity Type",
          key:"",
        },
        {
          label: "Fuel Type",
          key: " ",
        },
        {
        label:"Quantity of Fuel Used",
        key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Business%20Travel"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Method",
          key:"",
        },
        {
          label: "Emission Data",
          key: " ",
        },
        {
        label:"Activity Data",
        key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Employee%20commuting"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Method",
          key:"",
        },
        {
          label: "Emission Data",
          key: " ",
        },
        {
        label:"Activity Data",
        key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Leased%20Assets"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Activity Type",
          key:"",
        },
        {
          label: "Emission Data",
          key: " ",
        },
        {
        label:"Activity Data",
        key:"",
        },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Use%20Of%20Sold%20Products"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Activity Type",
          key:"",
        },
        {
        label:"Emission Data",
        key:"",
        },
        {
          label:"Activity  Data",
          key:"",
          },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/End%20of%20life%20treatment%20of%20sold%20products"){
    tableArr = [
        {
          label: " ",
          key: "",
          option: "checkbox",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Location",
          key: "location",
        },
        {
          label: "Supplier Name",
          key: "",
        },
        {
          label: "Organisational Unit",
          key: "",
        },
        {
          label:"Activity Type",
          key:"",
        },
        {
        label:"Emission Data",
        key:"",
        },
        {
          label:"Activity  Data",
          key:"",
          },
        { ...extraColumns },
      ];
  }else if(url==="/data-collection/ghg-emission/tables/Leased%20Assets")
  {
    tableArr = [
      {
        label: " ",
        key: "",
        option: "checkbox",
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Location",
        key: "location",
      },
      {
        label: "Supplier Name",
        key: "",
      },
      {
        label: "Organisational Unit",
        key: "",
      },
      {
        label:"Activity Type",
        key:"",
      },
      {
      label:"Emission Data",
      key:"",
      },
      {
        label:"Activity  Data",
        key:"",
        },
      { ...extraColumns },
    ];
  }else if(url==="/data-collection/ghg-emission/tables/Franchises")
  {
    tableArr = [
      {
        label: " ",
        key: "",
        option: "checkbox",
      },
      {
        label: "Name",
        key: "name",
      },
      {
        label: "Location",
        key: "location",
      },
      {
        label: "Supplier Name",
        key: "",
      },
      {
        label: "Organisational Unit",
        key: "organisation_unit",
      },
      {
        label:"Method Type",
        key:"",
      },
      {
      label:"Emission Data",
      key:"",
      },
      {
        label:"Activity  Data",
        key:"",
        },
      { ...extraColumns },
    ];
  }

  return tableArr;
};
