const tokenVerification = {
  tokenVerifyUrl: (module: string) =>
    `${process.env.REACT_APP_MARKETPLACE_BASE_URL}/se-marketplace/v1/resources/moduleAccess?module=${module}`,
};

const DashboardApi = {
  getOverviewData: (from_date: string, to_date: string, location: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/overview-dashboard?${
      from_date ? `from_date=${from_date}` : ""
    }${to_date ? `&to_date=${to_date}` : ""}${
      location ? `&location=${location}` : ""
    }`,

  getGhgEmissionData: (
    investor_id: string,
    scope_type: string,
    category_list: string[],
    organisation_unit_list: string[],
    from_date: string,
    to_date: string,
    emission_year: string,
    use_cases: string[],
    facility_types: string[],
    location: string[]
  ) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/ghg-emissions-dashboard?${
      scope_type ? `scope_type=${scope_type}` : ""
    }${
      category_list && category_list.length > 0
        ? `&category_list=${JSON.stringify(category_list)}`
        : ""
    }${
      organisation_unit_list && organisation_unit_list.length > 0
        ? `&organisation_unit_list=${JSON.stringify(organisation_unit_list)}`
        : ""
    }${from_date ? `&from_date=${from_date}` : ""}${
      to_date ? `&to_date=${to_date}` : ""
    }${emission_year ? `&emission_year=${emission_year}` : ""}${
      use_cases ? `&use_cases=${JSON.stringify(use_cases)}` : ""
    }${
      facility_types ? `&facility_types=${JSON.stringify(facility_types)}` : ""
    }${location ? `&location=${JSON.stringify(location)}` : ""}`,

  getFinancedEmissionOverviewData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/financed-emissions-overview-dashboard`,
  getFinancedEmissionLocationData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/financed-emission-location-dashboard`,
  getFinancedEmissionLocationDetails: (location: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/financed-emission-by-country-dashboard?country_short_name=${location}`,
  getFinancedEmissionPortfolioAnalysisData: (
    investor_id: string,
    analysis_type: string,
    asset_class: string[],
    sub_asset_class: string[],
    sector: string[],
    sub_sector: string[],
    dq_score: number[],
    location: string[],
    from_date: string,
    to_date: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/financed-emission-portfolio-analysis-dashboard?${
      analysis_type && analysis_type.length > 0
        ? `analysis_type=${analysis_type}`
        : ""
    }${
      asset_class && asset_class.length > 0
        ? `&asset_class=${JSON.stringify(asset_class)}`
        : ""
    }${
      sub_asset_class && sub_asset_class.length > 0
        ? `&sub_asset_class=${JSON.stringify(sub_asset_class)}`
        : ""
    }${sector && sector.length > 0 ? `&sector=${JSON.stringify(sector)}` : ""}${
      sub_sector && sub_sector.length > 0
        ? `&sub_sector=${JSON.stringify(sub_sector)}`
        : ""
    }${
      dq_score && dq_score.length > 0
        ? `&dq_score=${JSON.stringify(dq_score)}`
        : ""
    }${
      location && location.length > 0
        ? `&location=${JSON.stringify(location)}`
        : ""
    }${from_date && from_date.length > 0 ? `&from_date=${from_date}` : ""}${
      to_date && to_date.length > 0 ? `&to_date=${to_date}` : ""
    }`,
  getAssetClassData: (
    investor_id: string,
    asset_class_name: string,
    sub_asset_class: string,
    from_date: string,
    to_date: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/financed-emission-all-asset-class-dashboard?${
      asset_class_name ? `asset_class_name=${asset_class_name}` : ""
    }${sub_asset_class ? `&sub_asset_class=${sub_asset_class}` : ""}${
      from_date ? `&from_date=${from_date}` : ""
    }${to_date ? `&to_date=${to_date}` : ""}`,

  getSectorData: (
    investor_id: string,
    sector: string,
    sub_sector: string,
    from_date: string,
    to_date: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/financed-emission-sector-dashboard?${
      sector ? `sector=${sector}` : ""
    }${sub_sector ? `&sub_sector=${sub_sector}` : ""}${
      from_date ? `&from_date=${from_date}` : ""
    }${to_date ? `&to_date=${to_date}` : ""}`,

  getCompetitiveInvestorsData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/list-dropdown-data`,
  getBenchmarkingListData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/list-benchmarking-dashboard`,
  getBenchmarkingComparedData: (
    investor_id: string,
    competitor_investor_name: string,
    asset_class: string[],
    sector: string[],
    location: string[],
    x_axis: string,
    y_axis: string
  ) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/compare-benchmarking-data?${
      competitor_investor_name
        ? `competitor_investor_name=${competitor_investor_name}`
        : ""
    }${
      asset_class && asset_class.length > 0
        ? `&asset_class=${JSON.stringify(asset_class)}`
        : ""
    }${sector && sector.length > 0 ? `&sector=${JSON.stringify(sector)}` : ""}${
      location && location.length > 0
        ? `&location=${JSON.stringify(location)}`
        : ""
    }${x_axis ? `&x_axis=${x_axis}` : ""}${y_axis ? `&y_axis=${y_axis}` : ""}`,
  postBenchmark: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/create-benchmarking-dashboard`,
  patchBenchmarkData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/edit-or-delete-benchmarking-dashboard`,
};

const testApi = {};

const financedEmission = {
  getAssetDetails: (invester_name: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/get-asset-class-summary`,
  postAssetDetails: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/update-asset-class-summary`,

  getAssetInventoryList: (
    investor_name: string,
    limit: number,
    search_key: string,
    asset_class: string,
    sub_asset_class: string,
    sector: string,
    sub_sector: string,
    country: string,
    data_score: string | number,
    next_token: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/get-investees-asset-inventory?limit=${limit}${
      search_key ? `&search_key=${search_key}` : ""
    }${sub_asset_class ? `&sub_asset_class=${sub_asset_class}` : ""}${
      country ? `&country=${country}` : ""
    }${data_score ? `&data_score=${data_score}` : ""}${
      asset_class ? `&asset_class=${asset_class}` : ""
    }${sector ? `&sector=${sector}` : ""}${
      sub_sector ? `&sub_sector=${sub_sector}` : ""
    }${next_token ? `&next_token=${next_token}` : ""}`,

  getPcafMappingData: (filter: string, filter_value: string, page: number) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcafMapping/pcafTableList?${filter}=${filter_value}&page=${page}`,

  patchAssetInventory: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/edit-or-delete-investees-asset-inventory`,
  patchInvesteeInventory: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/updateDeleteRecord`,
  getPCAFMappingList: (investor_name: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/get-asset-class-mapping`,
  getOnboardedInvesteelist: (
    investee_id: string,
    limit: number,
    search_key: string,
    next_token: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/list-onboarded-investees?limit=${limit}${
      search_key ? `&search_key=${search_key}` : ""
    }${next_token ? `&next_token=${next_token}` : ""}`,
  postInvesteeInvite: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/notify-investees-to-take-action`,
  getInvesteeInventoryList: (
    dataType: string,
    dataSource: string,
    search_key: string,
    next_token: string,
    data_source_filter: string[]
  ) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/getFEData?datatype=${dataType}${
      dataSource ? `&source=${dataSource}` : ""
    }${search_key ? `&search_key=${search_key}` : ""}${
      next_token ? `&next_token=${next_token}` : ""
    }${
      data_source_filter.length > 0
        ? `&data_source_filter=${data_source_filter}`
        : ""
    }`,

  getDataByFilterTypeValues: (filter_type: string, filter_values: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/get-data-by-filter-type-values?filter_type=${filter_type}&filter_values=${filter_values}`,

  postAssetClassData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/add-asset-class-summary`,

  updateAssetClassData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/edit-or-delete-asset-class-summary`,

  updateDropdownList: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/ghgemissions/update-dropdown-list`,
};

const GHGEmission = {
  getPartnerIntegrationList: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/list-partner-integration-sources`,
  postGHGEmissionData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/ghgemissions/create-scope-based-ghg-emissions`,
  getGHGEmissionDataList: (
    scope_type: string,
    emission_source: string,
    limit: number,
    offset: number,
    search: string,
    location: string,
    facility_type: string,
    organisation_type: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/ghgemissions/get-scope1-2-3-emission-data?scope_type=${scope_type}&emission_source=${emission_source}${
      search ? `&search=${search}` : ""
    }${location ? `&location=${location}` : ""}${
      facility_type ? `&facility_type=${facility_type}` : ""
    }${organisation_type ? `&organisation_type=${organisation_type}` : ""}${
      limit ? `&limit=${limit}` : ""
    }${offset ? `&page=${offset}` : ""}`,

  updateGhgEmissionDataList: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/ghgemissions/create-scope-based-ghg-emissions`,
  getDropDowncontents: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/ghgemissions/dropdown-list`,
};

const investeeSection = {
  getInvesteeListHomepage: (
    query_limit?: string,
    next_token?: string,
    asset_class?: string,
    sub_asset_class?: string,
    sector?: string,
    sub_sector?: string,
    country?: string,
    dq_score?: string
  ) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/pcaf/list-investees-for-dashboard?${
      query_limit ? `limit=${query_limit}` : ""
    }${next_token ? `&next_token=${next_token}` : ""}${
      asset_class ? `&asset_class=${asset_class}` : ""
    }${sub_asset_class ? `&sub_asset_class=${sub_asset_class}` : ""}${
      sector ? `&sector=${sector}` : ""
    }${sub_sector ? `&sub_sector=${sub_sector}` : ""}${
      country ? `&country=${country}` : ""
    }${dq_score ? `&dq_score=${dq_score}` : ""}`,
};

const targetSetting = {
  getInvestorTargetHomepage: (targetScope?: string, id?: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/targetSettings/getTargets${
      targetScope ? `?targetType=${targetScope}` : ""
    }${id ? `&id=${id}` : ""}`,
  postInvestorTargetForm: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/targetSettings/setTargets`,
  getDropdownList: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/targetSettings/dropdownList`,
  deleteTarget: (url: string, id?: string) => {
    let encodedId = encodeURIComponent(id ?? "");
    return `${process.env.REACT_APP_BASE_URL}${url}?id=${encodedId}`;
  },
  updatePcafAssetClass: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcafMapping/updatePcafAssetClass`,
};

const investeeePortal = {
  getAssetFormData: (asset_class?: string, id?: string) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/investeePortal/getAssetClassDetails?${
      asset_class && `assetClass=${asset_class}`
    }${id && `&id=${id}`}`,
  postAssetFormData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/investeePortal/dataUpload`,
  getActivityLog: (asset_class: string, to_date: string, from_date: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/investeePortal/dataLogs?${
      asset_class ? `assetClass=${asset_class}` : ""
    }${from_date ? `&startDate=${from_date}` : ""}${
      to_date ? `&endDate=${to_date}` : ""
    }`,
  getDashboardData: (id?: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/investeePortal/dashboard${
      id ? `?id=${id}` : ""
    }`,
  getTargetDetails: (targetScope?: string, id?: string) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/investeePortal/targetSettings/getTargets${
      targetScope ? `?targetType=${targetScope}` : ""
    }${id ? `&id=${id}` : ""}`,
  getTargetHomepage: (targetScope?: string, id?: string) =>
    `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/investeePortal/targetSettings/getTargets${
      targetScope ? `?targetType=${targetScope}` : ""
    }${id ? `&id=${id}` : ""}`,
  postTargetSettingData: () =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/investeePortal/targetSettings/setTargets`,
  deleteActivityLogs: (id?: any) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/investeePortal/deleteRecord?${
      id ? `id=${id}` : ""
    }`,
};

const utilities = {
  getPRreSignUrlS3: () => `${process.env.REACT_APP_AWS_LAMBDA_URL}`,
  getFilterBasedData: (filter_type: string, filter_value: string) =>
    `${process.env.REACT_APP_BASE_URL}/api/v1/pcaf/get-data-by-filter-type?filter_type=${filter_type}&filter_value=${filter_value}`,
};

const path = {
  ...tokenVerification,
  ...DashboardApi,
  ...testApi,
  ...financedEmission,
  ...GHGEmission,
  ...utilities,
  ...DashboardApi,
  ...testApi,
  ...financedEmission,
  ...GHGEmission,
  ...targetSetting,
  ...investeeSection,
  ...utilities,

  ...investeeePortal,
};

export default path;
