//  improt library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

//  create base selector for reducer
const selectInvesteeInventoryStore = (state: RootState) => state.investeeInventoryList;

export const selectInvesteeInventoryResponse = createSelector(
    [selectInvesteeInventoryStore],
    (investeeInventoryStore) => investeeInventoryStore.response
)
