// import library components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import styles file
import "./Sidebar.scss";

// import custom components
import { logoColoured } from "../../../assets/images/images";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as DatabaseIcon } from "../../../assets/icons/server-gear-icon.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settingsIcon.svg";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/question-mark-icon.svg";

// import utils
import {
  sidebarJsonDataDashbaord,
  sidebarJsonDataCollection,
} from "../../../utils/data/sidebarData";
import { ReactComponent as Meter } from "../../../assets/icons/meter-icon.svg";

type Props = {};

type childrenObj = {
  [key: string]: boolean;
};

const SidebarInvestor = ({}: Props) => {
  const navigate = useNavigate();
  const [sidebarMode, setSidebarMode] = useState("data-collection");
  const [activePath, setActivePath] = useState("");
  const [activeFullPath, setActiveFullPath] = useState("");
  const [childrenActive, setChildrenActive] = useState<childrenObj>({});

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");

    if (
      pathSegments &&
      pathSegments.length > 1 &&
      pathSegments[1] === "dashboard"
    ) {
      let filteredArr = sidebarJsonDataDashbaord.filter(
        (el) => el.children.length > 0
      );

      let tempVisibilityObj = {};

      filteredArr.forEach((el) => {
        let childrenRoutes = el.children.map((child) => child.route);

        if (childrenRoutes.includes(window.location.pathname)) {
          tempVisibilityObj = {
            ...tempVisibilityObj,
            [el.title]: true,
          };
        }
      });
      setChildrenActive(tempVisibilityObj);
      setSidebarMode("dashboard");
    } else {
      setSidebarMode("data-collection");
    }

    routeMaker(window.location.pathname);
  }, []);

  const routeMaker = (receivedRoute: string) => {
    const pathSegments = receivedRoute.split("/");

    if (pathSegments.length > 2) {
      let extractedPath = `/${pathSegments[1]}/${pathSegments[2]}`;
      setActivePath(extractedPath);
    } else {
      setActivePath(receivedRoute);
    }
    setActiveFullPath(receivedRoute);
  };

  const sidebarTileCLickHandler =
    (clickedRoute: string, childrenActive: boolean, parentName: string) =>
    () => {
      if (childrenActive) {
        setChildrenActive((prevState) => {
          prevState[parentName] = true;
          return prevState;
        });
      } else {
        setChildrenActive({});
      }

      navigate(clickedRoute);
      routeMaker(clickedRoute);
    };

  const sidebarJsx = (receivedArr: any) => {
    return (
      <>
        {receivedArr.map((sidebarItem: any) => {
          return (
            <>
              <div className="sidebar-item">
                <div
                  className={
                    activePath === sidebarItem.parent
                      ? "sidebar-item-tile-container sidebar-tile-active"
                      : "sidebar-item-tile-container"
                  }
                  onClick={
                    sidebarItem.children.length > 0
                      ? sidebarTileCLickHandler(
                          sidebarItem.route,
                          true,
                          sidebarItem.title
                        )
                      : sidebarTileCLickHandler(
                          sidebarItem.route,
                          false,
                          sidebarItem.title
                        )
                  }
                >
                  {sidebarItem.icon}
                  <div className="sidebar-item-tile-text">
                    {sidebarItem.title}
                  </div>

                  {sidebarItem.children.length > 0 && (
                    <div className="sidebar-parent-chevron-icon-container">
                      <Chevron
                        className={
                          childrenActive[sidebarItem.title]
                            ? "sidebar-parent-chevron-icon-active"
                            : "sidebar-parent-chevron-icon-inactive"
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
              {childrenActive[sidebarItem.title] && (
                <div className="sidebar-tile-child-container">
                  {sidebarItem.children.map((childTile: any) => (
                    <div
                      className={
                        childTile.route === activeFullPath
                          ? "sidebar-child-tile-active"
                          : "sidebar-child-tile-inactive"
                      }
                      onClick={sidebarTileCLickHandler(
                        childTile.route,
                        true,
                        sidebarItem.title
                      )}
                    >
                      <Chevron className="sidebar-child-chevron-icon" />
                      {childTile.title}
                    </div>
                  ))}
                </div>
              )}
            </>
          );
        })}
      </>
    );
  };

  return (
    <div id="SidebarComponent" className="sidebar-component">
      <div className="se-logo-container" onClick={() => navigate("/")}>
        <img src={logoColoured} alt="se logo colored" />
      </div>
      <div className="sidebar-main-content-container">
        {sidebarMode === "dashboard" && (
          <div className="sidebar-main-content">
            <div className="sidebar-header-container">
              <Meter />
              Dashboard
            </div>

            {sidebarJsx(sidebarJsonDataDashbaord)}
          </div>
        )}
        {sidebarMode === "data-collection" && (
          <div className="sidebar-main-content">
            <div className="sidebar-header-container">
              <DatabaseIcon />
              Data Management
            </div>

            {sidebarJsx(sidebarJsonDataCollection)}
          </div>
        )}
      </div>
      <div className="sidebar-footer-container">
        <div className="sidebar-footer-switch-tab">
          {sidebarMode === "dashboard" ? (
            <div
              onClick={() => {
                navigate("/data-collection/ghg-emission");
                setActivePath("/data-collection/ghg-emission");
                setSidebarMode("data-collection");
              }}
            >
              <DatabaseIcon />
              Data Management
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/dashboard/overview");
                setActivePath("/dashboard/overview");
                setSidebarMode("dashboard");
              }}
            >
              <Meter />
              Dashboard
            </div>
          )}
        </div>
        <div className="sidebar-footer-partitioner"></div>
        <div className="sidebar-footer-extra-btn-container">
          <div className="sidebar-footer-settings-btn-container">
            <SettingsIcon /> Settings
          </div>
          <div className="sidebar-footer-help-center-btn-container">
            <QuestionIcon /> Help Center
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarInvestor;
