// import library components
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../../Api";

// import initial state
import { initialStateApi } from "../../../../utils";

type payloadType = {};

export const getDropdownListAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk("getDropdownListAction", async ({}, { rejectWithValue }) => {
    try {
      const response = await fetch(
        api.config.getDropdownList(),
        api.http.get()
      );
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );
      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      rejectWithValue(error);
    }
  });

const getDropdownList = createSlice({
  name: "getDropdownList",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDropdownListAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getDropdownListAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getDropdownListAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.payload;
        state.isError = true;
      });
  },
});

export default getDropdownList.reducer;
