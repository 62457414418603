// import library components
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";

// import styles file
import "./TextInput.scss";

type dynamicObj = {
  [key: string]: any;
};

type Props = {
  width?: string;
  height?: string;
  header?: string;
  value?: string | number;
  onChangeHandler?: (input: string) => void;
  onFocusOut?: (object: any) => void;
  placeholder?: string;
  type?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  theme?: "theme-light" | "theme-gray";
  disabled?: boolean;
  headerStyles?: dynamicObj;
  numeric?:boolean;
};

const TextInput = (props: Props) => {
  const {
    width = "100%",
    height = "100%",
    header,
    type = "text",
    value,
    onChangeHandler,
    onFocusOut,
    placeholder = "Type here",
    leftIcon,
    rightIcon,
    theme = "theme-light",
    disabled,
    headerStyles,
    numeric=false,
  } = props;

  const [data, setData] = useState<string | number | undefined>(value);

  const handleOnchange = (inputValue: string) => {
    const valueInput = numeric ? inputValue.replace(/[^0-9]/, '') : inputValue;

    setData(valueInput);
    onChangeHandler?.(valueInput);
  };

  useEffect(() => {
    setData(value);
  }, [value]);

  return (
    <div
      id="TextInputComponent"
      className="text-type-input-container"
      style={{ width }}
    >
      {header && (
        <div className="input-label" style={headerStyles}>
          {header}
        </div>
      )}
      <div className={`input-field ${theme}`} style={{ height }}>
        {leftIcon}
        <input
          disabled={disabled}
          value={data}
          placeholder={placeholder}
          onChange={(event) => {
            handleOnchange?.(event.target.value);
          }}
          onBlur={onFocusOut}
          type={numeric ? " " : type}
        />
        {rightIcon}
      </div>
    </div>
  );
};

export default TextInput;
