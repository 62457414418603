import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../Components/Core/ToggleSwitch/ToggleSwitch";
import Dropdown from "../../../Components/Core/DropdownV2/Dropdown";
import "./BenchmarkingModalContent.scss";
import MultiSelect from "../../../Components/Core/MultiSelect/MultiSelect";

type dynamicObj = {
  [key: string]: any;
};

interface IRadioBtnObj {
  text?: string;
  checked?: boolean;
}

type options = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

type multiSelectOptions = {
  text: string;
  value: boolean;
};

interface Props {
  receivedCompetitiveInvestorsData?: dynamicObj;
  benchmarkingOptions?: options[];
  usecaseOptions?: options[];
  assetClassOptions?: multiSelectOptions[];
  sectorOptions?: multiSelectOptions[];
  locationOptions?: multiSelectOptions[];
  setBenchmarkingOptions?: (option: any) => void;
  setUsecaseOptions?: (option: any) => void;
  setAssetClassOptions?: (option: any) => void;
  setSectorOptions?: (option: any) => void;
  setLocationOptions?: (option: any) => void;
  setSelectedBenchmark?: (option: any) => void;
  setSelectedUseCase?: (option: any) => void;
  setSelectedAssetClass?: (option: any) => void;
  setSelectedSector?: (option: any) => void;
  setSelectedLocation?: (option: any) => void;
  radioBtnArr?: IRadioBtnObj[];
  setRadioBtnArr?: (option: any) => void;
}

const BenchmarkingModalContent = ({
  receivedCompetitiveInvestorsData,
  benchmarkingOptions = [],
  usecaseOptions = [],
  assetClassOptions = [],
  sectorOptions = [],
  locationOptions = [],
  setBenchmarkingOptions,
  setUsecaseOptions,
  setAssetClassOptions,
  setSectorOptions,
  setLocationOptions,
  setSelectedBenchmark,
  setSelectedUseCase,
  setSelectedAssetClass,
  setSelectedSector,
  setSelectedLocation,
  radioBtnArr,
  setRadioBtnArr,
}: Props) => {
  const [selectedScope, setSelectedScope] = useState(0);

  useEffect(() => {
    const dropdownOptionsFunc = (receivedKey: string) => {
      return (
        receivedCompetitiveInvestorsData?.[receivedKey]?.map((el: any) => {
          return { name: el };
        }) ?? []
      );
    };

    const multiSelectOptionsFunc = (receivedKey: string) => {
      console.log(receivedCompetitiveInvestorsData);
      return (
        receivedCompetitiveInvestorsData?.[receivedKey]?.map((el: any) => {
          return { text: el, checked: false };
        }) ?? []
      );
    };

    let tempLocationOptions =
      receivedCompetitiveInvestorsData?.location?.map((el: any) => {
        return { text: el.country_name, checked: false };
      }) ?? [];

    setBenchmarkingOptions?.(dropdownOptionsFunc("competitor_investors_data"));
    setUsecaseOptions?.(dropdownOptionsFunc("use_cases"));
    setAssetClassOptions?.([
      { text: "All", checked: false },
      ...multiSelectOptionsFunc("asset_class"),
    ]);
    setSectorOptions?.([
      { text: "All", checked: false },
      ...multiSelectOptionsFunc("sector"),
    ]);
    setLocationOptions?.([
      { text: "All", checked: false },
      ...tempLocationOptions,
    ]);
  }, [receivedCompetitiveInvestorsData]);

  const radioBtnClickHandler = (receivedRadioBtnObj: IRadioBtnObj) => () => {
    setRadioBtnArr?.((prevArr: any) =>
      prevArr.map((el: any) =>
        el.text === receivedRadioBtnObj.text
          ? { ...el, checked: true }
          : { ...el, checked: false }
      )
    );
  };

  return (
    <div>
      <div className="tab-dropdown-container">
        <div className="tab-container">
          <ToggleSwitch
            switchState={["Peer", "Market"]}
            selectedValue={selectedScope}
            onSwitchChange={setSelectedScope}
            toggleSwitchClassnameExtra="asset-class-toggle-switch"
            buttonClassnameExtra="asset-class-toggle-switch-btn"
            btnTheme="gray"
          />
        </div>
        <div className="benchmark-dropdown">
          Benchmark with
          <Dropdown
            options={benchmarkingOptions}
            selectedCallBack={(el) => {
              setSelectedBenchmark?.(el[0]?.name ?? "");
            }}
          />
        </div>
      </div>
      <div className="partitioner"></div>
      <div className="checkboxes-container">
        <div className="checkboxes-container-title">Select X Axis</div>
        <div className="checkboxes-section">
          {radioBtnArr &&
            radioBtnArr.map((radioBtn) => (
              <div>
                <input
                  type="radio"
                  checked={radioBtn.checked}
                  name={radioBtn.text}
                  value={radioBtn.text}
                  onChange={radioBtnClickHandler(radioBtn)}
                />
                <label>{radioBtn.text}</label>
              </div>
            ))}
        </div>
      </div>
      <div className="dropdowns-container">
        <div className="select-container">
          <span>Use Case</span>
          <Dropdown
            options={usecaseOptions}
            selectedCallBack={(el) => {
              setSelectedUseCase?.(el[0]?.name ?? "");
            }}
          />
        </div>
        <div className="select-container">
          <span>Asset Class</span>
          <MultiSelect
            options={assetClassOptions}
            onSelectHandler={(receivedOptions) => {
              let tempStrArr = receivedOptions
                ?.filter((el) => el.checked)
                ?.map((el: any) => el.text);

              setSelectedAssetClass?.(tempStrArr);
            }}
          />
        </div>
        <div className="select-container">
          <span>Sub Asset Class</span>
          <MultiSelect />
        </div>
        <div className="select-container">
          <span>Sector</span>
          <MultiSelect
            options={sectorOptions}
            onSelectHandler={(receivedOptions) => {
              let tempStrArr = receivedOptions
                ?.filter((el) => el.checked)
                ?.map((el: any) => el.text);

              setSelectedSector?.(tempStrArr);
            }}
          />
        </div>
        <div className="select-container">
          <span>Sub Sector</span>
          <MultiSelect />
        </div>
        <div className="select-container">
          <span>Location</span>
          <MultiSelect
            options={locationOptions}
            onSelectHandler={(receivedOptions) => {
              let tempStrArr = receivedOptions
                ?.filter((el) => el.checked)
                ?.map((el: any) => el.text);

              setSelectedLocation?.(tempStrArr);
            }}
          />
        </div>
      </div>
      <div className="partitioner"></div>
    </div>
  );
};

export default BenchmarkingModalContent;
