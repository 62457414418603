import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";
import "./AvgDataQualityTable.scss";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const AvgDataQualityTable = ({ receivedFinancedEmissionData }: Props) => {
  const [qualityTableData, setQualityTableData] = useState([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData?.average_data_quality_score_by_asset_class
    ) {
      let tempQualityTableData =
        receivedFinancedEmissionData.average_data_quality_score_by_asset_class.map(
          (el: any) => {
            return {
              supplier_name: el.asset_class,
              image: "",
              total_emission_co2e: el.average_data_quality_score,
            };
          }
        );
      setQualityTableData(tempQualityTableData);
    }
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData?.average_data_quality_score_by_sub_asset_class
    ) {
      let tempQualityTableData =
        receivedFinancedEmissionData.average_data_quality_score_by_sub_asset_class.map(
          (el: any) => {
            return {
              supplier_name: el.sub_asset_class,
              image: "",
              total_emission_co2e: el.average_data_quality_score,
            };
          }
        );
      setQualityTableData(tempQualityTableData);
    }
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.ListChart
      listArray={qualityTableData}
      header="Average Data Quality Score"
      tableHeaders={["Asset Class", "Data Quality Score"]}
      customRowClassname="high-emitting-asset-classes-table-row"
      dividerVisibility={true}
    />
  );
};

export default AvgDataQualityTable;
