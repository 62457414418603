// library components
import { Navigate } from "react-router-dom";

// data collection
import DataCollectionForms from "../Pages/DataCollection/GHGEmission/DataCollectionForms/ParentForm/DataCollectionForms";
import DataCollectionTable from "../Pages/DataCollection/GHGEmission/DataCollectionTable/DataCollectionTable";

//dashboard
import DashboardOverview from "../Pages/DashBoard/DashboardOverview/DashboardOverview";
import DashboardFinancedEmission from "../Pages/DashBoard/FinancedEmission/index";

// financed emission
import FinancedEmission from "../Pages/FinancedEmission/FinancedEmission";
import DataValidation from "../Pages/DataCollection/FinancedEmission/DataValidation/DataValidation";

//testing routes
import GhgEmission from "../Pages/DashBoard/GHGemission/GhgEmission";
import LandingScreen from "../Pages/LandingScreen/LandingScreen";
import DataCollection from "../Pages/DataCollection/GHGEmission/DataCollection/DataCollection";
import AssetClass from "../Pages/DataCollection/FinancedEmission/AssetClass/AssetClass";

//$ testing

import Benchmarking from "../Pages/DashBoard/Benchmarking/Benchmarking";
import EditGraph from "../Pages/DashBoard/Benchmarking/EditGraph";
import AddGraph from "../Pages/DashBoard/Benchmarking/AddGraph";

// Investee Platform
import DataCollectionForm from "../Pages/InvesteePlatform/DataCollectionForm/DataCollectionForm";
import LogScreen from "../Pages/InvesteePlatform/LogScreen/LogScreen";
import Dashboard from "../Pages/InvesteePlatform/Dashboard/Dashboard";
import DashboardInvestor from "../Pages/DashBoard/Investee/Dashboard/DashboardInvestor";
import Homepage from "../Pages/InvesteePlatform/TargetSetting/Homepage/Homepage";
import TargetDetails from "../Pages/InvesteePlatform/TargetSetting/TargetDetails/TargetDetails";
import TargetSettingForm from "../Pages/InvesteePlatform/TargetSetting/TargetSettingForm/TargetSettingForm";
import InvesteeHomepage from "../Pages/DashBoard/Investee/InvesteeHomepage/InvesteeHomepage";
import InvesteeDetails from "../Pages/DashBoard/Investee/InvesteeDetails/InvesteeDetails";

// type definition
export type typeRoutesConfig = {
  path?: string | undefined;
  component: JSX.Element;
  index?: boolean;
};

export const routeWithoutLayoutInvestor: typeRoutesConfig[] = [
  {
    path: "/",
    component: <LandingScreen />,
  },
  {
    path: "/data-collection-models",
    component: <LandingScreen />,
  },
];

export const routeWithLayoutInvestor: typeRoutesConfig[] = [
  {
    path: "/dashboard/overview",
    component: <DashboardOverview />,
  },
  {
    path: "/dashboard/ghg-emission",
    component: <GhgEmission />,
  },
  {
    path: "/dashboard/financed-emission/overview",
    component: <DashboardFinancedEmission.Overview />,
  },
  {
    path: "/dashboard/financed-emission/asset-class",
    component: <DashboardFinancedEmission.AssetClass />,
  },
  {
    path: "/dashboard/financed-emission/sector",
    component: <DashboardFinancedEmission.Sector />,
  },
  {
    path: "/dashboard/financed-emission/location",
    component: <DashboardFinancedEmission.Location />,
  },
  {
    path: "/dashboard/financed-emission/location/:country",
    component: <DashboardFinancedEmission.LocationDetails />,
  },
  {
    path: "/dashboard/financed-emission/pofolio-analysis",
    component: <DashboardFinancedEmission.PortfolioAnalysis />,
  },
  {
    path: "/dashboard/investee",
    component: <InvesteeHomepage />,
  },
  {
    path: "/dashboard/investee-details/:id?",
    component: <DashboardInvestor />,
  },
  {
    path: "/dashboard/investor/set-target",
    component: <Homepage />,
  },
  {
    path: "/dashboard/investor/set-target/details/:id",
    component: <TargetDetails />,
  },
  {
    path: "/dashboard/investor/set-target-form",
    component: <TargetSettingForm />,
  },
  {
    path: "/dashboard/investor/set-target-form/edit",
    component: <TargetSettingForm />,
  },
  {
    path: "/dashboard/benchmarking",
    component: <Benchmarking />,
  },
  {
    path: "/dashboard/benchmarking/addgraph",
    component: <AddGraph />,
  },
  {
    path: "/dashboard/benchmarking/editgraph/:graphId",
    component: <EditGraph />,
  },
  {
    path: "/data-collection/ghg-emission",
    component: <DataCollection />,
  },
  {
    path: "/data-collection/ghg-emission/forms/:formSection",
    component: <DataCollectionForms />,
  },
  {
    path: "/data-collection/ghg-emission/forms/:formSection/edit",
    component: <DataCollectionForms />,
  },
  {
    path: "/data-collection/ghg-emission/tables/:table",
    component: <DataCollectionTable />,
  },
  {
    path: "/data-collection/financed-emission",
    component: <AssetClass />,
  },
  {
    path: "/data-collection/financed-emission/data-validation",
    component: <DataValidation />,
  },
];

export const routeWithLayoutInvestee: typeRoutesConfig[] = [
  {
    path: "/",
    component: <Navigate to="/investee/data-collection" />,
    index: true,
  },
  {
    path: "/investee/data-collection/:id?",
    component: <DataCollectionForm />,
    index: true,
  },
  {
    path: "/investee/user-logs",
    component: <LogScreen />,
  },
  {
    path: "/investee/dashboard",
    component: <Dashboard />,
  },
  {
    path: "/investee/set-target",
    component: <Homepage />,
  },
  {
    path: "/investee/set-target/details/:id",
    component: <TargetDetails />,
  },
  {
    path: "/investee/set-target-form",
    component: <TargetSettingForm />,
  },
];

export const routeWithoutLayoutInvestee: typeRoutesConfig[] = [
  {
    path: "/",
    component: <>homepage</>,
    index: true,
  },
];
