// import library components
import api from "../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";

// payload type definition
type payloadType = {
  action: "edit" | "delete";
  assetClass: string;
  name_of_issuer: string;
  datatype?: string;
  data: any;
};

export const patchInvesteeInventoryDataAction: AsyncThunk<
  any,
  payloadType,
  {}
> = createAsyncThunk(
  "assetInventory/patchInvesteeInventory",
  async (updatedDataList, { rejectWithValue }) => {
    try {
      const response = await fetch(
        api.config.patchInvesteeInventory(),
        api.http.post(updatedDataList)
      );

      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.post()
      );
      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      rejectWithValue(error);
    }
  }
);

const patchInvesteeInventory = createSlice({
  name: "patchInvesteeInventory",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(patchInvesteeInventoryDataAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(patchInvesteeInventoryDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(patchInvesteeInventoryDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.error;
      });
  },
});

export default patchInvesteeInventory.reducer;
