// import library components
import { ChangeEvent, useEffect, useState, useRef } from "react";

// import styles file
import "./dropdown.scss";

// import custom components
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { SearchIcon } from "../../../assets/icons/searchIcon";

type IDropDownMenuProps = {
  optionsList: string[];
  selected?: string;
  onSelectHandler?: (index: string) => void;
  dropdownHeader?: string;
  dropDownContainerClassName?: string;
  dropDownBodyClassName?: string;
  searchEnabled?: boolean;
  iconVisibility?: boolean;
  placeholderEnabled?: boolean;
  placeholderText?: string;
  disabled?: boolean;
};

const DropDown = ({
  selected,
  optionsList,
  onSelectHandler,
  dropdownHeader,
  dropDownContainerClassName,
  dropDownBodyClassName,
  searchEnabled = false,
  iconVisibility = true,
  placeholderEnabled = false,
  placeholderText = "Select Option",
  disabled = false,
}: IDropDownMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    !placeholderEnabled ? selected ?? optionsList[0] : ""
  );
  const [filteredOptions, setFilteredOptions] = useState<string[]>(optionsList);
  const [searchValue, setSearchValue] = useState("");
  const [placeholderVisibility, setPlaceholderVisibility] =
    useState<boolean>(placeholderEnabled);

  const optionSelectHandler = (index: number) => {
    if (filteredOptions[index] && filteredOptions[index].length > 0) {
      setPlaceholderVisibility(false);
    }
    setSelectedOption(filteredOptions[index]);
    setIsMenuOpen(false);

    onSelectHandler?.(filteredOptions[index]);
  };

  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const filteredOptions = optionsList.filter((option) =>
      option.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredOptions(filteredOptions);
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setFilteredOptions(optionsList);
  }, [optionsList]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <div ref={dropdownRef} id="dropdown" className={`dropdown-menu-component`}>
      <div className="label">{dropdownHeader}</div>
      <div
        className={`dropdown-title ${dropDownContainerClassName} ${
          disabled ? "dropdown-disabled" : ""
        }  `}
        onClick={() => setIsMenuOpen((prev) => !disabled && !prev)}
      >
        {!placeholderVisibility && (
          <div className="display-title">{selectedOption}</div>
        )}
        {placeholderVisibility && (
          <div className="display-title-placeholder">{placeholderText}</div>
        )}
        {iconVisibility && (
          <div className="chevron-container">
            <Chevron
              className={`chevron chevron-${
                isMenuOpen ? "active" : "inactive"
              }`}
            />
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div className={`${dropDownBodyClassName} dropdown-content-list`}>
          {searchEnabled && (
            <div className="dropdown-input-container-parent">
              <div className="dropdown-input-container">
                <SearchIcon />
                <input
                  placeholder="Search"
                  value={searchValue}
                  onChange={inputChangeHandler}
                ></input>
              </div>
              <div className="dropdown-search-partitioner"></div>
            </div>
          )}
          {filteredOptions.map((option, index) => {
            return (
              <div
                key={option}
                onClick={() => optionSelectHandler(index)}
                className="list-content"
                style={
                  selectedOption === option
                    ? { backgroundColor: "#E7F2F6" }
                    : {}
                }
              >
                {option}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DropDown;
