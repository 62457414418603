import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payLoadType = {
  investor_id?: string;
  asset_class_name?: string;
  sub_asset_class?: string;
  from_date?: string;
  to_date?: string;
};

export const getAssetClassData: AsyncThunk<any, payLoadType, {}> =
  createAsyncThunk(
    "getAssetClassData",
    async (
      { investor_id, asset_class_name, sub_asset_class, from_date, to_date },
      { rejectWithValue }
    ) => {
      try {
        const response = await fetch(
          api.config.getAssetClassData(
            investor_id,
            asset_class_name,
            sub_asset_class,
            from_date,
            to_date
          ),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const assetClassData = createSlice({
  name: "assetClassData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAssetClassData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getAssetClassData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getAssetClassData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default assetClassData.reducer;
