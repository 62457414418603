import { useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";

type Props = {};

const EndofLifeTreatment = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    location: "",
    organisation_unit: "",
    supplier_name: "",
  });

  useEffect(() => {
    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        organisation_unit: "",
        supplier_name: "",
        activity_type: "direct_use_phase",
        activity_type2: "product_that_consume_fuel",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3D", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    if (state.scopePath === "transportation_&_distribution") {
      payload["emission_source"] = "transportation_distribution";
    }
    if (payload.activity_type === "upstream") {
      delete payload.method;
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(
          `/data-collection/ghg-emission/tables/End of life treatment of sold products`,
          {
            state: {
              scope: "S3D",
              scopePath: "end_of_life_treatment_of_sold_products",
            },
          }
        );
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="49%"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="49%"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="49%"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="49%"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <div className="section-header">Emission Data</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Waste Emission Factor"
              width="49%"
              theme="theme-gray"
              value={data.waste_emission_factor}
              onChangeHandler={(value) =>
                handleChanges(value, "waste_emission_factor")
              }
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
          </div>
          <hr className="horizontal-row" />

          <div className="section-header">Activity Data</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Total Product Sold"
              width="49%"
              theme="theme-gray"
              value={data.total_products_sold}
              onChangeHandler={(value) =>
                handleChanges(value, "total_products_sold")
              }
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <TextInput
              header="% Treated by Waste Treatment Method"
              width="49%"
              theme="theme-gray"
              value={data.pct_treated_by_waste_treatment_method}
              onChangeHandler={(value) =>
                handleChanges(value, "pct_treated_by_waste_treatment_method")
              }
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
          </div>
          <hr className="horizontal-row" />
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default EndofLifeTreatment;
