export function changeOpacityOfRgb(
  rgbaColor: string,
  newOpacity: number
): string {
  // Extract RGB components and remove spaces
  const components = rgbaColor?.replace(/\s/g, "").match(/\d+,\d+,\d+/);
  if (!components) {
    return "#FFFF";
  }

  // Destructure components and construct the new RGBA string with the specified opacity
  const [r, g, b] = components[0].split(",");
  const newColor = `rgba(${r}, ${g}, ${b}, ${newOpacity})`;
  return newColor;
}
