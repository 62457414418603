import React, { useEffect, useState } from "react";
import "./Comparison.scss";
import ChartDetails from "./ChartDetails";
import { Graphs } from "../../../Components/graphs";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon2.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/save-icon2.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-icon.svg";
import { ReactComponent as AlertIcon } from "../../../assets/icons/alert.svg";
import DialogueModal from "../../../Components/Core/DialogueModal/DialogueModal";
import { useNavigate } from "react-router-dom";
import BenchmarkingModalContent from "./BenchmarkingModalContent";
import { useDispatch } from "react-redux";
import { createBenchmarkAction } from "../../../Redux/Slices/dashboard/benchmarking/createBenchmark/createBenchmark.slice";
import { AppDispatch } from "../../../Redux/store";
import { getCompetitiveInvestorsData } from "../../../Redux/Slices/dashboard/benchmarking/competitiveInvestors/getCompetitiveInvestorsData.slice";
import { selectCompetitiveInvestorsDataResponse } from "../../../Redux/Slices/dashboard/benchmarking/competitiveInvestors/getCompetitiveInvestors.selector";
import { useSelector } from "react-redux";
import countryData from "../../../utils/data/countries.json";
import { patchBenchmarkDataAction } from "../../../Redux/Slices/dashboard/benchmarking/updateBenchmark/updateBenchmark.slice";
import ConfirmationModal from "../../../Components/Core/ConfirmationModal/ConfirmationModal";
import { toastifyAsync } from "../../../utils/notifications/toastifyAsync";

type dynamicObj = {
  [key: string]: any;
};

type receivedObj = {
  data?: dynamicObj;
  query_data?: dynamicObj;
};

interface Props {
  mode: "edit" | "add";
  receivedBenchmarkingComparedData?: receivedObj;
}

type options = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

type multiSelectOptions = {
  text: string;
  value: boolean;
};

const Comparison = ({ mode, receivedBenchmarkingComparedData }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const competitiveInvestorsData = useSelector(
    selectCompetitiveInvestorsDataResponse
  );

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [benchmarkName, setBenchmarkName] = useState<string>("");
  const [benchmarkDes, setBenchmarkDes] = useState<string>("");

  const [graphLabels, setGraphLabels] = useState<string[]>([]);
  const [graphValues, setGraphValues] = useState<number[]>([]);

  const [competitorGraphLabels, setCompetitorGraphKeys] = useState<string[]>(
    []
  );
  const [competitorGraphValues, setCompetitorGraphValues] = useState<number[]>(
    []
  );
  const [benchmarkingOptions, setBenchmarkingOptions] = useState<options[]>([]);
  const [usecaseOptions, setUsecaseOptions] = useState<options[]>([]);
  const [assetClassOptions, setAssetClassOptions] = useState<
    multiSelectOptions[]
  >([]);
  const [sectorOptions, setSectorOptions] = useState<multiSelectOptions[]>([]);
  const [locationOptions, setLocationOptions] = useState<multiSelectOptions[]>(
    []
  );
  const [selectedBenchmark, setSelectedBenchmark] = useState<string>("");
  const [selectedUseCase, setSelectedUseCase] = useState<string>("");
  const [selectedAssetClass, setSelectedAssetClass] = useState<string[]>([]);
  const [selectedSector, setSelectedSector] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [radioBtnArr, setRadioBtnArr] = useState([
    { text: "Asset Class", checked: true },
    { text: "Sector", checked: false },
    { text: "Location", checked: false },
  ]);
  const [
    receivedCompetitiveInvestorsData,
    setReceivedCompetitiveInvestorsData,
  ] = useState<dynamicObj>();

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    let tempLabels =
      receivedBenchmarkingComparedData?.data?.graph_data?.map(
        (el: any) => el.graph_content_name
      ) ?? [];
    let tempCompetitorLabels =
      receivedBenchmarkingComparedData?.data?.competitor_graph_data?.map(
        (el: any) => el.graph_content_name
      ) ?? [];
    let tempValues =
      receivedBenchmarkingComparedData?.data?.graph_data?.map(
        (el: any) => el.graph_value
      ) ?? [];
    let tempCompetitorValues =
      receivedBenchmarkingComparedData?.data?.competitor_graph_data?.map(
        (el: any) => el.graph_value
      ) ?? [];

    setGraphLabels(tempLabels);
    setGraphValues(tempValues);
    setCompetitorGraphKeys(tempCompetitorLabels);
    setCompetitorGraphValues(tempCompetitorValues);
  }, [receivedBenchmarkingComparedData]);

  useEffect(() => {
    setReceivedCompetitiveInvestorsData(competitiveInvestorsData?.data);
  }, [competitiveInvestorsData]);

  const bookmarkClickHandler = () => {
    setShowModal(true);
  };

  const saveConfirmationHandler = () => {
    let createBenchmarkBody = {
      benchmark_name: benchmarkName,
      benchmark_description: benchmarkDes,
      competitor_investor_name:
        receivedBenchmarkingComparedData?.query_data?.competitor_investor_name,
      location: receivedBenchmarkingComparedData?.query_data?.location,
      asset_class: receivedBenchmarkingComparedData?.query_data?.asset_class,
      sector: receivedBenchmarkingComparedData?.query_data?.sector,
      x_axis: receivedBenchmarkingComparedData?.query_data?.x_axis,
      y_axis: receivedBenchmarkingComparedData?.query_data?.y_axis,
    };

    dispatch(createBenchmarkAction(createBenchmarkBody)).then((res) => {
      toastifyAsync(res);
      navigate("/dashboard/benchmarking");
    });
  };

  const editBtnClickHandler = () => {
    dispatch(getCompetitiveInvestorsData({}));
    setShowEditModal(true);
  };

  const submitClickHandler = () => {
    let selectedXaxis = radioBtnArr.filter((el) => el.checked)[0].text;
    let locationShortNames = selectedLocation.map(
      (el) =>
        countryData.find((country) => country.name === el)?.shortName ?? ""
    );

    dispatch(
      patchBenchmarkDataAction({
        action: "EDIT",
        sk: `peer${window.location.hash}`,
        competitor_investor_name: selectedBenchmark,
        asset_class: selectedAssetClass,
        sector: selectedSector,
        location: locationShortNames,
        x_axis: selectedXaxis,
        y_axis: selectedUseCase,
      })
    ).then((res) => {
      toastifyAsync(res);
      navigate("/dashboard/benchmarking");
    });
  };

  const deleteClickHandler = () => {
    setDeleteModal(true);
  };

  const confirmationModalContent = (
    <div className="confirmation-modal-content">
      <div className="alert-icon-text-container">
        <div className="alert-icon-container">
          <AlertIcon />
        </div>
        <div>Alert!</div>
      </div>
      <div className="confirmation-modal-content-line-1">
        You’re going to delete the benchmark
      </div>
      <div>Are you sure ?</div>
    </div>
  );

  const deleteModalConfirmationHandler = () => {
    setDeleteModal(false);
    dispatch(
      patchBenchmarkDataAction({
        action: "DELETE",
        sk: `peer${window.location.hash}`,
      })
    ).then((res) => {
      toastifyAsync(res);
      navigate("/dashboard/benchmarking");
    });
  };

  return (
    <div className="demographics-container d-grid-c6 demographics-container-benchmarking">
      {deleteModal && (
        <ConfirmationModal
          content={confirmationModalContent}
          confirmText="Yes, Delete!"
          cancelText="No, Keep it"
          confirmBtnClickhandler={deleteModalConfirmationHandler}
          cancelBtnClickHandler={() => {
            setDeleteModal(false);
          }}
        />
      )}
      <div className="demographic-tile col-6 demographic-tile-benchmarking">
        <div className="benchmarking-header-btn-container">
          {mode === "add" && (
            <button className="save-btn" onClick={bookmarkClickHandler}>
              <SaveIcon /> <div className="save-btn-text">Save</div>
            </button>
          )}
          {mode === "edit" && (
            <button className="edit-btn" onClick={editBtnClickHandler}>
              <div>
                <EditIcon />
              </div>
              Edit
            </button>
          )}
          {mode === "edit" && (
            <button className="delete-btn" onClick={deleteClickHandler}>
              <div>
                <DeleteIcon />
              </div>
              Delete
            </button>
          )}
        </div>
        <div className="benchmarking-barchart benchmarking-barchart-occurence-1">
          <Graphs.BarGraph
            header={<>By Asset Class</>}
            xAxisLabel=""
            yAxisLabel="Emissions (MtCO2e)"
            labels={graphLabels}
            chartData={[
              {
                label: "tCO2e",
                values: graphValues,
              },
            ]}
            xAxisLabelVisibility={false}
            maintainAspectRatio={true}
            datalabels={{
              datalabels: {
                display: true,
                anchor: "end",
                color: "white",
                borderRadius: 14,
                backgroundColor: "#9799E7",
                formatter: (value: any) => {
                  return "    ";
                },
              },
            }}
            borderWidth={10}
            barThickness={25}
            legendsPosition="bottom"
            xGridVisibility={false}
            legendsDisabled={true}
            barColor="rgb(151, 153, 231, 0.4)"
            yAxisVisibility={false}
          />
          <div className="chart-details-wrapper">
            <ChartDetails
              receivedData={receivedBenchmarkingComparedData?.data}
              caculated_aum_key="calculated_aum"
              total_aum_key="total_aum"
            />
          </div>
        </div>
        <div className="benchmarking-barchart benchmarking-barchart-occurence-2">
          <Graphs.BarGraph
            header={
              <>
                By Asset Class :{" "}
                {
                  receivedBenchmarkingComparedData?.query_data
                    ?.competitor_investor_name
                }{" "}
              </>
            }
            xAxisLabel=""
            yAxisLabel="Emissions (MtCO2e)"
            labels={competitorGraphLabels}
            chartData={[
              {
                label: "tCO2e",
                values: competitorGraphValues,
              },
            ]}
            xAxisLabelVisibility={false}
            maintainAspectRatio={true}
            datalabels={{
              datalabels: {
                display: true,
                anchor: "end",
                color: "white",
                borderRadius: 14,
                backgroundColor: "#9799E7",
                formatter: (value: any) => {
                  return "    ";
                },
              },
            }}
            borderWidth={10}
            barThickness={25}
            legendsPosition="bottom"
            xGridVisibility={false}
            legendsDisabled={true}
            barColor="rgb(151, 153, 231, 0.4)"
            yAxisVisibility={false}
          />
          <div className="chart-details-wrapper">
            <ChartDetails
              receivedData={receivedBenchmarkingComparedData?.data}
              caculated_aum_key="competitor_calculated_aum"
              total_aum_key="competitor_total_aum"
            />
          </div>
        </div>
      </div>
      {showModal && (
        <DialogueModal
          header="Bookmark Benchmarking"
          callback={() => {
            setShowModal(false);
          }}
          onSubmit={saveConfirmationHandler}
        >
          <div className="save-modal-container">
            <div className="field-container">
              <label htmlFor="name">Name</label>
              <div className="name-input-container">
                <input
                  placeholder="Enter name"
                  name="name"
                  className="name-input"
                  onChange={(e) => setBenchmarkName(e.target.value)}
                />
              </div>
            </div>
            <div className="field-container field-container-description">
              <label htmlFor="description">Description</label>
              <div className="description-input-container">
                <input
                  placeholder="Describe"
                  name="description"
                  className="description-input"
                  onChange={(e) => setBenchmarkDes(e.target.value)}
                />
              </div>
            </div>
          </div>
        </DialogueModal>
      )}
      {showEditModal && (
        <DialogueModal
          header="Edit Benchmark"
          callback={() => {
            setShowEditModal(false);
          }}
          onSubmit={submitClickHandler}
          children={
            <BenchmarkingModalContent
              receivedCompetitiveInvestorsData={
                receivedCompetitiveInvestorsData
              }
              benchmarkingOptions={benchmarkingOptions}
              usecaseOptions={usecaseOptions}
              assetClassOptions={assetClassOptions}
              sectorOptions={sectorOptions}
              locationOptions={locationOptions}
              setBenchmarkingOptions={setBenchmarkingOptions}
              setUsecaseOptions={setUsecaseOptions}
              setAssetClassOptions={setAssetClassOptions}
              setSectorOptions={setSectorOptions}
              setLocationOptions={setLocationOptions}
              setSelectedBenchmark={setSelectedBenchmark}
              setSelectedUseCase={setSelectedUseCase}
              setSelectedAssetClass={setSelectedAssetClass}
              setSelectedSector={setSelectedSector}
              setSelectedLocation={setSelectedLocation}
              radioBtnArr={radioBtnArr}
              setRadioBtnArr={setRadioBtnArr}
            />
          }
        />
      )}
    </div>
  );
};
export default Comparison;
