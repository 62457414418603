import React from "react";
import { ReactComponent as GraphVector } from "../../../assets/icons/graphVector.svg";
import "./StatsTile.scss";

interface extraStyles {
  [key: string]: any;
}

type Props = {
  header: string;
  value: string;
  unit: string;
  icon?: "error" | "success";
  progress?: string;
  theme?: string;
  extraStyles?: extraStyles;
};

const StatsTile = ({
  header,
  value,
  unit,
  icon,
  progress,
  theme,
  extraStyles = {},
}: Props) => {
  return (
    <div
      className="stats-tile-component"
      style={{ backgroundColor: theme, ...extraStyles }}
    >
      <div className="stats-tile-component-headline">{header}</div>

      <div className="stats-tile-component-values">
        {value} <sub>{unit}</sub>
      </div>

      {
        (icon || progress) &&
        <div className="stats-tile-component-progress">
          <span className={`indicator indicator-${icon}`}></span>
          <span>from Last year</span>

          <span className={`graph-section graph-section-${icon}`}>
            <span> {progress}</span>
            <GraphVector />
          </span>
        </div>
      }
    </div>
  );
};

export default StatsTile;
