import React, { ReactNode, useState } from "react";
import { Marker } from "react-simple-maps";
import { ReactComponent as Pin } from "../../../assets/icons/pin.svg";
export interface IMarkerProps {
  location: number[];
  svgJsx?: ReactNode;
  value?: number;
}

const MarkerPin = ({ location, svgJsx, value }: IMarkerProps) => {
  const [showPopUp, setShowPopUp] = useState(true);
  return (
    <>
      <Marker
        coordinates={[location[1], location[0]]}
        onMouseEnter={() => setShowPopUp(true)}
        onMouseLeave={() => setShowPopUp(false)}
      >
        {svgJsx ?? <Pin />}
      </Marker>
      {showPopUp && <div className="popup-modal">sjdbckj</div>}
    </>
  );
};

export default MarkerPin;
