// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./AssetClass.scss";

// import custom components
import StatsTile from "../../../../Components/Core/StatsTile/StatsTile.component";
import WeightedAvgBarGraph from "./WeightedAvgBarGraph/WeightedAvgBarGraph";
import AvgDataQualityTable from "./AvgDataQualityTable";
import EmissionByPeriod from "./EmissionByPeriod/EmissionByPeriod";
import EmissionByCountry from "./EmissionByCountry";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getAssetClassData } from "../../../../Redux/Slices/dashboard/financedEmission/assetClass/getAssetClassData.slice";
import { useSelector } from "react-redux";
import { selectAssetClassDataResponse } from "../../../../Redux/Slices/dashboard/financedEmission/assetClass/getAssetClassData.selector";
import AttributedEmission from "./AttributedEmission/AttributedEmission";
import HighEmittingInvestees from "../HighEmittingInvestees/HighEmittingInvestees";
import SectorEmissionByAssetClass from "./SectorEmissionByAssetClass/SectorEmissionByAssetClass";
import Dropdown from "../../../../Components/Core/DropdownV2/Dropdown";
import { pcaf } from "../../../../assets/images/images";

//type definition
type Props = {};

type dropDownOptionType = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

type receivedAssetClassDataType = {
  waci_investment?: number;
  waci_revenue?: number;
  attributed_total_financed_emission?: number;
  average_data_quality_score?: number;
  last_year_waci_investment?: number;
  last_year_waci_revenue?: number;
  last_year_average_data_quality_score?: number;
  last_year_attributed_total_financed_emission?: number;
  country_img_url?: string;
  is_financed_emission_decreased?: boolean;
  is_waci_investment_decreased?: boolean;
  is_waci_revenue_decreased?: boolean;
  is_avg_dq_score_decreased?: boolean;
};

const AssetClass = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const assetClassData = useSelector(selectAssetClassDataResponse);
  const [receivedAssetClassData, setReceivedAssetClassData] =
    useState<receivedAssetClassDataType>({});

  const [assetClassTabs, setAssetClassTabs] = useState<dropDownOptionType[]>(
    []
  );

  const [subAssetClassTabs, setSubAssetClassTabs] = useState<
    dropDownOptionType[]
  >([]);
  const [scenario, setScenario] = useState("All");
  const [selectedAssetClass, setSelectedAssetClass] = useState<string>("All");
  const [selectedSubAssetClass, setSelectedSubAssetClass] =
    useState<string>("All");

  useEffect(() => {
    dispatch(
      getAssetClassData({
        asset_class_name: "all",
        sub_asset_class: "all",
      })
    );
  }, []);

  useEffect(() => {
    if (
      assetClassData &&
      assetClassData?.data?.attributed_emission_by_asset_class
    ) {
      let tempCreditTabs =
        assetClassData?.data?.attributed_emission_by_asset_class.map(
          (el: any) => {
            return { img: "", name: el.asset_class };
          }
        ) ?? [];

      setAssetClassTabs([{ name: "All", img: "" }, ...tempCreditTabs]);
    }

    if (assetClassData && assetClassData?.data?.sub_asset_class_list) {
      let tempSubAssetClassTabs =
        assetClassData?.data?.sub_asset_class_list.map((el: any) => {
          return { name: el, img: "" };
        }) ?? [];

      if (tempSubAssetClassTabs && tempSubAssetClassTabs.length > 0) {
        setSubAssetClassTabs([
          { name: "All", img: "" },
          ...tempSubAssetClassTabs,
        ]);
      } else {
        setSubAssetClassTabs([]);
      }
    }

    setReceivedAssetClassData(assetClassData?.data);
  }, [assetClassData]);

  const parentDropDownClickHandler = (receivedOption: any) => {
    if (receivedOption[0].name === "All") {
      setSelectedSubAssetClass("All");
      setScenario("All");
    } else if (
      subAssetClassTabs.length > 0 &&
      selectedSubAssetClass === "All"
    ) {
      setScenario("All");
    } else {
      setScenario(receivedOption[0].name);
    }

    setSelectedAssetClass(receivedOption[0].name);
    dispatch(
      getAssetClassData({
        asset_class_name: receivedOption[0].name,
        sub_asset_class: selectedSubAssetClass,
      })
    );
  };

  const childDropDownClickHandler = (receivedOption: any) => {
    if (receivedOption[0].name === "All") {
      setScenario("All");
    } else {
      setScenario(receivedOption[0].name);
    }

    setSelectedSubAssetClass(receivedOption[0].name);
    dispatch(
      getAssetClassData({
        asset_class_name: selectedAssetClass,
        sub_asset_class: receivedOption[0].name,
      })
    );
  };

  return (
    <div className="financed-emission-asset-class-component noScrollBar">
      <div className="header-filter-row">
        <div className="header">
          <div>Asset Class</div>
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <img alt="powered by science based targets" src={pcaf} />
            </div>
          </div>
        </div>

        <div className="filter-row filter col-4 d-flex"></div>
      </div>
      <div className="demographic-container d-grid-c8">
        <div className="demographic-tile col-8 d-grid-c8">
          <div className="asset-class-filter-dropdowns col-8">
            <div className="asset-class-dropdown-1">
              <p>Asset Class</p>
              <Dropdown
                options={assetClassTabs}
                selectedCallBack={(e) => {
                  parentDropDownClickHandler(e);
                }}
                defaultOption={[{ name: "All", img: "" }]}
              />
            </div>
            {subAssetClassTabs && subAssetClassTabs.length > 0 && (
              <div className="asset-class-dropdown-2">
                <p>Sub Asset Class</p>
                <Dropdown
                  options={subAssetClassTabs}
                  selectedCallBack={(e) => {
                    childDropDownClickHandler(e);
                  }}
                  defaultOption={[{ name: "All", img: "" }]}
                />
              </div>
            )}
          </div>
          <div className="asset-class-partitioner col-8"></div>
          <div className="stats-row col-8 d-grid-c8">
            {scenario === "All" && (
              <div className="col-4">
                <AttributedEmission
                  receivedFinancedEmissionData={receivedAssetClassData}
                />
              </div>
            )}
            {scenario === "All" && (
              <div className="col-4 d-grid-c6">
                <div className="col-3">
                  <StatsTile
                    header="Attributed Emission"
                    value={`${
                      receivedAssetClassData?.attributed_total_financed_emission ??
                      0
                    }`}
                    icon={
                      receivedAssetClassData?.is_financed_emission_decreased
                        ? "success"
                        : "error"
                    }
                    progress={`${
                      receivedAssetClassData?.last_year_attributed_total_financed_emission ??
                      0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                    extraStyles={{ marginBottom: "1rem" }}
                  />
                  <StatsTile
                    header="WACI /$M Investment"
                    value={`${receivedAssetClassData?.waci_investment ?? 0}`}
                    icon={
                      receivedAssetClassData?.is_waci_investment_decreased
                        ? "success"
                        : "error"
                    }
                    progress={`${
                      receivedAssetClassData?.last_year_waci_investment ?? 0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                  />
                </div>
                <div className="col-3">
                  <StatsTile
                    header="WACI /$M Revenue"
                    value={`${receivedAssetClassData?.waci_revenue ?? 0}`}
                    icon={
                      receivedAssetClassData?.is_waci_revenue_decreased
                        ? "success"
                        : "error"
                    }
                    progress={`${
                      receivedAssetClassData?.last_year_waci_revenue ?? 0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                    extraStyles={{ marginBottom: "1rem" }}
                  />
                  <StatsTile
                    header="Average Data Quality Score"
                    value={`${
                      receivedAssetClassData?.average_data_quality_score ?? 0
                    }`}
                    icon={
                      receivedAssetClassData?.is_avg_dq_score_decreased
                        ? "success"
                        : "error"
                    }
                    progress={`${
                      receivedAssetClassData?.last_year_average_data_quality_score ??
                      0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                  />
                </div>
              </div>
            )}
            {scenario !== "All" && (
              <div className="col-8 horizontally-aligned-cards">
                <StatsTile
                  header="Attributed Emission"
                  value={`${
                    receivedAssetClassData?.attributed_total_financed_emission ??
                    0
                  }`}
                  icon={
                    receivedAssetClassData?.is_financed_emission_decreased
                      ? "success"
                      : "error"
                  }
                  progress={`${
                    receivedAssetClassData?.last_year_attributed_total_financed_emission ??
                    0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  extraStyles={{ marginBottom: "1rem", width: "23%" }}
                />
                <StatsTile
                  header="WACI /$M Investment"
                  value={`${receivedAssetClassData?.waci_investment ?? 0}`}
                  icon={
                    receivedAssetClassData?.is_waci_investment_decreased
                      ? "success"
                      : "error"
                  }
                  progress={`${
                    receivedAssetClassData?.last_year_waci_investment ?? 0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  extraStyles={{ width: "23%" }}
                />
                <StatsTile
                  header="WACI /$M Revenue"
                  value={`${receivedAssetClassData?.waci_revenue ?? 0}`}
                  icon={
                    receivedAssetClassData?.is_waci_revenue_decreased
                      ? "success"
                      : "error"
                  }
                  progress={`${
                    receivedAssetClassData?.last_year_waci_revenue ?? 0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  extraStyles={{ marginBottom: "1rem", width: "23%" }}
                />
                <StatsTile
                  header="Average Data Quality Score"
                  value={`${
                    receivedAssetClassData?.average_data_quality_score ?? 0
                  }`}
                  icon={
                    receivedAssetClassData?.is_avg_dq_score_decreased
                      ? "success"
                      : "error"
                  }
                  progress={`${
                    receivedAssetClassData?.last_year_average_data_quality_score ??
                    0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  extraStyles={{ width: "23%" }}
                />
              </div>
            )}
          </div>
        </div>

        {scenario === "All" && (
          <div className="demographic-tile col-5">
            <WeightedAvgBarGraph
              receivedFinancedEmissionData={receivedAssetClassData}
              mode={
                selectedAssetClass !== "All" ? "sub_asset_class" : "asset_class"
              }
            />
          </div>
        )}
        {scenario === "All" && (
          <div className="demographic-tile col-3">
            <AvgDataQualityTable
              receivedFinancedEmissionData={receivedAssetClassData}
            />
          </div>
        )}

        <div className="demographic-tile col-3">
          <HighEmittingInvestees
            receivedFinancedEmissionData={receivedAssetClassData}
          />
        </div>

        <div className="demographic-tile col-5">
          <EmissionByPeriod
            receivedFinancedEmissionData={receivedAssetClassData}
            assetClassTabs={assetClassTabs}
            selectedAssetClass={selectedAssetClass}
          />
        </div>

        {scenario !== "All" && (
          <div className="demographic-tile col-5">
            <SectorEmissionByAssetClass
              receivedFinancedEmissionData={receivedAssetClassData}
            />
          </div>
        )}
        {scenario !== "All" && (
          <div className="demographic-tile col-3">
            <EmissionByCountry
              receivedFinancedEmissionData={receivedAssetClassData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetClass;

const highEmittingCountries = [
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
  {
    country: "USA",
    image: "https://picsum.photos/200",
    data: ["100 MtCO2e"],
  },
];
