import React, { ReactNode } from "react";
import "./MatrixChart.scss";

interface ImatrixElements {
  text?: ReactNode;
  color?: string;
}

interface IMatrixChartProps {
  header?: ReactNode;
  matrixElements?: ImatrixElements[];
  legends?: ImatrixElements[];
}

let defaultMatrixElements: ImatrixElements[] = [
  {
    text: <>ELement 1</>,
    color: "#FA5B5B",
  },
  {
    text: <>ELement 2</>,
    color: "#FACE5E",
  },
  {
    text: <>ELement 3</>,
    color: "#9DCA5C",
  },

  {
    text: <>ELement 4</>,
    color: "#FACE5E",
  },
  {
    text: <> ELement 5</>,
    color: "#9DCA5C",
  },
  {
    text: <>ELement 6</>,
    color: "#FA5B5B",
  },

  { text: <>ELement 7</>, color: "#FA5B5B" },
  { text: <>ELement 8</>, color: "#FACE5E" },
  { text: <>ELement 9</>, color: "#9DCA5C" },
];

const MatrixChart = ({
  header = <>Matrix Chart</>,
  matrixElements = defaultMatrixElements,
  legends = [
    { text: <>legend 1</>, color: "#FA5B5B" },
    { text: <>legend 1</>, color: "#FACE5E" },
    { text: <>legend 1</>, color: "#9DCA5C" },
  ],
}: IMatrixChartProps) => {
  return (
    <div className="matrix-chart-container">
      <div className="matrix-header-container">{header}</div>
      <div className="matrix-body-container">
        {matrixElements.map((element, index) => (
          <div
            className={`matrix-body-element element-${index}`}
            style={{ backgroundColor: `${element.color}` }}
          >
            <div className="matrix-body-element-text-container">
              {element.text}
            </div>
          </div>
        ))}
      </div>
      <div className="matrix-footer-container">
        {legends.map((legend) => (
          <div className="matrix-chart-legend-container">
            <div
              className="matrix-chart-legend"
              style={{ backgroundColor: `${legend.color}` }}
            ></div>
            <div>{legend.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatrixChart;
