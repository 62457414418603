import { createSelector } from "reselect";
import { RootState } from "../../../../store";

const selectCompetitiveInvestorsDataStore = (state: RootState) =>
  state.competitiveInvestorsData;

export const selectCompetitiveInvestorsDataResponse = createSelector(
  [selectCompetitiveInvestorsDataStore],
  (selectCompetitiveInvestorsDataStore) =>
    selectCompetitiveInvestorsDataStore.response
);
