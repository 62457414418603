import React, { useEffect, useState } from 'react'

// import styles file
import './Loader.scss'
import { useSelector } from 'react-redux'
import BackgroundScreen from '../BackgroundScreen/BackgroundScreen'
import { RootState } from '../../../Redux/store';
import userProgressStateDC from '../../../Redux/Slices/InvesteePlatform/Utils/storeUserProgressDC/userDCProgressState.slice';
import getAssetFormData from '../../../Redux/Slices/InvesteePlatform/getAssetFormData/getAssetFormData.slice';
import postAssetFormData from '../../../Redux/Slices/InvesteePlatform/postAssetFormData/postAssetFormData.slice';
import getInvesteeActivityLogs from '../../../Redux/Slices/InvesteePlatform/getActivityLogs/getActivityLogs.slice';
import investeeDashboardData from '../../../Redux/Slices/InvesteePlatform/getDashboardData/getDashboardData.slice';
import investeeTargetDetails from '../../../Redux/Slices/InvesteePlatform/getTargetDetails/getTargetDetails.slice';
import investeeTargetHomepage from '../../../Redux/Slices/InvesteePlatform/getTargetHomepage/getTargetHomepage.slice';
import postTargetSettingData from '../../../Redux/Slices/InvesteePlatform/postTargetSetting/postTargetSettingData.slice';
import { dataCollectionProgressReducer } from '../../../Redux/Slices/InvesteePlatform/Utils/storeDataCollectionProgress/dataCollectionProgress.slice';


type Props = {
    hardSetLoader?: boolean;
}

const Loader = ({ hardSetLoader = false }: Props) => {

    const [showLoader, setShowLoader] = useState(false)
    const loadingState = useSelector((state: RootState) => state)

    useEffect(() => {
        (
            hardSetLoader ||
            // dashboard
            loadingState.benchmarkingComparedData.isLoading
            || loadingState.benchmarkingListData.isLoading
            || loadingState.createBenchmark.isLoading
            || loadingState.patchBenchmark.isLoading

            || loadingState.assetClassDetails.isLoading
            || loadingState.updateAssetClassDetails.isLoading
            || loadingState.partnerIntegrationList.isLoading
            || loadingState.assetInventoryList.isLoading
            || loadingState.onboardedInvesteeList.isLoading
            || loadingState.postFilesS3Slice.isLoading
            || loadingState.postInviteInvestee.isLoading
            || loadingState.postGHGEmissionData.isLoading
            || loadingState.investeeInventoryList.isLoading
            || loadingState.getGhgEmissionDataList.isLoading
            || loadingState.patchAssetInventory.isLoading
            || loadingState.postAssetFormData.isLoading

            // investee platform
            || loadingState?.getAssetFormData?.isLoading
            || loadingState?.postAssetFormData?.isLoading
            || loadingState?.getInvesteeActivityLogs?.isLoading
            || loadingState?.investeeDashboardData?.isLoading
            || loadingState?.investeeTargetDetails?.isLoading
            || loadingState?.investeeTargetHomepage?.isLoading
            || loadingState?.postTargetSettingData?.isLoading
        )

            ? setShowLoader(true) : setShowLoader(false)
    }, [
        hardSetLoader,
        // dashboard
        loadingState.benchmarkingComparedData.isLoading,
        loadingState.benchmarkingListData.isLoading,
        loadingState.createBenchmark.isLoading,
        loadingState.patchBenchmark.isLoading,

        loadingState.assetClassDetails.isLoading,
        loadingState.updateAssetClassDetails.isLoading,
        loadingState.partnerIntegrationList.isLoading,
        loadingState.assetInventoryList.isLoading,
        loadingState.onboardedInvesteeList.isLoading,
        loadingState.postFilesS3Slice.isLoading,
        loadingState.postInviteInvestee.isLoading,
        loadingState.postGHGEmissionData.isLoading,
        loadingState.investeeInventoryList.isLoading,
        loadingState.getGhgEmissionDataList.isLoading,
        loadingState.patchAssetInventory.isLoading,
        loadingState.postAssetFormData.isLoading,



        // investee platform
        loadingState?.getAssetFormData?.isLoading,
        loadingState?.postAssetFormData?.isLoading,
        loadingState?.getInvesteeActivityLogs?.isLoading,
        loadingState?.investeeDashboardData?.isLoading,
        loadingState?.investeeTargetDetails?.isLoading,
        loadingState?.investeeTargetHomepage?.isLoading,
        loadingState?.postTargetSettingData?.isLoading,
    ])

    return (
        showLoader ?
            <div id='loaderComponent' className='Loader-container'>
                <BackgroundScreen>
                    <span className="loader"></span>
                </BackgroundScreen>
            </div>
            : <div></div>
    )
}

export default Loader