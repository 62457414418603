// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

// create base selector for reducer
const selectAsseInventoryListStore = (state: RootState) =>
  state.assetInventoryList;

export const selectAssetInventoryListResponse = createSelector(
  [selectAsseInventoryListStore],
  (selectAsseInventoryListStore) => selectAsseInventoryListStore.response
);
