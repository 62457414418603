import React, { useState } from "react";
import { Graphs } from "../../../../../Components/graphs/index";
import "./EmissionDoughnutChart.scss";
import { colors } from "../../../../../DesignSystem/Colors/colors";
import { useEffect } from "react";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const EmissionDoughnutChart = ({ receivedFinancedEmissionData }: Props) => {
  const [labelsArr, setLabelsArr] = useState<string[]>([]);
  const [values, setValues] = useState([]);
  const [percentageArr, setPercentageArr] = useState([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData?.sector_emission_by_asset_class
    ) {
      let tempLabels =
        receivedFinancedEmissionData.sector_emission_by_asset_class.map(
          (el: any) => el.asset_class
        );

      let tempValues =
        receivedFinancedEmissionData.sector_emission_by_asset_class.map(
          (el: any) => el.financed_emission
        );

      let tempPercentValues =
        receivedFinancedEmissionData.sector_emission_by_asset_class.map(
          (el: any) => +el.percentage?.toFixed(2)
        );

      setPercentageArr(tempPercentValues);
      setLabelsArr(tempLabels);
      setValues(tempValues);
    }
  }, [receivedFinancedEmissionData]);

  return (
    <div className="emission-doughnut--labels-container">
      <div className="emission-doughnut-chart-container">
        <Graphs.PieChart
          header="Sector Emission by Asset Class"
          tooltip="Emission by Fuel"
          doughNut="60%"
          labels={labelsArr}
          legendsDisabled={true}
          chartData={[
            {
              label: "tCO2e",
              values: percentageArr,
            },
          ]}
        />
      </div>
      <div className="emisssion-doughnut-labels-container hide-scrollbar">
        <div className="labels-container-table-header-container">
          <div className="labels-container-table-cell labels-header-container-table-cell-column-1">
            Label
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-2">
            Emission
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-3">
            %
          </div>
        </div>
        {labelsArr.map((el, index) => (
          <div className="labels-container-table-row">
            <div className="labels-container-table-cell labels-body-container-table-cell-column-1">
              <div
                className="ebp-label-point"
                style={{ backgroundColor: `${colors.primaryColors[index]}` }}
              ></div>
              <div>{labelsArr[index]}</div>
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-2">
              {values[index]}
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-3">
              {percentageArr[index]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmissionDoughnutChart;
