import toast from 'react-hot-toast';

export const toastifyAsync = (res: any) => {
    toast.dismiss()
    if (res.type.includes('rejected')) {
        toast.error(res.error.message)
    } else if (res.type.includes('fulfilled')) {
        toast.success(res.payload.message)
    } else {
        toast.error('Something went wrong, Please try again')
    }
}