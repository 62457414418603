// import library components
import { ReactNode, RefObject, useEffect, useRef } from 'react';

//import styles file
import './BackgroundScreen.scss'

type Props = {
    children: ReactNode;
    customID?: string
}


const BackgroundScreen = ({ children, customID, ...props }: Props) => {

    const blankScreen = useRef<HTMLDivElement>(null)

    return (
        <div id={customID} className='background-screen-filter' {...props}>
            {children}
        </div>
    )
}

export default BackgroundScreen
