import { Children, useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import RadioInput from "../../../../../../Components/Core/RadioInput/RadioInput";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";

type Props = {};

const ProcessingSoldProducts = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const activityArray = ["Site Specific", "Average Data Method"];
  const [selectedActivity, setSelectedActivity] = useState<number>(0);
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<{ [key: string]: any }>({
    name: "",
    location: "",
    supplier_name: "",
    organisation_unit: "",
  });

  useEffect(() => {
    activityArray.forEach((el, index) => {
      if (el.toLocaleLowerCase() === state?.formData?.method) {
        setSelectedActivity(index);
      }
    });

    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        supplier_name: "",
        organisation_unit: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3U", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }
    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(
          `/data-collection/ghg-emission/tables/Processing Of Sold Products`,
          {
            state: {
              scope: "S3U",
              scopePath: "transportation_distribution",
            },
          }
        );
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData}
              width="46%"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <>
            <div className="radio-questions-container">
              <div className="header">Select Activity Type</div>

              <div className="radio-container">
                {Children.toArray(
                  activityArray.map((item: string, index: number) => (
                    <RadioInput
                      label={item}
                      onClickHandler={() => {
                        handleChanges(
                          item.toLowerCase().split(" ").join("_"),
                          "method"
                        );
                        setSelectedActivity(index);
                      }}
                      checked={index === selectedActivity}
                    />
                  ))
                )}
              </div>
            </div>
            <hr className="horizontal-row" />
          </>

          {selectedActivity === 0 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <Dropdown
                  options={[
                    { name: "Aviation Gasoline" },
                    { name: "diesel" },
                    { name: "keroscene" },
                    { name: "oil" },
                  ]}
                  width="46%"
                  header="LifeCycle Fuel Emission Factor (Country Wise)"
                  selectedCallBack={(selectedOption) =>
                    handleChanges(
                      selectedOption[0].name,
                      "lifecycle_electricity_emission_factor"
                    )
                  }
                  searchIncluded
                  heightInput="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                  defaultOption={[
                    {
                      name:
                        state?.formData
                          ?.lifecycle_electricity_emission_factor ?? "",
                    },
                  ]}
                />
                <Dropdown
                  options={countriesData}
                  width="46%"
                  header="LifeCycle Electrical Emission Factor (Country Wise)"
                  selectedCallBack={(selectedOption) =>
                    handleChanges(
                      selectedOption[0].name,
                      "lifecycle_fuel_emission_factor"
                    )
                  }
                  searchIncluded
                  heightInput="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                  defaultOption={[
                    {
                      name:
                        state?.formData?.lifecycle_fuel_emission_factor ?? "",
                    },
                  ]}
                />
                <TextInput
                  type="number"
                  header="Refrigerant Emission Factor"
                  width="46%"
                  theme="theme-gray"
                  value={data.refrigerent_emission_factor}
                  onChangeHandler={(value) =>
                    handleChanges(value, "refrigerent_emission_factor")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
                <TextInput
                  type="number"
                  header="Waste Emission Factor"
                  width="46%"
                  theme="theme-gray"
                  value={data.waste_emission_factor}
                  onChangeHandler={(value) =>
                    handleChanges(value, "waste_emission_factor")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  type="number"
                  header="Fuel consumed"
                  width="46%"
                  theme="theme-gray"
                  value={data.fuel_consumed}
                  onChangeHandler={(value) =>
                    handleChanges(value, "fuel_consumed")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
                <TextInput
                  type="number"
                  header="Electricity consumed"
                  width="46%"
                  theme="theme-gray"
                  value={data.electricity_consumed}
                  onChangeHandler={(value) =>
                    handleChanges(value, "electricity_consumed")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
                <TextInput
                  type="number"
                  header="Refrigerant Leakage"
                  width="46%"
                  theme="theme-gray"
                  value={data.refrigerant_leakage}
                  onChangeHandler={(value) =>
                    handleChanges(value, "refrigerant_leakage")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
                <TextInput
                  type="number"
                  header="Waste generated"
                  width="46%"
                  theme="theme-gray"
                  value={data.waste_generated}
                  onChangeHandler={(value) =>
                    handleChanges(value, "waste_generated")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}
          {selectedActivity === 1 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  type="number"
                  header="Product Emission Factor"
                  width="46%"
                  theme="theme-gray"
                  value={data.product_emission_factor}
                  onChangeHandler={(value) =>
                    handleChanges(value, "product_emission_factor")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <TextInput
                  type="number"
                  header="Amount of Product Sold"
                  width="46%"
                  theme="theme-gray"
                  value={data.amount_of_product_sold}
                  onChangeHandler={(value) =>
                    handleChanges(value, "amount_of_product_sold")
                  }
                  height="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default ProcessingSoldProducts;
