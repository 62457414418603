// import library components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import styles file
import "./DataCollectionForm.scss";

// import custom componennts
import MainContentWrapper from "../../../Components/Core/MainContent/ContentWrapper";
import TextInput from "../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../Components/Core/DropdownV2/Dropdown";
import DatePickerComponent from "../../../Components/Core/DatePicker/DatePicker";
import Button from "../../../Components/Core/Button/Button";

// import utils
import countries from "../../../utils/data/countries.json";
import { toastifyAsync } from "../../../utils/notifications/toastifyAsync";
import { dateConversion } from "../../../utils/helpers/dateHandlers";

// import redux components
import { selectAssetFormDataResponse } from "../../../Redux/Slices/InvesteePlatform/getAssetFormData/getAssetFormData.selector";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { getAssetFormDataAction } from "../../../Redux/Slices/InvesteePlatform/getAssetFormData/getAssetFormData.slice";
import { postAssetFormDataAction } from "../../../Redux/Slices/InvesteePlatform/postAssetFormData/postAssetFormData.slice";
import { updateDataCollectionProgressAction } from "../../../Redux/Slices/InvesteePlatform/Utils/storeDataCollectionProgress/dataCollectionProgress.slice";

type Props = {};

const DataCollectionForm = (props: Props) => {
  const userCurrentState = useSelector(selectAssetFormDataResponse);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { id } = useParams();

  const [questionnaire, setQuestionnaires] = useState<any>();

  useEffect(() => {
    const objToSend: { [key: string]: string } = {
      asset_type: "",
      id: id ? encodeURIComponent(`${id}`) : "",
    };

    dispatch(getAssetFormDataAction(objToSend));
  }, []);

  useEffect(() => {
    setQuestionnaires(userCurrentState?.data);
  }, [userCurrentState]);

  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [tempObjHolder, setTempObjHolder] = useState<Object>({});

  const handleInputChange = (key: string, value: string) => {
    const obj = { [key]: value };
    setTempObjHolder(obj);
  };

  const updateFormDataAndResetState = () => {
    setFormData((prev) => {
      return {
        ...prev,
        [Object.keys(tempObjHolder)[0]]: Object.values(tempObjHolder)[0],
      };
    });

    setTempObjHolder({});
  };

  const handleFormSubmit = () => {
    const dataToSend = {
      action: id ? "edit" : "add",
      assetClass: questionnaire.header,
      reporting_year_end: dateConversion(
        formData?.reporting_year_end,
        "mm/dd/yyyy"
      ),
      data: formData,
      sk: id,
    };
    const tempDataStore = userCurrentState;
    const indexOfSidebar = tempDataStore.sideBarList.indexOf(
      tempDataStore.data.header
    );

    if (indexOfSidebar + 1 === tempDataStore.sideBarList.length) {
      return navigate("/investee/dashboard");
    }

    dispatch(postAssetFormDataAction(dataToSend)).then((res: any) => {
      if (res.type.includes("fulfilled")) {
        toastifyAsync(res);

        if (id) {
          navigate(-1);
        } else {
          dispatch(
            updateDataCollectionProgressAction({ index: indexOfSidebar + 1 })
          );
        }
      }
    });
  };

  return (
    <div
      id="InvesteeDatacollectionComponent"
      className="investee-data-collection-form"
    >
      <MainContentWrapper customClassName="data-collection-wrapper NoScrollBar">
        <>
          <div className="wrapper NoScrollBar">
            <div className="content-header">
              Please provide the following data
            </div>

            {questionnaire?.questionnaires?.map((item: any) => (
              <div className="questionnaire-section">
                <div className="questionnaire-section-header">
                  {item.sub_header}
                </div>
                <div className="questionnaire-section-label">{item.label}</div>

                <div className="questionnaire-section-sub-questions">
                  {item.sub_questionnaires?.map((questionList: any) => {
                    return (
                      <>
                        {(questionList.type === "text" ||
                          questionList.type === "number") && (
                          <TextInput
                            // disabled={questionList.disabled}
                            header={questionList.question}
                            theme="theme-gray"
                            type={questionList.type}
                            value={questionList.value}
                            width="45%"
                            height="55%"
                            headerStyles={{ fontSize: "1.3rem" }}
                            onChangeHandler={(value: string) =>
                              handleInputChange(questionList.key, value)
                            }
                            onFocusOut={updateFormDataAndResetState}
                          />
                        )}
                        {questionList.type === "dropdown" && (
                          <Dropdown
                            onFocusOut={updateFormDataAndResetState}
                            heightInput="55%"
                            options={
                              questionList.question
                                .toLowerCase()
                                .includes("location")
                                ? countries
                                : questionList?.dropdown_list?.map(
                                    (item: string) => ({ name: item })
                                  )
                            }
                            width="45%"
                            header={questionList.question}
                            selectedCallBack={(option) =>
                              handleInputChange(
                                questionList.key,
                                option[0].name
                              )
                            }
                            searchIncluded
                          />
                        )}
                        {questionList.type === "date" && (
                          <DatePickerComponent
                            width="45%"
                            header={questionList.question}
                            selectedDateCallback={(date) =>
                              setFormData((prev) => ({
                                ...prev,
                                [questionList.key]: date,
                              }))
                            }
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className="footer-action-btns">
            <div className="btn-container">
              {!id && (
                <Button label="Skip" className="primary-border-outlined" />
              )}
              <Button
                onClick={handleFormSubmit}
                label={
                  userCurrentState?.sideBarList.indexOf(
                    userCurrentState?.data.header
                  ) +
                    1 ===
                  userCurrentState?.sideBarList?.length
                    ? "Go to Dashboard"
                    : id
                    ? "Update"
                    : "Save"
                }
                className="primary-button"
              />
            </div>
          </div>
        </>
      </MainContentWrapper>
    </div>
  );
};

export default DataCollectionForm;
