// import library component
import React, { ChangeEvent, useEffect, useState } from "react";
import { useForceUpdate } from "../../../../Hooks/useForceUpdate";
import { useNavigate } from "react-router-dom";

// import styles file
import "./AssetClass.scss";

// import custom components
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../Components/Core/Button/Button";
import InputFieldAssetclass from "./InputFieldAssetclass/InputField.assetclass";

// redux helper and functions

import { useSelector } from "react-redux";
import { selectAssetClassResponse } from "../../../../Redux/Slices/financedEmission/datacollection/getAssetClassDetails.selector";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../Redux/store";
import { fetchAssetClassDetails } from "../../../../Redux/Slices/financedEmission/datacollection/getAssetClassDetails.slice";
import { updateAssetDetails } from "../../../../Redux/Slices/financedEmission/updateAssetDetails/updateAssetDetails.slice";
import DialogueModal from "../../../../Components/Core/DialogueModal/DialogueModal";
import TextInput from "../../../../Components/Core/TextInput/TextInput";
import { addAssetClassAction } from "../../../../Redux/Slices/financedEmission/addAssetClass/addAssetClass.slice";
import { toastifyAsync } from "../../../../utils/notifications/toastifyAsync";
import ConfirmationModal from "../../../../Components/Core/ConfirmationModal/ConfirmationModal";
import { updateAssetClassAction } from "../../../../Redux/Slices/financedEmission/updateAssetClass/updateAssetClass.slice";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/alert.svg";

console.clear();

// type definition
type Props = {};

const AssetClass = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const assetClassData = useSelector(selectAssetClassResponse);

  const forceUpdate = useForceUpdate();
  const [assetDetailsArray, setAssetDetailsArray] =
    useState<assetObject1Type[]>(assetObject1);
  const [assetClassModalVisibility, setAssetClassModalVisibility] =
    useState(false);
  const [editAssetClassModalVisibility, setEditAssetClassModalVisibility] =
    useState(false);
  const [assetClassName, setAssetClassName] = useState("");
  const [aumValue, setAumValue] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedAssetClass, setSelectedAssetClass] = useState(0);
console.log(assetDetailsArray);
  useEffect(() => {
    dispatch(fetchAssetClassDetails({ investorName: "oak" }));
  }, []);

  useEffect(() => {
    setAssetDetailsArray(assetClassData?.data);
  }, [assetClassData]);

  const handleDataUpdate = (updatedObject: assetObject1Type, index: number) => {
    const updatedAssetClass = assetDetailsArray;

    const tempAssetClassHolder = [
      ...updatedAssetClass.slice(0, index),
      updatedObject,
      ...updatedAssetClass.slice(index + 1, updatedAssetClass.length),
    ];
    setAssetDetailsArray(tempAssetClassHolder);
    forceUpdate();
  };

  const addAssetClassToList = () => {
    setAssetClassModalVisibility(true);
  };

  const deleteAssetClass = (index: number) => {
    setAssetDetailsArray((prevArr) => {
      let modifiedAssetArr = prevArr.filter(
        (el, modIndex) => modIndex !== index
      );
      return [...modifiedAssetArr];
    });
  };

  const handleDataUpdateClick = () => {
    const payload = {
      asset_class_data: assetDetailsArray,
    };
    dispatch(updateAssetDetails(payload)).then(() => {
      navigate("/data-collection/financed-emission/data-validation", {
        state:assetDetailsArray.length
      });
    });
  };

  const submitClickHandler = () => {
    dispatch(
      addAssetClassAction({
        asset_class: assetClassName,
        aum_dollars: +aumValue,
      })
    )
      .then((res) => {
        toastifyAsync(res);
        dispatch(fetchAssetClassDetails({ investorName: "oak" }));
        setAssetDetailsArray((prev: any) => {
          return [
            ...(prev ?? []),
            {
              aum_assetclass: assetClassName,
              aum_dollars: +aumValue,
              sub_asset: [],
            },
          ];
        });
      })
      .catch((e) => {})
      .finally(() => {
        setAssetClassModalVisibility(false);
      });

    setAssetClassName("");
    setAumValue("");
  };

  const submitClickHandlerEditMode = () => {
    let payload = {
      aum_assetclass: assetClassName,
      aum_dollars: aumValue,
      action: "EDIT",
      pk: assetDetailsArray[selectedAssetClass]?.pk,
      sk: assetDetailsArray[selectedAssetClass]?.sk,
    };

    dispatch(updateAssetClassAction(payload))
      .then((res) => {
        toastifyAsync(res);
        if (!res.payload.is_error) {
          setAssetDetailsArray((prevArr: any) => {
            let copiedAssetDetailsArr = prevArr.map((a: any) => {
              return { ...a };
            });

            copiedAssetDetailsArr[selectedAssetClass].aum_assetclass =
              assetClassName;
            copiedAssetDetailsArr[selectedAssetClass].aum_dollars = aumValue;

            return copiedAssetDetailsArr;
          });
        }
      })
      .catch((e) => {})
      .finally(() => {
        setAssetClassName("");
        setAumValue("");
        setEditAssetClassModalVisibility(false);
      });
  };

  const deleteModalConfirmationHandler = () => {
    setDeleteModal(false);
    setAssetDetailsArray((prevArr) => {
      let modifiedAssetArr = prevArr.filter(
        (el, modIndex) => modIndex !== selectedAssetClass
      );
      return [...modifiedAssetArr];
    });
    dispatch(
      updateAssetClassAction({
        action: "DELETE",
        pk: assetDetailsArray[selectedAssetClass]?.pk,
        sk: assetDetailsArray[selectedAssetClass]?.sk,
      })
    )
      .then((res) => toastifyAsync(res))
      .catch((err) => {})
      .finally(() => {});
  };

  const confirmationModalContent = (
    <div className="confirmation-modal-content">
      <div className="alert-icon-text-container">
        <div className="alert-icon-container">
          <AlertIcon />
        </div>
        <div>Alert!</div>
      </div>
      <div className="confirmation-modal-content-line-1">
        You’re going to delete the asset class
      </div>
      <div>Are you sure ?</div>
    </div>
  );

  return (
    <div id="assetClassComponent" className="asset-class-container noScrollBar">
      <MainContentWrapper customClassName="customWrapperClass">
        <>
          <div className="header">
            Please list down you asset classes and there corresponding AUM
          </div>
          {assetDetailsArray &&
            assetDetailsArray.length > 0 &&
            assetDetailsArray?.map((asset, index) => {
              return (
                <div key={asset.aum_assetclass + index}>
                  <InputFieldAssetclass
                    fieldData={asset}
                    position={index}
                    deleteAssetClass={deleteAssetClass}
                    onFocusOutHandler={(object, index) =>
                      handleDataUpdate(object, index)
                    }
                    setSelectedAssetClass={setSelectedAssetClass}
                    setDeleteModal={setDeleteModal}
                    setEditAssetClassModalVisibility={
                      setEditAssetClassModalVisibility
                    }
                    setAssetClassName={setAssetClassName}
                    setAumValue={setAumValue}
                  />
                </div>
              );
            })}
          <div className="add-asset-btn">
            <Button
              onClick={addAssetClassToList}
              label="Add asset class"
              className="primary-border-outlined"
            />
          </div>
          <div className="footer-action-btns">
            <Button
              onClick={handleDataUpdateClick}
              label="Next"
              className="primary-button"
            />
          </div>
        </>
      </MainContentWrapper>
      {assetClassModalVisibility && (
        <DialogueModal
          callback={setAssetClassModalVisibility}
          header="Add Asset Class"
          customClassname="assetClassModel"
          onSubmit={submitClickHandler}
        >
          <div className="modal-dialogue-body">
            <TextInput
              header="Name of the Asset Class"
              placeholder="Senior Loans"
              width="35vw"
              value={assetClassName}
              onChangeHandler={setAssetClassName}
            />
            <br />
            <TextInput
              header="Total AUM of the Asset Class"
              placeholder="200 $M"
              value={aumValue}
              onChangeHandler={setAumValue}
            />
          </div>
        </DialogueModal>
      )}
      {editAssetClassModalVisibility && (
        <DialogueModal
          callback={(res) => {
            setAssetClassName("");
            setAumValue("");
            setEditAssetClassModalVisibility(res);
          }}
          header="Edit Asset Class"
          onSubmit={submitClickHandlerEditMode}
          customClassname="assetClassModel"
        >
          <div className="modal-dialogue-body">
            <TextInput
              header="Name of the Asset Class"
              placeholder="Senior Loans"
              width="35vw"
              value={assetClassName}
              onChangeHandler={setAssetClassName}
            />
            <br />
            <TextInput
              header="Total AUM of the Asset Class"
              placeholder="200 $M"
              width="35vw"
              value={aumValue}
              onChangeHandler={setAumValue}
            />
          </div>
        </DialogueModal>
      )}
      {deleteModal && (
        <ConfirmationModal
          content={confirmationModalContent}
          confirmText="Yes, Delete!"
          cancelText="No, Keep it"
          confirmBtnClickhandler={deleteModalConfirmationHandler}
          cancelBtnClickHandler={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AssetClass;

type subAssetClassType = {
  sub_asset_class: string;
  total_sub_asset_amount: number | string;
};

type assetObject1Type = {
  aum_assetclass: string;
  aum_dollars: number | string;
  sub_asset: subAssetClassType[];
  pk?: string;
  sk?: string;
};

const assetObject1: assetObject1Type[] = [
  {
    aum_assetclass: "",
    aum_dollars: 0,
    sub_asset: [
      {
        sub_asset_class: "",
        total_sub_asset_amount: 0,
      },
    ],
    pk: "",
    sk: "",
  },
];
