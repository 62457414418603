import React, { useState } from "react";

// import styles file
import "./DensityBlockChart.scss";

// import custom components
import Tooltip from "../../Core/Tooltip/Tooltip";

type valueStructure = {
  value: number;
  name: string;
  percent?: number | 0;
  rgbValues?: string;
};

type Props = {
  chartData: valueStructure[];
  header: string;
  cssPosition?: "unset" | "absolute";
  chartStyle?: "grid" | "flex";
  chartOrientation?: "horizontal" | "vertical";
  labelInsideBlock?: boolean;
};

const DensityBlockChart = ({
  header,
  chartData,
  chartStyle = "flex",
  cssPosition = "unset",
  chartOrientation = "horizontal",
  labelInsideBlock = false,
}: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);
  const [toolTipPoisition, setToolTipPosition] = useState<number>(0);

  const handleMouseEnter = (index: number) => (event: React.MouseEvent) => {
    const { width } = event.currentTarget.getBoundingClientRect();
    setToolTipPosition(width / 2);
    setHoveredIndex(index);
    setHovered(true);
  };

  return (
    <div
      className="density-chart-graph-container"
      style={{ height: `${chartStyle === "flex" ? "100%" : "500px"}` }}
    >
      <div className="chart-header" style={{ position: cssPosition }}>
        {header}
      </div>
      <div className="graph-container-flex">
        {chartData &&
          chartData.map((item, index) => {
            return (
              <div
                key={item.name}
                className="block-filler"
                style={{
                  width: `${(item.value>=0 && item.value<=2)?5:(item.value || 1)}%`,
                  backgroundColor: `rgba(${item.rgbValues}, ${
                    1 - 0.1 * index
                  })`,
                  color: "black",
                }}
                onMouseEnter={handleMouseEnter(index)}
                onMouseLeave={() => {
                  setHovered(false);
                }}
              >
                {hovered && hoveredIndex === index && (
                  <div
                    className="tooltip-absolute"
                    style={{ left: `${toolTipPoisition}` }}
                  >
                    <Tooltip
                      borderColor="#E6E6E6"
                      backgroundColor="white"
                      toolTipContent={
                        <div className="tooltip-content-container">
                          <div className="tooltip-content-container-dot-parent">
                            <div
                              className="tooltip-content-container-dot"
                              style={{
                                backgroundColor: `rgba(${item.rgbValues}, ${
                                  1 - 0.1 * index
                                })`,
                              }}
                            ></div>
                          </div>
                          <div className="tooltip-content-container-text">
                            {item.name}
                          </div>
                          <div className="tooltip-content-container-value">
                            {item.value}%
                          </div>
                        </div>
                      }
                      toolTipArrowPosition={
                        index < chartData.length - 3 ? "center" : "right"
                      }
                    />
                  </div>
                )}
                {labelInsideBlock && item.name}
                <br />
                {item.value}%
              </div>
            );
          })}
      </div>
      {!labelInsideBlock && (
        <div className="legends-collection">
          {chartData.map((item, index) => {
            return (
              <div
                key={item.name}
                className="legend-collection-block cursorPointer"
                title={item.name}
              >
                <div
                  className="indicator"
                  style={{
                    backgroundColor: `rgba(${item.rgbValues}, ${
                      1 - 0.1 * index
                    })`,
                  }}
                ></div>
                <div className="legend">{item.name}</div>
              </div>
            );
          })}
        </div>
      )}
      {/* <div className="graph-container-flex-vertical d-none">
        {chartData.map((item, index) => {
          return (
            <div
              className="grid-box"
              style={{
                height: `${item.value}%`,
                backgroundColor: `${colors.primaryColors[index]}`,
              }}
              key={item.name}
            >
              <div className="labels">{item.name}</div>
              <div className="values">{item.value}%</div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default DensityBlockChart;
