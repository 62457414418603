// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./Sector.scss";

// import custom components
import StatsTile from "../../../../Components/Core/StatsTile/StatsTile.component";
import WeightedAvgBarGraph from "../AssetClass/WeightedAvgBarGraph/WeightedAvgBarGraph";
import EmissionByCountry from "../AssetClass/EmissionByCountry";
import EmissionDoughnutChart from "./EmissionDoughnutChart/EmissionDoughnutChart";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectSectorDataResponse } from "../../../../Redux/Slices/dashboard/financedEmission/sector/getSectorData.selector";
import { getSectorData } from "../../../../Redux/Slices/dashboard/financedEmission/sector/getSectorData.slice";
import AttributedEmission from "./AttributedEmission/AttributedEmission";
import HighEmittingInvestees from "../HighEmittingInvestees/HighEmittingInvestees";
import EmissionByPeriod from "./EmissionByPeriod/EmissionByPeriod";
import Dropdown from "../../../../Components/Core/DropdownV2/Dropdown";
import { pcaf } from "../../../../assets/images/images";
import AvgDataQualityTable from "./AvgDataQualityTable/AvgDataQualityTable";

//type definition
type Props = {};

type sectorObj = {
  [key: string]: any;
};

type sectorDataType = {
  attributed_total_financed_emission?: number;
  last_year_attributed_total_financed_emission?: number;
  waci_investment?: number;
  last_year_waci_investment?: number;
  waci_revenue?: number;
  last_year_waci_revenue?: number;
  average_data_quality_score?: number;
  last_year_average_data_quality_score?: number;
  attributed_emission_by_sector?: sectorObj;
};

type dropDownOptionType = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

const Sector = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [sectorTabs, setSectorTabs] = useState<dropDownOptionType[]>([]);

  const [subSectorTabs, setSubSectorTabs] = useState<dropDownOptionType[]>([]);
  const [receivedSectorData, setReceivedSectorData] = useState<sectorDataType>(
    {}
  );
  const [selectedSector, setSelectedSector] = useState<string>("All");
  const [selectedSubSector, setSelectedSubSector] = useState<string>("All");

  const sectorData = useSelector(selectSectorDataResponse);

  useEffect(() => {
    dispatch(getSectorData({ sector: "all", sub_sector: "all" }));
  }, []);

  useEffect(() => {
    if (sectorData && sectorData?.data?.attributed_emission_by_sector) {
      let tempSectorTabs =
        sectorData?.data?.attributed_emission_by_sector.map((el: any) => {
          return { name: el.sector };
        }) ?? [];

      setSectorTabs([{ name: "All" }, ...tempSectorTabs]);
    }

    if (sectorData && sectorData?.data?.attributed_emission_by_sub_sector) {
      let tempSectorTabs =
        sectorData?.data?.attributed_emission_by_sub_sector.map((el: any) => {
          return { name: el.sub_sector };
        }) ?? [];

      if (tempSectorTabs && tempSectorTabs.length > 0) {
        setSubSectorTabs([{ name: "All" }, ...tempSectorTabs]);
      } else {
        setSubSectorTabs([]);
      }
    }

    setReceivedSectorData(sectorData?.data);
  }, [sectorData]);

  const parentDropDownClickHandler = (receivedOption: any) => {
    if (receivedOption[0].name === "All") {
      setSelectedSubSector("All");
    }
    setSelectedSector(receivedOption[0].name);
    dispatch(
      getSectorData({
        sector: receivedOption[0].name,
        sub_sector: selectedSubSector,
      })
    );
  };

  const childDropDownClickHandler = (receivedOption: any) => {
    setSelectedSubSector(receivedOption[0].name);
    dispatch(
      getSectorData({
        sector: selectedSector,
        sub_sector: receivedOption[0].name,
      })
    );
  };

  return (
    <div className="financed-emission-sectors-component noScrollBar">
      <div className="header-filter-row">
        <div className="header">
          <div>Sectors</div>
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <img alt="powered by science based targets" src={pcaf} />
            </div>
          </div>
        </div>
      </div>
      <div className="demographic-container d-grid-c8">
        <div className="demographic-tile col-8 d-grid-c8">
          <div className="asset-class-filter-dropdowns col-8">
            <div className="asset-class-dropdown-1">
              <p>Sector</p>
              <Dropdown
                options={sectorTabs}
                selectedCallBack={(e) => {
                  parentDropDownClickHandler(e);
                }}
                defaultOption={[{ name: "All", img: "" }]}
              />
            </div>
            {selectedSector !== "All" &&
              subSectorTabs &&
              subSectorTabs.length > 0 && (
                <div className="asset-class-dropdown-2">
                  <p>Sub sector</p>
                  <Dropdown
                    options={subSectorTabs}
                    selectedCallBack={(e) => {
                      childDropDownClickHandler(e);
                    }}
                    defaultOption={[{ name: "All", img: "" }]}
                  />
                </div>
              )}
          </div>
          <div className="asset-class-partitioner col-8"></div>
          <div className="stats-row col-8 d-grid-c8">
            {(selectedSector === "All" || selectedSubSector === "All") && (
              <div className="col-4">
                <AttributedEmission
                  receivedFinancedEmissionData={receivedSectorData}
                />
              </div>
            )}
            {(selectedSector === "All" || selectedSubSector === "All") && (
              <div className="col-4 d-grid-c6">
                <div className="col-3">
                  <StatsTile
                    header="Attributed Emission"
                    value={
                      `${receivedSectorData?.attributed_total_financed_emission}` ??
                      "0"
                    }
                    icon="error"
                    progress={`${
                      receivedSectorData?.last_year_attributed_total_financed_emission ??
                      0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                    extraStyles={{ marginBottom: "1rem" }}
                  />
                  <StatsTile
                    header="WACI /$M Investment"
                    value={`${receivedSectorData?.waci_investment}` ?? "0"}
                    icon="error"
                    progress={`${
                      receivedSectorData?.last_year_waci_investment ?? 0
                    }%`}
                    unit="MtCO2e"
                    theme="#F3F9FA"
                  />
                </div>
                <div className="col-3">
                  <StatsTile
                    header="WACI /$M Revenue"
                    value={`${receivedSectorData?.waci_revenue}` ?? "0"}
                    icon="error"
                    unit="MtCO2e"
                    theme="#F3F9FA"
                    progress={`${
                      receivedSectorData?.last_year_waci_revenue ?? 0
                    }%`}
                    extraStyles={{ marginBottom: "1rem" }}
                  />
                  <StatsTile
                    header="Average Data Quality Score"
                    value={
                      `${receivedSectorData?.average_data_quality_score}` ?? "0"
                    }
                    icon="error"
                    unit="MtCO2e"
                    progress={`${
                      receivedSectorData?.last_year_average_data_quality_score ??
                      0
                    }%`}
                    theme="#F3F9FA"
                  />
                </div>
              </div>
            )}
            {selectedSubSector !== "All" && (
              <div className="col-8 horizontally-aligned-cards">
                <StatsTile
                  header="Attributed Emission"
                  value={
                    `${receivedSectorData?.attributed_total_financed_emission}` ??
                    "0"
                  }
                  icon="error"
                  progress={`${
                    receivedSectorData?.last_year_attributed_total_financed_emission ??
                    0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  extraStyles={{ marginBottom: "1rem" }}
                />
                <StatsTile
                  header="WACI /$M Investment"
                  value={`${receivedSectorData?.waci_investment}` ?? "0"}
                  icon="error"
                  progress={`${
                    receivedSectorData?.last_year_waci_investment ?? 0
                  }%`}
                  unit="MtCO2e"
                  theme="#F3F9FA"
                />
                <StatsTile
                  header="WACI /$M Revenue"
                  value={`${receivedSectorData?.waci_revenue}` ?? "0"}
                  icon="error"
                  unit="MtCO2e"
                  theme="#F3F9FA"
                  progress={`${
                    receivedSectorData?.last_year_waci_revenue ?? 0
                  }%`}
                  extraStyles={{ marginBottom: "1rem" }}
                />
                <StatsTile
                  header="Average Data Quality Score"
                  value={
                    `${receivedSectorData?.average_data_quality_score}` ?? "0"
                  }
                  icon="error"
                  unit="MtCO2e"
                  progress={`${
                    receivedSectorData?.last_year_average_data_quality_score ??
                    0
                  }%`}
                  theme="#F3F9FA"
                />
              </div>
            )}
          </div>
        </div>

        {(selectedSector === "All" || selectedSubSector === "All") && (
          <div className="demographic-tile col-5">
            <WeightedAvgBarGraph
              mode={selectedSector !== "All" ? "sub_sector" : "sector"}
              receivedFinancedEmissionData={receivedSectorData}
            />
          </div>
        )}

        {(selectedSector === "All" || selectedSubSector === "All") && (
          <div className="demographic-tile col-3">
            <AvgDataQualityTable
              receivedFinancedEmissionData={receivedSectorData}
            />
          </div>
        )}

        <div className="demographic-tile col-3">
          <HighEmittingInvestees
            receivedFinancedEmissionData={receivedSectorData}
          />
        </div>

        <div className="demographic-tile col-5">
          <EmissionByPeriod
            receivedFinancedEmissionData={receivedSectorData}
            sectorTabs={sectorTabs}
            selectedSectorTab={selectedSector}
          />
        </div>

        {selectedSector !== "All" && (
          <div className="demographic-tile col-5">
            <EmissionDoughnutChart
              receivedFinancedEmissionData={receivedSectorData}
            />
          </div>
        )}
        {selectedSector !== "All" && (
          <div className="demographic-tile col-3">
            <EmissionByCountry
              receivedFinancedEmissionData={receivedSectorData}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sector;
