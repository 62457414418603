import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";
import { ReactComponent as CloudIcon } from "../../../../assets/icons/cloudCarbondiooxide.svg";
import { ReactComponent as FilledCloudIcon } from "../../../../assets/icons/filledCloud.svg";
import "./EmissionByLocation.scss";
import { abbreviatedNumber } from "../../../../utils/helpers/NumberConverter";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData?: dynamicObj;
  onClickHandler?: (path: string) => void;
}

const EmissionByLocation = ({
  dashboardOverviewData,
  onClickHandler,
}: Props) => {
  const [countriesData, setCountriesData] = useState<{ [key: string]: any }>(
    []
  );

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.emission_by_location) {
      let emissionByLocationValues =
        dashboardOverviewData.emission_by_location.map(
          (country: any) => country.emission_value
        );
      let maxEmissionValue = Math.max(...emissionByLocationValues);
      let minEmissionValue = Math.min(...emissionByLocationValues);

      let emissionByLocationCopy =
        dashboardOverviewData.emission_by_location.map((country: any) => {
          if (
            country.emission_value >
            maxEmissionValue - maxEmissionValue * 0.1
          ) {
            country = {
              ...country,
              svgJsx: (
                <g>
                  <foreignObject width="40" height="40" x="-20" y="-20">
                    <div className={`marker-filledCloudIcon-container`}>
                      <FilledCloudIcon />
                      <div className="marker-circle"></div>
                    </div>
                  </foreignObject>
                </g>
              ),
            };
          } else if (
            maxEmissionValue - maxEmissionValue * 0.1 <
              country.emission_value ||
            country.emission_value > minEmissionValue + minEmissionValue * 0.1
          ) {
            country = {
              ...country,
              svgJsx: (
                <g>
                  <foreignObject width="40" height="40" x="-20" y="-20">
                    <div className={`marker-filledCloudIcon-container`}>
                      <FilledCloudIcon className="filledcloud-yellow" />
                      <div className="marker-circle marker-circle-yellow"></div>
                    </div>
                  </foreignObject>
                </g>
              ),
            };
          } else {
            country = {
              ...country,
              svgJsx: (
                <g>
                  <foreignObject width="40" height="40" x="-20" y="-20">
                    <div className={`marker-filledCloudIcon-container`}>
                      <FilledCloudIcon className="filledcloud-green" />
                      <div className="marker-circle marker-circle-green"></div>
                    </div>
                  </foreignObject>
                </g>
              ),
            };
          }
          return country;
        });
      setCountriesData(emissionByLocationCopy);
    }
  }, [dashboardOverviewData]);

  const getStyle = (val: number) => () => {
    if (val > 210) {
      return {
        border: "0.6px solid rgba(250, 91, 91, 0.12)",
        background: "rgba(250, 91, 91, 0.06)",
      };
    } else if (val > 100) {
      return {
        border: "0.6px solid rgba(255, 153, 0, 0.12)",
        background: "rgba(255, 153, 0, 0.06)",
      };
    } else {
      return {
        border: "0.6px solid rgba(121, 172, 46, 0.12)",
        background: "rgba(121, 172, 46, 0.06)",
      };
    }
  };

  const getClassName = (val: number) => {
    if (val > 210) {
      return "cloud-icon-red";
    } else if (val > 100) {
      return "cloud-icon-orange";
    } else {
      return "cloud-icon-green";
    }
  };

  return (
    <>
      <div className="maplist-container-header">Emission by Location</div>
      <div className="maplist-container-map-section">
        <Graphs.MapGraph
          header=""
          className="maplist-container-map"
          projectionConfig={{
            center: [0, 30],
            scale: 128,
          }}
          mapData={countriesData}
        />
      </div>
      <div className="maplist-container-list-section">
        <div className="maplist-container-list-section-title">
          High Emitting Countries
        </div>
        <div className="maplist-container-list-section-body-parent">
          {countriesData.map((el: any) => (
            <div
              className="maplist-container-list-section-body"
              onClick={() => onClickHandler?.(el.country)}
            >
              <div className="listitem-image-container">
                <img src={el.img} alt="" />
              </div>
              <div className="listitem-details-container">
                <div
                  className="details-container-emission"
                  style={getStyle(el.emission_value)()}
                >
                  <div className="details-container-emissionandicon">
                    <div className="details-container-emission-cloud-icon">
                      <CloudIcon className={getClassName(el.emission_value)} />
                    </div>
                    <div>Emission</div>
                  </div>
                  <span className="details-container-emission-value">
                    {abbreviatedNumber(el.emission_value)} Mtco2e
                  </span>
                </div>
                <div className="details-container-country">{el.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmissionByLocation;
