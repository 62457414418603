//  import library compoenents
import { createSelector } from "reselect";
import { RootState } from "../../../../store";


// no need to create a base selector 
const dataFillingModeSwitchStore = (state: RootState) => state.dataFillModeSwitchSlice;

export const selectDataFillingModeSwitch = createSelector(
    [dataFillingModeSwitchStore],
    (switchState) => switchState.selectedSwitch
)