import moment from "moment";
export function dateConversion(
  date: string,
  format:
    | "dd/mm/yyyy"
    | "yyyy/mm/dd"
    | "mm/dd/yyyy"
    | "dd/mm/yyyy-hh/mm"
    | "day:tt/dd/mm/yyyy"
): string {
  if (!date) return "";

  const dateObj = new Date(date);

  if (format === "dd/mm/yyyy") {
    return `${dateObj.getDate()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()}`;
  } else if (format === "yyyy/mm/dd") {
    return `${dateObj.getFullYear()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getDate()}`;
  } else if (format === "mm/dd/yyyy") {
    return `${
      dateObj.getMonth() + 1
    }-${dateObj.getDate()}-${dateObj.getFullYear()}`;
  } else if (format === "dd/mm/yyyy-hh/mm") {
    return `${dateObj.getDate()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()} ${dateObj.getHours()}:${dateObj.getMinutes()}`;
  } else if (format === "day:tt/dd/mm/yyyy") {
    return `${dateObj.getDay()}: ${dateObj.getTime()}-${dateObj.getDate()}-${
      dateObj.getMonth() + 1
    }-${dateObj.getFullYear()}`;
  }

  return `${dateObj}`;
}

export function formatDateString(date: string) {
  let parsedDate = moment(date);
  const formattedDate = parsedDate.format("DD/MM/YYYY");

  return formattedDate;
}