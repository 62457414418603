import { useState, useEffect } from "react";
import ColoredRowList from "../../../GHGemission/ColoredRowList/ColoredRowList";
import { colors } from "../../../../../DesignSystem/Colors/colors";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const SectorEmissionByAssetClass = ({
  receivedFinancedEmissionData,
}: Props) => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.sector_emission_by_asset_class
    ) {
      let tempRowData =
        receivedFinancedEmissionData.sector_emission_by_asset_class?.map(
          (el: any) => {
            return {
              text: <>{el.sector}</>,
              value: `${el.financed_emission} Mtco2e`,
            };
          }
        );

      setRowData(tempRowData);
    }
  }, [receivedFinancedEmissionData]);

  // sector_emission_by_asset_class
  return (
    <>
      <div className="chart-header">Sector Emission by Asset Class</div>
      <ColoredRowList rowArr={rowData} colorArr={colors.primaryColors} />
    </>
  );
};

export default SectorEmissionByAssetClass;
