//import library components
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

// import initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
    asset_class?: string;
    from_date?: string;
    to_date?: string;
}


export const getInvesteeActivityLogs: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'investee/getActivityLogs',
    async ({ asset_class, to_date, from_date }, { rejectWithValue }) => {
        try {
            const response = await fetch(
                api.config.getActivityLog(asset_class, to_date, from_date),
                api.http.get()
            )

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
            return result;

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            rejectWithValue(error)
        }
    }
)

const investeeActivityLogs = createSlice({
    name: 'investeeActivityLogs',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getInvesteeActivityLogs.pending, (state, _) => {
                state.isLoading = true
            })
            .addCase(getInvesteeActivityLogs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getInvesteeActivityLogs.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
            })
    },
})

export default investeeActivityLogs.reducer