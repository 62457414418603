import { useEffect, useState } from "react";
import CheckBox from "../../../../Components/Core/CheckBox/CheckBox";
import "./FilterCard.scss";

interface ICheckboxList {
  value?: string;
  checked?: boolean;
}

interface IFilterCardPops {
  header?: string;
  checkboxList?: ICheckboxList[];
  applyBtnHandler?: (e: any) => void;
}

const FilterCard = ({
  header = "header",
  checkboxList = [{ value: "checkbox1", checked: false }],
  applyBtnHandler,
}: IFilterCardPops) => {
  const [checkBoxesStatus, setCheckBoxesStatus] =
    useState<ICheckboxList[]>(checkboxList);

  useEffect(() => {
    setCheckBoxesStatus(checkboxList);
  }, [checkboxList]);

  return (
    <div className="filtercard-container col-3">
      <div>
        <div className="header">
          <div>{header}</div>
          <div
            className="btn-selectAll"
            onClick={() => {
              setCheckBoxesStatus((prevState) =>
                prevState.map((checkBoxObj) =>
                  !checkBoxObj.checked
                    ? { ...checkBoxObj, checked: true }
                    : checkBoxObj
                )
              );
            }}
          >
            Select all
          </div>
        </div>
        <div className="list hide-scrollbar">
          {checkBoxesStatus &&
            checkBoxesStatus.length > 0 &&
            checkBoxesStatus.map((el, index) => {
              return (
                <CheckBox
                  name={el.value ?? ""}
                  checked={el.checked ?? false}
                  toggleChecked={(receivedVal) => {
                    setCheckBoxesStatus((prevState) =>
                      prevState.map((checkBoxObj) =>
                        el.value === checkBoxObj.value
                          ? { ...checkBoxObj, checked: receivedVal }
                          : checkBoxObj
                      )
                    );
                  }}
                />
              );
            })}
        </div>
      </div>
      <div className="footer-btns">
        <div
          className="btns apply"
          onClick={() => {
            applyBtnHandler?.(checkBoxesStatus);
          }}
        >
          Apply filter
        </div>
        <div
          className="btns deselect"
          onClick={() => {
            setCheckBoxesStatus((prevState) =>
              prevState.map((checkBoxObj) =>
                checkBoxObj.checked
                  ? { ...checkBoxObj, checked: false }
                  : checkBoxObj
              )
            );
          }}
        >
          Deselect all
        </div>
      </div>
    </div>
  );
};

export default FilterCard;
