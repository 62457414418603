import React, { useEffect, useState } from "react";
import AvgDataQualityTable from "../AssetClass/AvgDataQualityTable";
import { Graphs } from "../../../../Components/graphs";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const HighEmittingInvestees = ({ receivedFinancedEmissionData }: Props) => {
  const [tableData, setTableData] = useState([
    ["Salesforce.com", 50],
    ["Glasglow Catering", 60],
    ["Roseburg Product Co", 40],
    ["Five Winds Project, LCC", 20],
    ["Red Studio Design", 90],
    ["ACME", 10],
    ["RCE, Inc", 10],
    ["Office Print & Ship Services", 10],
  ]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.high_emitting_financed_entities
    ) {
      let tempTableData =
        receivedFinancedEmissionData.high_emitting_financed_entities?.map(
          (el: any) => [el.investee_name, el.financed_emission]
        ) ?? [];
      setTableData(tempTableData);
    }
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.MultiBarGraph
      header="High Emitting Investees (tCO2e)"
      chartData={tableData}
      extraStyles={{ height: "450px" }}
    />
  );
};

export default HighEmittingInvestees;
