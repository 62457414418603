// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";


// create base selector of the reducer
const selectDropdownContentsStore = (state: RootState) => state.getDropdowncontents;

export const selectDropdownContentResponse = createSelector(
    [selectDropdownContentsStore],
    (dropdownList) => dropdownList.response
)