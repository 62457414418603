// import library components
import api from "../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";


// payload type definition
type payloadType = {
    dataType: string;
    dataSource: string;
    search_key?: string;
    next_token?: string;
    data_source_filter?: string[]
};

export const getInvesteeInventoryAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'investeeInventory/getInvesteeInventoryList',
        async ({ dataType, dataSource, search_key, next_token, data_source_filter }, { rejectWithValue }) => {
            try {
                //dataType, dataSource
                const response = await fetch(
                    api.config.getInvesteeInventoryList(dataType, dataSource, search_key, next_token, data_source_filter),
                    api.http.get()
                )

                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const investeeInventoryList = createSlice({
    name: 'getInvesteeInventoryList',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getInvesteeInventoryAction.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getInvesteeInventoryAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getInvesteeInventoryAction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorResponse = action.error;
            })
    },
})

export default investeeInventoryList.reducer;