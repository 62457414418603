import React, { useEffect, useState } from "react";
import StatsTile from "../../../../Components/Core/StatsTile/StatsTile.component";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData?: dynamicObj;
}

// Define a type for the entries
type DashboardDataEntry = {
  [key: string]: any;
};

const DataStatsDisplay = ({ dashboardOverviewData }: Props) => {
  const [dashboardCurrYearEmissionData, setDashboardCurrYearEmissionData] =
    useState<DashboardDataEntry>({});
  let statsTileArr = new Array(4).fill(0);

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.current_year_emission) {
      setDashboardCurrYearEmissionData(
        dashboardOverviewData.current_year_emission
      );
    }
  }, [dashboardOverviewData]);

  let dashboardCoreDataMap = {
    0: "Scope 1 Emission",
    1: "Scope 2 Emission",
    2: "Scope 3 Emission",
    3: "Financed Emission",
  } as { [key: number]: string };

  let prevDashboardDataMap = {
    0: "previous_scope1_emission",
    1: "previous_scope2_emission",
    2: "previous_scope3_emission",
    3: "previous_financed_emission",
  } as { [key: number]: string };

  let flagDashboardDataMap = {
    0: "is_scope1_emission_decreased",
    1: "is_scope2_emission_decreased",
    2: "is_scope3_emission_decreased",
    3: "is_financed_emission_decreased",
  } as { [key: number]: string };

  return (
    <>
      {statsTileArr.map((el, index: number) => {
        return (
          <div className="col-2" style={{ marginTop: "2rem" }}>
            <StatsTile
              header={dashboardCoreDataMap[index]}
              value={`${
                dashboardCurrYearEmissionData[dashboardCoreDataMap[index]]
              }`}
              unit="MtCO2e"
              icon={
                dashboardCurrYearEmissionData[flagDashboardDataMap[index]]
                  ? "success"
                  : "error"
              }
              progress={`${
                dashboardCurrYearEmissionData[prevDashboardDataMap[index]]
              }%`}
            />
          </div>
        );
      })}
    </>
  );
};

export default DataStatsDisplay;
