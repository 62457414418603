import React, { useEffect, useState } from "react";
import "./Benchmarking.scss";
import { ReactComponent as NoDataIcon } from "../../../assets/icons/nodata.svg";
import BenchmarkingCard from "./BenchmarkingCard";
import BenchmarkingHeader from "./BenchMarkingHeader";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { useSelector } from "react-redux";
import { selectBenchmarkingListDataResponse } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingList/getBenchmarkingList.selector";
import { getBenchmarkingListData } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingList/getBenchmarkingListData.slice";

type receivedBenchmarkingListObj = {};

const Benchmarking = () => {
  const dispatch = useDispatch<AppDispatch>();
  const benchmarkingListData = useSelector(selectBenchmarkingListDataResponse);

  const [receivedBenchmarkingListData, setReceivedBenchmarkingListData] =
    useState<receivedBenchmarkingListObj[]>([]);

  useEffect(() => {
    dispatch(getBenchmarkingListData({}));
  }, []);

  useEffect(() => {
    setReceivedBenchmarkingListData(benchmarkingListData?.data ?? []);
  }, [benchmarkingListData]);

  return (
    <div>
      <BenchmarkingHeader />
      <div className="benchmarking-body-container">
        {receivedBenchmarkingListData &&
          receivedBenchmarkingListData.length === 0 && (
            <div className="no-data-msg-container">
              <div className="no-data-icon-container">
                <NoDataIcon />
              </div>
              <div className="msg-container">
                <b>No data yet...</b>
                <b>You haven't created any benchmarking yet.</b>
              </div>
            </div>
          )}
        {/* {data && <Comparison />} */}

        {receivedBenchmarkingListData &&
          receivedBenchmarkingListData.length > 0 && (
            <div className="benchmarks-cards-container">
              {receivedBenchmarkingListData.map((el) => (
                <BenchmarkingCard cardContent={el} />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default Benchmarking;
