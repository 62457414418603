import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData: dynamicObj;
}

const EmissionAumCovered = ({ dashboardOverviewData }: Props) => {
  const [outstandingAmount, setOutstandingAmount] = useState(0);
  const [values, setValues] = useState<number[]>([]);

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.emission_aum_covered) {
      setValues([
        dashboardOverviewData.emission_aum_covered.calculated_value,
        dashboardOverviewData.emission_aum_covered.uncalculated_value,
      ]);
      setOutstandingAmount(
        dashboardOverviewData.emission_aum_covered.outstanding_amount
      );
    }
  }, [dashboardOverviewData]);

  return (
    <Graphs.PieChart
      header="Emission AUM Covered"
      tooltip="Calculated"
      labels={["Calculated", "Uncalculated"]}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      legendsPosition="bottom"
      doughNut="80%"
      graphColors={["#14C99E", "#EFEFEF"]}
      valueInLegend={true}
      annotations={{
        annotations: {
          label1: {
            type: "label",
            xValue: 2.5,
            yValue: 60,
            backgroundColor: "rgba(0,0,0,0)",
            content: [outstandingAmount],
            font: {
              size: 30,
            },
          },
        },
      }}
    />
  );
};

export default EmissionAumCovered;
