// import library components
import React, { useId, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import styles file
import "./DatePicker.scss";

// import custom components
import { ReactComponent as Calendar } from "../../../assets/icons/calendar-icon.svg";
import { dateConversion } from "../../../utils/helpers/dateHandlers";

type dynamicObj = {
  [key: string]: any;
};

// type definitions
type Props = {
  header?: string;
  width?: string;
  value?: string | any;
  selectedDateCallback?: (value: string) => void;
  onFocusOut?: (object: any) => void;
  inputStyles?: dynamicObj;
};

const DatePickerComponent = ({
  header,
  width,
  selectedDateCallback,
  value,
  onFocusOut,
  inputStyles,
}: Props) => {
  const [startDate, setStartDate] = useState(
    value ? new Date(value) : new Date()
  );

  const datePickerID = useId();

  const handleDateClick = () => {
    document.getElementById(datePickerID)?.click();
  };

  const dateSelectHelper = (date: any) => {
    setStartDate(date);
    selectedDateCallback?.(date);
  };

  return (
    <div
      tabIndex={-1}
      id="DatePickerComponent"
      className="datepicker-container"
      style={{ width }}
      onBlur={onFocusOut}
    >
      {header && <div className="input-label">{header}</div>}

      <div className="container" style={inputStyles}>
        <div className="placeholder">
          {dateConversion(String(startDate), "dd/mm/yyyy")}
        </div>
        <div className="picker">
          <Calendar onClick={handleDateClick} />

          <DatePicker
            selected={startDate}
            onChange={(date: any) => dateSelectHelper(date)}
            id={datePickerID}
            className="d-none"
          />
        </div>
      </div>
    </div>
  );
};

export default DatePickerComponent;
