import api from "../../../../../../Api";
import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../../utils";

type payLoadType = {
  filter_type?: string;
  filter_values?: string[];
};

export const getDataByFilterTypeValues: AsyncThunk<any, payLoadType, {}> =
  createAsyncThunk(
    "getDataByFilterTypeValues",
    async ({ filter_type, filter_values }, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getDataByFilterTypeValues(filter_type, filter_values),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const dataByFilterType = createSlice({
  name: "dataByFilterType",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDataByFilterTypeValues.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getDataByFilterTypeValues.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getDataByFilterTypeValues.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default dataByFilterType.reducer;
