import React, { useEffect, useState } from "react";
import "./Analytics-Component.scss";
import FilterSection from "../../FilterSection/FilterSection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import SourcesOfEmission from "./SourcesOfEmission/SourcesOfEmission";
import EmissionByGas from "./EmissionByGas/EmissionByGas";
import Facilities from "./Facilities/Facilities";

type Props = {};

type emissionBreakdownObj = {
  [key: string]: any;
};

type ebsObj = {
  supplier_name: string;
  total_emission_co2e: number;
};

type eboObj = {
  organisation_unit: string;
  total_emission_co2e: number;
};

type receivedGhgEmissionDataType = {
  total_emission?: number;
  energy_consumed?: number;
  fuel_consumed?: number;
  last_year_total_emission?: number;
  last_year_energy_consumed?: number;
  renewables?: number;
  last_year_renewables?: number;
  emission_breakdown?: emissionBreakdownObj;
  total_emission_by_market?: number;
  last_year_total_emission_by_market?: number;
  total_emission_by_location?: number;
  last_year_total_emission_by_location?: number;
  emission_by_suppliers?: ebsObj[];
  emission_by_organisation_unit?: eboObj[];
};

const AnalyticsComponent = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionDataResponse);

  const [receivedGhgEmissionData, setReceivedGhgEmissionData] =
    useState<receivedGhgEmissionDataType>({ last_year_total_emission: 0 });
  const [fixedGhgEmissionData, setFixedGhgEmissionData] =
    useState<receivedGhgEmissionDataType>({});

  const [radioBtnArr, setRadioBtnArr] = useState([
    { text: "Time Period", checked: true },
    { text: "Facility Type", checked: false },
    { text: "Organization Unit", checked: false },
  ]);

  useEffect(() => {
    dispatch(
      getGhgEmissionData({
        scope_type: "ANALYTICS",
        emission_year: "2023",
      })
    );
    setFixedGhgEmissionData(ghgEmissionData?.data);
  }, [dispatch]);

  useEffect(() => {
    setReceivedGhgEmissionData(ghgEmissionData?.data);
  }, [ghgEmissionData]);

  return (
    <>
      <div className="main-chart-container d-grid-c8">
        <div className=" col-6 d-grid-c6">
          <div className="demographic-tile col-6">
            <Facilities
              radioBtnArr={radioBtnArr}
              receivedGhgEmissionData={receivedGhgEmissionData}
            />
          </div>
          <div className="demographic-tile col-4">
            <SourcesOfEmission
              receivedGhgEmissionData={receivedGhgEmissionData}
            />
          </div>
          <div className="demographic-tile col-2">
            <EmissionByGas receivedGhgEmissionData={receivedGhgEmissionData} />
          </div>
        </div>
        <div className="demographic-tile col-2">
          <FilterSection
            radioBtnArr={radioBtnArr}
            setRadioBtnArr={setRadioBtnArr}
            fixedGhgEmissionData={fixedGhgEmissionData}
          />
        </div>
      </div>
    </>
  );
};

export default AnalyticsComponent;
