// import libarary components
import { AsyncThunk, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

// import initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
    id?: string;
    targetScope?: string
}

export const getTargetDetailsAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'investee/getTargetDetails',
        async ({ targetScope, id }, { rejectWithValue }) => {

            try {
                const response = await fetch(
                    api.config.getTargetDetails(targetScope, id),
                    api.http.get()
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;

            } catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const investeeTargetDetails = createSlice({
    name: 'investeeTargetDetails',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getTargetDetailsAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getTargetDetailsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getTargetDetailsAction.rejected, (state, action,) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true
            })
    },
})

export default investeeTargetDetails.reducer;