import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "../../../utils";

type payloadType = {};

export const updateAssetClassAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "updateAssetClassAction",
    async (payload, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.updateAssetClassData(),
          api.http.post(payload)
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.post(payload)
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const updateAssetClass = createSlice({
  name: "updateAssetClass",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updateAssetClassAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateAssetClassAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(updateAssetClassAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.payload;
        state.isError = true;
      });
  },
});

export default updateAssetClass.reducer;
