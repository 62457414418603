//  improt library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

//  create base selector for reducer
const selectOnboardedInvesteeListStore = (state: RootState) => state.onboardedInvesteeList;

export const selectOnboardedInvesteeList = createSelector(
    [selectOnboardedInvesteeListStore],
    (selectOnboardedInvesteeListStore) => selectOnboardedInvesteeListStore.response
)

