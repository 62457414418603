import React, { useEffect, useState } from "react";
import "./ChartDetails.scss";

type dynamicObj = {
  [key: string]: any;
};

type Props = {
  receivedData?: dynamicObj;
  caculated_aum_key?: string;
  total_aum_key?: string;
};

const ChartDetails = ({
  receivedData,
  caculated_aum_key = "calculated_aum",
  total_aum_key = "total_aum",
}: Props) => {
  const [percentCalc, setPercentCalc] = useState<number>(0);

  useEffect(() => {
    if (receivedData) {
      let tempPercent =
        (receivedData[caculated_aum_key] * 100) / receivedData[total_aum_key];

      if (!tempPercent) tempPercent = 0;
      setPercentCalc(tempPercent);
    }
    // setPercentCalc
  }, [receivedData]);

  return (
    <div className="chart-details-container">
      <div>Asset Under Management Coverage</div>
      <div className="progress-bar">
        <div
          className="progress-bar-filled"
          style={{ width: `${percentCalc < 12 ? 12 : percentCalc}%` }}
        >
          <div className="progress-bar-filled-legend">
            {/* {`${Math.round(
              (receivedData?.[caculated_aum_key] ?? 0) / 1000000
            )}`}
            M */}
          </div>
        </div>
      </div>
      <div className="legend-container">
        <div className="legend-container-legend-1">
          <div className="legend-container-point"></div>
          <div>
            <b>{percentCalc}%</b> Calculated
          </div>
        </div>
        <div className="legend-container-legend-2">
          <div className="legend-container-point"></div>
          <div>
            <b>{100 - percentCalc}%</b> Uncalculated
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartDetails;
