// import library components
import api from "../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";


// payload type definition
type payloadType = {
    action_type: 'edit' | 'delete',
    data: any
};

export const patchAssetInventoryDataAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'assetInventory/patchAssetInventory',
        async (updatedDataList, { rejectWithValue }) => {
            try {

                const response = await fetch(
                    api.config.patchAssetInventory(),
                    api.http.post(updatedDataList)
                )

                const result = await api.afterFetchHandlers.parseContent(response, api.http.post())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const patchAssetInventory = createSlice({
    name: 'patchAssetInventory',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(patchAssetInventoryDataAction.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(patchAssetInventoryDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(patchAssetInventoryDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorResponse = action.error;
            })
    },
})

export default patchAssetInventory.reducer;