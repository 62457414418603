import { ReactNode } from "react";

interface IProps {
  value: number;
  text?: ReactNode;
}

export const RangeBasedColorAssigner = (
  receivedArr: IProps[],
  range: number,
  colorArr: string[]
): [IProps[], number, number] => {
  let clonedReceivedArr = receivedArr.map((el) => el.value);
  let maxVal = Math.max(...clonedReceivedArr);
  let maxValLower = maxVal - maxVal * range * 0.01;
  let minVal = Math.min(...clonedReceivedArr);
  let minValUpper = minVal + maxVal * range * 0.01;

  let appendedColorArr = receivedArr.map((el) => {
    if (maxValLower <= el.value && el.value <= maxVal) {
      return { ...el, color: colorArr[2] };
    } else if (minVal <= el.value && el.value <= minValUpper) {
      return { ...el, color: colorArr[0] };
    } else {
      return { ...el, color: colorArr[1] };
    }
  });

  return [appendedColorArr, maxValLower, minValUpper];
};
