// import library components
import React, { useEffect, useRef, ReactNode } from 'react'

// import styles file
import './DialogueModal.scss'

// import custom components
import BackgroundScreen from '../BackgroundScreen/BackgroundScreen'
import { ReactComponent as CrossIcon } from '../../../assets/icons/crossIcon.svg'
import Button from '../Button/Button'

// type definitions
type Props = {
    children?: ReactNode;
    callback?: (negatedState: boolean) => void;
    onSubmit?: () => void;
    header: string;
    customClassname?: string;
}

const DialogueModal = ({ children, callback, onSubmit, header, customClassname }: Props) => {



    const modalRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        const handleModalClick = (event: MouseEvent): void => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                callback?.(false)
            }
        }
        if (modalRef && modalRef.current) {
            modalRef.current.addEventListener('click', handleModalClick)
        }
        return () => {
            if (modalRef && modalRef.current) {
                return modalRef.current.removeEventListener('click', handleModalClick)
            }
        }
    }, [])


    return (
        <BackgroundScreen
            customID='backgroundScreen-component'
        >
            <div
                ref={modalRef}
                className={`dialogue-modal-container ${customClassname}`}
                id='dialogue-component'
            >
                <div className="close-btn"
                    onClick={() => callback?.(false)}
                >
                    <CrossIcon />
                </div>

                <div className="diaogue-header">
                    {header}
                </div>

                <div className="dialogue-body">
                    {children}
                </div>

                <div className="footer-btns">
                    <Button
                        onClick={onSubmit}
                        label='Submit'
                        className='primary-button'
                    />
                </div>


            </div>
        </BackgroundScreen>
    )
}

export default DialogueModal