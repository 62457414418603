import React, { useEffect, useState } from "react";
import ToggleSwitch from "../../../../../Components/Core/ToggleSwitch/ToggleSwitch";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { getFinancedEmissionPortfolioAnalysisData } from "../../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getFinancedEmissionPortfolioAnalysisData.slice";
import { Graphs } from "../../../../../Components/graphs";
import { getAbbreviatedMonthName } from "../../../../../utils/helpers/MonthMapper";

type dataObj = {
  [key: string]: any;
};

interface IRadioBtnObj {
  text?: string;
  checked?: boolean;
}

type receivedFinanceEmissionPortfolioAnalysisDataType = {
  receivedFePortfolioAnalysisData?: dataObj;
  setReceivedFePortfolioAnalysisData?: (obj: any) => void;
  radioBtnArr?: IRadioBtnObj[];
  selectedScope?: number;
  setSelectedScope?: (scope: number) => void;
};

const EmissionBarGraph = ({
  receivedFePortfolioAnalysisData,
  setReceivedFePortfolioAnalysisData,
  radioBtnArr,
  selectedScope,
  setSelectedScope,
}: receivedFinanceEmissionPortfolioAnalysisDataType) => {
  const dispatch = useDispatch<AppDispatch>();
  const [values, setValues] = useState<number[]>([]);
  const [keys, setKeys] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>("attributed_emission");

  useEffect(() => {
    let tempVal = "";

    let yaxisKey = "financed_emission";

    if (selectedTab === "emission_per_investment") {
      yaxisKey = "emission_per_investment";
    }
    if (selectedTab === "wadq") {
      yaxisKey = "wadq_score";
    }

    radioBtnArr
      ?.filter((el) => el.checked)
      ?.forEach((el) => {
        if (el.text === "Asset Class") {
          tempVal = "asset_class";
          if (
            receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_asset_class`
            ]
          ) {
            let keyArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_asset_class`
            ].map((el: any) => el.asset_class);

            let valuesArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_asset_class`
            ].map((el: any) => el[yaxisKey]);

            setKeys(keyArr);
            setValues(valuesArr);
          }
        } else if (el.text === "Sector") {
          tempVal = "sector";
          if (
            receivedFePortfolioAnalysisData?.data?.[`${selectedTab}_by_sector`]
          ) {
            let keyArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_sector`
            ].map((el: any) => el.sector);

            let valuesArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_sector`
            ].map((el: any) => el[yaxisKey]);

            setKeys(keyArr);
            setValues(valuesArr);
          }
        } else if (el.text === "Time Period") {
          tempVal = "time_period";
          if (
            receivedFePortfolioAnalysisData?.data?.[`${selectedTab}_by_period`]
          ) {
            let keyArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_period`
            ].map((el: any) => getAbbreviatedMonthName(el.month.split("-")[1]));

            let valuesArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_period`
            ].map((el: any) => el[yaxisKey]);

            setKeys(keyArr);
            setValues(valuesArr);
          }
        } else if (el.text === "Location") {
          tempVal = "location";
          if (
            receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_location`
            ]
          ) {
            let keyArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_location`
            ].map((el: any) => el.country);
            let valuesArr = receivedFePortfolioAnalysisData?.data?.[
              `${selectedTab}_by_location`
            ].map((el: any) => el[yaxisKey]);

            setKeys(keyArr);
            setValues(valuesArr);
          }
        }
      });
  }, [receivedFePortfolioAnalysisData, selectedTab, radioBtnArr]);

  const toggleSwitchHandler = (receviedArrIndex: number) => {
    let toggleArr = [
      "attributed_emission",
      "emission_per_investment",
      "waci",
      "wadq",
    ];

    setSelectedTab(toggleArr[receviedArrIndex]);

    dispatch(
      getFinancedEmissionPortfolioAnalysisData({
        analysis_type: toggleArr[receviedArrIndex],
      })
    );
  };

  return (
    <div
      className="demographic-tile col-8 d-grid-c8"
      style={{ height: "580px" }}
    >
      <div
        className="demographic-tile tile-header col-6"
        style={{ height: "75px" }}
      >
        <ToggleSwitch
          switchState={[
            "Attributed Emission",
            "Emission/$M (INV)",
            "WACI (INV)",
            "WADQ (REV)",
          ]}
          selectedValue={selectedScope}
          onSwitchChange={(e) => {
            toggleSwitchHandler(e);
            setSelectedScope?.(e);
          }}
          toggleSwitchClassnameExtra="asset-class-toggle-switch"
          buttonClassnameExtra="asset-class-toggle-switch-btn"
          btnTheme="gray"
        />
      </div>
      <div className="col-8">
        <Graphs.BarGraph
          legendsDisabled
          yAxisLabel="Emission (MtCO2e)"
          labels={keys}
          datalabels={{
            datalabels: {
              display: true,
              anchor: "end",
              color: "white",
              borderRadius: 14,
              backgroundColor: "#9DCA5C",
              formatter: (value: any) => {
                return "    ";
              },
            },
          }}
          xAxisLabelVisibility={false}
          xGridVisibility={false}
          yAxisVisibility={false}
          borderWidth={10}
          barThickness={25}
          barColor="rgba(157, 202, 92, 0.40)"
          chartData={[
            {
              label: "tCO2e",
              values: values,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default EmissionBarGraph;
