import { createSlice } from "@reduxjs/toolkit";

type stateType = {
  [key: string]: any;
};

const INITIAL_STATE: stateType = {};

const benchmarkingData = createSlice({
  name: "benchmarkingData",
  initialState: INITIAL_STATE,
  reducers: {
    benchmarkingDataAction(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const { benchmarkingDataAction } = benchmarkingData.actions;

export const benchmarkingDataReducer = benchmarkingData.reducer;
