import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payLoadType = {
  investor_id?: string;
  competitor_investor_name?: string;
  asset_class?: string[];
  sector?: string[];
  location?: string[];
  x_axis?: string;
  y_axis?: string;
};

export const getBenchmarkingComparedData: AsyncThunk<any, payLoadType, {}> =
  createAsyncThunk(
    "getBenchmarkingComparedData",
    async (
      {
        investor_id,
        competitor_investor_name,
        asset_class,
        sector,
        location,
        x_axis,
        y_axis,
      },
      { rejectWithValue }
    ) => {
      try {
        const response = await fetch(
          api.config.getBenchmarkingComparedData(
            investor_id,
            competitor_investor_name,
            asset_class,
            sector,
            location,
            x_axis,
            y_axis
          ),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const benchmarkingComparedData = createSlice({
  name: "benchmarkingComparedData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBenchmarkingComparedData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getBenchmarkingComparedData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getBenchmarkingComparedData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default benchmarkingComparedData.reducer;
