import React, { ReactNode } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import features from "./features.json";
import "./mapgraph.scss";
import MarkerPin from "./MarkerPin";
import { PatternCircles } from "@vx/pattern";

interface DynamicObject {
  [key: string]: any;
}
interface IMapGraphProps {
  mapData?: any;
  header?: string;
  className?: string;
  projectionConfig?: DynamicObject;
  greenCountries?: string[];
  yellowCountries?: string[];
  redCountries?: string[];
  defaultMapBackgroundColor?: string;
}

const MapGraph = ({
  mapData = [],
  header,
  className,
  projectionConfig,
  greenCountries = [],
  yellowCountries = [],
  redCountries = [],
  defaultMapBackgroundColor = "#EAF7FC",
}: IMapGraphProps) => {
  const getCountryFillColor = (countryName: string): string => {
    if (redCountries.includes(countryName)) return `url('#red-circles')`;
    if (yellowCountries.includes(countryName)) return `url('#yellow-circles')`;
    if (greenCountries.includes(countryName)) return `url('#green-circles')`;
    return `url('#circles')`;
  };

  return (
    <div className={className}>
      <div
        className="chart-header"
        style={{ margin: "1.2rem", fontWeight: 500, fontSize: "1.6rem" }}
      >
        {header}
      </div>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={projectionConfig}
      >
        <PatternCircles
          id="circles"
          height={5}
          width={5}
          stroke={defaultMapBackgroundColor}
          strokeWidth={3}
          background={defaultMapBackgroundColor}
        />
        <PatternCircles
          id="green-circles"
          height={5}
          width={5}
          stroke={"#9DCA5C"}
          strokeWidth={3}
          background="#9DCA5C"
        />
        <PatternCircles
          id="red-circles"
          height={5}
          width={5}
          stroke={"#FA5B5B"}
          strokeWidth={3}
          background="#FA5B5B"
        />
        <PatternCircles
          id="yellow-circles"
          height={5}
          width={5}
          stroke={"#FACE5E"}
          strokeWidth={3}
          background="#FACE5E"
        />

        <Geographies
          geography={features}
          stroke="#FFF"
          strokeWidth={1.4}
          // className="map"
        >
          {({ geographies }) =>
            geographies.map((geo) => {
              return (
                <>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getCountryFillColor(geo.properties.name)}
                  />
                </>
              );
            })
          }
        </Geographies>
        {mapData.map((dataEl: any) => {
          return (
            <MarkerPin
              key={dataEl.latlng[0]}
              location={dataEl.latlng}
              svgJsx={dataEl.svgJsx}
              value={dataEl.emission_value}
            />
          );
        })}
      </ComposableMap>
    </div>
  );
};

export default MapGraph;
