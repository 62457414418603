// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./TargetSetting.scss";
import Button from "../../../../Components/Core/Button/Button";
import { ReactComponent as TargetIcon } from "../../../../assets/icons/target-icon.svg";
import { ReactComponent as ThreeDots } from "../../../../assets/icons/threeDots.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteTargetAction } from "../../../../Redux/Slices/dashboard/targetSetting/deleteTarget/deleteTarget.slice";
import { AppDispatch } from "../../../../Redux/store";
import { toastifyAsync } from "../../../../utils/notifications/toastifyAsync";

type Props = {
  cardData: { [key: string]: any };
  setForceReRender: (data: any) => void;
};

const TargetCard = ({ cardData, setForceReRender }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const isInvestor = location.pathname?.includes("investor");
  const [popupVisibility, setPopupVisibility] = useState(false);

  const onCardClick = () => {
    isInvestor
      ? navigate(`/dashboard/investor/set-target/details/${cardData?.sk}`, {
          state: {
            sk: encodeURIComponent(cardData?.sk),
          },
        })
      : navigate(`/investee/set-target/details/${cardData?.sk}`, {
          state: {
            sk: encodeURIComponent(cardData?.sk),
          },
        });
  };

  const threeDotsClickHandler = () => {
    setPopupVisibility((prevState) => !prevState);
  };

  const popupEditClickHandler = () => {
    navigate("/dashboard/investor/set-target-form/edit", {
      state: { ...cardData },
    });
  };

  const popupDeleteClickHandler = () => {
    isInvestor
      ? dispatch(
          deleteTargetAction({
            url: "/api/v1/targetSettings/removeTarget",
            id: cardData?.sk,
          })
        ).then((res) => {
          setForceReRender((prevState: number) => {
            return prevState + 1;
          });

          toastifyAsync(res);
        })
      : dispatch(
          deleteTargetAction({
            url: "/api/v1/investeePortal/targetSettings/removeTarget",
            id: cardData?.sk,
          })
        ).then((res) => {
          setForceReRender((prevState: number) => {
            return prevState + 1;
          });

          toastifyAsync(res);
        });
  };

  return (
    <div className="target-setting-card-component">
      <div className="card-header">
        {cardData?.target_name}
        <div
          className="ts-card-header-btn-container"
          onClick={threeDotsClickHandler}
        >
          <ThreeDots />
        </div>
        {popupVisibility && (
          <div className="ts-card-popup-container">
            <div className="ts-card-popup-edit" onClick={popupEditClickHandler}>
              <EditIcon />
              Edit
            </div>
            <div
              className="ts-card-popup-delete"
              onClick={popupDeleteClickHandler}
            >
              <DeleteIcon />
              Delete
            </div>
          </div>
        )}
      </div>

      <div onClick={onCardClick}>
        <div className="details-row">
          <div className="label">Target Approach :</div>
          <div className="value">{cardData?.target_setting_approach}</div>
        </div>
        <div className="details-row">
          <div className="label">Target Category :</div>
          <div className="value">
            {cardData?.target_category?.[0]?.split("_").join(" ")}
          </div>
        </div>
        <div className="details-row">
          <div className="label">Emission Reduction :</div>
          <div className="value">
            {cardData?.reduction}
            <span className="numericals"> MtCO2e </span>
            <span className="percentile">
              {" "}
              {cardData?.reduction_percentage}%{" "}
            </span>
          </div>
        </div>

        <div className="action-btns">
          <Button className="primary-border-outlined" label="Current Target" />
          <div className="target-stat">
            <TargetIcon />
            {cardData?.target_year} year
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetCard;
