import React, { useEffect, useState } from "react";

//import styles file
import "./MultiBarGraph.scss";
import { colors } from "../../../DesignSystem/Colors/colors";
import Tooltip from "../../Core/Tooltip/Tooltip";
import { abbreviatedNumber } from "../../../utils/helpers/NumberConverter";

type styleObj = {
  [key: string]: any;
};

type Props = {
  header?: string;
  chartData: (string | number)[][];
  tableHeader?: string[];
  extraStyles?: styleObj;
};

const MultiBarGraph = ({
  header,
  chartData,
  tableHeader,
  extraStyles,
}: Props) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>(0);
  const [totalEmission, setTotalEmission] = useState<number>(0);

  useEffect(() => {
    let sumOfValue = chartData
      .map((el: any) => el[1])
      .reduce((a, b) => a + b, 0);

    setTotalEmission(sumOfValue);
  }, [chartData]);

  const handleMouseEnter = (index: number) => (event: React.MouseEvent) => {
    setHoveredIndex(index);
    setHovered(true);
  };

  const ToolTipContent = (rowData: any) => {
    return (
      <div className="tooltip-container">
        <div className="tooltip-header">{rowData[0]}</div>
        <div className="tooltip-body">
          <span>Emission - </span>
          <span>{rowData[1]}</span>
          <span>MtCO2e</span>
        </div>
      </div>
    );
  };

  return (
    <div
      id="barGraph"
      className="custom-multi-bargraph-component hide-scrollbar"
      style={extraStyles}
    >
      <div className="chart-header">{header}</div>
      <table className="graph-container-table">
        <thead>
          {tableHeader && (
            <tr>
              {tableHeader?.map((header, index) => {
                return (
                  <th className="table-header">
                    {index === 0 ? (
                      <div className="header">{header}</div>
                    ) : (
                      <div className="header-container">
                        <div
                          className="header-container-dot"
                          style={{
                            backgroundColor:
                              colors.staticColorsMultiBarGraph[index - 1],
                          }}
                        ></div>
                        <div className="header">{header}</div>
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          )}
        </thead>

        <tbody>
          {chartData.map((rowData, rowIndex) => {
            return (
              <tr
                key={rowData[0]}
                className="table-row"
                onMouseEnter={handleMouseEnter(rowIndex)}
                onMouseLeave={() => setHovered(false)}
              >
                {/* <div className="tooltip-absolute">
                  {hovered && rowIndex === hoveredIndex && (
                    <Tooltip
                      borderColor="#E6E6E6"
                      backgroundColor="white"
                      toolTipContent={ToolTipContent(rowData)}
                    />
                  )}
                </div> */}
                {rowData.map((cell, index) => {
                  return (
                    <td key={index} className="multi-bar-graph-table-cell">
                      {typeof cell === "string" ? (
                        <div className="cell">{cell}</div>
                      ) : (
                        <div className="progress-bar">
                          {index === 0 ? (
                            <div
                              className="filler"
                              style={{
                                width: `${(cell / totalEmission) * 100}%`,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="filler"
                              style={{
                                width: `${(cell / totalEmission) * 100}%`,
                                backgroundColor:
                                  colors.staticColorsMultiBarGraph[index - 1],
                              }}
                            ></div>
                          )}
                          <span> {abbreviatedNumber(cell)} </span>
                        </div>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MultiBarGraph;
