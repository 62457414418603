// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./DataValidation.scss";

// import custom components
import ToggleSwitch from "../../../../Components/Core/ToggleSwitch/ToggleSwitch";
import AssetInventory from "./AssetInventory/AssetInventory";
import PcafMapping from "./PcafMapping/PcafMapping";
import InvesteeOnboarding from "./InvesteeOnboarding/InvesteeOnboarding";

// import redux related components
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getAssetInventoryList } from "../../../../Redux/Slices/financedEmission/assetInventory/getAssetInventory.slice";
import InvesteeInventory from "./InvesteeInventory/InvesteeInventory";

// type definition for props
type Props = {};

const DataValidation = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const tabNavigationData = [
    "Asset Inventory - Data collection & Validation",
    "PCAF Mapping",
    "Investee Onboarding",
    "Investee Inventory - Data Acknowledge",
  ];
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div id="dataValidationComponent" className="data-validation-container">
      <ToggleSwitch
        switchState={tabNavigationData}
        selectedValue={selectedTab}
        onSwitchChange={setSelectedTab}
      />

      {selectedTab === 0 && (
        <AssetInventory updateNavigationTab={setSelectedTab} />
      )}
      {selectedTab === 1 && <PcafMapping updateNavigationTab={setSelectedTab}/>}
      {selectedTab === 2 && <InvesteeOnboarding updateNavigationTab={setSelectedTab} />}
      {selectedTab === 3 && <InvesteeInventory updateNavigationTab={setSelectedTab} />}
    </div>
  );
};

export default DataValidation;
