import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData?: dynamicObj;
}

const EmissionPerYear = ({ dashboardOverviewData }: Props) => {
  const [xAxisValues, setXAxisValues] = useState<string[]>([]);
  const [scope1Values, setScope1Values] = useState<number[]>([]);
  const [scope2Values, setScope2Values] = useState<number[]>([]);
  const [scope3Values, setScope3Values] = useState<number[]>([]);
  const [financedEmission, setFinancedEmission] = useState<number[]>([]);

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.all_emission_list) {
      setXAxisValues(
        dashboardOverviewData.all_emission_list.map((item: any) => item.year)
      );
      setScope1Values(
        dashboardOverviewData.all_emission_list.map(
          (item: any) => item["Scope 1 Emission"]
        )
      );
      setScope2Values(
        dashboardOverviewData.all_emission_list.map(
          (item: any) => item["Scope 2 Emission"]
        )
      );
      setScope3Values(
        dashboardOverviewData.all_emission_list.map(
          (item: any) => item["Scope 3 Emission"]
        )
      );
      setFinancedEmission(
        dashboardOverviewData.all_emission_list.map(
          (item: any) => item["Financed Emission"]
        )
      );
    }
  }, [dashboardOverviewData]);

  return (
    <Graphs.LineGraph
      chartData={[scope1Values, scope2Values, scope3Values, financedEmission]}
      labels={xAxisValues}
      header={<>Emission per Year</>}
      legends={["Scope 1", "Scope 2", "Scope 3", "Financed Emission"]}
      xAxisLabel="Years"
      yAxisLabel="Emissions(MtCO2e)"
      legendsPosition="top"
      // annotations={{
      //   annotations: [
      //     {
      //       type: "line",
      //       xMin: xAxisValues.length / 2,
      //       xMax: xAxisValues.length / 2,
      //       yMin: 0,
      //       yMax: Math.max(
      //         ...[
      //           ...scope1Values,
      //           ...scope2Values,
      //           ...scope3Values,
      //           ...financedEmission,
      //         ]
      //       ),
      //       borderColor: "rgb(0, 0, 0)",
      //       borderWidth: 1,
      //       borderDash: [5, 5],
      //       label: {
      //         display: true,
      //         type: "label",
      //         xAdjust: 35,
      //         yAdjust: 0,
      //         backgroundColor: "rgba(0,0,0,0)",
      //         color: "rgb(0, 0, 0)",
      //         content: "Base year",
      //         textAlign: "start",
      //         font: {
      //           size: 13,
      //           weight: "normal",
      //         },
      //       },
      //     },
      //     {
      //       type: "line",
      //       xMin: xAxisValues.length - 1,
      //       xMax: xAxisValues.length - 1,
      //       yMin: 0,
      //       yMax: Math.max(
      //         ...[
      //           ...scope1Values,
      //           ...scope2Values,
      //           ...scope3Values,
      //           ...financedEmission,
      //         ]
      //       ),
      //       borderColor: "rgb(255, 0, 0)",
      //       borderWidth: 2,
      //       borderDash: [5, 5],
      //       label: {
      //         display: true,
      //         type: "label",
      //         xAdjust: 8,
      //         yAdjust: 0,
      //         backgroundColor: "rgba(0,0,0,0)",
      //         color: "rgb(255, 0, 0)",
      //         content: "Current year",
      //         textAlign: "start",
      //         font: {
      //           size: 13,
      //           weight: "normal",
      //         },
      //       },
      //     },
      //   ],
      // }}
    />
  );
};

export default EmissionPerYear;
