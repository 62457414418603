// import library components
import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
  from_date?: string;
  to_date?: string;
  location?: string;
};

export const getOverviewData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getOverviewData",
    async ({ from_date, to_date, location }, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getOverviewData(from_date, to_date, location),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const overviewData = createSlice({
  name: "overviewData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getOverviewData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getOverviewData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getOverviewData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default overviewData.reducer;
