// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./PortfolioAnalysis.scss";

// import components
import FilterSection from "./FilterSection";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { selectFinancedEmissionPortfolioAnalysisDataResponse } from "../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getFinancedEmissionPortfolioAnalysisData.selector";
import { getFinancedEmissionPortfolioAnalysisData } from "../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getFinancedEmissionPortfolioAnalysisData.slice";
import EmissionBarGraph from "./EmissionBarGraph/EmissionBarGraph";
import { pcaf } from "../../../../assets/images/images";

type Props = {};

type receivedFinanceEmissionPortfolioAnalysisDataType = {};

const PortfolioAnalysis = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const financedEmissionPortfolioAnalysisData = useSelector(
    selectFinancedEmissionPortfolioAnalysisDataResponse
  );
  const [receivedFePortfolioAnalysisData, setReceivedFePortfolioAnalysisData] =
    useState<receivedFinanceEmissionPortfolioAnalysisDataType>({});
  const [selectedScope, setSelectedScope] = useState(0);

  const [radioBtnArr, setRadioBtnArr] = useState([
    { text: "Asset Class", checked: false },
    { text: "Sector", checked: false },
    { text: "Time Period", checked: true },
    { text: "Location", checked: false },
  ]);

  useEffect(() => {
    dispatch(
      getFinancedEmissionPortfolioAnalysisData({
        analysis_type: "attributed_emission",
      })
    );
  }, []);

  useEffect(() => {
    setReceivedFePortfolioAnalysisData(financedEmissionPortfolioAnalysisData);
  }, [financedEmissionPortfolioAnalysisData]);

  return (
    <div className="portfolio-analysys-overview-component noScrollBar">
      <div className="header">
        <div>Portfolio Analysis</div>
        <div className="poweredby-details">
          <div className="label">Powered by:</div>
          <div className="logo">
            <img alt="powered by science based targets" src={pcaf} />
          </div>
        </div>
      </div>

      <div className="demographic-container d-grid-c8">
        <div className="col-6 d-grid-c8">
          <EmissionBarGraph
            receivedFePortfolioAnalysisData={receivedFePortfolioAnalysisData}
            setReceivedFePortfolioAnalysisData={
              setReceivedFePortfolioAnalysisData
            }
            radioBtnArr={radioBtnArr}
            selectedScope={selectedScope}
            setSelectedScope={setSelectedScope}
          />
        </div>
        <div className="demographic-tile col-2">
          <FilterSection
            radioBtnArr={radioBtnArr}
            setRadioBtnArr={setRadioBtnArr}
            selectedScope={selectedScope}
          />
        </div>
      </div>
    </div>
  );
};

export default PortfolioAnalysis;
