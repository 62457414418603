// import library components
import api from '../../../../Api';
import { createSlice, createAsyncThunk, AsyncThunk, } from '@reduxjs/toolkit';

// iniitial state
import { initialStateApi } from '../../../utils';

//  payload type definition
type payloadType = {

}

export const updateAssetDetails: AsyncThunk<any, any, {}> = createAsyncThunk(
    'updateAssetDetails',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetch(
                api.config.postAssetDetails(),
                api.http.postNoAuth(payload)
            )

            const result = await api.afterFetchHandlers.parseContent(response, api.http.postNoAuth())
            return result
        }
        catch (error) {
            rejectWithValue(error)
            throw error
        }
    }

)

const updateClassDetailsSlice = createSlice({
    name: 'assetClassDetails',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(updateAssetDetails.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateAssetDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(updateAssetDetails.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    }
})

export default updateClassDetailsSlice.reducer;