// import library components
import React, { ReactNode, useEffect, useState } from "react";

// import styles file
import "./PcafMapping.scss";

// impoprt custom components
import DialogueModal from "../../../../../Components/Core/DialogueModal/DialogueModal";
import MainContentWrapper from "../../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../../Components/Core/Button/Button";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info-icon.svg";
import { ReactComponent as UploadCloud } from "../../../../../assets/icons/upload-cloud-icon.svg";
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";
import TextInput from "../../../../../Components/Core/TextInput/TextInput";
import Pagination from "../../../../../Components/Core/Pagination/Pagination";
import { useNavigate } from "react-router-dom";
import DropDown from "../../../../../Components/Core/DropDown/DropDown";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectPcafMappingResponse } from "../../../../../Redux/Slices/financedEmission/pcafMapping/getPcafMapping.selector";
import { getPcafMappingData } from "../../../../../Redux/Slices/financedEmission/pcafMapping/getPcafMapping.slice";
import TableEnhanced from "../../../../../Components/Core/TableEnhanced/TableEnhanced";
import CheckBox from "../../../../../Components/Core/CheckBox/CheckBox";
import { updatePcafAssetClassAction } from "../../../../../Redux/Slices/financedEmission/updatePcafAssetClass/updatePcafAssetClass.slice";
import { toastifyAsync } from "../../../../../utils/notifications/toastifyAsync";

// type definition
type Props = {
  updateNavigationTab: (index: number) => void;
};

const PcafMapping = ({updateNavigationTab }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const receivedPcafMappingData = useSelector(selectPcafMappingResponse);

  const [pcafMappingData, setPcafMappingData] = useState<any>();

  const [totalItems, setTotalItems] = useState<any>();
  const [checkboxObj, setCheckboxObj] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleConfirmation = () => {
   
    updateNavigationTab (2);
  };

  const [showUploadInvesteeModal, setShowUploadInvesteeModal] = useState(false);

  //^ to handle the pagination navigations
  const [nextPageToken, setNextPageToken] = useState("");

  useEffect(() => {
    dispatch(getPcafMappingData({ page: 1 }));
  }, []);

  useEffect(() => {
    setTotalItems(receivedPcafMappingData?.totalItems);
    setPcafMappingData(receivedPcafMappingData);
  }, [receivedPcafMappingData]);

  const onNextClick = (offset: number | string) => {
    if (typeof offset === "number") {
      dispatch(getPcafMappingData({ page: offset }));
    }
  };

  const onPreviousClick = (offset: number | string) => {
    if (typeof offset === "number") {
      dispatch(getPcafMappingData({ page: offset }));
    }
  };

  const onPageClick = (offset: number | string) => {
    if (typeof offset === "number") {
      dispatch(getPcafMappingData({ page: offset }));
    }
  };

  const checkboxClickHandler = (checked: any, el: string, rowId: string) => {
    dispatch(
      updatePcafAssetClassAction({
        id: rowId,
        pcaf_asset_class: el,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  const headerData: {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu" | "popup";
    width?: string;
    customStyles?: boolean;
    popupContent?: (
      receivedFunction: any,
      index: any,
      receivedRowData: any
    ) => ReactNode;
  }[] = [
    {
      label: "Sl no",
      key: "sl_no",
      width: "7rem",
    },
    {
      label: "Investee name",
      key: "investee_name",
    },
    {
      label: "Asset class",
      key: "asset_class",
    },
    {
      label: "Sub asset class",
      key: "sub_asset_class",
    },
    {
      label: "PCAF Asset Class",
      key: "pcaf_asset_class",
    },
    {
      label: "Mapping Status",
      key: "mapping_status",
      customStyles: true,
    },
    {
      label: "Options",
      key: "",
      option: "popup",
      popupContent: (receivedFunction, index, receivedRowData) => {
        return (
          <div className="popup-checkbox-container hide-scrollbar">
            {pcafMappingData?.static_data?.pcaf_asset_class?.map(
              (el: string) => {
                return (
                  <div className="popup-checkbox-entry">
                    <CheckBox
                      toggleChecked={(checked) => {
                        checkboxClickHandler(!checked, el, receivedRowData.id);
                        setCheckboxObj((prevState) => {
                          prevState[el] = checked;
                          return { ...prevState };
                        });
                      }}
                      checked={checkboxObj[el] ?? false}
                    />
                    <p>{el}</p>
                  </div>
                );
              }
            )}
          </div>
        );
      },
    },
  ];

  const filterSelectHandler = (filter: string) => (receivedValue: string) => {
    dispatch(
      getPcafMappingData({
        filter: filter,
        filter_value: receivedValue,
        page: 1,
      })
    );
  };

  return (
    <div id="pcafMappingComponent" className="pcaf-component-container">
      <MainContentWrapper customClassName="wrapper-in-data-validation">
        <div className="data-validation-body">
          <div className="pcaf-filter-header-section">
            <DropDown
              optionsList={
                receivedPcafMappingData?.static_data?.asset_class ?? []
              }
              placeholderEnabled={true}
              placeholderText="Select Asset Class"
              dropDownContainerClassName="pcaf-dropdown-container"
              dropDownBodyClassName="pcaf-dropdown-body hide-scrollbar"
              onSelectHandler={filterSelectHandler("asset_class")}
            />
            <DropDown
              optionsList={
                receivedPcafMappingData?.static_data?.sub_asset_class ?? []
              }
              placeholderEnabled={true}
              placeholderText="Select Sub Asset Class"
              dropDownContainerClassName="pcaf-dropdown-container"
              dropDownBodyClassName="pcaf-dropdown-body hide-scrollbar"
              onSelectHandler={filterSelectHandler("sub_asset_class")}
            />
            <DropDown
              optionsList={
                receivedPcafMappingData?.static_data?.pcaf_asset_class ?? []
              }
              placeholderEnabled={true}
              placeholderText="PCAF Asset Class"
              dropDownContainerClassName="pcaf-dropdown-container"
              dropDownBodyClassName="pcaf-dropdown-body hide-scrollbar"
              onSelectHandler={filterSelectHandler("pcaf_asset_class")}
            />
            <DropDown
              optionsList={
                receivedPcafMappingData?.static_data?.mapping_status ?? []
              }
              placeholderEnabled={true}
              placeholderText="Mapping Status"
              dropDownContainerClassName="pcaf-dropdown-container"
              dropDownBodyClassName="pcaf-dropdown-body hide-scrollbar"
              onSelectHandler={filterSelectHandler("mapping_status")}
            />
          </div>

          <div className="table-container noScrollBar">
            {pcafMappingData && (
              <TableEnhanced
                tableData={pcafMappingData?.data ?? []}
                tableHeader={headerData}
                // editedDataList={handleEditedData}
                // deletedDataList={handleDeletedData}
              />
            )}
          </div>
        </div>
      </MainContentWrapper>

      <div className="footer-action-btns">
        <Pagination
          totalNumberOfData={totalItems}
          perPage={5}
          nextClickHandler={onNextClick}
          previousClickHandler={onPreviousClick}
          pageClickHandler={onPageClick}
          classNames={{
            nextButton: "next-button",
            previousButton: "previous-button",
            pageButton: "page-button",
            pageActiveButton: "page-active",
            buttons: "buttons",
          }}
          components={{
            nextButton: <Chevron />,
            previousButton: <Chevron />,
          }}
        />

        <Button
          label="Confirm"
          className="primary-button"
          onClick={handleConfirmation}
        />
      </div>

      {showUploadInvesteeModal && (
        <DialogueModal
          header="Kindly provide Asset Class & Investee Details"
          callback={setShowUploadInvesteeModal}
          onSubmit={handleConfirmation}
        >
          <div className="upload-file-investee-modal">
            <div className="file-upload-tile">
              <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div>

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Asset class details</div>
                </div>
                <hr />
                <div className="sub-header">
                  Asset Classes / Sub Asset Classes /Asset Allocation Amount /
                  Funds
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal2"
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal2"> Browse</label>
                </div>
              </div>
            </div>

            <div className="file-upload-tile">
              <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div>

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Investee / Portfolio</div>
                </div>
                <hr />
                <div className="sub-header">
                  List of Investee / Portfolio / Location of Investment /
                  Sectors and Sub - Sector of Investee / Asset Allocation Amount
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal1"
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal"> Browse</label>
                </div>
              </div>
            </div>

            <div className="tile-divider">
              <hr />
              <span>or</span>
              <hr />
            </div>

            <div>
              <TextInput header="Import from URL" placeholder="Enter URL" />
            </div>
          </div>
        </DialogueModal>
      )}
    </div>
  );
};

export default PcafMapping;
