// import library components
import React from "react";
import { useNavigate } from "react-router-dom";

// import styles file
import "./LandingScreen.scss";

// import custom components
import { ReactComponent as GHGIcon } from "../../assets/icons/GHGEmission.svg";
import { ReactComponent as FinancedIcon } from "../../assets/icons/FinancedIcon.svg";
import { ReactComponent as Chevron } from "../../assets/icons/dottedChevron.svg";
import { worldMap } from "../../assets/images/images";

// type definition
type Props = {};

const LandingScreen = (props: Props) => {
  const navigate = useNavigate();

  const handleNavigate = (path: string): void => navigate(path);

  return (
    <div id="landingPageComponent" className="landing-page-container">
      <img className="worldmap" src={worldMap} alt="worldmap image" />

      <div className="content-area">
        <div className="header">
          Please choose a Category to review or update data
        </div>

        <div className="cards-container">
          <div
            className="card"
            onClick={() => handleNavigate("/data-collection/ghg-emission")}
          >
            <div className="icon-placeholder">
              <GHGIcon />
            </div>
            <div className="card-caption">
              <div className="card-name">GHG Emission</div>
              <div className="card-details">
                Scope 1, Scope 2 and Scope 3 Emissions
              </div>
            </div>
            <div className="navigation-icon">
              <Chevron />
            </div>
          </div>

          <div
            className="card"
            onClick={() => handleNavigate("/data-collection/financed-emission")}
          >
            <div className="icon-placeholder">
              <FinancedIcon />
            </div>
            <div className="card-caption">
              <div className="card-name">Financed Emission</div>
              <div className="card-details">
                Emissions associated with investment and lending practices.
              </div>
            </div>
            <div className="navigation-icon">
              <Chevron />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
