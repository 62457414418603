export function expandAbbreviatedNumber(receivedString: string): number {
  let numberUnitMap = {
    K: 1000,
    M: 1000000,
    B: 1000000000,
  } as { [index: string]: number };

  // Regular expression to match the number
  const numberRegex = /^(\d+(\.\d+)?)/;
  let extractedNumbers: any = receivedString.match(numberRegex)?.[0];
  if (extractedNumbers) {
    extractedNumbers = parseFloat(extractedNumbers);
  } else {
    extractedNumbers = 0;
  }

  // Regular expression to match the string
  const stringRegex = /[A-Za-z]+/;
  const extractedString = receivedString.match(stringRegex)?.[0];

  if (extractedString) {
    return extractedNumbers * numberUnitMap[extractedString];
  } else {
    return extractedNumbers;
  }
}

export function abbreviatedNumber(receivedNumber: number): string {
  if (receivedNumber >= 1000000000) {
    return (receivedNumber / 1000000000).toFixed(2) + "B";
  } else if (receivedNumber >= 1000000) {
    return (receivedNumber / 1000000).toFixed(2) + "M";
  } else if (receivedNumber >= 1000) {
    return (receivedNumber / 1000).toFixed(2) + "K";
  } else {
    return receivedNumber.toString();
  }
}
