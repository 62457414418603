import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";
import { colors } from "../../../../DesignSystem/Colors/colors";
import { snakeToPascalCase } from "../../../../utils/helpers/stringToSnakeCase";
import "../../DashboardOverview/EmissionBreakdown/EmissionBreakdown.scss";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionBreakDown = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [keys, setKeys] = useState<string[]>([]);

  useEffect(() => {
    if (receivedGhgEmissionData && receivedGhgEmissionData.emission_breakdown) {
      let receivedEntries = Object.entries(
        receivedGhgEmissionData.emission_breakdown
      ).filter((el: any) => el[1] !== 0);

      let receivedValues = receivedEntries.map((el: any) => el[1]);

      let receivedKeys: string[] = receivedEntries.map((el: any) =>
        snakeToPascalCase(el[0])
      );
      setKeys(receivedKeys);
      setValues(receivedValues);
    }
  }, [receivedGhgEmissionData]);

  return (
    <div className="emission-breakdown-chart-container">
      <div className="emission-breakdown-chart-header">Emission Breakdown</div>
      <div className="emission-breakdown-pie-chart-container">
        <Graphs.PieChart
          header=""
          tooltip="Emission by Scope"
          labels={keys}
          legendsDisabled={true}
          chartData={[
            {
              label: "tCO2e",
              values: values,
            },
          ]}
          dataLabels={false}
          legendsPosition="bottom"
          pieChartStyle={{ marginTop: "2rem" }}
        />
      </div>
      <div className="emission-breakdown-label-container hide-scrollbar">
        <div className="labels-container-table-header-container">
          <div className="labels-container-table-cell labels-header-container-table-cell-column-1">
            Label
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-2">
            Emission
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-3">
            %
          </div>
        </div>
        {keys.map((el, index) => (
          <div className="labels-container-table-row">
            <div className="labels-container-table-cell labels-body-container-table-cell-column-1">
              <div
                className="ebp-label-point"
                style={{ backgroundColor: `${colors.primaryColors[index]}` }}
              ></div>
              <div>{keys[index]}</div>
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-2">
              {values[index]}
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-3">
              {values[index]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmissionBreakDown;
