import { ReactComponent as ServerIcon } from "../../assets/icons/server-gear-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/arrow-graph-icon.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/block-grid-icon.svg";
import { ReactComponent as Cloud } from "../../assets/icons/cloud-co2-icon.svg";
import { ReactComponent as Currency } from "../../assets/icons/currency-tree-icon.svg";
import { ReactComponent as Meter } from "../../assets/icons/meter-icon.svg";
import { ReactComponent as Target } from "../../assets/icons/target-icon.svg";
import { ReactComponent as Scale } from "../../assets/icons/weighing-scale-icon.svg";
import { ReactComponent as HistoryIcon } from "../../assets/icons/history-icon.svg";

export type SidebarItem = {
  title: string;
  icon: React.ReactNode;
  route: string;
  childVisible: boolean;
  children: SidebarItem[];
  parent?: string;
};

export const sidebarJsonDataDashbaord: SidebarItem[] = [
  {
    title: "Overview",
    icon: <Dashboard />,
    parent: "/dashboard/overview",
    route: "/dashboard/overview",
    childVisible: false,
    children: [],
  },
  {
    title: "GHG Emissions",
    icon: <Cloud />,
    parent: "/dashboard/ghg-emission",
    route: "/dashboard/ghg-emission",
    childVisible: false,
    children: [],
  },
  {
    title: "Financed Emission",
    icon: <Arrow />,
    parent: "/dashboard/financed-emission",
    route: "/dashboard/financed-emission/overview",
    childVisible: false,
    children: [
      {
        title: "Overview",
        icon: <ServerIcon />,
        parent: "/dashboard/financed-emission",
        route: "/dashboard/financed-emission/overview",
        childVisible: false,
        children: [],
      },
      {
        title: "Asset Class",
        icon: <ServerIcon />,
        parent: "/dashboard/financed-emission",
        route: "/dashboard/financed-emission/asset-class",
        childVisible: false,
        children: [],
      },
      {
        title: "Sector",
        icon: <ServerIcon />,
        parent: "/dashboard/financed-emission",
        route: "/dashboard/financed-emission/sector",
        childVisible: false,
        children: [],
      },
      {
        title: "Location",
        icon: <ServerIcon />,
        parent: "/dashboard/financed-emission",
        route: "/dashboard/financed-emission/location",
        childVisible: false,
        children: [],
      },
      {
        title: "Portfolio Analysis",
        icon: <ServerIcon />,
        parent: "/dashboard/financed-emission",
        route: "/dashboard/financed-emission/pofolio-analysis",
        childVisible: false,
        children: [],
      },
    ],
  },
  {
    title: "Investee",
    icon: <Currency />,
    parent: "/dashboard/investee",
    route: "/dashboard/investee",
    childVisible: false,
    children: [],
  },
  {
    title: "Benchmarking",
    icon: <Scale className="scale" />,
    parent: "/dashboard/benchmarking",
    route: "/dashboard/benchmarking",
    childVisible: false,
    children: [],
  },
  {
    title: "Target Setting",
    icon: <Target />,
    parent: "/dashboard/investor",
    route: "/dashboard/investor/set-target",
    childVisible: false,
    children: [],
  },
];

export const sidebarJsonDataCollection: SidebarItem[] = [
  {
    title: "GHG Emission",
    icon: <Cloud />,
    parent: "/data-collection/ghg-emission",
    route: "/data-collection/ghg-emission",
    childVisible: false,
    children: [],
  },
  {
    title: "Financed Emission",
    icon: <Arrow />,
    parent: "/data-collection/financed-emission",
    route: "/data-collection/financed-emission",
    childVisible: false,
    children: [],
  },
];

export const sidebarJsonDataInvestee: SidebarItem[] = [
  {
    title: "Dashboard",
    icon: <Meter />,
    route: "/investee/dashboard",
    childVisible: false,
    children: [
      {
        title: "Overview",
        icon: <Dashboard />,
        route: "/investee/dashboard",
        childVisible: false,
        children: [],
      },
      {
        title: "Target Setting",
        icon: <Target />,
        route: "/investee/set-target",
        childVisible: false,
        children: [],
      },
      {
        title: "Data Logs",
        icon: <HistoryIcon />,
        route: "/investee/user-logs",
        childVisible: false,
        children: [],
      },
    ],
  },
  {
    title: "Data Management",
    icon: <ServerIcon />,
    route: "/investee/data-collection",
    childVisible: false,
    children: [],
  },
 
];
