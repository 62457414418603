import React, { useEffect, useState } from "react";
import Comparison from "./Comparison";
import "./Benchmarking.scss";
import BenchmarkingHeader from "./BenchMarkingHeader";
import { useSelector } from "react-redux";
import { SelectBenchmarkingDataObj } from "../../../Redux/Slices/dashboard/benchmarking/Utils/storeBenchmarkingData/benchmarkingData.selector";
import { useDispatch } from "react-redux";
import { getBenchmarkingComparedData } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingComparedData/getBenchmarkingComparedData.slice";
import { AppDispatch } from "../../../Redux/store";
import { selectBenchmarkingComparedDataResponse } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingComparedData/getBenchmarkingCompared.selector";

const EditGraph = () => {
  const dispatch = useDispatch<AppDispatch>();
  const benchmarkingData = useSelector(SelectBenchmarkingDataObj);
  const benchmarkingComparedData = useSelector(
    selectBenchmarkingComparedDataResponse
  );

  const [queriedBenchmarkingComparedData, setQueriesBenchmarkingComparedData] =
    useState({});

  useEffect(() => {
    if (benchmarkingData) {
      dispatch(
        getBenchmarkingComparedData({
          competitor_investor_name: benchmarkingData.benchmark_with,
          y_axis: benchmarkingData.y_axis,
          asset_class: benchmarkingData.asset_class,
          sector: benchmarkingData.sector,
          location: benchmarkingData.location,
          x_axis: benchmarkingData.x_axis,
        })
      );
    }
  }, [benchmarkingData]);

  useEffect(() => {
    setQueriesBenchmarkingComparedData(benchmarkingComparedData);
  }, [benchmarkingComparedData]);

  return (
    <>
      <BenchmarkingHeader backBtnEnabled={true} />
      <div className="benchmarking-body-container">
        <Comparison
          mode={"edit"}
          receivedBenchmarkingComparedData={queriedBenchmarkingComparedData}
        />
      </div>
    </>
  );
};

export default EditGraph;
