// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./Overview.scss";

// import custom components
import DropDown from "../../../Components/Core/DropDown/DropDown";
import { ReactComponent as LocationMarker } from "../../../assets/icons/lcoationIcon.svg";
import { ReactComponent as GreenhouseGasProtocol } from "../../../assets/icons/greenhouseGasProtocol.svg";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { selectOverviewDataResponse } from "../../../Redux/Slices/dashboard/overview/getOverviewData.selector";
import { getOverviewData } from "../../../Redux/Slices/dashboard/overview/getOverviewData.slice";
import EmissionBreakDown from "./EmissionBreakdown/EmissionBreakdown";
import EmissionAumCovered from "./EmissionAumCovered/EmissionAumCovered";
import DataStatsDisplay from "./DataStatsDisplay/DataStatsDisplay";
import EmissionPerYear from "./EmissionPerYear/EmissionPerYear";
import EmissionByPeriod from "./EmissionByPeriod/EmissionByPeriod";
import EmissionByLocation from "./EmissionByLocation/EmissionByLocation";
import countriesData from "../../../utils/data/countries.json";

type Props = {};

const DashboardOverview = (props: Props) => {
  // constants
  const dispatch = useDispatch<AppDispatch>();
  const overviewData = useSelector(selectOverviewDataResponse);

  const [receivedDashboardData, setReceivedDashboardData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState("");

  useEffect(() => {
    dispatch(getOverviewData({ location: selectedCountry }));
  }, [selectedCountry]);

  useEffect(() => {
    setReceivedDashboardData(overviewData?.data);
  }, [overviewData]);

  return (
    <div className="dashboard-overview-container-component noScrollBar">
      <div className="content-header">
        <div className="headline">Overview</div>
        <div className="date-location-selector-container">
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <GreenhouseGasProtocol />
            </div>
          </div>
          <div className="location-selector">
            <DropDown
              optionsList={countriesData.map((el) => el.name).sort()}
              dropDownContainerClassName="dropdown-container"
              dropDownBodyClassName="dropdown-body"
              iconVisibility={false}
              searchEnabled={true}
              onSelectHandler={(e) => {
                setSelectedCountry(
                  countriesData.find((el) => el.name === e)?.shortName ?? ""
                );
              }}
              placeholderEnabled={true}
              placeholderText={"select country"}
            />
            <div className={"date-selector-datePartitioner"}></div>
            <LocationMarker />
          </div>
        </div>
      </div>
      {/* tiles */}
      <div className="quick-stats-row d-grid-c8">
        <DataStatsDisplay dashboardOverviewData={receivedDashboardData} />
      </div>
      <div className="demographics-container d-grid-c6">
        <div className="demographic-tile col-2">
          <EmissionBreakDown dashboardOverviewData={receivedDashboardData} />
        </div>
        <div className="demographic-tile col-4">
          <EmissionPerYear dashboardOverviewData={receivedDashboardData} />
        </div>
        <div className="demographic-tile col-2">
          <EmissionAumCovered dashboardOverviewData={receivedDashboardData} />
        </div>
        <div className="demographic-tile col-4 line-graph-emission-by-period">
          <EmissionByPeriod dashboardOverviewData={receivedDashboardData} />
        </div>
        <div className="demographic-tile col-6 maplist-container">
          <EmissionByLocation dashboardOverviewData={receivedDashboardData} />
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;
