// import libarary components
import { AsyncThunk, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../../Api";

// import initial state
import { initialStateApi } from "../../../../utils";

type payloadType = {
    id?: string;
    targetScope?: string
}

export const getInvestorTargetHomepageAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'investee/getInvestorTargetHomepage',
        async ({ targetScope, id }, { rejectWithValue }) => {

            try {
                const response = await fetch(
                    api.config.getInvestorTargetHomepage(targetScope, id),
                    api.http.get()
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;

            } catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const investorTargetHomepage = createSlice({
    name: 'investorTargetHomepage',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getInvestorTargetHomepageAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getInvestorTargetHomepageAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getInvestorTargetHomepageAction.rejected, (state, action,) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true
            })
    },
})

export default investorTargetHomepage.reducer;