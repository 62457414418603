import React, { useEffect, useRef, useState } from "react";

// import styles file
import "./Dropdown.scss";
import TextInput from "../TextInput/TextInput";

import { ReactComponent as Magnify } from "../../../assets/icons/magnifyingGlass-icon.svg";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";
import { ReactComponent as Cross } from "../../../assets/icons/crossIcon.svg";

type options = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

type dynamicObj = {
  [key: string]: any;
};

type Props = {
  options: options[];
  multiSelect?: boolean;
  selectedCallBack?: (selectedOption: options[]) => void;
  width?: string;
  heightInput?: string;
  header?: string;
  searchIncluded?: boolean;
  addButtonIncluded?: boolean;
  onFocusOut?: (object: any) => void;
  headerStyles?: dynamicObj;
  addClickHandler?: (enteredOption: string) => void;
  defaultOption?: options[];
};

const Dropdown = ({
  options,
  multiSelect = false,
  selectedCallBack,
  width = "100%",
  heightInput = "100%",
  header,
  searchIncluded,
  addButtonIncluded,
  onFocusOut,
  headerStyles,
  addClickHandler,
  defaultOption = [],
}: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownOptions, setDropdownOptions] = useState<options[]>(options);
  const [selectedOption, setSelectedOption] =
    useState<options[]>(defaultOption);
  const [addToListInpt, setAddToListInpt] = useState<string>("");

  const searchHandler = (input: string) => {
    setDropdownOptions(
      options.filter((item) => item.name.toLowerCase().includes(input))
    );
  };

  const selectHandler = (option: options) => {
    if (multiSelect) {
      if (selectedOption.includes(option)) {
        setSelectedOption((prev) => [
          ...prev.filter((item) => item.name !== option.name),
        ]);
      } else {
        setSelectedOption((prev) => [...prev, option]);
      }
      selectedCallBack?.(selectedOption);
    } else {
      setSelectedOption([option]);
      selectedCallBack?.([option]);
      setDropDownOpen(false);
    }
  };

  const addItemToList = () => {
    addClickHandler?.(addToListInpt);
    setDropdownOptions((prev) => [{ name: addToListInpt, img: "" }, ...prev]);
    setAddToListInpt("");
  };
  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropDownOpen(false);
      }
    };

    if (dropDownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownOpen]);

  const handleDropDownClick = () => {
    setDropDownOpen((prev) => !prev);
  };

  return (
    <div
      tabIndex={-1}
      ref={dropdownRef}
      id="drodownComponentV2"
      className="dropdown-container"
      style={{ width }}
      onBlur={onFocusOut}
    >
      {header && (
        <div className="input-label" style={headerStyles}>
          {header}
        </div>
      )}

      <div
        className="selected-item"
        style={{ height: heightInput }}
        onClick={handleDropDownClick}
      >
        <div className="item">
          {selectedOption[0]?.img &&
            typeof selectedOption[0]?.img === "string" && (
              <img src={selectedOption[0]?.img} alt="" />
            )}

          {multiSelect ? (
            selectedOption.length > 1 ? (
              <span>
                {" "}
                {selectedOption.slice(0, 3).map((item) => `${item.name},`)}{" "}
              </span>
            ) : (
              <span style={{ color: "#666666" }}>Select</span>
            )
          ) : selectedOption[0]?.name ? (
            <span>{selectedOption[0]?.name}</span>
          ) : (
            <span style={{ color: "#B7B7B7" }}>-Select-</span>
          )}
        </div>
        <Chevron
          className="chevron-icon"
          style={{
            transform: `${dropDownOpen ? "rotate(270deg)" : "rotate(90deg)"}`,
          }}
        />
      </div>

      {dropDownOpen && (
        <div className="dropdown-list">
          {searchIncluded && (
            <TextInput
              placeholder="Search here"
              leftIcon={<Magnify />}
              onChangeHandler={(string) => searchHandler(string)}
            />
          )}
          {dropDownOptions?.map((item) => {
            return (
              <div
                className={`item ${selectedOption.includes(item) && "active"} `}
                key={item.name}
                onClick={() => selectHandler(item)}
              >
                {item.img && typeof item.img === "string" && (
                  <img src={item.img} alt="" />
                )}
                {item.img && item.img instanceof File && (
                  <span>File type: {item.img.name}</span>
                )}
                <span>{item.name}</span>
              </div>
            );
          })}

          {addButtonIncluded && (
            <div className="add-new">
              <TextInput
                placeholder="Add new"
                leftIcon={
                  <Cross className="cross-icon" onClick={addItemToList} />
                }
                onChangeHandler={(string) => setAddToListInpt(string)}
                value={addToListInpt}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
