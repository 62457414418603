//  improt library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

//  create base selector for reducer
const selectPartnerIntegrationList = (state: RootState) => state.partnerIntegrationList;

export const selectAssetClassResponse = createSelector(
    [selectPartnerIntegrationList],
    (partnerIntegrationList) => partnerIntegrationList.response
)
