// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./LogScreen.scss";
import MainContentWrapper from "../../../Components/Core/MainContent/ContentWrapper";
import DropDown from "../../../Components/Core/DropDown/DropDown";

import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";

// import custom components

// import redux components
import { Pagination } from "react-light-pagination";
import TableEnhanced from "../../../Components/Core/TableEnhanced/TableEnhanced";
import { useNavigate } from "react-router-dom";
import DatePickerComponent from "../../../Components/Core/DatePicker/DatePicker";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { getInvesteeActivityLogs } from "../../../Redux/Slices/InvesteePlatform/getActivityLogs/getActivityLogs.slice";
import { useSelector } from "react-redux";
import { selectInvesteeActivityLogsResponse } from "../../../Redux/Slices/InvesteePlatform/getActivityLogs/getActiityLogs.selector";
import { dateConversion } from "../../../utils/helpers/dateHandlers";
import { patchAssetInventoryDataAction } from "../../../Redux/Slices/financedEmission/updateAssetInventory/patchAssetInventory.slice";
import { toastifyAsync } from "../../../utils/notifications/toastifyAsync";
import { deleteActivityLogsAction } from "../../../Redux/Slices/InvesteePlatform/deleteActivityLogs/deleteActivityLogs.slice";

type Props = {};

const LogScreen = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const activityDataLogStore = useSelector(selectInvesteeActivityLogsResponse);

  const [activityLog, setActivityLog] = useState<{ [key: string]: any }>();
  const [filterData, setFilterData] = useState<{
    startDate?: string;
    endDate?: string;
    asset_class?: string;
  } | null>({});

  const handleNavigate = async (data: any): Promise<"success" | "error"> => {
    navigate(`/investee/data-collection/${encodeURIComponent(data.sk)}`);
    return "success" as "success";
  };

  useEffect(() => {
    dispatch(
      getInvesteeActivityLogs({
        asset_class: filterData?.asset_class,
        from_date: filterData?.startDate,
        to_date: filterData?.endDate,
      })
    );
  }, [filterData]);

  useEffect(() => {
    setActivityLog(activityDataLogStore);
  }, [activityDataLogStore]);

  const handleDeletedData = async (data: any): Promise<"success" | "error"> => {
    return dispatch(deleteActivityLogsAction({ id: data?.sk }))
      .then((res) => {
        toastifyAsync(res);
        if (res.type.includes("fulfilled")) {
          return "success" as "success";
        } else {
          return "error" as "error";
        }
      })
      .catch((error) => {
        return "error" as "error";
      });
  };

  return (
    <div id="InvesteeDashboard" className="investee-dashboard-container">
      <MainContentWrapper customClassName="content-wrapper-classname">
        <>
          <div className="main-content-wrapper">
            <div className="filter-section">
              <div className="header-label">Data Logs</div>

              <div className="section-right">
                <DatePickerComponent
                  header="From data"
                  width="22%"
                  value={filterData?.startDate}
                  selectedDateCallback={(date) =>
                    setFilterData((prev) => ({
                      ...prev,
                      startDate: dateConversion(date, "mm/dd/yyyy"),
                    }))
                  }
                />
                <DatePickerComponent
                  header="To date"
                  width="22%"
                  value={filterData?.endDate}
                  selectedDateCallback={(date) =>
                    setFilterData((prev) => ({
                      ...prev,
                      endDate: dateConversion(date, "mm/dd/yyyy"),
                    }))
                  }
                />
                <DropDown
                  dropdownHeader="Asset class"
                  optionsList={activityLog?.sideBarList}
                  onSelectHandler={(option) =>
                    setFilterData((prev) => ({ ...prev, asset_class: option }))
                  }
                  placeholderEnabled={true}
                  placeholderText="Select Asset Class"
                />
              </div>
            </div>
            <div className="table-container">
              {activityLog?.data?.data && (
                <TableEnhanced
                  tableHeader={tableHeaderData}
                  tableData={activityLog?.data?.data}
                  editedDataList={handleNavigate}
                  deletedDataList={handleDeletedData}
                />
              )}
            </div>
          </div>
          {/* disabled for the time being */}
          <div className="footer-pagination d-none">
            <Pagination
              totalNumberOfData={30}
              perPage={3}
              // nextClickHandler={onNextClick}
              // previousClickHandler={onPreviousClick}
              // pageClickHandler={onPageClick}
              classNames={{
                container: "container",
                nextButton: "next-button",
                previousButton: "previous-button",
                pageButton: "page-button",
                pageActiveButton: "page-active",
                buttons: "buttons",
              }}
              components={{
                nextButton: <Chevron />,
                previousButton: <Chevron />,
              }}
            />
          </div>
        </>
      </MainContentWrapper>
    </div>
  );
};

export default LogScreen;

type tableHeader = {
  label: string;
  key: string | null;
  option?: "checkbox" | "menu";
  width?: string;
  customStyles?: boolean;
  redirectedEdit?: boolean;
};
const tableHeaderData: tableHeader[] = [
  {
    label: "Date",
    key: "Date",
  },
  {
    label: "Invested Amount",
    key: "Invested Amount",
  },
  {
    label: "Asset class",
    key: "Asset Class",
  },
  {
    label: "Sub Asset class",
    key: "Sub Asset Class",
  },
  {
    label: "",
    key: "data_score",
    option: "menu",
    redirectedEdit: true,
  },
];
