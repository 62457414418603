// import library components
import React, { useEffect, useState } from "react";

// improt styles file
import "./Overview.scss";

// import custom components
import StatsTile from "../../../../Components/Core/StatsTile/StatsTile.component";
import EmissionBySector from "./EmissionBySector/EmissionBySector";
import EmissionByAssetClass from "./EmissionByAssetClass/EmissionByAssetClass";
import EmissionByRegion from "./EmissionByRegion/EmissionByRegion";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectFinancedEmissionOverviewDataResponse } from "../../../../Redux/Slices/dashboard/financedEmission/overview/getFinancedEmissionOverviewData.selector";
import { getFinancedEmissionOverviewData } from "../../../../Redux/Slices/dashboard/financedEmission/overview/getFinancedEmissionOverviewData.slice";
import AumCoverage from "./AumCoverage/AumCoverage";
import { pcaf } from "../../../../assets/images/images";

type Props = {};

type receivedFinancedEmissionDataType = {
  total_financed_emission?: number;
  last_year_total_financed_emission?: number;
  waci_investment?: string;
  last_year_waci_investment?: string;
  waci_revenue?: string;
  last_year_waci_revenue?: string;
  average_data_quality_score?: number;
  last_year_average_data_quality_score?: number;
  is_financed_emission_decreased?: boolean;
  is_waci_investment_decreased?: boolean;
  is_waci_revenue_decreased?: boolean;
  is_avg_dq_score_decreased?: boolean;
};

const FinancedEmissionOverview = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const financedEmissionData = useSelector(
    selectFinancedEmissionOverviewDataResponse
  );
  const [receivedFinanceEmissionData, setReceivedFinancedEmissionData] =
    useState<receivedFinancedEmissionDataType>({});

  useEffect(() => {
    dispatch(getFinancedEmissionOverviewData({}));
  }, []);

  useEffect(() => {
    setReceivedFinancedEmissionData(financedEmissionData?.data);
  }, [financedEmissionData]);

  return (
    <div className="financed-emission-overview-component noScrollBar">
      <div className="header">
        <div className="header-text">Financed Emission Overview</div>
        <div className="poweredby-details">
          <div className="label">Powered by:</div>
          <div className="logo">
            <img alt="powered by science based targets" src={pcaf} />
          </div>
        </div>
      </div>

      <div className="quick-stats-row d-grid-c8">
        <div className="col-2">
          <StatsTile
            header="Total Financed Emissions"
            value={`${
              receivedFinanceEmissionData?.total_financed_emission ?? 0
            }`}
            unit="MtCO2e"
            icon={
              receivedFinanceEmissionData?.is_financed_emission_decreased
                ? "success"
                : "error"
            }
            progress={`${
              receivedFinanceEmissionData?.last_year_total_financed_emission ??
              0
            }%`}
          />
        </div>

        <div className="col-2">
          <StatsTile
            header="WACI /$M Investment"
            value={receivedFinanceEmissionData?.waci_investment ?? ""}
            unit="MtCO2e"
            icon={
              receivedFinanceEmissionData?.is_waci_investment_decreased
                ? "success"
                : "error"
            }
            progress={`${
              receivedFinanceEmissionData?.last_year_waci_investment ?? 0
            }%`}
          />
        </div>
        <div className="col-2">
          <StatsTile
            header="WACI /$M Revenue"
            value={receivedFinanceEmissionData?.waci_revenue ?? ""}
            unit="MtCO2e"
            icon={
              receivedFinanceEmissionData?.is_waci_revenue_decreased
                ? "success"
                : "error"
            }
            progress={`${
              receivedFinanceEmissionData?.last_year_waci_revenue ?? 0
            }%`}
          />
        </div>
        <div className="col-2">
          <StatsTile
            header="Average Data Quality Score"
            value={`${
              receivedFinanceEmissionData?.average_data_quality_score ?? 0
            }`}
            unit="MtCO2e"
            icon={
              receivedFinanceEmissionData?.is_avg_dq_score_decreased
                ? "success"
                : "error"
            }
            progress={`${
              receivedFinanceEmissionData?.last_year_average_data_quality_score ??
              0
            }%`}
          />
        </div>
      </div>

      <div className="demographic-container d-grid-c10">
        <div className="demographic-tile col-3">
          <AumCoverage
            receivedFinancedEmissionData={receivedFinanceEmissionData}
          />
        </div>
        <div className="demographic-tile p-rel col-7 d-flex-c-c">
          <EmissionByAssetClass
            receivedFinancedEmissionData={receivedFinanceEmissionData}
          />
        </div>
        <div className="demographic-tile col-5 overview-matrix-chart-container">
          <EmissionBySector
            receivedFinancedEmissionData={receivedFinanceEmissionData}
          />
        </div>

        <div className="demographic-tile col-5">
          <EmissionByRegion />
        </div>
      </div>
    </div>
  );
};

export default FinancedEmissionOverview;
