import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionPerYear = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState([]);
  const [labelsArr, setLabelsArr] = useState<string[]>([]);

  useEffect(() => {
    if (receivedGhgEmissionData && receivedGhgEmissionData.emission_per_year) {
      setValues(
        receivedGhgEmissionData.emission_per_year.map(
          (el: any) => el.total_emission_co2e
        )
      );

      setLabelsArr(
        receivedGhgEmissionData.emission_per_year.map((el: any) => el.year)
      );
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Emission per Year</>}
      xAxisLabel="Year"
      yAxisLabel="Emission (MtCO2e)"
      labels={labelsArr}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      maintainAspectRatio={false}
      barColor="#14C99E"
      xGridVisibility={false}
      yAxisVisibility={false}
      legendsPosition="bottom"
      legendsDisabled={true}
    />
  );
};

export default EmissionPerYear;
