// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

// create base selector for reducer
const selectGhgEmissionDataStore = (state: RootState) => state.ghgEmissionData;

export const selectGhgEmissionDataResponse = createSelector(
  [selectGhgEmissionDataStore],
  (selectGhgEmissionDataStore) => selectGhgEmissionDataStore.response
);
