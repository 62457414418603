import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payloadType = {};

export const getFinancedEmissionLocationData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getFinancedEmissionLocationData",
    async ({}, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getFinancedEmissionLocationData(),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const financedEmissionLocationData = createSlice({
  name: "financedEmissionLocationData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFinancedEmissionLocationData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getFinancedEmissionLocationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getFinancedEmissionLocationData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default financedEmissionLocationData.reducer;
