// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./LocationHomepage.scss";

// import custom components
import { useNavigate } from "react-router-dom";
import EmissionByLocation from "../../../DashboardOverview/EmissionByLocation/EmissionByLocation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectFinancedEmissionLocationDataResponse } from "../../../../../Redux/Slices/dashboard/financedEmission/location/getFinancedEmissionLocation.selector";
import { getFinancedEmissionLocationData } from "../../../../../Redux/Slices/dashboard/financedEmission/location/getFinancedEmissionLocationData.slice";
import { pcaf } from "../../../../../assets/images/images";

//type definition
type Props = {};

type receivedFinancedEmissionDataType = {};

const LocationHomepage = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const financedEmissionLocationData = useSelector(
    selectFinancedEmissionLocationDataResponse
  );
  const [
    receivedFinanceEmissionLocationData,
    setReceivedFinancedEmissionLocationData,
  ] = useState<receivedFinancedEmissionDataType>({});

  const navigateHandler = (path: string) =>
    navigate(`/dashboard/financed-emission/location/${path}`);

  useEffect(() => {
    dispatch(getFinancedEmissionLocationData({}));
  }, []);

  useEffect(() => {
    let formattedData =
      financedEmissionLocationData?.data?.emission_by_location;

    let modifiedFormattedArr = [];
    if (formattedData && formattedData.length > 0) {
      modifiedFormattedArr = formattedData.map(
        ({
          financed_emission,
          ...restKeys
        }: {
          financed_emission: number;
        }) => ({
          emission_value: Math.round(financed_emission ?? 0),
          ...restKeys,
        })
      );
    }

    setReceivedFinancedEmissionLocationData({
      emission_by_location: modifiedFormattedArr,
    });
  }, [financedEmissionLocationData]);

  return (
    <div className="financed-emission-location-component noScrollBar">
      <div className="header-filter-row">
        <div className="header">
          <div>Location</div>
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <img alt="powered by science based targets" src={pcaf} />
            </div>
          </div>
        </div>
      </div>
      <div className="demographic-container d-grid-c8">
        <div className="demographic-tile col-8 maplist-container">
          <EmissionByLocation
            onClickHandler={navigateHandler}
            dashboardOverviewData={receivedFinanceEmissionLocationData}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationHomepage;
