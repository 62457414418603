import React from "react";
import "./CategoryFilterCard.scss";
import { ArrowDown } from "../../../../../assets/icons/arrowDown";
import { useEffect, useState } from "react";
import CheckBox from "../../../../../Components/Core/CheckBox/CheckBox";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface ICheckboxList {
  value?: string;
  checked?: boolean;
  type?: string;
}

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
  applyBtnHandler?: (e: any) => void;
  checkboxList?: ICheckboxList[];
}

const CategoryFilterCard = ({
  receivedGhgEmissionData,
  checkboxList = [],
  applyBtnHandler,
}: Props) => {
  const [checkBoxesStatus, setCheckBoxesStatus] =
    useState<ICheckboxList[]>(checkboxList);
  const [upstreamContentVisibility, setUpstreamContentVisibility] =
    useState<boolean>(false);
  const [downstreamContentVisibility, setDownstreamContentVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    setCheckBoxesStatus(checkboxList);
  }, [checkboxList]);

  const arrowClickHandler = (type: string) => () => {
    if (type === "upstream") {
      setUpstreamContentVisibility((prevState) => !prevState);
    } else {
      setDownstreamContentVisibility((prevState) => !prevState);
    }
  };

  const selectAllClickHandler = (type: string) => () => {
    // setCheckBoxesStatus((prevState) =>
    //   prevState.map((checkBoxObj) =>
    //     !checkBoxObj.checked ? { ...checkBoxObj, checked: true } : checkBoxObj
    //   )
    // );

    setCheckBoxesStatus((prevState: any) => {
      let newState = prevState.map((checkBoxObj: any) => {
        if (checkBoxObj.type === type) {
          return !checkBoxObj.checked
            ? { ...checkBoxObj, checked: true }
            : checkBoxObj;
        } else {
          return checkBoxObj;
        }
      });

      return newState;
    });
  };

  let Upstream = {
    waste_generated_in_operations: "Waster generated in operations",
    transportation_distribution: "Transportation Distribution",
    purchased_goods_services: "Purchased Goods Services",
    leased_assets: "Leased Assets",
    fuel_energy_related: "Fuel Energy Related",
    end_of_life_treatment_of_sold_products:
      "End Of Life Treatment Of Sold Products",
    employee_commuting: "Employee Commuting",
    capital_goods: "Capital Goods",
    business_travel: "Business Travel",
  } as any;

  let Downstream = {
    use_of_sold_products: "Use Of Sold Products",
    transportation_distribution: "Transportation Distribution",
    processing_of_sold_products: "Processing Of Sold Products",
    leased_assets: "Leased Aassets",
    franchises: "Franchises",
  } as any;

  return (
    <div className="filtercard-container col-3">
      <div id="filter-category-card" className="header-container">
        <div>Category</div>
      </div>
      <div className="filter-category-card-body-container hide-scrollbar">
        <div className="upstream-container">
          <div className="upstream-header-container">
            <div className="upstream-text-container">
              <div className="upstream-text-container-heading">Upstream</div>
              <div
                className="upstream-text-container-icon"
                onClick={arrowClickHandler("upstream")}
              >
                <ArrowDown />
              </div>
            </div>
            <div
              className="btn-selectAll"
              onClick={selectAllClickHandler("upstream")}
            >
              Select all
            </div>
          </div>
          <div className="partitioner"></div>
          <div className="upstream-body-container">
            {upstreamContentVisibility &&
              checkBoxesStatus &&
              checkBoxesStatus.length > 0 &&
              checkBoxesStatus.map((el, index) => {
                if (el.type === "upstream") {
                  return (
                    <CheckBox
                      name={Upstream[el.value ?? ""] ?? ""}
                      checked={el.checked ?? false}
                      toggleChecked={(receivedVal) => {
                        setCheckBoxesStatus((prevState) =>
                          prevState.map((checkBoxObj) =>
                            el.value === checkBoxObj.value
                              ? { ...checkBoxObj, checked: receivedVal }
                              : checkBoxObj
                          )
                        );
                      }}
                    />
                  );
                }
              })}
          </div>
        </div>
        <div className="downstream-container">
          <div className="downstream-header-container">
            <div className="downstream-text-container">
              <div className="downstream-text-container-heading">
                Downstream
              </div>
              <div
                className="downstream-text-container-icon"
                onClick={arrowClickHandler("downstream")}
              >
                <ArrowDown />
              </div>
            </div>
            <div
              className="btn-selectAll"
              onClick={selectAllClickHandler("downstream")}
            >
              Select all
            </div>
          </div>
          <div className="partitioner"></div>
          <div className="downstream-body-container">
            {downstreamContentVisibility &&
              checkBoxesStatus &&
              checkBoxesStatus.length > 0 &&
              checkBoxesStatus.map((el, index) => {
                if (el.type === "downstream") {
                  return (
                    <CheckBox
                      name={Downstream[el.value ?? ""] ?? ""}
                      checked={el.checked ?? false}
                      toggleChecked={(receivedVal) => {
                        setCheckBoxesStatus((prevState) =>
                          prevState.map((checkBoxObj) =>
                            el.value === checkBoxObj.value
                              ? { ...checkBoxObj, checked: receivedVal }
                              : checkBoxObj
                          )
                        );
                      }}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
      <div className="footer-btns">
        <div
          className="btns apply"
          onClick={() => {
            applyBtnHandler?.(checkBoxesStatus);
          }}
        >
          Apply filter
        </div>
        <div
          className="btns deselect"
          onClick={() => {
            setCheckBoxesStatus((prevState) =>
              prevState.map((checkBoxObj) =>
                checkBoxObj.checked
                  ? { ...checkBoxObj, checked: false }
                  : checkBoxObj
              )
            );
          }}
        >
          Deselect all
        </div>
      </div>
    </div>
  );
};

export default CategoryFilterCard;
