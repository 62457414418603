// import library components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import styles file
import "./InvesteeInventory.scss";

//import  custom componetns
import DialogueModal from "../../../../../Components/Core/DialogueModal/DialogueModal";
import MainContentWrapper from "../../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../../Components/Core/Button/Button";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info-icon.svg";
import { ReactComponent as UploadCloud } from "../../../../../assets/icons/upload-cloud-icon.svg";
import { ReactComponent as Check } from "../../../../../assets/icons/check-line.svg";
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";
import { ReactComponent as ArrowRight } from "../../../../../assets/icons/arrowRightExtendedTail.svg";
import { menShakingHand } from "../../../../../assets/images/images";
import TextInput from "../../../../../Components/Core/TextInput/TextInput";
import Pagination from "../../../../../Components/Core/Pagination/Pagination";
import { Graphs } from "../../../../../Components/graphs";
import TableEnhanced from "../../../../../Components/Core/TableEnhanced/TableEnhanced";
import ToggleSwitch from "../../../../../Components/Core/ToggleSwitch/ToggleSwitch";

// import redux related components
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { selectInvesteeInventoryResponse } from "../../../../../Redux/Slices/financedEmission/investeeInventory/getInvesteeInventory.selector";
import { ReactComponent as MagnifyGlass } from "../../../../../assets/icons/magnifyingGlass-icon.svg";
import { getInvesteeInventoryAction } from "../../../../../Redux/Slices/financedEmission/investeeInventory/getInvesteeInventoryList.slice";
import { useForceUpdate } from "../../../../../Hooks/useForceUpdate";
import { patchInvesteeInventoryDataAction } from "../../../../../Redux/Slices/financedEmission/updateInvesteeInventory/patchInvesteeInventory.slice";
import { toastifyAsync } from "../../../../../utils/notifications/toastifyAsync";
import { stringToSnakeCase } from "../../../../../utils/helpers/stringToSnakeCase";

// type definition
type Props = {
  updateNavigationTab: (index: number) => void;
};

const InvesteeInventory = ({ updateNavigationTab }: Props) => {
  // ^ default constants
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const dispatch = useDispatch<AppDispatch>();

  const investeeInventoryListData = useSelector(
    selectInvesteeInventoryResponse
  );

  const [investeeInventoryList, setInvesteeInventoryList] = useState<any[]>();
  const [assetInventoryExtraData, setAssetInventoryExtraData] = useState<any>();
  const [searchField, setSearchField] = useState("");

  const toggleSwitchList = ["Financial data", "Emission data"];
  const [selectedSwitch, setSelectedSwitch] = useState(0);

  const [currentIndexDataSource, setCurrentIndexDataSource] =
    useState<number>(0);
  const [completedDataSource, setCompletedDataSource] = useState<string[]>([]);

  const [selectedDataFilter, setSelectedDataFilter] = useState<string[]>([]);
const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
const handleConfirmation = () => {
  // setShowAcknowledgeModal(false);
   updateNavigationTab(1);
 };


  type headerData = {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu";
    customStyles?: boolean;
    sub_column?: string[];
  };

  const [tableHeaderData, setTableHeaderData] = useState<{
    tableheaderNested?: string[];
    tableheader: headerData[];
  }>();

  const handleFilterSelection = (filter: string): void => {
    const tempFilter: string[] = selectedDataFilter;
    const itemIndex = tempFilter.indexOf(filter);
    if (itemIndex !== -1) {
      tempFilter.splice(itemIndex, 1);
    } else {
      tempFilter.push(filter);
    }
    setSelectedDataFilter(tempFilter);
    forceUpdate();
  };

  //^ to handle the pagination navigations
  const [nextPageToken, setNextPageToken] = useState("");

  const onNextClick = (offset: number | string) => {
    setNextPageToken(
      assetInventoryExtraData?.next_token_list?.[Number(offset) - 1]?.next_token
    );
  };

  const onPreviousClick = (offset: number | string) => {
    setNextPageToken(
      assetInventoryExtraData?.next_token_list?.[Number(offset) - 1]?.next_token
    );
  };

  const onPageClick = (offset: number | string) => {
    setNextPageToken(
      assetInventoryExtraData?.next_token_list?.[Number(offset) - 1]?.next_token
    );
  };

  //^ setter function for updating the states
  useEffect(() => {
    setInvesteeInventoryList(investeeInventoryListData?.data);
    if (investeeInventoryListData?.static_data?.next_token_list) {
      setAssetInventoryExtraData(investeeInventoryListData?.static_data);
      setTableHeaderData({
        tableheaderNested:
          investeeInventoryListData?.static_data?.tableLabelsNested,
        tableheader: [
          {
            label: "select",
            key: "Revenue",
            option: "checkbox",
            width: "maxContent",
          },
          ...investeeInventoryListData?.static_data?.tableLabels?.map(
            (item: any) => ({ ...item, customStyles: true })
          ),
          {
            label: "option",
            key: null,
            option: "menu",
          },
        ],
      });
    }
  }, [investeeInventoryListData]);

  //^ to fetch the api, based on debouncing
  useEffect(() => {
    let tempDataSource =
      (assetInventoryExtraData?.dataSource[currentIndexDataSource] !==
        "Submit" &&
        assetInventoryExtraData?.dataSource[currentIndexDataSource]) ||
      "";

    const getData = setTimeout(() => {
      dispatch(
        getInvesteeInventoryAction({
          dataType: toggleSwitchList[selectedSwitch]
            .toUpperCase()
            .split(" ")
            .join(""),
          dataSource: tempDataSource,
          search_key: searchField,
          next_token: nextPageToken,
          data_source_filter: selectedDataFilter,
        })
      );
    }, 600);

    return () => clearTimeout(getData);
  }, [
    searchField,
    nextPageToken,
    selectedSwitch,
    currentIndexDataSource,
    JSON.stringify(selectedDataFilter),
  ]);

  // $ to handle the table edited and deleted data

  const handleEditedData = async (data: any): Promise<"success" | "error"> => {
    return dispatch(
      patchInvesteeInventoryDataAction({
        action: "edit",
        name_of_issuer: data.name_of_issuer,
        assetClass: assetInventoryExtraData?.dataSource[currentIndexDataSource],
        data: data,
      })
    ).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        return "success" as "success";
      } else {
        return "error" as "error";
      }
    });
  };

  const handleDeletedData = async (data: any): Promise<"success" | "error"> => {
    let tempData = { ...data };
    tempData["datatype"] = ["FINANCIALDATA", "EMISSIONDATA"][selectedSwitch];

    return dispatch(
      patchInvesteeInventoryDataAction({
        action: "delete",
        name_of_issuer: data.name_of_issuer,
        datatype: ["FINANCIALDATA", "EMISSIONDATA"][selectedSwitch],
        assetClass: assetInventoryExtraData?.dataSource[currentIndexDataSource],
        data: data,
      })
    ).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        return "success" as "success";
      } else {
        return "error" as "error";
      }
    });
  };

  //$ to handle the user progress bar from the left side of the UI

  const handleProgressBar = (index: number) => {
    setCurrentIndexDataSource(index);
    let dataSource = [...assetInventoryExtraData?.dataSource];
    setCompletedDataSource([...dataSource.splice(0, index)]);
  };

  const dashboardBtnClickHandler = () => {
    navigate("/dashboard/overview");
  };

  return (
    <div
      id="investeeInventoryComponent"
      className="investee-inventory-container"
    >
      <MainContentWrapper customClassName="wrapper-in-data-validation">
        <div className="data-validation-body">
          <div className="header-section-sub d-grid-c10">
            <div className="col-2">
              <div className="graph-container">
                <div className="graph">
                  <Graphs.PieChart
                    doughNut="70%"
                    hoverOffset={0}
                    chartData={[
                      {
                        label: "tCO2e",
                        values: [708, 234, 324, 232],
                      },
                    ]}
                    graphColors={["#3B82F6", "#F59E0B", "#14B8A6", "#FA5B5B"]}
                  />
                  <div className="graph-numericals">343</div>
                </div>
                <div className="legends">
                  <div
                    className="option"
                    onClick={() => handleFilterSelection("investee")}
                  >
                    {/* {selectedDataFilter.includes('investee') ? <CheckIcon /> : <UncheckIcon />} */}
                    <div
                      className="color-block"
                      style={{ backgroundColor: "#3B82F6" }}
                    />
                    <div className="legend-name">Data from investee</div>
                  </div>

                  <div
                    className="option"
                    onClick={() => handleFilterSelection("se")}
                  >
                    {/* {selectedDataFilter.includes('se') ? <CheckIcon /> : <UncheckIcon />} */}
                    <div
                      className="color-block"
                      style={{ backgroundColor: "#F59E0B" }}
                    />
                    <div className="legend-name">Data from SE</div>
                  </div>

                  <div
                    className="option"
                    onClick={() => handleFilterSelection("investor")}
                  >
                    {/* {selectedDataFilter.includes('investor') ? <CheckIcon /> : <UncheckIcon />} */}
                    <div
                      className="color-block "
                      style={{ backgroundColor: "#14B8A6" }}
                    />
                    <div className="legend-name">Your data</div>
                  </div>

                  <div
                    className="option"
                    onClick={() => handleFilterSelection("na")}
                  >
                    {/* {selectedDataFilter.includes('na') ? <CheckIcon /> : <UncheckIcon />} */}
                    <div
                      className="color-block "
                      style={{ backgroundColor: "#FA5B5B" }}
                    />
                    <div className="legend-name">Unavailable</div>
                  </div>
                </div>
              </div>
              <div className="progress-tile-container">
                {assetInventoryExtraData?.dataSource.map(
                  (item: any, index: number) => {
                    return (
                      <div
                        onClick={() => handleProgressBar(index)}
                        className={`progress-tile ${
                          completedDataSource.includes(item) && "completed"
                        } ${currentIndexDataSource < index && "inactive"}  ${
                          currentIndexDataSource === index && "active"
                        }`}
                      >
                        <div className="indicator">
                          <Check />
                        </div>
                        <div className="tile-header">{item}</div>
                        <div className="active-indicator" />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            {assetInventoryExtraData?.dataSource[currentIndexDataSource] !==
              "Submit" && (
              <div className="table-container col-8">
                <div className="header-section col-8">
                  <div className="header">
                    Please acknowledge the following Asset Class/ PCAF Asset
                    Class
                  </div>
                  <div className="search-toggle-bar">
                    <div className="search-bar">
                      <TextInput
                        placeholder="Search"
                        leftIcon={<MagnifyGlass />}
                        value={searchField}
                        onChangeHandler={setSearchField}
                      />
                    </div>
                    <div className="togglebar">
                      <ToggleSwitch
                        btnTheme="gray"
                        switchState={toggleSwitchList}
                        selectedValue={selectedSwitch}
                        onSwitchChange={setSelectedSwitch}
                      />
                    </div>
                  </div>
                </div>

                <div className="table-container-parent noScrollBar">
                  {investeeInventoryList && (
                    <TableEnhanced
                      tableData={investeeInventoryList}
                      tableHeader={tableHeaderData?.tableheader}
                      tableHeaderNested={tableHeaderData?.tableheaderNested}
                      editedDataList={handleEditedData}
                      deletedDataList={handleDeletedData}
                    />
                  )}
                </div>
              </div>
            )}
            {assetInventoryExtraData?.dataSource[currentIndexDataSource] ===
              "Submit" && (
              <div className="investee-inventory-submit-tab d-grid-c8 col-8">
                <div className="vector-hands-shaking-icon-container">
                  <img src={menShakingHand} />
                </div>
                <div className="data-collection-message-container">
                  <p>You have successfully completed your data collection.</p>
                  <p>Please, click the button below to proceed further.</p>
                  <button
                    className="go-to-dashboard-btn"
                    onClick={dashboardBtnClickHandler}
                  >
                    Go to dashboard <ArrowRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </MainContentWrapper>

      {assetInventoryExtraData?.dataSource[currentIndexDataSource] !==
        "Submit" && (
        <div className="footer-action-btns">
          <Pagination
            totalNumberOfData={assetInventoryExtraData?.next_token_list.length}
            perPage={1}
            nextClickHandler={onNextClick}
            previousClickHandler={onPreviousClick}
            pageClickHandler={onPageClick}
            classNames={{
              nextButton: "next-button",
              previousButton: "previous-button",
              pageButton: "page-button",
              pageActiveButton: "page-active",
              buttons: "buttons",
            }}
            components={{
              nextButton: <Chevron />,
              previousButton: <Chevron />,
            }}
          />

          <Button
            label="Confirm"
            className="primary-button"
             onClick={() => setShowAcknowledgeModal(true)}
          />
         {showAcknowledgeModal && (
        <DialogueModal
          header="Acknowledge before you move"
          callback={setShowAcknowledgeModal}
          onSubmit={handleConfirmation}
        ></DialogueModal>
      )} 
        </div>
        


      )}

      {false && (
        <DialogueModal
          header="Kindly provide Asset Class & Investee Details"
          // callback={setShowUploadInvesteeModal}
          // onSubmit={handleConfirmation}
        >
          <div className="upload-file-investee-modal">
            <div className="file-upload-tile">
              <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div>

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Asset class details</div>
                </div>
                <hr />
                <div className="sub-header">
                  Asset Classes / Sub Asset Classes /Asset Allocation Amount /
                  Funds
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal2"
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal2"> Browse</label>
                </div>
              </div>
            </div>

            <div className="file-upload-tile">
              <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div>

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Investee / Portfolio</div>
                </div>
                <hr />
                <div className="sub-header">
                  List of Investee / Portfolio / Location of Investment /
                  Sectors and Sub - Sector of Investee / Asset Allocation Amount
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal1"
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal"> Browse</label>
                </div>
              </div>
            </div>

            <div className="tile-divider">
              <hr />
              <span>or</span>
              <hr />
            </div>

            <div>
              <TextInput header="Import from URL" placeholder="Enter URL" />
            </div>
          </div>
        </DialogueModal>
      )}
    </div>
  );
};

export default InvesteeInventory;
