// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./TargetSettingForm.scss";

// import custom components
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import Dropdown from "../../../../Components/Core/DropdownV2/Dropdown";
import { scienceBasedTarget } from "../../../../assets/images/images";
import countriesData from "../../../../utils/data/countries.json";
import CheckBox from "../../../../Components/Core/CheckBox/CheckBox";
import RadioInput from "../../../../Components/Core/RadioInput/RadioInput";
import Button from "../../../../Components/Core/Button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Chevron } from "../../../../assets/icons/arrowLeft.svg";
import TextInput from "../../../../Components/Core/TextInput/TextInput";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { toastifyAsync } from "../../../../utils/notifications/toastifyAsync";
import { postInvestorTargetFormAction } from "../../../../Redux/Slices/dashboard/targetSetting/investorTargetForm/postInvestorTargetForm.slice";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { stringToSnakeCase } from "../../../../utils/helpers/stringToSnakeCase";
import { selectDropdownListReponse } from "../../../../Redux/Slices/dashboard/targetSetting/getDropdownList/getDropdownList.selector";
import { useSelector } from "react-redux";
import { getDropdownListAction } from "../../../../Redux/Slices/dashboard/targetSetting/getDropdownList/getDropdownList.slice";
import { yearData } from "../../../../utils/data/yearData";
import { snakeCase } from "lodash";
import { postTargetSettingFormAction } from "../../../../Redux/Slices/InvesteePlatform/postTargetSetting/postTargetSettingData.slice";

type Props = {};

type dynamicObj = {
  [key: string]: any;
};

const TargetSettingForm = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dropdownListResponse = useSelector(selectDropdownListReponse);
  const [editMode, setEditMode] = useState(false);

  const location = useLocation();
  const isInvestor = location.pathname?.includes("investor");

  const [investorPrefillData, setInvestorPrefillData] = useState<{
    [key: string]: any;
  } | null>(null);

  const scopeItems = isInvestor
    ? ["Scope 1", "Scope 2", "Scope 3", "Financed Emission"]
    : ["Scope 1", "Scope 2", "Scope 3"];
  const feItems = ["Whole Portfolio", "Asset Class", "Sector", "Location"];
  const [selectedScope, setSelectedScope] = useState<number>(0);

  const scope1Category = [
    "Fugitive Emission",
    "Mobile Combustion",
    "Stationary Combustion",
    "Industrial Process",
  ];
  const scope2Category = [
    "Purchased Electricity",
    "Purchased Cooling",
    "Purchased Heat",
    "Purchased Steam",
  ];
  const scope3CategoryUpstream = [
    "Business Travel",
    "Fuel energy related",
    "Leased assets",
    "Employee commuting",
    "Waste generated in operations",
    "Purchased goods services",
    "Capital goods",
    "Transportation distribution",
  ];

  const scope3CategoryDownstream = [
    "Processing of sold products",
    "Use of sold products",
    "End of life treatment of sold products",
    "Franchises",
    "Leased assets",
    "Transportation distribution",
  ];

  const targetSetApproachItems = [
    "Absolute Contraction",
    "Economic Intensity",
    "Physical Intensity",
  ];
  const [selectedApproach, setSelectedApproach] = useState<number>(0);

  const [selectedS1Category, setSelectedS1Category] = useState<string[]>([]);
  const [selectedS2Category, setSelectedS2Category] = useState<string[]>([]);
  const [selectedS3CategoryUpstream, setSelectedS3CategoryUpstream] = useState<
    string[]
  >([]);
  const [selectedS3CategoryDownstream, setSelectedS3CategoryDownstream] =
    useState<string[]>([]);
  const [selectedFeCategory, setSelectedFeCategory] = useState(0);
  const [dropdownData, setDropdownData] = useState<dynamicObj>({});
  const [formData, setFormData] = useState<{ [key: string]: any }>();
  const { state: receivedTargetData } = useLocation();

  useEffect(() => {
    const containsEdit = window.location.pathname;

    if (containsEdit.includes("/edit")) {
      if (receivedTargetData?.scope_type === "S1") {
        setSelectedScope(0);
      } else if (receivedTargetData?.scope_type === "S2") {
        setSelectedScope(1);
      } else if (receivedTargetData?.scope_type === "S3") {
        setSelectedScope(2);
      } else {
        setSelectedScope(3);
      }
      setInvestorPrefillData((prevState: any) => {
        return { ...prevState, ...receivedTargetData };
      });
      setEditMode(true);
    }

    dispatch(getDropdownListAction({}));
  }, []);

  useEffect(() => {
    setDropdownData(dropdownListResponse?.data);
  }, [dropdownListResponse]);

  const handleSelectScope = (number: number) => {
    setSelectedS1Category([]);
    setSelectedS2Category([]);
    setSelectedS3CategoryUpstream([]);
    setSelectedS3CategoryDownstream([]);
    setSelectedScope(number);
  };

  const selectCategoryHandler = (receivedValue: number) => {
    setSelectedFeCategory(receivedValue);
  };

  const handleFormUpdate = (
    key: string,
    value: string | number | string[] | number[] | { [key: string]: any }
  ) => {
    if (key === "scope" && typeof value == "string") {
      value = value.toUpperCase();
    }
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  //handle submit
  const onSubmitClickHandler = () => {
    let payload: { [key: string]: any } = {
      action: "save",
      baselineEmissionUnit: "Mtco2",
      currentEmissionUnit: "Mtco2",
      scope: scopeItems[selectedScope].toUpperCase(),
      targetSettingApproach: "Absolute Contraction",
      currentEmission: investorPrefillData?.currentYearEmission,
      baselineEmission: investorPrefillData?.baseYearEmission,
      secondaryScope: [
        ...selectedS1Category?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS2Category?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS3CategoryUpstream?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS3CategoryDownstream?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
      ],
      ...formData,
    };

    if (selectedScope === 3) {
      payload["secondaryScope"] = [
        stringToSnakeCase(feItems[selectedFeCategory]),
      ];
    }

    if (payload.secondaryScope.length === 0) {
      delete payload.secondaryScope;
    }

    if (isInvestor) {
      dispatch(postInvestorTargetFormAction(payload)).then((res) => {
        toastifyAsync(res);
        if (res.type.includes("fulfilled")) {
          navigate("/dashboard/investor/set-target");
        }
      });
    } else {
      dispatch(postTargetSettingFormAction(payload)).then((res) => {
        toastifyAsync(res);
        if (res.type.includes("fulfilled")) {
          navigate("/investee/set-target");
        }
      });
    }
  };

  const getPrefilledDataHandler = () => {
    let payload: { [key: string]: any } = {
      action: "prefill",
      ...formData,
      baselineEmissionUnit: "Mtco2",
      currentEmissionUnit: "Mtco2",
      scope: scopeItems[selectedScope].toUpperCase(),
      targetSettingApproach: "Absolute Contraction",
      currentEmission: investorPrefillData?.currentYearEmission,
      baselineEmission: investorPrefillData?.baseYearEmission,
      secondaryScope: [
        ...selectedS1Category?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS2Category?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS3CategoryUpstream?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
        ...selectedS3CategoryDownstream?.map((item: string) =>
          item.split(" ").join("_").toLowerCase()
        ),
      ],
    };

    if (selectedScope === 3) {
      payload["secondaryScope"] = [
        stringToSnakeCase(feItems[selectedFeCategory]),
      ];
    }

    if (payload.secondaryScope.length === 0) {
      delete payload.secondaryScope;
    }

    dispatch(postInvestorTargetFormAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        setInvestorPrefillData(res.payload.data);
      }
    });
  };

  return (
    <div
      id="TargetSettingFormComponent"
      className="target-setting-form-container noScrollBar"
    >
      <MainContentWrapper customClassName="wrapper-container">
        <>
          <div className="header-row-1">
            <div className="section-name">
              <Chevron
                className="chevron-icon"
                onClick={() => window.history.go(-1)}
              />
              Set Up Your Target
            </div>
            <div className="poweredby-details">
              <div className="label">Powered by:</div>
              <div className="logo">
                <img
                  alt="powered by science based targets"
                  src={scienceBasedTarget}
                />
              </div>
            </div>
          </div>
          <div className="tile">
            <>
              <div className="header">Enter name for Target</div>

              <TextInput
                theme="theme-gray"
                value={receivedTargetData?.target_name}
                onChangeHandler={(value) =>
                  handleFormUpdate("targetName", value)
                }
              />
            </>

            <div className="header">Select Scope</div>
            <div className="radio-selects">
              {scopeItems.map((item, index) => (
                <RadioInput
                  label={item}
                  checked={selectedScope === index}
                  onClickHandler={() => {
                    handleSelectScope(index);
                    // handleFormUpdate("scope", item);
                  }}
                />
              ))}
            </div>
          </div>

          {selectedScope === 0 && (
            <div className="dropdown-selection tile deflex">
              <div className="section">
                <div className="header">
                  Select Category of Scope 1 Emission
                </div>
                <div className="radio-selects">
                  {scope1Category?.map((item, index) => (
                    <CheckBox
                      name={item}
                      checked={selectedS1Category.includes(item)}
                      toggleChecked={(checked) => {
                        let convertedItem = snakeCase(item);
                        if (checked) {
                          setSelectedS1Category((prev) => [
                            ...prev,
                            convertedItem,
                          ]);
                        } else {
                          setSelectedS1Category((prev) =>
                            prev.filter((el) => el !== convertedItem)
                          );
                        }

                        handleFormUpdate("secondaryScope", selectedS1Category);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {selectedScope === 1 && (
            <div className="dropdown-selection tile deflex">
              <div className="section">
                <div className="header">
                  Select Category of Scope 2 Emission
                </div>
                <div className="radio-selects">
                  {scope2Category?.map((item, index) => (
                    <CheckBox
                      name={item}
                      checked={selectedS2Category.includes(item)}
                      toggleChecked={(checked) => {
                        let convertedItem = snakeCase(item);
                        if (checked) {
                          setSelectedS2Category((prev) => [
                            ...prev,
                            convertedItem,
                          ]);
                        } else {
                          setSelectedS2Category((prev) =>
                            prev.filter((el) => el !== convertedItem)
                          );
                        }
                        handleFormUpdate("secondaryScope", selectedS2Category);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {selectedScope === 2 && (
            <div className="dropdown-selection tile deflex">
              <div className="section w-100">
                <div className="header">
                  Select Category of Scope 3 Emission
                </div>
                <div className="sub-header">Upstream</div>
                <div className="radio-selects">
                  {scope3CategoryUpstream?.map((item, index) => (
                    <CheckBox
                      name={item}
                      checked={selectedS3CategoryUpstream.includes(item)}
                      toggleChecked={(checked) => {
                        let convertedItem = snakeCase(item);
                        if (checked) {
                          setSelectedS3CategoryUpstream((prev) => [
                            ...prev,
                            convertedItem,
                          ]);
                        } else {
                          setSelectedS3CategoryUpstream((prev) =>
                            prev.filter((el) => el !== convertedItem)
                          );
                        }
                        handleFormUpdate(
                          "secondaryScope",
                          selectedS3CategoryUpstream
                        );
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="section">
                <div className="sub-header">Downstream</div>
                <div className="radio-selects">
                  {scope3CategoryDownstream?.map((item, index) => (
                    <CheckBox
                      name={item}
                      checked={selectedS3CategoryDownstream.includes(item)}
                      toggleChecked={(checked) => {
                        let convertedItem = snakeCase(item);
                        if (checked) {
                          setSelectedS3CategoryDownstream((prev) => [
                            ...prev,
                            convertedItem,
                          ]);
                        } else {
                          setSelectedS3CategoryDownstream((prev) =>
                            prev.filter((el) => el !== convertedItem)
                          );
                        }
                        handleFormUpdate(
                          "secondaryScope",
                          selectedS3CategoryDownstream
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {selectedScope !== 3 && (
            <div className="dropdown-selection tile">
              <div className="section">
                <div className="header">Select Country</div>
                <div className="radio-selects">
                  <Dropdown
                    options={countriesData}
                    searchIncluded
                    multiSelect
                    selectedCallBack={(options) => {
                      handleFormUpdate("country", options);
                    }}
                  />
                </div>
              </div>

              <div className="section">
                <div className="header">Select Facility</div>
                <div className="radio-selects">
                  <Dropdown
                    options={
                      dropdownData?.facility_type?.map((el: any) => {
                        return { name: el };
                      }) ?? []
                    }
                    searchIncluded
                    selectedCallBack={(options) =>
                      handleFormUpdate("facility", [options[0].name])
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {selectedScope === 2 && (
            <div className="dropdown-selection tile">
              <div className="section">
                <div className="header">Target Setting Approach</div>
                <div className="radio-selects">
                  {targetSetApproachItems.map((item, index) => (
                    <RadioInput
                      label={item}
                      checked={selectedApproach === index}
                      onClickHandler={() => {
                        setSelectedApproach(index);
                        handleFormUpdate("targetSettingApproach", item);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}

          {selectedScope === 3 && (
            <div className="dropdown-selection tile deflex">
              <div className="header">Select category of financed emission</div>
              <div className="radio-selects">
                {feItems.map((item, index) => (
                  <RadioInput
                    label={item}
                    checked={selectedFeCategory === index}
                    onClickHandler={() => {
                      selectCategoryHandler(index);
                      // handleFormUpdate("scope", item);
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          {selectedFeCategory === 1 && (
            <>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Asset Class</div>
                  <div className="radio-selects">
                    <DropDown
                      optionsList={dropdownData?.asset_class}
                      dropDownContainerClassName="ts-baseline-year-dropdown"
                      dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                      placeholderEnabled
                      placeholderText="Select Asset Class"
                      onSelectHandler={(seletedOption) => {
                        handleFormUpdate("assetClass", [seletedOption]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Sub Asset Class</div>
                  <div className="radio-selects">
                    <DropDown
                      optionsList={dropdownData?.sub_asset_class}
                      dropDownContainerClassName="ts-baseline-year-dropdown"
                      dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                      placeholderEnabled
                      placeholderText="Select Sub Asset Class"
                      onSelectHandler={(selectedOption) => {
                        handleFormUpdate("subAssetClass", [selectedOption]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Location</div>
                  <div className="radio-selects-location">
                    <Dropdown
                      options={countriesData}
                      searchIncluded
                      multiSelect
                      selectedCallBack={(options) =>
                        handleFormUpdate(
                          "location",
                          options?.map((item) => item.name)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {selectedFeCategory === 2 && (
            <>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Sector</div>
                  <div className="radio-selects">
                    <DropDown
                      optionsList={dropdownData?.sector}
                      dropDownContainerClassName="ts-baseline-year-dropdown"
                      dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                      placeholderEnabled
                      placeholderText="Select Sector"
                      onSelectHandler={(seletedOption) => {
                        handleFormUpdate("sector", [seletedOption]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Sub Sector</div>
                  <div className="radio-selects">
                    <DropDown
                      optionsList={dropdownData?.sub_sector}
                      dropDownContainerClassName="ts-baseline-year-dropdown"
                      dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                      placeholderEnabled
                      placeholderText="Select Sub Sector"
                      onSelectHandler={(selectedOption) => {
                        handleFormUpdate("subSector", [selectedOption]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="dropdown-selection tile">
                <div className="section">
                  <div className="header">Select Location</div>
                  <div className="radio-selects-location">
                    <Dropdown
                      options={countriesData}
                      searchIncluded
                      multiSelect
                      selectedCallBack={(options) =>
                        handleFormUpdate(
                          "location",
                          options?.map((item) => item.name)
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {selectedScope === 3 && selectedFeCategory === 3 && (
            <div className="dropdown-selection tile">
              <div className="section">
                <div className="header">Select Location</div>
                <div className="radio-selects-location">
                  <Dropdown
                    options={countriesData}
                    searchIncluded
                    multiSelect
                    selectedCallBack={(options) =>
                      handleFormUpdate(
                        "location",
                        options?.map((item) => item.name)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}

          {
            <div className="dropdown-selection tile">
              <div className="section">
                <div className="header">Baseline Emission (MTCO2)</div>
                <div className="radio-selects">
                  <TextInput
                    theme="theme-gray"
                    onChangeHandler={(value) => {
                      handleFormUpdate("baselineEmission", Number(value));
                    }}
                    type="number"
                    value={investorPrefillData?.baseYearEmission}
                  />
                </div>
              </div>

              <div className="section">
                <div className="header">Baseline Year</div>
                <div className="radio-selects">
                  <DropDown
                    optionsList={yearData}
                    dropDownContainerClassName="ts-baseline-year-dropdown"
                    dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                    placeholderEnabled
                    placeholderText="Select Year"
                    searchEnabled
                    onSelectHandler={(receivedYear) => {
                      handleFormUpdate("baselineYear", receivedYear);
                    }}
                  />
                </div>
              </div>

              {
                <div className="section">
                  <div className="header">Current Emission (MTCO2)</div>
                  <div className="radio-selects">
                    <TextInput
                      theme="theme-gray"
                      onChangeHandler={(value) =>
                        handleFormUpdate("currentEmission", Number(value))
                      }
                      type="number"
                      disabled={isInvestor}
                      value={investorPrefillData?.currentYearEmission}
                    />
                  </div>
                </div>
              }
            </div>
          }

          {
            <div className="dropdown-selection tile">
              <div className="section">
                <div className="header">Reduction Target (%)</div>
                <div className="radio-selects">
                  <TextInput
                    theme="theme-gray"
                    value={receivedTargetData?.reduction_percentage}
                    onChangeHandler={(value) =>
                      handleFormUpdate("targetReduction", Number(value))
                    }
                    type="number"
                    placeholder="Target %"
                  />
                </div>
              </div>

              <div className="section">
                <div className="header">Target year</div>
                <div className="radio-selects">
                  <DropDown
                    optionsList={yearData}
                    dropDownContainerClassName="ts-baseline-year-dropdown"
                    dropDownBodyClassName="ts-baseline-year-dropdown-body hide-scrollbar"
                    placeholderText="Select Year"
                    onSelectHandler={(receivedYear) => {
                      handleFormUpdate("targetYear", receivedYear);
                    }}
                    searchEnabled
                    placeholderEnabled={
                      receivedTargetData?.target_year ? false : true
                    }
                    selected={receivedTargetData?.target_year}
                  />
                </div>
              </div>
            </div>
          }

          <div className="footer">
            <Button
              label="Submit"
              className="primary-button"
              onClick={onSubmitClickHandler}
            ></Button>
            {isInvestor && (
              <Button
                label="Get Current Emission"
                className="primary-button"
                onClick={getPrefilledDataHandler}
              ></Button>
            )}
          </div>
        </>
      </MainContentWrapper>
    </div>
  );
};

export default TargetSettingForm;
