import React, { useState } from "react";
import { Graphs } from "../../../../../Components/graphs";
import { useEffect } from "react";
import { getAbbreviatedMonthName } from "../../../../../utils/helpers/MonthMapper";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionBySupplier = ({ receivedGhgEmissionData }: Props) => {
  const [multiBarGraphData, setMultiBarGraphData] = useState([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.emission_by_suppliers
    ) {
      let tempMultiBarGraph = receivedGhgEmissionData.emission_by_suppliers.map(
        (el: any) => [
          el.supplier_name,
          Math.round(el.total_emission_co2e),
          el.expense,
          el.emission_per_expense,
        ]
      );
      setMultiBarGraphData(tempMultiBarGraph);
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.MultiBarGraph
      header="Emission by Supplier"
      chartData={multiBarGraphData}
      tableHeader={[
        "Supplier Name",
        "Emissions",
        "Expense",
        "Emission/Expense",
      ]}
    />
  );
};

export default EmissionBySupplier;

const tableData = [
  ["Salesforce.com", 50, 30, 45],
  ["Glasglow Catering", 60, 20, 67],
  ["Roseburg Product Co", 40, 50, 34],
  ["Five Winds Project, LCC", 20, 60, 23],
  ["Red Studio Design", 90, 90, 89],
  ["ACME", 10, 55, 12],
  ["RCE, Inc", 29, 35, 76],
  ["Office Print & Ship Services", 61, 65, 62],
];
