import React, { useEffect, useState } from "react";
import "./BenchmarkingHeader.scss";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus-blue.svg";
import { ReactComponent as Arrow } from "../../../assets/icons/backArrow.svg";
import { useNavigate } from "react-router-dom";
import DialogueModal from "../../../Components/Core/DialogueModal/DialogueModal";
import BenchmarkingModalContent from "./BenchmarkingModalContent";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { getCompetitiveInvestorsData } from "../../../Redux/Slices/dashboard/benchmarking/competitiveInvestors/getCompetitiveInvestorsData.slice";
import { useSelector } from "react-redux";
import { selectCompetitiveInvestorsDataResponse } from "../../../Redux/Slices/dashboard/benchmarking/competitiveInvestors/getCompetitiveInvestors.selector";
import { getBenchmarkingComparedData } from "../../../Redux/Slices/dashboard/benchmarking/benchmarkingComparedData/getBenchmarkingComparedData.slice";
import countryData from "../../../utils/data/countries.json";
import { toastifyAsync } from "../../../utils/notifications/toastifyAsync";

interface Props {
  //   showModal?: boolean;
  //   setShowModal?: (show: boolean) => void;
  backBtnEnabled?: boolean;
}

interface receivedInvestorsObj {}

type options = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

type multiSelectOptions = {
  text: string;
  value: boolean;
};

const BenchmarkingHeader = ({
  //   showModal,
  //   setShowModal,
  backBtnEnabled = false,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const competitiveInvestorsData = useSelector(
    selectCompetitiveInvestorsDataResponse
  );
  const [showModal, setShowModal] = useState(false);
  const [
    receivedCompetitiveInvestorsData,
    setReceivedCompetitiveInvestorsData,
  ] = useState<receivedInvestorsObj>();
  const [benchmarkingOptions, setBenchmarkingOptions] = useState<options[]>([]);
  const [usecaseOptions, setUsecaseOptions] = useState<options[]>([]);
  const [assetClassOptions, setAssetClassOptions] = useState<
    multiSelectOptions[]
  >([]);
  const [sectorOptions, setSectorOptions] = useState<multiSelectOptions[]>([]);
  const [locationOptions, setLocationOptions] = useState<multiSelectOptions[]>(
    []
  );
  const [selectedBenchmark, setSelectedBenchmark] = useState<string>("");
  const [selectedUseCase, setSelectedUseCase] = useState<string>("");
  const [selectedAssetClass, setSelectedAssetClass] = useState<string[]>([]);
  const [selectedSector, setSelectedSector] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [radioBtnArr, setRadioBtnArr] = useState([
    { text: "Asset Class", checked: true },
    { text: "Sector", checked: false },
    { text: "Location", checked: false },
  ]);

  useEffect(() => {
    setReceivedCompetitiveInvestorsData(competitiveInvestorsData?.data);
  }, [competitiveInvestorsData]);

  const addBtnClickHandler = () => {
    dispatch(getCompetitiveInvestorsData({}));
    setShowModal?.(true);
  };

  const backIconClickHandler = () => {
    navigate("/dashboard/benchmarking");
  };

  const submitClickHandler = () => {
    let selectedXaxis = radioBtnArr.filter((el) => el.checked)[0].text;
    let locationShortNames = selectedLocation.map(
      (el) =>
        countryData.find((country) => country.name === el)?.shortName ?? ""
    );

    dispatch(
      getBenchmarkingComparedData({
        competitor_investor_name: selectedBenchmark,
        y_axis: selectedUseCase,
        asset_class: selectedAssetClass,
        sector: selectedSector,
        location: locationShortNames,
        x_axis: selectedXaxis,
      })
    ).then((res) => {
      toastifyAsync(res);
      navigate("/dashboard/benchmarking/addgraph");
    });
  };

  return (
    <>
      <div className="benchmarking-header-container">
        <div className="benchmarking-header-title-container">
          {backBtnEnabled && (
            <div
              className="benchmarking-header-title-back-icon"
              onClick={backIconClickHandler}
            >
              <Arrow />
            </div>
          )}
          <div className="benchmarking-header-title">Benchmarking</div>
        </div>
        <div className="benchmarking-add-graph-btn-container">
          <button className="add-graph-btn" onClick={addBtnClickHandler}>
            <span>
              <PlusIcon />
            </span>
            Add Graph
          </button>
        </div>
      </div>
      <div className="add-graph-modal-container">
        {showModal && (
          <DialogueModal
            header="Add New Benchmark"
            callback={() => {
              setShowModal(false);
            }}
            onSubmit={submitClickHandler}
            children={
              <BenchmarkingModalContent
                receivedCompetitiveInvestorsData={
                  receivedCompetitiveInvestorsData
                }
                benchmarkingOptions={benchmarkingOptions}
                usecaseOptions={usecaseOptions}
                assetClassOptions={assetClassOptions}
                sectorOptions={sectorOptions}
                locationOptions={locationOptions}
                setBenchmarkingOptions={setBenchmarkingOptions}
                setUsecaseOptions={setUsecaseOptions}
                setAssetClassOptions={setAssetClassOptions}
                setSectorOptions={setSectorOptions}
                setLocationOptions={setLocationOptions}
                setSelectedBenchmark={setSelectedBenchmark}
                setSelectedUseCase={setSelectedUseCase}
                setSelectedAssetClass={setSelectedAssetClass}
                setSelectedSector={setSelectedSector}
                setSelectedLocation={setSelectedLocation}
                radioBtnArr={radioBtnArr}
                setRadioBtnArr={setRadioBtnArr}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default BenchmarkingHeader;
