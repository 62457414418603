import React from "react";
import "./BenchmarkingCard.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { benchmarkingDataAction } from "../../../Redux/Slices/dashboard/benchmarking/Utils/storeBenchmarkingData/benchmarkingData.slice";

type cardContentObj = {
  [key: string]: any;
};

interface Props {
  cardContent?: cardContentObj;
}

const BenchmarkingCard = ({ cardContent }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const cardClickHandler = () => {
    dispatch(benchmarkingDataAction(cardContent));
    navigate(`/dashboard/benchmarking/editgraph/${cardContent?.sk}`, {
      state: {
        redirected: true,
      },
    });
  };

  return (
    <div className="benchmarking-card-container" onClick={cardClickHandler}>
      {/* <div className="bcc-header">
        <div className="bcc-header-brick">Peer</div>
        <div className="bcc-header-icon">
          <BagIcon />
        </div>
      </div> */}
      <div className="benchmarking-name">{cardContent?.benchmark_name}</div>
      <div className="benchmarking-details">
        {cardContent?.benchmark_description}
      </div>
      <div className="bcc-mapping-container">
        <div className="bbc-mapping-key-value-pair">
          <div className="bcc-mapping-key">Benchmarking with</div> :
          <div className="bcc-mapping-value bbc-mapping-value-company-name">
            {cardContent?.benchmark_with}
          </div>
        </div>
        <div className="bbc-mapping-key-value-pair">
          <div className="bcc-mapping-key">Use Case</div> :
          <div className="bcc-mapping-value">{cardContent?.y_axis}</div>
        </div>
        <div className="bbc-mapping-key-value-pair">
          <div className="bcc-mapping-key">Asset Class</div> :
          <div className="bcc-mapping-value">
            {cardContent?.asset_class?.join(", ")}
          </div>
        </div>
        <div className="bbc-mapping-key-value-pair">
          <div className="bcc-mapping-key">Sector</div> :
          <div className="bcc-mapping-value">
            {cardContent?.sector?.join(", ")}
          </div>
        </div>
        <div className="bbc-mapping-key-value-pair">
          <div className="bcc-mapping-key">Location</div> :{" "}
          <div className="bcc-mapping-value">
            {cardContent?.location?.join(", ")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkingCard;
