import React, { useEffect, useState } from "react";
import { colors } from "../../../../../DesignSystem/Colors/colors";
import "./EmissionByAssetClass.scss";
import { changeOpacityOfRgb } from "../../../../../utils/helpers/OpacityModifier";
import ColoredRowList from "../../../GHGemission/ColoredRowList/ColoredRowList";

type feDataObj = {
  [key: string]: any;
};

type Props = {
  receivedFinancedEmissionLocationDetails?: feDataObj;
};

const EmissionByAssetClass = ({
  receivedFinancedEmissionLocationDetails,
}: Props) => {
  const [assetClassData, setAssetClassData] = useState([]);

  useEffect(() => {
    let tempAssetClassData =
      receivedFinancedEmissionLocationDetails?.emission_by_asset_class?.map(
        (el: any) => {
          return {
            text: <>{el.asset_class}</>,
            value: Math.round(el.financed_emission),
          };
        }
      ) ?? [];
    setAssetClassData(tempAssetClassData);
  }, [receivedFinancedEmissionLocationDetails]);

  return (
    <div className="eac-container">
      {/* <div className="eac-header-container">Emission by Asset Class</div>
      <div className="eac-chart-container hide-scrollbar">
        {eacCharts.map((chart, index) => (
          <div className="eac-chart">
            <div
              className="eac-chart-title"
              style={{ backgroundColor: `${colors.primaryColors[index]}` }}
            >
              <div>
                <h3 className="hide-scrollbar">Listed Equity</h3>
              </div>
            </div>
            <div className="eac-chart-elements-container">
              {new Array(index + 1).fill(0).map((el) => (
                <div
                  className="eac-chart-element"
                  style={{
                    backgroundColor: `${changeOpacityOfRgb(
                      colors.primaryColors[index],
                      0.12
                    )}`,
                  }}
                >
                  <div>Value Equities</div>
                  <div>23%</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div> */}
      <div className="chart-header">Sector Emission by Asset Class</div>
      <ColoredRowList rowArr={assetClassData} colorArr={colors.primaryColors} />
    </div>
  );
};

export default EmissionByAssetClass;
