import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questionnaire: {}
}

const userDCProgressState = createSlice({
    name: 'investee/storeUserProgressDataCollection',
    initialState: initialState,
    reducers: {
        updateUserProgressStateAction(state, { payload }) {
            return {
                ...state,
                questionnaire: payload
            }
        }
    }
})

export const { updateUserProgressStateAction } = userDCProgressState.actions;
const userProgressStateDC = userDCProgressState.reducer
export default userProgressStateDC