import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { colors } from "../../../DesignSystem/Colors/colors";
import React from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";

export interface GraphData {
  labels: Array<string>;
}
type chartData = {
  label: string;
  values: number[];
};

export interface IPieChartProps {
  className?: string;
  data?: {};
  tooltip?: string;
  labels?: Array<string>;
  doughNut?: string;
  chartData: chartData[];
  header?: string;
  legendsPosition?: "left" | "right" | "top" | "bottom";
  legendsDisabled?: boolean;
  graphColors?: string[];
  hoverOffset?: number;
  dataLabels?: boolean;
  valueInLegend?: boolean;
  annotations?: {};
  pieChartStyle?: {};
}
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  className = "",
  header,
  legendsPosition = "top",
  tooltip,
  labels,
  doughNut,
  chartData,
  legendsDisabled = false,
  graphColors,
  hoverOffset = 10,
  dataLabels = false,
  valueInLegend = false,
  annotations,
  pieChartStyle = {},
}: IPieChartProps) => {
  const memoisedChartData = React.useMemo(() => {
    let newarray = [];

    for (let index = 0; index < chartData.length; index++) {
      const element = chartData[index];
      newarray.push({
        label: tooltip,
        data: element.values,
        borderWidth: 1,
        backgroundColor: graphColors || colors.primaryColors,
        hoverOffset: hoverOffset,
      });
    }
    return newarray;
  }, [chartData]);

  return (
    <>
      <div
        className="chart-header"
        style={{
          margin: "1.2rem",
          fontWeight: 500,
          fontSize: "1.6rem",
        }}
      >
        {header}
      </div>
      <Pie
        data={{
          labels: labels,
          datasets: memoisedChartData,
        }}
        options={{
          responsive: true,
          cutout: doughNut,
          plugins: {
            annotation: { ...annotations },
            datalabels: {
              display: dataLabels,
              formatter: function (value: number) {
                return `${value}%`;
              },
              color: "white",
              font: {
                size: 14,
              },
              anchor: "center",
            },
            legend: {
              display: !legendsDisabled,
              position: legendsPosition,
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                useBorderRadius: true,
                borderRadius: 0,
                usePointStyle: true,
                // generateLabels: function (chart) {
                //   const data = chart.data as ChartData;
                //   const labels = data.labels || []; // Check if labels is undefined, use an empty array as a default

                //   if (labels.length && data.datasets.length) {
                //     return labels.map((label, index) => {
                //       const dataset = data.datasets[0];
                //       const value = dataset.data ? dataset.data[index] : 0; // Check if dataset.data is undefined
                //       const backgroundColor =
                //         (dataset.backgroundColor as string[])[index] || "";

                //       return {
                //         text: valueInLegend ? `${value}% ${label}` : `${label}`, // Custom label text with value
                //         fillStyle: backgroundColor,
                //       };
                //     });
                //   }
                //   return [];
                // },
              },
              title: {
                color: "orange",
                text: "string",
              },
            },
            title: {
              display: false,
              text: "Custom Chart Title",
              align: "center",
            },
            subtitle: {
              display: true,
              text: "Custom Chart Subtitle",
            },
          },
          layout: {
            padding: {
              top: 10,
            },
          },
          elements: {
            arc: {
              circular: true,
              borderWidth: 5,
            },
          },
        }}
        style={pieChartStyle}
      />
    </>
  );
};

export default PieChart;
