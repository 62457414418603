import { useState, useEffect } from "react";
import { Graphs } from "../../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionByCategoryDownstream = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.downstream_category_emission
    ) {
      let receivedValues: number[] = Object.values(
        receivedGhgEmissionData.downstream_category_emission
      )
        .filter((el) => el !== 0)
        .map((el: any) => Math.round(el));

      let receivedLabels = Object.keys(
        receivedGhgEmissionData.downstream_category_emission
      );

      setValues(receivedValues);
      setLabels(receivedLabels);
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.PieChart
      header="Emission by Category: Downstream"
      tooltip="Downstream emission"
      labels={labels}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      dataLabels={true}
      legendsPosition="bottom"
    />
  );
};

export default EmissionByCategoryDownstream;
