import { createSelector } from "reselect";
import { RootState } from "../../../../store";

const selectFinancedEmissionPortfolioAnalysisDataStore = (state: RootState) =>
  state.financedEmissionPortfolioAnalysisData;

export const selectFinancedEmissionPortfolioAnalysisDataResponse =
  createSelector(
    [selectFinancedEmissionPortfolioAnalysisDataStore],
    (selectFinancedEmissionPortfolioAnalysisDataStore) =>
      selectFinancedEmissionPortfolioAnalysisDataStore.response
  );
