import { useState, ChangeEvent } from 'react';
import DialogueModal from '../../../../../../Components/Core/DialogueModal/DialogueModal';

import './AddInvesteeModal.scss';
import TextInput from '../../../../../../Components/Core/TextInput/TextInput';
import { ReactComponent as UploadCloud } from '../../../../../../assets/icons/upload-cloud-icon.svg';

type Props = {
    toggleModal: (bool: boolean) => void;
}

const AddInvesteeModal = ({ toggleModal }: Props) => {
    const [addInvesteeFormData, setAddInvesteeFormData] = useState({
        investeeName: '',
        isin: '',
        asset_allocation_amount: '',
        asset_class: '',
        sub_asset_class: '',
        sector: '',
        sub_sector: '',
        country: '',
        email: ''
    })

    const [selectedFile, setSelectedFile] = useState<File | null>(null)

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()
        if (event.target.files) {
            const file = event.target.files[0]
            setSelectedFile(file)
        }
    }

    const handleSubmit = () => {

        toggleModal(false)
    }

    return (
        <DialogueModal
            header="Add New Investee"
            callback={toggleModal}
            onSubmit={handleSubmit}
            customClassname='add-investee-modal'
        >
            <TextInput
                header='Investee Name'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='ISIN/CUSIP/FIGI/LEI'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Asset Allocation Amount'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Asset Class'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Sub Asset Class'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Sub Sector'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Sector'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Country'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <TextInput
                header='Email'
                width='49%'
                theme='theme-gray'
            // onChangeHandler={(input: string) => handleChanges(input, "name")}
            // value={data.name}
            />
            <div className="tile-divider">
                <hr />
                <span>or</span>
                <hr />
            </div>

            <div className="drag-drop-zone">
                <div className="header">
                    <UploadCloud />
                    <div>Investee / Portfolio</div>
                </div>
                <hr />
                <div className="sub-header">
                    List of Investee / Portfolio / Location of Investment /
                    Sectors and Sub - Sector of Investee / Asset Allocation Amount
                </div>
                <div className="links">
                    <input
                        type="file"
                        className="d-none"
                        id="upload-file-modal1"
                        onChange={handleFileUpload}
                    />
                    Drop file here or <label htmlFor="upload-file-modal1"> Browse</label>
                    <br />
                    <br />
                    {selectedFile && <span>Selected File:{selectedFile?.name} </span>}
                </div>
            </div>


        </DialogueModal>
    )
}

export default AddInvesteeModal