import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

// initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
  investor_id?: string;
  scope_type?: string;
  category_list?: string[];
  organisation_unit_list?: string[];
  from_date?: string;
  to_date?: string;
  emission_year?: string;
  use_cases?: string[];
  facility_types?: string[];
  location?: string[];
};

export const getGhgEmissionData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getGhgEmissionData",
    async (
      {
        investor_id,
        scope_type,
        category_list,
        organisation_unit_list,
        from_date,
        to_date,
        emission_year,
        use_cases,
        facility_types,
        location,
      },
      { rejectWithValue }
    ) => {
      try {
        const response = await fetch(
          api.config.getGhgEmissionData(
            investor_id,
            scope_type,
            category_list,
            organisation_unit_list,
            from_date,
            to_date,
            emission_year,
            use_cases,
            facility_types,
            location
          ),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

export const ghgEmissionData = createSlice({
  name: "ghgEmissionData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGhgEmissionData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getGhgEmissionData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getGhgEmissionData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default ghgEmissionData.reducer;
