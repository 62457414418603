import { useEffect, useState } from "react";
import "./FilterSection.scss";
import MultiSelect from "../../../Components/Core/MultiSelect/MultiSelect";
import DropDown from "../../../Components/Core/DropDown/DropDown";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrowRightExtendedTail.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { getGhgEmissionData } from "../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import { yearData } from "../../../utils/data/yearData";

interface IRadioBtnObj {
  text?: string;
  checked?: boolean;
}

type ghgEmissionDataObj = {
  [key: string]: any;
};

type Props = {
  radioBtnArr?: IRadioBtnObj[];
  setRadioBtnArr?: (radioBtnArr: any) => void;
  fixedGhgEmissionData?: ghgEmissionDataObj;
};

const FilterSection = ({
  radioBtnArr,
  setRadioBtnArr,
  fixedGhgEmissionData,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [chosenUseCase, setChosenUseCase] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "Scope 1", checked: false },
    { text: "Scope 2", checked: false },
    { text: "Scope 3", checked: false },
  ]);
  const [chosenFacilityType, setChosenFacilityType] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
  ]);
  const [chosenOrgUnit, setChosenOrgUnit] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
  ]);
  const [chosenSupplier, setChosenSupplier] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "Ebay", checked: false },
    { text: "Facebook", checked: false },
    { text: "Jp Morgan", checked: false },
  ]);
  const [chosenFuelType, setChosenFuelType] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "LNG", checked: false },
    { text: "Electric", checked: false },
    { text: "Diesel", checked: false },
  ]);
  const [chosenElectricityType, setChosenElectricityType] = useState<
    IRadioBtnObj[]
  >([
    { text: "All", checked: false },
    { text: "Coal", checked: false },
    { text: "Wind", checked: false },
    { text: "Solar", checked: false },
  ]);
  const [chosenCategory, setChosenCategory] = useState<IRadioBtnObj[]>([]);
  const [chosenLocation, setChosenLocation] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
  ]);

  useEffect(() => {
    let extractedFacilityType =
      fixedGhgEmissionData?.emission_per_facilities?.map((el: any) => {
        return { text: el.facility_name, checked: false };
      }) ?? [];

    let extractedOrgUnit =
      fixedGhgEmissionData?.emission_by_organisation_unit?.map((el: any) => {
        return { text: el.organisation_unit, checked: false };
      }) ?? [];

    let extractedLocation =
      fixedGhgEmissionData?.emission_by_location?.map((el: any) => {
        return { text: el.location, checked: false };
      }) ?? [];

    setChosenFacilityType((prevState) => [
      ...prevState,
      ...extractedFacilityType,
    ]);

    setChosenOrgUnit((prevState) => [...prevState, ...extractedOrgUnit]);

    setChosenLocation((prevState) => [...prevState, ...extractedLocation]);
  }, [fixedGhgEmissionData]);

  const radioBtnClickHandler = (receivedRadioBtnObj: IRadioBtnObj) => () => {
    setRadioBtnArr?.((prevArr: any) =>
      prevArr.map((el: any) =>
        el.text === receivedRadioBtnObj.text
          ? { ...el, checked: true }
          : { ...el, checked: false }
      )
    );
  };

  const getFilteredValues = (arr: IRadioBtnObj[]) =>
    arr
      .filter((el) => el.checked && el.text !== "All")
      .map((el) => el.text?.toUpperCase() ?? "");

  const selectOptionHandler = (
    receivedOptionArr: any,
    prevState: any,
    setState: any,
    filterType: string
  ) => {
    let copiedPrevState = [...prevState];

    copiedPrevState.forEach((el: any) => {
      el.checked = false;
    });

    receivedOptionArr.forEach((option: any) => {
      copiedPrevState.forEach((el: any) => {
        if (el.text === option.text) {
          el.checked = option.checked;
        }
      });
    });

    dispatch(
      getGhgEmissionData({
        scope_type: "ANALYTICS",
        emission_year: "2023",
        use_cases: getFilteredValues(chosenUseCase),
        facility_types: getFilteredValues(chosenFacilityType),
        organisation_unit_list: getFilteredValues(chosenOrgUnit),
        category_list: getFilteredValues(chosenCategory),
        location: getFilteredValues(chosenLocation),
        [filterType]: getFilteredValues(copiedPrevState),
      })
    );

    setState?.([...copiedPrevState]);
  };

  const dateSelectHandler = ({
    initialYear = "2022",
    finalYear = "2023",
  }: any) => {
    let fromDate = `${initialYear}-01-01T00:00:18.000Z`;
    let toDate = `${finalYear}-01-01T23:58:18.000Z`;

    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 1",
        from_date: fromDate,
        to_date: toDate,
      })
    );
  };

  return (
    <div className="filter-section">
      <b>Select X-Axis</b>
      <div className="radio-btns-section">
        {radioBtnArr &&
          radioBtnArr.map((radioBtn) => (
            <div>
              <input
                type="radio"
                checked={radioBtn.checked}
                name={radioBtn.text}
                value={radioBtn.text}
                onChange={radioBtnClickHandler(radioBtn)}
              />
              <label>{radioBtn.text}</label>
            </div>
          ))}
      </div>
      <div className="partitioner-line"></div>
      <div className="filter-section-body">
        <div className="filter-section-body-title">
          <b>Select Filter</b>
        </div>
        <div className="filter-section-body-fitler-container hide-scrollbar">
          <div className="filter-section-body-fitler">
            <div className="filter-section-body-fitler-title">Use Case</div>
            <MultiSelect
              options={chosenUseCase}
              onSelectHandler={(receivedOptionArr) => {
                let tempArr = [{ text: "All", checked: false }] as any;
                receivedOptionArr.forEach((option) => {
                  if (option.text === "Scope 1") {
                    tempArr.push(
                      { text: "Fugitive Emissions", checked: false },
                      { text: "Stationary Combustion", checked: false },
                      { text: "Mobile Combustion", checked: false },
                      { text: "Industrial Processes", checked: false }
                    );
                  }
                  if (option.text === "Scope 2") {
                    tempArr.push(
                      { text: "Purchased Electricity", checked: false },
                      { text: "Purchased Heat", checked: false },
                      { text: "Purchased Steam", checked: false },
                      { text: "Purchased Cooling", checked: false }
                    );
                  }
                  if (option.text === "Scope 3") {
                    tempArr.push(
                      { text: "Purchased Goods and Sevices", checked: false },
                      { text: "Capital Goods", checked: false },
                      {
                        text: "Fuel and Energy Related Activities",
                        checked: false,
                      },
                      {
                        text: "Transportation and Distribution",
                        checked: false,
                      },
                      {
                        text: "Waste Generated in Operations",
                        checked: false,
                      },
                      {
                        text: "Business Travel",
                        checked: false,
                      },
                      {
                        text: "Employee commuting",
                        checked: false,
                      },
                      {
                        text: "Leased assets",
                        checked: false,
                      },
                      {
                        text: "Procesing of Sold Products",
                        checked: false,
                      },
                      {
                        text: "Use of Sold products",
                        checked: false,
                      },
                      {
                        text: "End of life treatment of Sold Products",
                        checked: false,
                      },
                      {
                        text: "Franchises",
                        checked: false,
                      }
                    );
                  }
                });
                setChosenCategory((prevArr) => [...tempArr]);
                selectOptionHandler(
                  receivedOptionArr,
                  chosenUseCase,
                  setChosenUseCase,
                  "use_cases"
                );
              }}
            />
            <div className="filter-section-body-fitler-title">
              Facility Type
            </div>
            <MultiSelect
              options={chosenFacilityType}
              onSelectHandler={(receivedOptionArr) => {
                selectOptionHandler(
                  receivedOptionArr,
                  chosenFacilityType,
                  setChosenFacilityType,
                  "facility_types"
                );
              }}
            />
            <div className="filter-section-body-fitler-title">
              Organizational Unit
            </div>
            <MultiSelect
              options={chosenOrgUnit}
              onSelectHandler={(receivedOptionArr) => {
                selectOptionHandler(
                  receivedOptionArr,
                  chosenOrgUnit,
                  setChosenOrgUnit,
                  "organisation_unit_list"
                );
              }}
            />
            {(chosenUseCase.filter((el) => el.text === "Scope 2")[0].checked ||
              chosenUseCase.filter((el) => el.text === "Scope 3")[0]
                .checked) && (
              <>
                <div className="filter-section-body-fitler-title">Supplier</div>
                <MultiSelect
                  options={chosenSupplier}
                  onSelectHandler={(receivedOptionArr) => {
                    selectOptionHandler(
                      receivedOptionArr,
                      chosenSupplier,
                      setChosenSupplier,
                      "supplier"
                    );
                  }}
                />
              </>
            )}
            {chosenUseCase.filter((el) => el.text === "Scope 1")[0].checked && (
              <>
                <div className="filter-section-body-fitler-title">
                  Fuel Type
                </div>
                <MultiSelect
                  options={chosenFuelType}
                  onSelectHandler={(receivedOptionArr) => {
                    selectOptionHandler(
                      receivedOptionArr,
                      chosenFuelType,
                      setChosenFuelType,
                      "fuel_types"
                    );
                  }}
                />
              </>
            )}
            {chosenUseCase.filter((el) => el.text === "Scope 2")[0].checked && (
              <>
                <div className="filter-section-body-fitler-title">
                  Electricity Type
                </div>
                <MultiSelect
                  options={chosenElectricityType}
                  onSelectHandler={(receivedOptionArr) => {
                    selectOptionHandler(
                      receivedOptionArr,
                      chosenElectricityType,
                      setChosenElectricityType,
                      "electricity_types"
                    );
                  }}
                />
              </>
            )}
            <div className="filter-section-body-fitler-title">Category</div>
            <MultiSelect
              options={chosenCategory}
              onSelectHandler={(receivedOptionArr) => {
                selectOptionHandler(
                  receivedOptionArr,
                  chosenCategory,
                  setChosenCategory,
                  "category_list"
                );
              }}
            />
            <div className="filter-section-body-fitler-title">Location</div>
            <MultiSelect
              options={chosenLocation}
              onSelectHandler={(receivedOptionArr) => {
                selectOptionHandler(
                  receivedOptionArr,
                  chosenLocation,
                  setChosenLocation,
                  "location"
                );
              }}
            />
          </div>
          <div className="filter-section-body-time-filter">
            <div className="filter-section-body-time-fitler-title">
              Time Period
            </div>
            <div className="time-period-container">
              <DropDown
                optionsList={yearData}
                dropDownContainerClassName="dropdown-container"
                dropDownBodyClassName="dropdown-body hide-scrollbar"
                placeholderText="From"
                placeholderEnabled={true}
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    initialYear: e,
                  });
                }}
              />
              <ArrowRight />
              <DropDown
                optionsList={yearData}
                dropDownContainerClassName="dropdown-container"
                dropDownBodyClassName="dropdown-body hide-scrollbar"
                placeholderText="To"
                placeholderEnabled={true}
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    finalYear: e,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
