// import library components
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import styles file
import "./DataCollectionManual.scss";

// import custom components
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../Components/Core/Button/Button";
import { ReactComponent as Dashboard } from "../../../../assets/icons/dashboard.svg";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";

import dataCollectionJson from "../../../../utils/data/dataCollectionHomepage.json";

// import redux components
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getDropdowncontentsAction } from "../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { useSelector } from "react-redux";
import { selectDropdownContentResponse } from "../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";

type Props = {};

const DataCollectionManual = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);

  const redirectHandler = (path: string, scope?: string): void => {
    const scopePath = path.toLowerCase().split(" ").join("_");

    navigate(`tables/${path}`, {
      state: {
        scope,
        scopePath,
      },
    });
  };

  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  return (
    <MainContentWrapper>
      <div className="datacollection-manual-homepage-component">
        {/* <div className='activity-btn'>
                    <Button label='Activity' className='primary-button' leftIcon={<Dashboard />} />
                </div>
                <br /> */}
        {dataCollectionJson.map((data: any) => {
          return (
            <div key={data.section_name} className="scope-container">
              <div className="header">
                <div className="bolded">{data.section_name}</div>
                <div className="light">{data.section_subtitle}</div>
              </div>

              <div className="section-row">
                {data.sub_sections.map((subsection: any) => {
                  return (
                    <div
                      key={subsection}
                      className="section cursorPointer"
                      onClick={() =>
                        redirectHandler(subsection, data?.section_name_short)
                      }
                    >
                      <div className="left">{subsection}</div>
                      <div className="icon">
                        <Chevron />
                      </div>
                    </div>
                  );
                })}
              </div>
              <br />
              <br />
              <br />
            </div>
          );
        })}
      </div>
    </MainContentWrapper>
  );
};

export default DataCollectionManual;
