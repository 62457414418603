import React from "react";
import { Chart } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

type Props = {
  className?: string;
  data?: {};
  tooltip?: string;
  labels?: Array<string>;
  chartData?: number[];
  header: string;
  legends?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  y1AxisLabel?: string;
};

const MixedChart = ({
  header,
  className,
  data,
  tooltip,
  labels,
  chartData,
  legends,
  xAxisLabel,
  yAxisLabel,
  y1AxisLabel,
}: Props) => {
  return (
    <>
      <div
        className="chart-header"
        style={{ margin: "1.2rem", fontWeight: 500, fontSize: "1.6rem" }}
      >
        {header}
      </div>

      <Chart
        type="line"
        data={{
          labels: labels,
          datasets: [
            {
              type: "line" as const,
              label: "Dataset 1",
              backgroundColor: "#14C9C9",
              borderColor: "#14C9C9",
              borderWidth: 2,
              fill: true,
              data: [5.4, 6, 2, 10, 56, 34],
              yAxisID: "y",
            },
            {
              type: "bar" as const,
              label: "Dataset 2",
              backgroundColor: "#17839D",
              borderColor: "#17839D",
              data: [5.4, 6, 2, 10, 56, 34],
              // borderColor: 'white',
              borderWidth: 2,
              yAxisID: "y1",
            },
          ],
        }}
        options={{
          scales: {
            x: {
              stacked: false,
              title: {
                display: true,
                color: "black",
                text: xAxisLabel,
              },
              ticks: {},
            },
            y: {
              stacked: false,
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                color: "#17839D",
              },
              title: {
                display: true,
                color: "black",
                text: yAxisLabel,
              },
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",
              ticks: {
                color: "#14C9C9",
              },
              title: {
                display: true,
                color: "black",
                text: y1AxisLabel,
              },
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          },

          responsive: true,
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              position: "right",
              labels: {
                boxHeight: 20,
                boxWidth: 20,
                useBorderRadius: true,
                borderRadius: 10,
                pointStyle: "circle",
              },
              title: {
                color: "red",
              },
            },
            title: {
              display: false,
              text: "Chart.js Bar Chart",
            },
          },
          elements: {
            bar: {
              inflateAmount: 1,
              borderColor: "#fff",
              borderWidth: 0.5,
              borderSkipped: "bottom",
            },

            // barThickness: 10,
          },
        }}
      />
    </>
  );
};

export default MixedChart;
