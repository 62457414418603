import React, { ReactNode } from "react";
import "./ConfirmationModal.scss";

type Props = {
  content?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  cancelBtnClickHandler?: () => void;
  confirmBtnClickhandler?: () => void;
  theme?: string;
};

const ConfirmationModal = ({
  content = <>Hii</>,
  cancelText = "cancel",
  confirmText = "Ok",
  cancelBtnClickHandler,
  confirmBtnClickhandler,
  theme = "#fa5b5b",
}: Props) => {
  return (
    <>
      <div className="black-screen"></div>
      <div id="confirmation-modal" className="confirmation-modal-container">
        <div
          className="confirmation-modal-decorator"
          style={{ backgroundColor: `${theme}` }}
        ></div>
        <div className="confirmation-modal-body">{content}</div>
        <div className="confirmation-modal-partitioner"></div>
        <div className="confirmation-modal-footer">
          <div
            className="footer-cancel-btn-container"
            onClick={cancelBtnClickHandler}
          >
            {cancelText}
          </div>
          <div
            className="footer-confirm-btn-container"
            onClick={confirmBtnClickhandler}
            style={{ backgroundColor: `${theme}` }}
          >
            {confirmText}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationModal;
