// import library components
import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";

// import styles file
import "./Table.scss";

// import custom components
import { ReactComponent as Ellipsis } from "../../../assets/icons/elipsis-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete-outline-icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icons/save-icon1.svg";
import { useForceUpdate } from "../../../Hooks/useForceUpdate";
import CheckBox from "../CheckBox/CheckBox";

type tableHeader = {
  label: string;
  key: string | null;
  option?: "checkbox" | "menu" | "popup";
  width?: string;
  customStyles?: boolean;
  redirectedEdit?: boolean;
  popupContent?: any;
};

type Props = {
  tableData?: any;
  tableHeader?: tableHeader[] | undefined;
  tableHeaderNested?: string[];
  selectedDataList?: (seletedItemList: any) => void;
  deletedDataList?: (deletedItemList: any) => Promise<"success" | "error">;
  editedDataList?: (editedItemList: any) => Promise<"success" | "error">;
  handleRowClick?: (rowData: any) => void;
};

const TableEnhanced = ({
  tableData,
  tableHeader,
  tableHeaderNested,
  selectedDataList,
  deletedDataList,
  editedDataList,
  handleRowClick,
}: Props) => {
  const forceUpdate = useForceUpdate();

  // store the the table data
  const [tableBodyData, setTableBodyData] = useState(tableData);

  useEffect(() => setTableBodyData(tableData), [tableData]);

  function deleteTableRow(index: number) {
    let tempData = [...tableBodyData];
    const response = deletedDataList?.(tempData[index]);

    response?.then((response: "success" | "error") => {
      if (response === "success") {
        tempData.splice(index, 1);
        setTableBodyData(tempData);
      } else {
        return;
      }
    });

    forceUpdate();
  }

  const markRowForEdit = (
    index: number,
    mode: "save" | "edit",
    editType: "inline" | "redirect"
  ) => {
    if (editType === "inline") {
      if (mode === "edit") {
        const updatedData = [...tableBodyData];
        updatedData[index] = {
          ...updatedData[index],
          editEnabled: true,
        };
        setTableBodyData(updatedData);
        forceUpdate();
      } else if (mode === "save") {
        const updatedData = [...tableBodyData];
        const response = editedDataList?.(updatedData[index]);

        response?.then((response: "success" | "error") => {
          if (response === "success") {
            updatedData[index] = {
              ...updatedData[index],
              editEnabled: false,
            };
            setTableBodyData(updatedData);
          } else {
            return;
          }
        });

        forceUpdate();
      }
    } else if (editType === "redirect") {
      editedDataList?.(tableBodyData[index]);
    }
  };

  const handleRowEdit = (
    rowIndex: number,
    key: number | string,
    value: number | string
  ) => {
    const updatedData = [...tableBodyData];
    updatedData[rowIndex] = {
      ...updatedData[rowIndex],
      [key]: value,
    };
    setTableBodyData(updatedData);

    // let tempData = tableBodyData;
    // let rowForEditing = tempData[rowIndex]
    // rowForEditing[key] = value

    // setTableBodyData(tempData)
    forceUpdate();
  };

  const handleRowCheck = (index: number, value: boolean) => {
    const updatedData = [...tableBodyData];

    // Update the selected value for the specific row
    updatedData[index] = {
      ...updatedData[index],
      selected: value,
    };

    // Set the updated array as the new state
    setTableBodyData(updatedData);
    selectedDataList?.(updatedData);
    forceUpdate();
  };

  return (
    <div className="table-container-parent">
      <table id="tableComponent" className="table-container">
        <thead>
          <tr>
            {tableHeaderNested?.map((item: any, index: number) => {
              return <th key={`${item}${index}`}>{item}</th>;
            })}
          </tr>
          <tr>
            {tableHeader?.map((item: any, index: number) => {
              if (index === tableHeader.length - 1) {
                return (
                  <th
                    key={`${item?.sub_column}${index}`}
                    style={{
                      position: "sticky",
                      top: 0,
                      right: 0,
                      backgroundColor: "#e2f2f6",
                    }}
                  >
                    {item.label}
                  </th>
                );
              } else {
                return (
                  <th key={`${item?.sub_column}${index}`}>{item.label}</th>
                );
              }
            })}
          </tr>
        </thead>
        <tbody>
          {tableBodyData.map((data: any, index: number) => {
            return (
              <TableRow
                handleRowClick={handleRowClick}
                key={`${data}${index}`}
                tableHeader={tableHeader}
                rowData={data}
                index={index}
                deleteTableRow={deleteTableRow}
                markRowForEdit={(
                  index: number,
                  mode: "edit" | "save",
                  editType: "inline" | "redirect"
                ) => markRowForEdit?.(index, mode, editType)}
                upliftDataToTable={(
                  index: number,
                  key: string | number,
                  value: string | number
                ) => handleRowEdit(index, key, value)}
                checkboxRow={(index: number, value: boolean) =>
                  handleRowCheck(index, value)
                }
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TableEnhanced;

type tableRowProps = {
  tableHeader: any;
  rowData: any;
  deleteTableRow?: (index: number) => void;
  index: number;
  markRowForEdit?: (
    index: number,
    mode: "edit" | "save",
    editType: "inline" | "redirect"
  ) => void;
  upliftDataToTable: (
    index: number,
    key: string | number,
    value: string | number
  ) => void;
  checkboxRow?: (index: number, value: boolean) => void;

  handleRowClick?: (rowData: any) => void;
};

const TableRow = ({
  tableHeader,
  rowData,
  deleteTableRow,
  index,
  markRowForEdit,
  upliftDataToTable,
  checkboxRow,
  handleRowClick,
}: tableRowProps) => {
  return (
    <tr
      style={{ backgroundColor: `${rowData.editEnabled ? "#ECFAFD" : ""}` }}
      onClick={() => handleRowClick?.(rowData)}
    >
      {tableHeader?.map((cell: any, xandex: number) => {
        if (xandex === tableHeader.length - 1) {
          return (
            <TableCell
              key={`${rowData[cell.key]}${xandex}`}
              option={cell?.option}
              data={rowData[cell.key]}
              cellData={cell}
              onDeleteClick={deleteTableRow}
              index={index}
              onEditClick={(
                index: number,
                mode: "edit" | "save",
                editType: "inline" | "redirect"
              ) => markRowForEdit?.(index, mode, editType)}
              editEnabled={rowData?.editEnabled}
              upLiftInputata={(
                index: number,
                key: string | number,
                value: string | number
              ) => upliftDataToTable(index, key, value)}
              checkboxRow={(index: number, value: boolean) =>
                checkboxRow?.(index, value)
              }
              cellStyle={{
                position: "sticky",
                top: 0,
                right: 0,
                backgroundColor: "white",
                zIndex: 0,
              }}
              rowData={rowData}
            />
          );
        } else {
          return (
            <TableCell
              key={`${rowData[cell.key]}${xandex}`}
              option={cell?.option}
              data={rowData[cell.key]}
              cellData={cell}
              onDeleteClick={deleteTableRow}
              index={index}
              onEditClick={(
                index: number,
                mode: "edit" | "save",
                editType: "inline" | "redirect"
              ) => markRowForEdit?.(index, mode, editType)}
              editEnabled={rowData?.editEnabled}
              upLiftInputata={(
                index: number,
                key: string | number,
                value: string | number
              ) => upliftDataToTable(index, key, value)}
              checkboxRow={(index: number, value: boolean) =>
                checkboxRow?.(index, value)
              }
              rowData={rowData}
            />
          );
        }
      })}
    </tr>
  );
};

type dynamicObj = {
  [key: string]: any;
};

type childProps = {
  option?: "checkbox" | "menu" | "popup";
  data: any;
  onDeleteClick?: (index: number) => void;
  index: number;
  onEditClick?: (
    index: number,
    mode: "edit" | "save",
    editType: "inline" | "redirect"
  ) => void;
  editEnabled?: boolean;
  upLiftInputata?: (
    index: number,
    key: string | number,
    value: string | number
  ) => void;
  cellData: {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu" | "popup";
    width?: string;
    customStyles?: boolean;
    redirectedEdit?: boolean;
    popupContent?: any;
  };
  checkboxRow?: (index: number, value: boolean) => void;
  cellStyle?: dynamicObj;
  rowData?: any;
};

const TableCell = ({
  option,
  data,
  onDeleteClick,
  index,
  onEditClick,
  editEnabled,
  upLiftInputata,
  cellData,
  checkboxRow,
  cellStyle,
  rowData,
}: childProps) => {
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const onDeleteClickHandler = () => {
    setOpenDialogue(false);
    onDeleteClick?.(index);
  };

  const onEditSaveClickHandler = (
    index: number,
    type: "save" | "edit"
  ): void => {
    onEditClick?.(
      index,
      type,
      cellData?.redirectedEdit === true ? "redirect" : "inline"
    );
    setOpenDialogue(false);
  };

  let colorHighlight = "";
  let dataToRender = data;

  if (cellData.customStyles) {
    if (data?.includes?.("#")) {
      const splitData = data?.split?.("#");

      dataToRender = splitData?.[0];

      switch (splitData?.[1]) {
        case "investee": {
          colorHighlight = "border-blue";
          break;
        }
        case "scraped": {
          colorHighlight = "border-yellow";
          break;
        }
        case "investor": {
          colorHighlight = "border-blue";
          break;
        }
        case "unavailable": {
          colorHighlight = "border-red";
          break;
        }

        default: {
          colorHighlight = "";
          break;
        }
      }
    } else {
      switch (typeof dataToRender === "string" && dataToRender?.toLowerCase()) {
        case "pending": {
          colorHighlight = "data-complete";
          break;
        }
        case "completed": {
          colorHighlight = "data-pending";
          break;
        }
        case "auto-mapped": {
          colorHighlight = "background-blue";
          break;
        }
        case "mapped": {
          colorHighlight = "background-green";
          break;
        }
        case "unmapped": {
          colorHighlight = "background-red";
          break;
        }
        default: {
          colorHighlight = "";
          break;
        }
      }
    }
  }

  const [inputFieldData, setInputFieldData] = useState<string | number>(
    dataToRender
  );

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputFieldData(event.target.value);
  };

  const onFocusOutSave = () => {
    upLiftInputata?.(index, String(cellData.key), inputFieldData);
  };

  return (
    <td title={data} style={cellStyle}>
      {option == "menu" && (
        <div className="menu-container ">
          <Ellipsis
            className="icon"
            onClick={() => setOpenDialogue((prev) => !prev)}
          />
          {openDialogue && (
            <div className="popup-container">
              <div className="edit cursorPointer">
                {!editEnabled ? (
                  <span onClick={() => onEditSaveClickHandler?.(index, "edit")}>
                    <EditIcon />
                    Edit
                  </span>
                ) : (
                  <span onClick={() => onEditSaveClickHandler?.(index, "save")}>
                    <SaveIcon />
                    Save
                  </span>
                )}
              </div>
              <div
                onClick={onDeleteClickHandler}
                className="delete cursorPointer"
              >
                <DeleteIcon />
                Delete
              </div>
            </div>
          )}
        </div>
      )}

      {option == "checkbox" && (
        <div className="menu-container">
          <span onClick={() => checkboxRow?.(index, !data)}>
            <CheckBox checked={data} svgType />
          </span>
        </div>
      )}

      {option == "popup" && (
        <div className="menu-container">
          <Ellipsis
            className="icon"
            onClick={() => setOpenPopup((prev) => !prev)}
          />
          {openPopup && (
            <div className="popup-container">
              {cellData.popupContent?.(onDeleteClick, index, rowData)}
            </div>
          )}
        </div>
      )}

      {!option && (
        <div
          className={`data-cell ${cellData?.customStyles && colorHighlight}`}
          style={{
            background: `${editEnabled && "unset"}`,
          }}
        >
          {editEnabled ? (
            <input
              type="text"
              className="edit-input"
              value={inputFieldData}
              onChange={onInputChange}
              onBlur={onFocusOutSave}
            />
          ) : (
            <div
              style={{
                width: "10rem",

                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {dataToRender}
            </div>
          )}
        </div>
      )}
    </td>
  );
};
