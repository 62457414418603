// import library components
import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";

// payload type definition
type payloadType = {};

export const patchDropdownListAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "patchDropdownListAction",
    async (updatedDataList, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.updateDropdownList(),
          api.http.post(updatedDataList)
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.post()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const patchDropdownList = createSlice({
  name: "patchDropdownList",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(patchDropdownListAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(patchDropdownListAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(patchDropdownListAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.error;
      });
  },
});

export default patchDropdownList.reducer;
