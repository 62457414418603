import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const SourcesOfEmission = ({ receivedGhgEmissionData }: Props) => {
  const [emissionSourceData, setEmissionSourceData] = useState([]);

  useEffect(() => {
    if (receivedGhgEmissionData && receivedGhgEmissionData.emission_sources) {
      const receivedValues = receivedGhgEmissionData.emission_sources.map(
        (el: any) => {
          return [el.source_type, Math.round(el.total_emission_co2e)];
        }
      );
      setEmissionSourceData(receivedValues);
    }
  }, [receivedGhgEmissionData]);

  return (
    <div id="ghg-emissions-component" className="custom-bargraph-container">
      <Graphs.MultiBarGraph
        header="Sources of Emission"
        chartData={emissionSourceData}
        tableHeader={[]}
      />
    </div>
  );
};

export default SourcesOfEmission;
