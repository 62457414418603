// import library components
import React, { useEffect, useState } from "react";

// import custom components
import { ReactComponent as GraphVector } from "../../../../assets/icons/graphVector.svg";
import FilterCard from "../FilterCard/FilterCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import "./Scope1-Component.scss";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import EmissionPerFacilities from "./EmissionPerFacilities";
import EmissionByOrgUnit from "./EmissionByOrgUnit";
import EmissionByPeriod from "./EmissionByPeriod";
import EmissionByFuelConsumption from "./EmissionByFuelConsumption";
import EmissionPerYear from "./EmissionPerYear";
import EnergyConsumePerYear from "./EnergyConsumePerYear";
import EmissionBreakDown from "./EmissionBreakdown";

type Props = {};

type emissionBreakdownObj = {
  [key: string]: any;
};

type receivedGhgEmissionDataType = {
  total_emission?: number;
  is_total_emission_decreased?: boolean;
  energy_consumed?: number;
  is_energy_consumed_decreased?: boolean;
  fuel_consumed?: number;
  last_year_fuel_consumed?: number;
  is_fuel_consumed_decreased?: boolean;
  last_year_total_emission?: number;
  last_year_energy_consumed?: number;
  renewables?: number;
  is_renewables_decreased?: number;
  last_year_renewables?: number;
  emission_breakdown?: emissionBreakdownObj;
};

const Scope1 = ({}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionDataResponse);

  const [receivedGhgEmissionData, setReceivedGhgEmissionData] =
    useState<receivedGhgEmissionDataType>({ last_year_total_emission: 0 });
  const [categoryFiltersSelected, setCategoryFiltersSelected] = useState( [
    { value: "Fugitive Emission", checked: false },
    { value: "Mobile Combustion", checked: false },
    { value: "Stationary Combustion", checked: false },
  ]);
  const [orgUnitFiltersSelected, setOrgUnitFiltersSelected] = useState([]);
  const [orgUnitList, setOrgUnitList] = useState([]);

  useEffect(() => {
    let filteredCategoryList = categoryFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    let filteredOrgUnitList = orgUnitFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 1",
        category_list: filteredCategoryList,
        organisation_unit_list: filteredOrgUnitList,
        emission_year: "2023",
      })
    );
  }, [dispatch, categoryFiltersSelected, orgUnitFiltersSelected]);

  useEffect(() => {
    if (ghgEmissionData?.static_data) {
      let tempOrgUnitList =
        ghgEmissionData?.static_data?.organisation_unit_list.map((el: any) => {
          return { value: el, checked: false };
        }) ?? [];

      setOrgUnitList(tempOrgUnitList);
    }
    setReceivedGhgEmissionData(ghgEmissionData?.data);
  }, [ghgEmissionData]);

  return (
    <>
      <div className="stats-filter-container d-grid-c6">
        <div className="quickstats-container col-3 d-grid-c6">
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Scope 1 Emission</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.total_emission ?? 0} <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_total_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_total_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_total_emission}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Energy Consumed</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.energy_consumed} <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_energy_consumed_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_energy_consumed_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_energy_consumed}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Fuel Consumed</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.fuel_consumed ?? 0} <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_fuel_consumed_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_fuel_consumed_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_fuel_consumed}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Renewables</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.renewables ?? 0} <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_renewables_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_renewables_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span> {receivedGhgEmissionData?.last_year_renewables}%</span>
                <GraphVector />
              </span>
            </div>
          </div>
        </div>
        <div className="filters col-3 d-grid-c6">
          <FilterCard
            header="Category"
            checkboxList={
             
            categoryFiltersSelected
          }
            applyBtnHandler={(e) => {
              setCategoryFiltersSelected(e);
            }}
          />
          <FilterCard
            header="Organisation unit"
            checkboxList={orgUnitList}
            applyBtnHandler={(e) => {
              setOrgUnitFiltersSelected(e);
            }}
          />
        </div>
      </div>
      <div className="demographics-container d-grid-c6">
        <div className="demographic-tile col-2">
          <EmissionBreakDown
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div className="demographic-tile col-4">
          <EmissionPerFacilities
            receivedGhgEmissionData={receivedGhgEmissionData}
            setReceivedGhgEmissionData={setReceivedGhgEmissionData}
          />
        </div>
        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "500px", paddingBottom: "7rem" }}
        >
          <EmissionByOrgUnit
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>
        <div className="demographic-tile col-4">
          <EmissionByPeriod receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>

        <div className="demographic-tile col-2">
          <EmissionByFuelConsumption
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "438.6px", paddingBottom: "7rem" }}
        >
          <EmissionPerYear receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>
        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "438.6px", paddingBottom: "7rem" }}
        >
          <EnergyConsumePerYear
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>
      </div>
    </>
  );
};

export default Scope1;
