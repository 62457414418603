// import library components
import React, { useEffect, useState } from "react";

// import styels file
import "./InvesteeHomepage.scss";

// import custom components

// import redux components
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import TableEnhanced from "../../../../Components/Core/TableEnhanced/TableEnhanced";

import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";

import { Pagination } from "react-light-pagination";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getInvesteeHomepageDataAction } from "../../../../Redux/Slices/dashboard/investeeSection/homepageData/getHomepageData.slice";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectInvesteeDashboardInvesteeResponse } from "../../../../Redux/Slices/dashboard/investeeSection/homepageData/getHomepageData.selector";
import { selectFilterBasedDataResponse } from "../../../../Redux/Slices/Utils/getFilterBasedData/getFilterBasedData.selector";
import { getFilterBasedDataAction } from "../../../../Redux/Slices/Utils/getFilterBasedData/getFilterBasedData.slice";

// type definition
type Props = {};

const InvesteeHomepage = (props: Props) => {
  const homepageResponse = useSelector(selectInvesteeDashboardInvesteeResponse);
  const subFilterResponse = useSelector(selectFilterBasedDataResponse);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [homepageData, setHomepageData] = useState<{ [key: string]: any }>([]);
  const [staticData, setStaticData] = useState<{ [key: string]: any }>({});
  const [paginationKeys, setPaginationKeys] = useState<{ [key: string]: any }>(
    {}
  );

  // ^ filters data for dropdown
  const [subFilterData, setSubFilterData] = useState<{
    sub_asset_class?: string[];
    sub_sector?: string[];
    country?: string[];
  }>({ sub_asset_class: [], sub_sector: [], country: [] });

  const [selectedFilter, setSelectedFilters] = useState<{
    [key: string]: string;
  }>({});

  const [assetClassArr, setAssetClassArr] = useState<string[]>([]);
  const [sectorArr, setSectorArr] = useState<string[]>([]);

  const handleNavigate = (obj: any) => {
    navigate(
      "/dashboard/investee-details/" + encodeURIComponent(obj.common_uuid)
    );
  };

  useEffect(() => {
    dispatch(
      getInvesteeHomepageDataAction({
        query_limit: 10,
        asset_class: selectedFilter?.asset_class,
        sub_asset_class: selectedFilter?.sub_asset_class,
        country: selectedFilter?.country,
        dq_score: selectedFilter?.dq_score,
        next_token: selectedFilter?.next_token,
        sector: selectedFilter?.sector,
        sub_sector: selectedFilter?.sub_sector,
      })
    );
  }, [selectedFilter]);

  useEffect(() => {
    setHomepageData(homepageResponse?.data);
    if (homepageResponse?.static_data) {
      setStaticData(homepageResponse?.static_data);
    }
    if (assetClassArr.length === 0) {
      setAssetClassArr(homepageResponse?.static_data?.asset_class_list ?? []);
    }
    if (sectorArr.length === 0) {
      setSectorArr(homepageResponse?.static_data?.sector_list ?? []);
    }
  }, [homepageResponse]);

  const getSubFilterData = (filterType: string, filterValue: string) => {
    dispatch(
      getFilterBasedDataAction({
        filter_type: filterType,
        filter_value: filterValue,
      })
    ).then((response) => {
      if (response.type.includes("fulfilled")) {
        switch (filterType) {
          case "asset_class": {
            setSubFilterData((prev) => ({
              ...prev,
              sub_asset_class: response?.payload?.data?.sub_asset_class_list,
            }));
            break;
          }
          case "sector": {
            setSubFilterData((prev) => ({
              ...prev,
              sub_sector: response?.payload?.data?.sub_sector_list,
            }));
            break;
          }
          case "country": {
            setSubFilterData((prev) => ({
              ...prev,
              country: response?.payload?.data?.country_list,
            }));
            break;
          }
          default: {
            break;
          }
        }
      }
    });
  };

  return (
    <div id="InvesteeComponent" className="investee-homepage-container">
      <div className="header-label">Investee</div>

      <MainContentWrapper customClassName="content-wrapper-classname">
        <>
          <div className="main-content-wrapper">
            <div className="filter-section">
              {
                <DropDown
                  optionsList={["Select", ...assetClassArr]}
                  dropdownHeader="Asset class"
                  onSelectHandler={(selectedOption: string) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      asset_class: selectedOption,
                    }));
                    getSubFilterData("asset_class", selectedOption);
                  }}
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                />
              }
              {subFilterData.sub_asset_class && (
                <DropDown
                  disabled={subFilterData.sub_asset_class?.length === 0}
                  optionsList={["Select", ...subFilterData.sub_asset_class]}
                  dropdownHeader="Sub asset class"
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                  onSelectHandler={(selectedOption: string) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      sub_asset_class: selectedOption,
                    }));
                  }}
                />
              )}
              {
                <DropDown
                  optionsList={["Select", ...sectorArr]}
                  dropdownHeader="Sector"
                  onSelectHandler={(selectedOption: string) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      sector: selectedOption,
                    }));
                    getSubFilterData("sector", selectedOption);
                  }}
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                />
              }
              {subFilterData.sub_sector && (
                <DropDown
                  disabled={subFilterData.sub_sector?.length === 0}
                  optionsList={["Select", ...subFilterData.sub_sector]}
                  dropdownHeader="Sub sector"
                  onSelectHandler={(selectedOption: string) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      sub_sector: selectedOption,
                    }));
                  }}
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                />
              )}
              {staticData?.region_list && (
                <DropDown
                  optionsList={["Select", ...staticData?.region_list]}
                  dropdownHeader="Region"
                  onSelectHandler={(selectedOption: string) => {
                    getSubFilterData("country", selectedOption);
                  }}
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                />
              )}
              {subFilterData.country && (
                <DropDown
                  disabled={subFilterData.country?.length === 0}
                  optionsList={["Select", ...subFilterData.country]}
                  dropdownHeader="Country"
                  onSelectHandler={(selectedOption: string) => {
                    setSelectedFilters((prev) => ({
                      ...prev,
                      country: selectedOption,
                    }));
                  }}
                  dropDownBodyClassName="dropdown-body-classname noScrollBar"
                />
              )}
              <DropDown
                optionsList={["Select", "1", "2", "3", "4", "5"]}
                dropdownHeader="Data score"
                onSelectHandler={(selectedOption: string) => {
                  setSelectedFilters((prev) => ({
                    ...prev,
                    dq_score: selectedOption,
                  }));
                }}
                dropDownBodyClassName="dropdown-body-classname noScrollBar"
              />
              <span className="placeholder"></span>
            </div>
            <div className="table-container">
              {homepageData && (
                <TableEnhanced
                  tableHeader={tableHeaderData}
                  tableData={homepageData}
                  handleRowClick={handleNavigate}
                />
              )}
            </div>
          </div>
          <div className="footer-pagination">
            {/* {

                            <Pagination
                                totalNumberOfData={staticData?.next_token_list.length}
                                perPage={1}
                                nextClickHandler={onNextClick}
                                // previousClickHandler={onPreviousClick}
                                // pageClickHandler={onPageClick}
                                classNames={{
                                    container: 'container',
                                    nextButton: "next-button",
                                    previousButton: "previous-button",
                                    pageButton: "page-button",
                                    pageActiveButton: "page-active",
                                    buttons: "buttons",
                                }}
                                components={{
                                    nextButton: <Chevron />,
                                    previousButton: <Chevron />,
                                }}
                            />
                        } */}
          </div>
        </>
      </MainContentWrapper>
    </div>
  );
};

export default InvesteeHomepage;

type tableHeader = {
  label: string;
  key: string | null;
  option?: "checkbox" | "menu";
  width?: string;
  customStyles?: boolean;
};
const tableHeaderData: tableHeader[] = [
  {
    label: "Investee Name",
    key: "investee_name",
  },
  {
    label: "Financed Emission (Mtco2e)",
    key: "financed_emission",
  },
  {
    label: "Investment Amount ($M)",
    key: "outstanding_amount",
  },
  {
    label: "Data Score",
    key: "data_score",
  },
];
