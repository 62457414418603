import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRightExtendedTail } from "../../../../../assets/icons/arrowRightExtendedTail.svg";
import { Graphs } from "../../../../../Components/graphs";
import DropDown from "../../../../../Components/Core/DropDown/DropDown";
import { colors } from "../../../../../DesignSystem/Colors/colors";
import "../../AssetClass/EmissionByPeriod/EmissionByPeriod.scss";
import { changeOpacityOfRgb } from "../../../../../utils/helpers/OpacityModifier";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import {
  getMonthNumber,
  getAbbreviatedMonthName,
} from "../../../../../utils/helpers/MonthMapper";
import { getSectorData } from "../../../../../Redux/Slices/dashboard/financedEmission/sector/getSectorData.slice";

type financedEmissionDataObj = {
  [key: string]: any;
};

type dropDownOptionType = {
  img?: string | File | HTMLImageElement | undefined;
  name: string;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
  sectorTabs: dropDownOptionType[];
  selectedSectorTab: string;
}

const EmissionByPeriod = ({
  receivedFinancedEmissionData,
  sectorTabs,
  selectedSectorTab,
}: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [labels, setLabels] = useState<string[]>([]);
  const [chartDataArr, setChartDataArr] = useState([
    [10, 70, 45, 32, 57, 13, 4, 12, 79, 99],
    [21, 61, 46, 62, 17, 26, 63, 71, 19, 95],
    [32, 52, 47, 53, 18, 37, 74, 42, 20, 93],
    [43, 43, 48, 44, 19, 48, 85, 33, 32, 92],
    [54, 34, 49, 95, 16, 59, 96, 64, 34, 90],
  ]);
  const [selectedYear, setSelectedYear] = useState<string>("2023");
  const [selectedInitialMonth, setSelectedInitalMonth] =
    useState<string>("January");
  const [selectedFinalMonth, setSelectedFinalMonth] =
    useState<string>("December");
  const [legendArr, setLegendArr] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.emission_by_period
    ) {
      let tempLabels = receivedFinancedEmissionData.emission_by_period?.map(
        (el: any) => getAbbreviatedMonthName(+el.month.split("-")[1])
      );
      setLabels(tempLabels);

      let tempData = [] as any;

      receivedFinancedEmissionData.emission_by_period?.forEach((el: any) => {
        if (el.sector_data) {
          el.sector_data?.map((sectorEl: any, index: number) => {
            if (Array.isArray(tempData[index])) {
              tempData[index].push(sectorEl.financed_emission);
            } else {
              tempData.push([sectorEl.financed_emission]);
            }
          });
        } else {
          el.sub_sector_data?.map((sectorEl: any, index: number) => {
            if (Array.isArray(tempData[index])) {
              tempData[index].push(sectorEl.financed_emission);
            } else {
              tempData.push([sectorEl.financed_emission]);
            }
          });
        }
      });

      receivedFinancedEmissionData.emission_by_period?.forEach((el: any) => {
        if (el.financed_emission) {
          tempData.push([el.financed_emission]);
        }
      });
      setChartDataArr(tempData);
    }
  }, [receivedFinancedEmissionData]);

  useEffect(() => {
    if (selectedSectorTab === "All") {
      let tempSectorTabs = sectorTabs
        .filter((el) => el.name !== "All")
        .map((el) => el.name);

      setLegendArr(tempSectorTabs);
    } else {
      setLegendArr([selectedSectorTab]);
    }
  }, [sectorTabs, selectedSectorTab]);

  const dateSelectHandler = ({
    year = "2022",
    initialMonth = "January",
    finalMonth = "December",
  }: any) => {
    let fromDate = `${year}-${getMonthNumber(initialMonth)}-01T00:00:18.000Z`;
    let toDate = `${year}-${getMonthNumber(finalMonth)}-31T23:58:18.000Z`;

    dispatch(
      getSectorData({
        sector: selectedSectorTab,
        sub_sector: "all",
        from_date: fromDate,
        to_date: toDate,
      })
    );
  };

  return (
    <Graphs.LineGraph
      chartData={chartDataArr}
      labels={
        labels ?? [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "july",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ]
      }
      header={
        <>
          <div className="ebp-date-selector-container">
            <div>Emission By Period</div>
            <div className="ebp-date-selector">
              <DropDown
                optionsList={["2023", "2022", "2021", "2020", "2019", "2018"]}
                dropDownContainerClassName="dropdown-container"
                dropDownBodyClassName="dropdown-body"
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    year: e,
                    initialMonth: selectedInitialMonth,
                    finalMonth: selectedFinalMonth,
                  });
                  setSelectedYear(e);
                }}
              />
              <div className="ebp-partitioner"></div>
              <DropDown
                optionsList={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                dropDownContainerClassName="dropdown-container dropdown-container-months"
                dropDownBodyClassName="dropdown-body"
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    year: selectedYear,
                    initialMonth: e,
                    finalMonth: selectedFinalMonth,
                  });
                  setSelectedInitalMonth(e);
                }}
              />
              <div className="ebp-right-arrow">
                <ArrowRightExtendedTail />
              </div>
              <DropDown
                optionsList={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                dropDownContainerClassName="dropdown-container dropdown-container-months"
                dropDownBodyClassName="dropdown-body"
                selected="December"
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    year: selectedYear,
                    initialMonth: selectedInitialMonth,
                    finalMonth: e,
                  });
                  setSelectedFinalMonth(e);
                }}
              />
            </div>
          </div>
          <div className="ebp-labels-container">
            {legendArr.map((el, index) => (
              <div
                className="ebp-label"
                style={{
                  backgroundColor: `${changeOpacityOfRgb(
                    colors.mixedColors[index],
                    0.12
                  )}`,
                }}
              >
                <div
                  className="ebp-label-point"
                  style={{ backgroundColor: `${colors.mixedColors[index]}` }}
                ></div>
                <div>{el}</div>
              </div>
            ))}
          </div>
        </>
      }
      legends={legendArr}
      xAxisLabel="Months"
      yAxisLabel="Emissions(MtCO2e)"
      theme=""
      legendsDisabled={true}
    />
  );
};

export default EmissionByPeriod;
