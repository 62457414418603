//import library components
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

// import initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
  filter_type: string;
  filter_value: string;
};

export const getFilterBasedDataAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "investor/utils/getFilterBasedDataAction",
    async ({ filter_type, filter_value }, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getFilterBasedData(filter_type, filter_value),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const filterBasedDataUtils = createSlice({
  name: "filterBasedDataUtils",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFilterBasedDataAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getFilterBasedDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getFilterBasedDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.payload;
      });
  },
});

export default filterBasedDataUtils.reducer;
