// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "./Dashboard.scss";

// import custom components
import { Graphs } from "../../../../Components/graphs";
import { ReactComponent as BulbIcon } from "../../../../assets/icons/bulb-filled.svg";
import { ReactComponent as MoneyBagIcon } from "../../../../assets/icons/money-bag.svg";
import { ReactComponent as PieChartIcon } from "../../../../assets/icons/piechart.svg";
import { ReactComponent as RobotFaceIcon } from "../../../../assets/icons/robot-face.svg";
import { ReactComponent as SackDollarIcon } from "../../../../assets/icons/sack-dollar.svg";
import { ReactComponent as ShoppingBagIcon } from "../../../../assets/icons/shopping-bag-percent.svg";
import { ReactComponent as CloudIcon } from "../../../../assets/icons/cloud-co2-icon.svg";
import StatsTile from "../../../../Components/Core/StatsTile/StatsTile.component";

// import redux components
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectInvesteeDashboardDataResponse } from "../../../../Redux/Slices/InvesteePlatform/getDashboardData/getDashboardData.selector";
import { getInvesteeDashboardDataAction } from "../../../../Redux/Slices/InvesteePlatform/getDashboardData/getDashboardData.slice";
import { useParams } from "react-router-dom";

type Props = {};

const DashboardInvestor = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const response = useSelector(selectInvesteeDashboardDataResponse);

  const [dashboardData, setDashboardData] = useState<{
    [key: string]: any;
  } | null>(null);

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getInvesteeDashboardDataAction({ id: decodeURIComponent(`${id}`) })
    );
  }, []);

  useEffect(() => {
    setDashboardData(response?.data);
  }, [response]);

  const chartColors = ["#0C81A8", "#14C99E", "#14C9C9"];

  return (
    <div
      id="InvesteeDashboardComponent"
      className="investee-dashboard-container"
    >
      <div className="header-stats-info">
        <div className="content">
          <div className="label">Country:</div>
          <div className="value">{dashboardData?.investeeDetails?.country}</div>
        </div>
        <div className="content">
          <div className="label">Sector:</div>
          <div className="value">{dashboardData?.investeeDetails?.sector}</div>
        </div>
        <div className="content">
          <div className="label">Sub sector:</div>
          <div className="value">
            {dashboardData?.investeeDetails?.sub_sector}
          </div>
        </div>
      </div>

      <div className="d-grid-c10">
        <div className="col-5 tile d-grid-c10">
          <div className="col-4 graph-container">
            <Graphs.PieChart
              chartData={[
                {
                  label: "",
                  values: dashboardData?.emissionByScope?.map(
                    (item: any) => item.percentage
                  ),
                },
              ]}
              doughNut="70%"
              header="Emission by Scope"
            />
          </div>
          <div className="col-6 stats-container">
            <div className="table-header">
              <div className="cell">Label</div>
              <div className="cell">Emission</div>
              <div className="cell">%</div>
            </div>
            {dashboardData?.emissionByScope?.map((item: any, index: number) => {
              return (
                <div className="body-row">
                  <div className="cell">
                    <div
                      className="icon"
                      style={{ backgroundColor: chartColors[index] }}
                    />
                    <div className="content">{item.scope}</div>
                  </div>
                  <div className="cell">{item.emission}</div>
                  <div className="cell">{item.percentage}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-5 d-grid-c10">
          <div className="col-5">
            <StatsTile
              header="Amount Invested"
              unit="MtCO2e"
              value={dashboardData?.investementDetails?.investedAmount}
              theme="#FFF"
            />
          </div>
          <div className="col-5">
            <StatsTile
              header="Attributed Financed Emission"
              unit="MtCO2e"
              value={
                dashboardData?.investementDetails?.attributedFinancialEmission
              }
              theme="#FFF"
            />
          </div>
          <div className="col-10">
            <StatsTile
              header="WACI/ $M investment"
              unit="MtCO2e"
              value={dashboardData?.investementDetails?.waci}
              theme="#FFF"
            />
          </div>
        </div>

        <div className="col-5 tile">
          {dashboardData && (
            <Graphs.LineGraph
              chartData={Object.values(
                dashboardData?.emissionIntensityByPeriod
              )}
              header="Emission Intensity by Period"
              legends={["one", "two"]}
              xAxisLabel=""
              yAxisLabel="Emissions(MtCO2e)"
              legendsPosition="top"
              labels={Object.keys(dashboardData?.emissionIntensityByPeriod)}
            />
          )}
        </div>
        <div className="col-5 tile stats-tile-container">
          <div className="label">Asset class (Listed Equity)</div>

          <div className="asset-stats-container">
            <div
              className="stats-tile"
              style={{ backgroundColor: "#F59E0B1F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #F59E0B" }}
              >
                <BulbIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">Energy consumption</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.energyConsumption}{" "}
                  <span>kwh</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#9747FF1F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #9747FF" }}
              >
                <MoneyBagIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">Asset Per Sector</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.assetsOfTheSector} <span>$M</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#A96A2B1F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #A96A2B" }}
              >
                <PieChartIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">Revenue of the Sector</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.revenueOfTheSector}{" "}
                  <span>$M</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#7B91B01F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #7B91B0" }}
              >
                <RobotFaceIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">Company Production</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.production}{" "}
                  <span>Metric Tons</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#3B82F61F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #3B82F6" }}
              >
                <SackDollarIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">Revenue</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.revenue} <span>$M</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#14C99E1F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #14C99E" }}
              >
                <CloudIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">GHG Emission of the Sector</div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.ghgEmission}
                  <span>Mtco2e</span>{" "}
                </div>
              </div>
            </div>
            <div
              className="stats-tile"
              style={{ backgroundColor: "#2F6C7B1F" }}
            >
              <div
                className="icon"
                style={{ borderRight: "2px solid #2F6C7B" }}
              >
                <ShoppingBagIcon />{" "}
              </div>
              <div className="content">
                <div className="stats-label">
                  Asset Turnover Ratio Per Sector
                </div>
                <div className="stats-value">
                  {dashboardData?.assetClass?.assetTurnoverRatio}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardInvestor;

const data = {
  emissionByScope: [
    {
      scope: "Scope 1",
      percentage: 0,
      emission: 12,
    },
    {
      scope: "Scope 2",
      percentage: 0,
      emission: 42,
    },
    {
      scope: "Scope 3",
      percentage: 0,
      emission: 36,
    },
  ],
  investeeDetails: {
    sector: "Communication Services",
    sub_sector: "Telecom Services",
    country: "US",
  },
  investementDetails: {
    investedAmount: 48996,
    attributedFinancialEmission: 0,
    waci: 0,
  },

  assetClass: {
    energyConsumption: 1150096000,
    revenue: 3000000,
    production: 1015023,
    assetTurnoverRatio: 0.5,
    revenueOfTheSector: 350000,
    assetsOfTheSector: 700000,
    ghgEmission: 15,
  },

  emissionIntensityByPeriod: {
    "2023": [1, 2],
    "2024": [2, 4],
    "2025": [1, 6],
  },
};
