import AssetClass from "./AssetClass/AssetClass";
import Overview from "./Overview/Overview";
import Sector from "./Sector/Sector";
import Location from "./Location/Homepage/LocationHomepage";
import LocationDetails from "./Location/LocationDetails/LocationDetails";
import PortfolioAnalysis from "./PortfolioAnalysis/PortfolioAnalysis";

const DashboardFinancedEmission = {
    AssetClass,
    Overview,
    Sector,
    Location,
    LocationDetails,
    PortfolioAnalysis,
}
export default DashboardFinancedEmission;