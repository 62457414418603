import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../utils";
import api from "../../../../Api";

type payloadType = any



export const postAssetFormDataAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'investee/postFormData',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetch(
                api.config.postAssetFormData(),
                api.http.post(payload)
            )

            const result = await api.afterFetchHandlers.parseContent(response, api.http.post(payload))
            return result;

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            rejectWithValue(error)
        }
    }
)


const postFormData = createSlice({
    name: 'postFormData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(postAssetFormDataAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(postAssetFormDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(postAssetFormDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true
                state.errorResponse = action.payload;
            })
    },
})

export default postFormData.reducer