import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionByOrgUnit = ({ receivedGhgEmissionData }: Props) => {
  const [labels, setLabels] = useState<string[]>([
    "Date center",
    "Warehouse",
    "Storage Space",
    "Cafeteria",
    "Office Buldings",
  ]);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.emission_by_organisation_unit
    ) {
      setLabels(
        receivedGhgEmissionData.emission_by_organisation_unit.map(
          (el: any) => el.organisation_unit
        )
      );
      setValues(
        receivedGhgEmissionData.emission_by_organisation_unit.map(
          (el: any) => el.total_emission_co2e
        )
      );
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      orientationHorizontal
      header={<>Emission by Organisation Unit</>}
      xAxisLabel="Emissions (MtCO2e)"
      yAxisLabel=""
      labels={labels}
      chartData={[
        {
          label: "tCO2e",
          values: values ?? [],
        },
      ]}
      maintainAspectRatio={false}
      yAxisLabelVisibility={false}
      barColor="#9DCA5C"
      yGridVisibility={false}
      legendsPosition="bottom"
    />
  );
};

export default EmissionByOrgUnit;
