import { useState, useEffect } from "react";
import { Graphs } from "../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EnergyConsumePerYear = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState([]);
  const [labelsArr, setLabelsArr] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.energy_consumed_per_year
    ) {
      setValues(
        receivedGhgEmissionData.energy_consumed_per_year.map(
          (el: any) => el.energy_consumed
        )
      );

      setLabelsArr(
        receivedGhgEmissionData.energy_consumed_per_year.map(
          (el: any) => el.month
        )
      );
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Energy Consumed Per Year</>}
      xAxisLabel="Year"
      yAxisLabel="Energy (kWh)"
      labels={labelsArr}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      maintainAspectRatio={false}
      barColor="#9DCA5C"
      xGridVisibility={false}
      yAxisVisibility={false}
      legendsPosition="bottom"
      legendsDisabled={true}
    />
  );
};

export default EnergyConsumePerYear;
