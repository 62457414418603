// import library components
import { combineReducers } from "@reduxjs/toolkit";

//@ dashboard reducers\
import overviewData from "./Slices/dashboard/overview/getOverviewData.slice";
import ghgEmissionData from "./Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import financedEmissionOverviewData from "./Slices/dashboard/financedEmission/overview/getFinancedEmissionOverviewData.slice";
import financedEmissionLocationData from "./Slices/dashboard/financedEmission/location/getFinancedEmissionLocationData.slice";
import financedEmissionLocationDetails from "./Slices/dashboard/financedEmission/locationDetails/getFinancedEmissionLocationDetails.slice";
import financedEmissionPortfolioAnalysisData from "./Slices/dashboard/financedEmission/portfolioAnalysis/getFinancedEmissionPortfolioAnalysisData.slice";
import assetClassData from "./Slices/dashboard/financedEmission/assetClass/getAssetClassData.slice";
import sectorData from "./Slices/dashboard/financedEmission/sector/getSectorData.slice";
import competitiveInvestorsData from "./Slices/dashboard/benchmarking/competitiveInvestors/getCompetitiveInvestorsData.slice";
import benchmarkingListData from "./Slices/dashboard/benchmarking/benchmarkingList/getBenchmarkingListData.slice";
import benchmarkingComparedData from "./Slices/dashboard/benchmarking/benchmarkingComparedData/getBenchmarkingComparedData.slice";
import createBenchmark from "./Slices/dashboard/benchmarking/createBenchmark/createBenchmark.slice";
import patchBenchmark from "./Slices/dashboard/benchmarking/updateBenchmark/updateBenchmark.slice";
import { benchmarkingDataReducer } from "./Slices/dashboard/benchmarking/Utils/storeBenchmarkingData/benchmarkingData.slice";
import dataByFilterType from "./Slices/dashboard/financedEmission/portfolioAnalysis/getDataByFilter/getDataByFilterTypeValues.slice";

//@ ghg emission reducers
import partnerIntegrationList from "./Slices/ghgEmission/partnerIntegrationList/partnerIntegrationList.slice";
import postGHGEmissionData from "./Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import getGhgEmissionDataList from "./Slices/ghgEmission/ghgEmissionDataList/getGhgEmissionDataList.slice";
import getDropdowncontents from "./Slices/ghgEmission/dropdownContents/getDropdownContents.slice";

//@ financed emission reducers
import assetClassDetails from "./Slices/financedEmission/datacollection/getAssetClassDetails.slice";
import updateAssetClassDetails from "./Slices/financedEmission/updateAssetDetails/updateAssetDetails.slice";
import assetInventoryList from "./Slices/financedEmission/assetInventory/getAssetInventory.slice";
import pcafMappingData from "./Slices/financedEmission/pcafMapping/getPcafMapping.slice";
import onboardedInvesteeList from "./Slices/financedEmission/onboardedInvesteeList/onboardedInvesteeList.slice";
import postInviteInvestee from "./Slices/financedEmission/inviteInvestee/postInviteInvestee.slice";
import investeeInventoryList from "./Slices/financedEmission/investeeInventory/getInvesteeInventoryList.slice";
import patchAssetInventory from "./Slices/financedEmission/updateAssetInventory/patchAssetInventory.slice";
import patchInvesteeInventory from "./Slices/financedEmission/updateInvesteeInventory/patchInvesteeInventory.slice";
import addAssetClass from "./Slices/financedEmission/addAssetClass/addAssetClass.slice";
import patchDropdownList from "./Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import patchGhgEmissionDataList from "./Slices/ghgEmission/updateGhgEmissionDataList/updateGhgEmissionDataList.slice";

//@ investor dashboard investee section
import investeeHomepageData from "./Slices/dashboard/investeeSection/homepageData/getHomepageData.slice";

//@ investor target setting reducers
import getInvestorTargetHomepage from "./Slices/dashboard/targetSetting/investorTargetHomepage/getInvestorTargetHomepage.slice";
import getInvestorTargetDetails from "./Slices/dashboard/targetSetting/investorTargetDetails/getInvestorTargetDetails.slice";
import postInvestorTargetForm from "./Slices/dashboard/targetSetting/investorTargetForm/postInvestorTargetForm.slice";
import deleteTargetReducer from "./Slices/dashboard/targetSetting/deleteTarget/deleteTarget.slice";

//@ Investee Platform
import userProgressStateDC from "./Slices/InvesteePlatform/Utils/storeUserProgressDC/userDCProgressState.slice";
import getAssetFormData from "./Slices/InvesteePlatform/getAssetFormData/getAssetFormData.slice";
import postAssetFormData from "./Slices/InvesteePlatform/postAssetFormData/postAssetFormData.slice";
import getInvesteeActivityLogs from "./Slices/InvesteePlatform/getActivityLogs/getActivityLogs.slice";
import investeeDashboardData from "./Slices/InvesteePlatform/getDashboardData/getDashboardData.slice";
import investeeTargetDetails from "./Slices/InvesteePlatform/getTargetDetails/getTargetDetails.slice";
import investeeTargetHomepage from "./Slices/InvesteePlatform/getTargetHomepage/getTargetHomepage.slice";
import postTargetSettingData from "./Slices/InvesteePlatform/postTargetSetting/postTargetSettingData.slice";
import deleteActivityLogsReducer from "./Slices/InvesteePlatform/deleteActivityLogs/deleteActivityLogs.slice";
import updatePcafAssetClassReducer from "./Slices/financedEmission/updatePcafAssetClass/updatePcafAssetClass.slice";

//@ utils reducers/ helper reducers
import { dataFillModeSwitchSlice } from "./Slices/ghgEmission/utils/dataFillModeSwitchGHG/switch.dataFillModeGHG.slice";
import postFilesS3Slice from "./Slices/Utils/uploadFilesS3/postFilesS3";
import { dataCollectionProgressReducer } from "./Slices/InvesteePlatform/Utils/storeDataCollectionProgress/dataCollectionProgress.slice";
import filterBasedDataUtils from "./Slices/Utils/getFilterBasedData/getFilterBasedData.slice";
import utilsVerifyToken from "./Slices/Utils/TokenVerification/TokenVerification.slice";
import getDropdownListAction from "./Slices/dashboard/targetSetting/getDropdownList/getDropdownList.slice";

const dashboardReducers = {
  overviewData,
  ghgEmissionData,
  financedEmissionOverviewData,
  financedEmissionLocationData,
  financedEmissionLocationDetails,
  financedEmissionPortfolioAnalysisData,
  assetClassData,
  sectorData,
  competitiveInvestorsData,
  benchmarkingListData,
  benchmarkingComparedData,
  createBenchmark,
  benchmarkingDataReducer,
  patchBenchmark,
  dataByFilterType,
};

const ghgEmissionReducers = {
  partnerIntegrationList,
  postGHGEmissionData,
  getGhgEmissionDataList,
  getDropdowncontents,
};

const financedEmissionReducers = {
  assetClassDetails,
  updateAssetClassDetails,
  assetInventoryList,
  pcafMappingData,
  onboardedInvesteeList,
  postInviteInvestee,
  investeeInventoryList,
  patchAssetInventory,
  patchInvesteeInventory,
  addAssetClass,
  patchDropdownList,
  patchGhgEmissionDataList,
};

const investorDashboardInvestee = {
  investeeHomepageData,
};

const investorTargetSetting = {
  getInvestorTargetHomepage,
  getInvestorTargetDetails,
  postInvestorTargetForm,
  getDropdownListAction,
  deleteTargetReducer,
  updatePcafAssetClassReducer,
};

const utils = {
  utilsVerifyToken,
  dataFillModeSwitchSlice,
  postFilesS3Slice,
  filterBasedDataUtils,
};

//@ reducers related to ivnestee platform

const investeePlatform = {
  userProgressStateDC,
  getAssetFormData,
  postAssetFormData,
  getInvesteeActivityLogs,
  investeeDashboardData,
  investeeTargetDetails,
  investeeTargetHomepage,
  postTargetSettingData,
  dataCollectionProgressReducer,
  deleteActivityLogsReducer,
};

export const rootReducer = combineReducers({
  ...dashboardReducers,
  ...ghgEmissionReducers,
  ...financedEmissionReducers,
  ...investorTargetSetting,
  ...utils,

  ...investeePlatform,
  ...investorDashboardInvestee,
});
