// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../../store";

// create base selector for reducer
const selectFinancedEmissionDataStore = (state: RootState) =>
  state.financedEmissionOverviewData;

export const selectFinancedEmissionOverviewDataResponse = createSelector(
  [selectFinancedEmissionDataStore],
  (selectFinancedEmissionDataStore) => selectFinancedEmissionDataStore.response
);
