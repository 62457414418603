// import library components
import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../../utils";

//  payload type definition
type payloadType = {
  investor_name?: string;
  limit: number;
  search_key?: string;
  asset_class?: string;
  sub_asset_class?: string;
  sector?: string;
  sub_sector?: string;
  country?: string;
  data_score?: string | number;
  next_token?: string;
};

export const getAssetInventoryList: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getAssetInventoryList",
    async (
      {
        investor_name,
        limit,
        search_key,
        asset_class,
        sub_asset_class,
        sector,
        sub_sector,
        country,
        data_score,
        next_token,
      },
      { rejectWithValue }
    ) => {
      // order fo the parameters in the url
      // investor_name, limit, search_key, asset_class, sub_asset_class, sector, sub_sector, country, data_score, next_token

      try {
        const response = await fetch(
          api.config.getAssetInventoryList(
            investor_name,
            limit,
            search_key,
            asset_class,
            sub_asset_class,
            sector,
            sub_sector,
            country,
            data_score,
            next_token
          ),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const assetInventoryList = createSlice({
  name: "assetInventoryList",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAssetInventoryList.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getAssetInventoryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getAssetInventoryList.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default assetInventoryList.reducer;
