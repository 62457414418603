import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./Tooltip.scss";

interface ITooltip {
  borderColor?: string;
  backgroundColor?: string;
  toolTipContent?: ReactNode;
  toolTipArrowPosition?: "center" | "left" | "right";
}

const Tooltip = ({
  borderColor = "red",
  backgroundColor = "",
  toolTipContent = <>Tooltip Content</>,
  toolTipArrowPosition = "center",
}: ITooltip) => {
  const [tooltipPosition, setToolTipPosition] = useState<number>(0);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tooltipRef.current) {
      const { width } = tooltipRef.current.getBoundingClientRect();
      setToolTipPosition(width);
    }
  }, []);

  let arrowBorderClass = "Tooltip-container-arrow-border-position-center";
  arrowBorderClass =
    toolTipArrowPosition === "right"
      ? "Tooltip-container-arrow-border-position-right"
      : toolTipArrowPosition === "left"
      ? "Tooltip-container-arrow-border-position-left"
      : "Tooltip-container-arrow-border-position-center";

  let containerArrowClass = "Tooltip-container-arrow-position-center";
  containerArrowClass =
    toolTipArrowPosition === "right"
      ? "Tooltip-container-arrow-position-right"
      : toolTipArrowPosition === "left"
      ? "Tooltip-container-arrow-position-left"
      : "Tooltip-container-arrow-position-center";

  const tooltipContainerStyle =
    toolTipArrowPosition === "right"
      ? { marginLeft: `calc(-${tooltipPosition}px + 20%)` }
      : toolTipArrowPosition === "left"
      ? { marginLeft: `calc(${tooltipPosition}px)` }
      : {};

  return (
    <div
      className="Tooltip-container-parent"
      style={tooltipContainerStyle}
      ref={tooltipRef}
    >
      <div
        className="Tooltip-container"
        style={{
          border: `1px solid ${borderColor}`,
          backgroundColor: `${backgroundColor}`,
        }}
      >
        {toolTipContent}
      </div>
      <div
        className={`Tooltip-container-arrow ${containerArrowClass}`}
        style={{ backgroundColor: `${borderColor}` }}
      ></div>
      {backgroundColor.length > 0 && (
        <div
          className={`Tooltip-container-arrow-border ${arrowBorderClass}`}
        ></div>
      )}
      <div
        className={`Tooltip-container-arrow-border ${arrowBorderClass}`}
        style={{ backgroundColor: `${backgroundColor}` }}
      ></div>
    </div>
  );
};

export default Tooltip;
