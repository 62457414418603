import { useState, useEffect } from "react";
import { Graphs } from "../../../../../Components/graphs";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
  mode: string;
}

const WeightedAvgBarGraph = ({ receivedFinancedEmissionData, mode }: Props) => {
  const [labels, setLabels] = useState<string[]>([]);

  const [investmentData, setInvestmentData] = useState([]);
  const [revenueData, setRevenueData] = useState([]);

  const extractedNumber = (inputString: string): number | null => {
    const matches = inputString.match(/[\d.]+/);

    if (matches && matches.length > 0) {
      return parseFloat(matches[0]);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.weighted_average_carbon_intensity
    ) {
      setLabels(
        receivedFinancedEmissionData.weighted_average_carbon_intensity.map(
          (el: any) => el[mode]
        )
      );
      setInvestmentData(
        receivedFinancedEmissionData.weighted_average_carbon_intensity.map(
          (el: any) => extractedNumber(el.waci_investment)
        )
      );
      setRevenueData(
        receivedFinancedEmissionData.weighted_average_carbon_intensity.map(
          (el: any) => extractedNumber(el.waci_revenue)
        )
      );
    }
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Weighted Average Carbon Intensity</>}
      xAxisLabel=""
      yAxisLabel="Emission in MtCO2e"
      labels={labels}
      chartData={[
        {
          label: "$M Investment",
          values: investmentData,
        },
        {
          label: "$M Revenue",
          values: revenueData,
        },
      ]}
      datalabels={{
        datalabels: {
          display: true,
          anchor: "end",
          color: "white",
          borderRadius: 14,
          backgroundColor: (context: any) => {
            // Dynamically set the label color based on the bar's background color
            const backgroundColor = context.dataset.backgroundColor;
            return backgroundColor;
          },
          formatter: (value: any) => {
            return "    ";
          },
        },
      }}
      borderWidth={10}
      barThickness={25}
    />
  );
};

export default WeightedAvgBarGraph;
