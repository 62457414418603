import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../../Components/graphs";

type densityDataObj = {
  value: number;
  name: string;
  percent?: number | 0;
  rgbValues?: string;
};

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const EmissionByAssetClass = ({ receivedFinancedEmissionData }: Props) => {
  const [densityData, setDensityData] = useState<densityDataObj[]>([]);

  useEffect(() => {
    let finEmissionDataEntries = Object.entries(
      receivedFinancedEmissionData?.emission_by_asset_class ?? []
    ) as any[];

    let formattedDensityData =
      finEmissionDataEntries && finEmissionDataEntries.length > 0
        ? finEmissionDataEntries
            .map((el) => {
              return {
                name: el[0],
                value: el[1],
              };
            })
            .sort((a, b) => b.value - a.value)
        : [];

    let maxValue = Math.max(...formattedDensityData.map((el) => el.value));

    let coloredDensityArr = formattedDensityData.map((el) => {
      if (el.value > maxValue - maxValue / 3 && el.value <= maxValue) {
        return { ...el, rgbValues: "250, 91, 91" };
      } else if (
        el.value >= maxValue / 3 &&
        el.value < maxValue - maxValue / 3
      ) {
        return { ...el, rgbValues: "250, 206, 94" };
      } else {
        return { ...el, rgbValues: "157, 202, 92" };
      }
    });

    setDensityData(coloredDensityArr);
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.DensityBlockChart
      header="Emission by Asset Class"
      chartData={densityData}
      cssPosition="absolute"
    />
  );
};

export default EmissionByAssetClass;
