export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.29289 6.29289C1.68342 5.90237 2.31658 5.90237 2.70711 6.29289L9.14088 12.7267L15.5747 6.29289C15.9652 5.90237 16.5983 5.90237 16.9889 6.29289C17.3794 6.68342 17.3794 7.31658 16.9889 7.70711L9.84799 14.848C9.45746 15.2385 8.8243 15.2385 8.43377 14.848L1.29289 7.70711C0.902369 7.31658 0.902369 6.68342 1.29289 6.29289Z"
        fill="#666666"
      />
    </svg>
  );
};
