// import library components
import api from "../../../../Api/index";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

// iniitial state
import { initialStateApi } from "../../../utils";

//  payload type definition
type payloadType = any;

export const postGHGEmissionDataAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "ghgEmission/postGHGEmissionData",
    async (payload, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.postGHGEmissionData(),
          api.http.post(payload)
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.post(payload)
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const postGHGEmissionData = createSlice({
  name: "postGHGEmissionData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postGHGEmissionDataAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postGHGEmissionDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(postGHGEmissionDataAction.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default postGHGEmissionData.reducer;
