import React, { useState } from "react";
import "./EmissionByCountry.scss";
import { ReactComponent as CloudIcon } from "../../../../assets/icons/cloudCarbondiooxide.svg";
import { ReactComponent as DollarIcon } from "../../../../assets/icons/dollar.svg";
import { useEffect } from "react";
import countriesList from "../../../../utils/data/countries.json";

type financedEmissionDataObj = {
  [key: string]: any;
};
interface Props {
  countryList?: string[];
  receivedFinancedEmissionData?: financedEmissionDataObj;
}
type countryDataType = {
  country: string;
  country_name: string;
  financed_emission: number;
  country_img_url: string;
  investment: string;
};

const EmissionByCountry = ({
  countryList = ["India", "USA", "China", "Russia", "Brazil", "Pakistan"],
  receivedFinancedEmissionData,
}: Props) => {
  const [countryData, setCountryData] = useState<countryDataType[]>([]);

  useEffect(() => {
    if (
      receivedFinancedEmissionData &&
      receivedFinancedEmissionData.high_emitting_countries
    ) {
      setCountryData(
        receivedFinancedEmissionData.high_emitting_countries ?? []
      );
    }
  }, [receivedFinancedEmissionData]);

  return (
    <div className="ebc-country-list-container">
      <div className="ebc-country-list-container-title">
        High Emitting Countries (tCO2e)
      </div>
      <div className="ebc-country-flag-details-container-parent hide-scrollbar">
        {countryData.map((el) => (
          <div className="ebc-country-flag-details-container">
            <div className="ebc-country-flag-container">
              <img src={el.country_img_url} alt="country.png" />
            </div>
            <div className="ebc-country-details-container">
              <div className="ebc-country-emission-details-container-parent">
                <div className="ebc-country-emission-details-container">
                  <div className="ebc-country-emission-cloud-icon">
                    <CloudIcon />
                  </div>
                  <div className="ebc-country-emission-text">
                    Attributed Emission
                  </div>
                  <div className="ebc-country-emission-value">
                    <span>{el.financed_emission}</span> Mtco2e
                  </div>
                </div>
                <div className="ebc-country-investment-details-container">
                  <div className="ebc-country-investment-icon">
                    <DollarIcon />
                  </div>
                  <div className="ebc-country-investment-text">
                    Investment $M
                  </div>
                  <div className="ebc-country-investment-value">
                    {el.investment}
                  </div>
                </div>
              </div>
              <div className="ebc-country-details-name">{el.country_name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmissionByCountry;
