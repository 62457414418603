import { ReactNode } from "react";
import { changeOpacityOfRgb } from "../../../../utils/helpers/OpacityModifier";
import { getRandomColor } from "../../../../utils/helpers/RandomColorGenerator";
import "./ColoredRowList.scss";

interface IRowArr {
  text?: ReactNode;
  value?: string;
}

interface IColoredRowList {
  rowArr?: IRowArr[];
  colorArr?: string[];
}

const ColoredRowList = ({ rowArr = [], colorArr = [] }: IColoredRowList) => {
  return (
    <div className="colored-rows-container">
      {rowArr &&
        rowArr.length > 0 &&
        rowArr.map((el, index) => {
          let randomColor = getRandomColor();
          return (
            <div
              className="colored-row"
              style={{
                backgroundColor: changeOpacityOfRgb(randomColor, 0.12),
              }}
            >
              <div className="colored-row-text" style={{ color: randomColor }}>
                {el.text}
              </div>
              <div className="colored-row-value">{el.value}</div>
            </div>
          );
        })}
    </div>
  );
};

export default ColoredRowList;
