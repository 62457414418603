// import library components
import api from "../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";

// payload type definition
type payloadType = {
  id: any;
};

export const deleteActivityLogsAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "deleteActivityLogs",
    async ({ id }, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.deleteActivityLogs(id),
          api.http.delete()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.delete()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const deleteActivityLogsReducer = createSlice({
  name: "deleteActivityLogs",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteActivityLogsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(deleteActivityLogsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(deleteActivityLogsAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.error;
      });
  },
});

export default deleteActivityLogsReducer.reducer;
