import React from "react";
import "./header.scss";
import { ReactComponent as Globe } from "../../../assets/icons/Globe.svg";
import { ReactComponent as NotificationBell } from "../../../assets/icons/NotificationBell.svg";

const Header = () => {
  return (
    <div className="header-container">
      <div className="menu-bar-icon-container">
        <div className="menu-bar-globe-icon">
          <Globe />
        </div>
        <div className="menu-bar-notiification-icon">
          <NotificationBell />
        </div>
      </div>
    </div>
  );
};

export default Header;
