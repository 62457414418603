import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionByFuelConsumption = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState([]);
  const [labelsArr, setLabelsArr] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.emission_by_fuel_consumption
    ) {
      setValues(
        receivedGhgEmissionData.emission_by_fuel_consumption.map(
          (el: any) => el.total_emission_co2e
        )
      );
      setLabelsArr(
        receivedGhgEmissionData.emission_by_fuel_consumption.map(
          (el: any) => el.fuel_type
        )
      );
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.PieChart
      header="Emission by Fuel Consumption"
      tooltip="Emission by Fuel"
      doughNut="60%"
      labels={labelsArr}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      legendsPosition="bottom"
      annotations={{
        annotations: {
          label1: {
            type: "label",
            xValue: 2.5,
            yValue: 60,
            backgroundColor: "rgba(0,0,0,0)",
            content: ["Emission"],
            font: {
              size: 30,
            },
            yAdjust: 10,
          },
          label2: {
            type: "label",
            xValue: 10.5,
            yValue: 190,
            backgroundColor: "rgba(0,0,0,0)",
            content: ["MtCO2e"],
            font: {
              size: 15,
            },
            yAdjust: 40,
          },
        },
      }}
    />
  );
};

export default EmissionByFuelConsumption;
