import { createSelector } from "reselect";
import { RootState } from "../../../../../store";

const selectBenchmarkingDataStore = (state: RootState) =>
  state.benchmarkingDataReducer;

export const SelectBenchmarkingDataObj = createSelector(
  [selectBenchmarkingDataStore],
  (selectBenchmarkingDataStore) => selectBenchmarkingDataStore
);
