import React from "react";
import { Graphs } from "../../../../../Components/graphs";
import { ReactComponent as FilledCloudIcon } from "../../../../assets/icons/filledCloud.svg";

const EmissionByRegion = () => {
  let legends = [
    { text: "Low", color: "#9DCA5C" },
    { text: "Medium", color: "#FACE5E" },
    { text: "High", color: "#FA5B5B" },
  ];
  let greenCountries = [
    "Norway",
    "Germany",
    "United Kingdom",
    "Spain",
    "Netherlands",
    "Greenland",
    "Finland",
    "Sweden",
    "Canada",
    "Chile",
    "Peru",
    "Argentina",
    "Uruguay",
    "United States",
    "Mexico",
    "Brazil",
  ];
  let yellowCountries = [
    "Algeria",
    "Angola",
    "Benin",
    "Botswana",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Comoros",
    "Democratic Republic of the Congo",
    "Djibouti",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Eswatini",
    "Ethiopia",
    "Gabon",
    "Gambia",
    "Ghana",
    "Guinea",
    "Guinea-Bissau",
    "Ivory Coast",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Libya",
    "Madagascar",
    "Malawi",
    "Mali",
    "Mauritania",
    "Mauritius",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Niger",
    "Nigeria",
    "Rwanda",
    "Sao Tome and Principe",
    "Senegal",
    "Seychelles",
    "Sierra Leone",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Sudan",
    "Tanzania",
    "Togo",
    "Tunisia",
    "Uganda",
    "Zambia",
    "Zimbabwe",
    "Russia",
    "Ukraine",
  ];
  let redCountries = [
    "India",
    "Nepal",
    "Bhutan",
    "China",
    "Pakistan",
    "Afghanistan",
    "Myanmar",
    "Vietnam",
    "Laos",
    "Thailand",
    "Cambodia",
    "Bangladesh",
    "Sri Lanka",
    "Malaysia",
    "Indonesia",
    "Philippines",
    "Japan",
    "Singapore",
    "Taiwan",
    "Australia",
    "Brunei",
    "Papua New Guinea",
  ];

  return (
    <>
      <Graphs.MapGraph
        header="Emission by Region"
        projectionConfig={{
          center: [0, 40],
          scale: 128,
        }}
        className="emission-region-map-graph"
        redCountries={redCountries}
        yellowCountries={yellowCountries}
        greenCountries={greenCountries}
        defaultMapBackgroundColor="#EFEFEF"
        mapData={highEmittingCountries}
      />
      <div className="emission-region-footer-container">
        {legends.map((legend) => (
          <div className="emission-region-legend-container">
            <div
              className="emission-region-legend"
              style={{ backgroundColor: `${legend.color}` }}
            ></div>
            <div>{legend.text}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default EmissionByRegion;

const highEmittingCountries = [
  {
    country: "United States America",
    image: "https://picsum.photos/200",
    data: [411],
    location: {
      lat: 39.152089,
      long: -100.48729,
    },
    latlng: [39.152089, -100.48729],
    svgJsx: (
      <g>
        <foreignObject width="100" height="100" x="-20" y="-20">
          <div
            style={{
              paddingLeft: "2rem",
            }}
          >
            <div className={`emission-region-map-marker-container`}>
              <div className="emission-region-marker-circle marker-circle-green"></div>
              <div className="emission-region-marker-point marker-point-green"></div>
              <div className="emission-region-marker-text">Americas</div>
            </div>
          </div>
        </foreignObject>
      </g>
    ),
  },
  {
    country: "United States America",
    image: "https://picsum.photos/200",
    data: [411],
    location: {
      lat: 33.74964,
      long: 102.843895,
    },
    latlng: [33.74964, 102.843895],
    svgJsx: (
      <g>
        <foreignObject width="100" height="100" x="-20" y="-20">
          <div
            style={{
              paddingLeft: "2rem",
            }}
          >
            <div className={`emission-region-map-marker-container`}>
              <div className="emission-region-marker-circle marker-circle-red"></div>
              <div className="emission-region-marker-point marker-point-red"></div>
              <div className="emission-region-marker-text">APJC</div>
            </div>
          </div>
        </foreignObject>
      </g>
    ),
  },
  {
    country: "Russia",
    image: "https://picsum.photos/200",
    data: [411],
    location: {
      lat: 65.352617,
      long: 100.58685,
    },
    latlng: [65.352617, 100.58685],
    svgJsx: (
      <g>
        <foreignObject width="100" height="100" x="-20" y="-20">
          <div
            style={{
              paddingLeft: "2rem",
            }}
          >
            <div className={`emission-region-map-marker-container`}>
              <div className="emission-region-marker-circle marker-circle-yellow"></div>
              <div className="emission-region-marker-point marker-point-yellow"></div>
              <div className="emission-region-marker-text">EMEA</div>
            </div>
          </div>
        </foreignObject>
      </g>
    ),
  },
];
