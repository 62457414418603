import { createSelector } from "reselect";
import { RootState } from "../../../../store";

const selectBenchmarkingListDataStore = (state: RootState) =>
  state.benchmarkingListData;

export const selectBenchmarkingListDataResponse = createSelector(
  [selectBenchmarkingListDataStore],
  (selectBenchmarkingListDataStore) => selectBenchmarkingListDataStore.response
);
