import PieChart from "./PieChart/PieChart";
import BarGraph from "./BarGraph/BarGraph";
import LineGraph from "./LineGraph/LineGraph";
import MapGraph from "./MapGraph/MapGraph";
import MixedChart from "./MixedChart/MixedChart";
import ListChart from "./ListChart/ListChart";
import DensityBlockChart from "./DensityBlockChart/DensityBlockChart";
import TrapezoidGraph from "./TrapezoidGraph/TrapezoidGraph";
import MultiBarGraph from "./MultiBarGraph/MultiBarGraph";
import MatrixChart from "./MatrixChart/MatrixChart";

export const Graphs = {
  PieChart,
  BarGraph,
  LineGraph,
  MapGraph,
  MixedChart,
  ListChart,
  DensityBlockChart,
  TrapezoidGraph,
  MultiBarGraph,
  MatrixChart,
};
