import React from "react";

// improt styles file
import "./MainContentWrapper.scss";

type Props = {
  children: JSX.Element;
  customClassName?: string;
};

const MainContentWrapper = ({ customClassName, children }: Props) => {
  return (
    <div
      className={`maincontent-wrapper-component ${customClassName} hide-scrollbar`}
    >
      {children}
    </div>
  );
};

export default MainContentWrapper;
