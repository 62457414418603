import { Children, useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import RadioInput from "../../../../../../Components/Core/RadioInput/RadioInput";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";

type Props = {};

const UseofSoldProducts = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const activityArray = ["Direct use Phase", "Indirect use Phase"];
  const [selectedActivity, setSelectedActivity] = useState<number>(0);

  const activityArrayFirst = [
    "Product that Consume Fuel",
    "Fuel and Feedstock Products",
    "Product that Contains GHGs",
  ];
  const [selectedactivityArrayFirst, setSelectedactivityArrayFirst] =
    useState<number>(0);

  const activityArraySecond = [
    "Product that Consume Fuel",
    "Sold Intermediary Products",
  ];
  const [selectedactivityArraySecond, setSelectedactivityArraySecond] =
    useState<number>(0);
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    location: "",
    organisation_unit: "",
    supplier_name: "",
    activity_type: "direct_use_phase",
    activity_type2: "product_that_consume_fuel",
  });

  useEffect(() => {
    activityArray.forEach((el, index) => {
      if (el.toLocaleLowerCase() === state?.formData?.method) {
        setSelectedActivity(index);
      }
    });

    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        organisation_unit: "",
        supplier_name: "",
        activity_type: "direct_use_phase",
        activity_type2: "product_that_consume_fuel",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3D", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    if (state.scopePath === "transportation_&_distribution") {
      payload["emission_source"] = "transportation_distribution";
    }
    if (payload.activity_type === "upstream") {
      delete payload.method;
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(`/data-collection/ghg-emission/tables/Use Of Sold Products`, {
          state: {
            scope: "S3D",
            scopePath: "use_of_sold_products",
          },
        });
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <>
            <div className="radio-questions-container">
              <div className="header">Select Activity Type</div>

              <div className="radio-container">
                {Children.toArray(
                  activityArray.map((item: string, index: number) => (
                    <RadioInput
                      label={item}
                      onClickHandler={() => {
                        handleChanges(
                          item.toLowerCase().split(" ").join("_"),
                          "activity_type"
                        );
                        setSelectedActivity(index);
                      }}
                      checked={index === selectedActivity}
                    />
                  ))
                )}
              </div>
            </div>
            <hr className="horizontal-row" />
          </>

          {selectedActivity === 0 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Method</div>

                  <div className="radio-container">
                    {Children.toArray(
                      activityArrayFirst.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "activity_type2"
                            );
                            setSelectedactivityArrayFirst(index);
                          }}
                          checked={index === selectedactivityArrayFirst}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>
              {selectedactivityArrayFirst === 0 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={dropDownData?.fuel_type?.values?.map(
                        (item: any) => ({
                          name: item?.fuel_type,
                        })
                      )}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />
                    <TextInput
                      header="Electricity Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Refrigerant Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.refrigerant_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refrigerant_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Amount of Time Product Used"
                      width="46%"
                      theme="theme-gray"
                      value={data.amt_of_time_product_used}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amt_of_time_product_used")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Amount of Product Sold"
                      width="46%"
                      theme="theme-gray"
                      value={data.amount_of_product_sold}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amount_of_product_sold")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Electricity Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Fuel Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.fuel_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "fuel_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Refrigerant Leakage per Use"
                      width="46%"
                      theme="theme-gray"
                      value={data.refrigerant_leakage_per_use}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refrigerant_leakage_per_use")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {selectedactivityArrayFirst === 1 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "Ethane" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Amount of Fuel Sold"
                      width="46%"
                      theme="theme-gray"
                      value={data.amount_of_fuel_sold}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amount_of_fuel_sold")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {selectedactivityArrayFirst === 2 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="GWP of GHG"
                      width="46%"
                      theme="theme-gray"
                      value={data.gwp_of_ghg}
                      onChangeHandler={(value) =>
                        handleChanges(value, "gwp_of_ghg")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="% of GHG Released During Lifetime of Product"
                      width="46%"
                      theme="theme-gray"
                      value={data.pct_of_ghg_released_for_lifetime_of_product}
                      onChangeHandler={(value) =>
                        handleChanges(
                          value,
                          "pct_of_ghg_released_for_lifetime_of_product"
                        )
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="GHG Contained per Product"
                      width="46%"
                      theme="theme-gray"
                      value={data.ghg_contained_per_product}
                      onChangeHandler={(value) =>
                        handleChanges(value, "ghg_contained_per_product")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Amount Product Sold"
                      width="46%"
                      theme="theme-gray"
                      value={data.amount_of_product_sold}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amount_of_product_sold")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}

          {selectedActivity === 1 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Method</div>

                  <div className="radio-container">
                    {Children.toArray(
                      activityArraySecond.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "activity_type2"
                            );
                            setSelectedactivityArraySecond(index);
                          }}
                          checked={index === selectedactivityArraySecond}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {selectedactivityArraySecond === 0 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />
                    <TextInput
                      header="Electricity Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Refrigerant Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.refrigerant_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refrigerant_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Amount of Time Product Used"
                      width="46%"
                      theme="theme-gray"
                      value={data.amt_of_time_product_used}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amt_of_time_product_used")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Amount of Product Sold"
                      width="46%"
                      theme="theme-gray"
                      value={data.amount_of_product_sold}
                      onChangeHandler={(value) =>
                        handleChanges(value, "amount_of_product_sold")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Electricty Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Fuel Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.fuel_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "fuel_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Refrigerant Leakage per Use"
                      width="46%"
                      theme="theme-gray"
                      value={data.refrigerant_leakage_per_use}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refrigerant_leakage_per_use")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {selectedactivityArraySecond === 1 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Emission per use of Sold Product"
                      width="46%"
                      theme="theme-gray"
                      value={data.emissions_per_use_of_sold_product}
                      onChangeHandler={(value) =>
                        handleChanges(
                          value,
                          "emissions_per_use_of_sold_product"
                        )
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Total product sold"
                      width="46%"
                      theme="theme-gray"
                      value={data.total_products_sold}
                      onChangeHandler={(value) =>
                        handleChanges(value, "total_products_sold")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="Total Lifetime of Product"
                      width="46%"
                      theme="theme-gray"
                      value={data.total_lifetime_uses_of_product}
                      onChangeHandler={(value) =>
                        handleChanges(value, "total_lifetime_uses_of_product")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default UseofSoldProducts;
