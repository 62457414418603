// import library components
import api from '../../../../Api/index';
import { createSlice, createAsyncThunk, AsyncThunk, } from '@reduxjs/toolkit';

// iniitial state
import { initialStateApi } from '../../../utils';

//  payload type definition
type payloadType = {
    scope_type: string;
    emission_source: string;
    location?: string;
    facility_type?: string;
    organisation_type?: string;
    search?: string;
    offset?: number;
    limit?: number;
}

export const getGHGEmissionDataListAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'ghgEmission/getGMGEmissionDataList',
        async ({ scope_type, emission_source, limit, offset, search, location, facility_type, organisation_type }, { rejectWithValue }) => {
            // scope_type emission_source location facility_type organisation_type            
            try {
                const response = await fetch(
                    api.config.getGHGEmissionDataList(scope_type, emission_source, limit, offset, search, location, facility_type, organisation_type),
                    api.http.get()
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const getGHGEmissionDataList = createSlice({
    name: 'getGHGEmissionDataList',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getGHGEmissionDataListAction.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getGHGEmissionDataListAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getGHGEmissionDataListAction.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    }
})

export default getGHGEmissionDataList.reducer;