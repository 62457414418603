import { createSelector } from "reselect";
import { RootState } from "../../../../store";

const selectDropdownListStore = (state: RootState) =>
  state.getDropdownListAction;

export const selectDropdownListReponse = createSelector(
  [selectDropdownListStore],
  (state) => state.response
);
