// import library components
import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../../../utils";

type payloadType = {
  query_limit?: number;
  asset_class?: string;
  sub_asset_class?: string;
  sector?: string;
  sub_sector?: string;
  country?: string;
  dq_score?: string;
  next_token?: string;
};

export const getInvesteeHomepageDataAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "investor/dashboard/investeeHomepage",
    async (
      {
        query_limit,
        next_token,
        asset_class,
        sub_asset_class,
        sector,
        sub_sector,
        country,
        dq_score,
      },
      { rejectWithValue }
    ) => {
      try {
        const response = await fetch(
          api.config.getInvesteeListHomepage(
            query_limit,
            next_token,
            asset_class,
            sub_asset_class,
            sector,
            sub_sector,
            country,
            dq_score
          ),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const investeeHomepageData = createSlice({
  name: "investeeHomepageData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInvesteeHomepageDataAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getInvesteeHomepageDataAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getInvesteeHomepageDataAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default investeeHomepageData.reducer;
