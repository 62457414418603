import React, { useState } from "react";
import "./FilterSection.scss";
import MultiSelect from "../../../../Components/Core/MultiSelect/MultiSelect";
import countriesData from "../../../../utils/data/countries.json";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { ReactComponent as ArrowRight } from "../../../../assets/icons/arrowRightExtendedTail.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getFinancedEmissionPortfolioAnalysisData } from "../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getFinancedEmissionPortfolioAnalysisData.slice";
import { useSelector } from "react-redux";
import { selectDataByFilterResponse } from "../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getDataByFilter/getDataByFilterTypeValues.selector";
import { getDataByFilterTypeValues } from "../../../../Redux/Slices/dashboard/financedEmission/portfolioAnalysis/getDataByFilter/getDataByFilterTypeValues.slice";
import { yearData } from "../../../../utils/data/yearData";

interface IRadioBtnObj {
  text?: string;
  checked?: boolean;
}

type filterObjType = {
  radioBtnArr?: IRadioBtnObj[];
  setRadioBtnArr?: (prevArr: any) => void;
  selectedScope?: number;
};

const FilterSection = ({
  radioBtnArr,
  setRadioBtnArr,
  selectedScope = 0,
}: filterObjType) => {
  const dispatch = useDispatch<AppDispatch>();
  const filterBaseData = useSelector(selectDataByFilterResponse);

  const [chosenAssetClass, setChosenAssetClass] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "Listed Equity", checked: false },
    { text: "Corporate Bonds", checked: false },
    { text: "Business Loans", checked: false },
    { text: "Unlisted Equity", checked: false },
    { text: "Project Finance", checked: false },
    { text: "Commercial Real Estate", checked: false },
    { text: "Mortgages", checked: false },
    { text: "Motor Vehicle Loans", checked: false },
    { text: "Sovereign Bonds", checked: false },
  ]);
  const [chosenSector, setChosenSector] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "Financial Services", checked: false },
    { text: "Real Estate", checked: false },
    { text: "Healthcare", checked: false },
    { text: "Consumer Cyclical", checked: false },
    { text: "Industrials", checked: false },
    { text: "Basic Materials", checked: false },
    { text: "Technology", checked: false },
    { text: "Consumer Defensive", checked: false },
    { text: "Utilities", checked: false },
    { text: "Energy", checked: false },
    { text: "Communication Services", checked: false },
  ]);
  const [chosenDataScore, setChosenDataScore] = useState<IRadioBtnObj[]>([
    { text: "All", checked: false },
    { text: "1", checked: false },
    { text: "2", checked: false },
    { text: "3", checked: false },
    { text: "4", checked: false },
  ]);
  const [chosenLocation, setChosenLocation] = useState<IRadioBtnObj[]>(
    countriesData.map((country) => {
      return { text: country.name, checked: false };
    })
  );
  const [initialDate, setInitialDate] = useState<string>("2022");
  const [finalDate, setFinalDate] = useState<string>("2023");
  const [subAssetClassOptions, setSubAssetClassOptions] = useState<
    IRadioBtnObj[]
  >([]);
  const [subSectorOptions, setSubSectorOptions] = useState<IRadioBtnObj[]>([]);

  let toggleArr = [
    "attributed_emission",
    "emission_per_investment",
    "waci",
    "wadq",
  ];

  const radioBtnClickHandler = (receivedRadioBtnObj: IRadioBtnObj) => () => {
    setRadioBtnArr?.((prevArr: any) =>
      prevArr.map((el: any) =>
        el.text === receivedRadioBtnObj.text
          ? { ...el, checked: true }
          : { ...el, checked: false }
      )
    );
  };

  const getFilteredValues = (arr: IRadioBtnObj[]) =>
    arr
      .filter((el) => el.checked && el.text !== "All")
      .map((el) => el.text ?? "");

  const formatDateString = (date: string, time: string) => `${date}`;

  const dispatchAnalysisData = (
    filterType: string,
    filteredPrevState: IRadioBtnObj[],
    receivedFromDate: string,
    receivedToDate: string
  ) => {
    let countryShortNameArr = countriesData
      .filter((el) => getFilteredValues(chosenLocation).includes(el.name))
      ?.map((el) => el.shortName);

    let dynamicFilterVal;

    if (filterType === "location") {
      dynamicFilterVal = countriesData
        .filter((el) => getFilteredValues(filteredPrevState).includes(el.name))
        ?.map((el) => el.shortName);
    } else {
      dynamicFilterVal = getFilteredValues(filteredPrevState);
    }

    dispatch(
      getFinancedEmissionPortfolioAnalysisData({
        analysis_type: toggleArr[selectedScope],
        asset_class: getFilteredValues(chosenAssetClass),
        sector: getFilteredValues(chosenSector),
        dq_score: getFilteredValues(chosenDataScore).map((el) => +el),
        location: countryShortNameArr,
        from_date: formatDateString(receivedFromDate, "00:00:18.000Z"),
        to_date: formatDateString(receivedToDate, "23:58:18.000Z"),
        [filterType]: dynamicFilterVal,
      })
    );
  };

  const optionSelectHandler = (
    receivedOptionArr: any,
    prevState: any,
    setState: any,
    filterType: string
  ) => {
    let copiedPrevState = [...prevState];

    copiedPrevState.forEach((el: any) => {
      el.checked = false;
    });

    receivedOptionArr.forEach((option: any) => {
      copiedPrevState.forEach((el: any) => {
        if (el.text === option.text) {
          el.checked = option.checked;
        }
      });
    });

    dispatchAnalysisData(filterType, copiedPrevState, initialDate, finalDate);

    setState?.([...copiedPrevState]);
  };

  const dateSelectHandler = ({
    initialYear = "2022",
    finalYear = "2023",
  }: any) => {
    dispatchAnalysisData(
      "asset_class",
      chosenAssetClass,
      initialYear,
      finalYear
    );
  };

  const assetClassSelectHandler = (receivedOptionArr: any) => {
    let optionArrToSend = receivedOptionArr?.map((el: any) => el.text) ?? [];

    dispatch(
      getDataByFilterTypeValues({
        filter_type: "asset_class",
        filter_values: optionArrToSend,
      })
    ).then((res) => {
      let tempAssetClass =
        res.payload?.data?.sub_asset_class_list?.map((el: any) => {
          return { text: el, checked: false };
        }) ?? [];

      setSubAssetClassOptions((prevArr) => {
        return [{ text: "All", checked: false }, ...tempAssetClass];
      });
    });

    optionSelectHandler(
      receivedOptionArr,
      chosenAssetClass,
      setChosenAssetClass,
      "asset_class"
    );
  };

  const sectorSelectHandler = (receivedOptionArr: any) => {
    let optionArrToSend = receivedOptionArr?.map((el: any) => el.text) ?? [];

    dispatch(
      getDataByFilterTypeValues({
        filter_type: "sector",
        filter_values: optionArrToSend,
      })
    ).then((res) => {
      let tempSector =
        res.payload?.data?.sub_sector_list?.map((el: any) => {
          return { text: el, checked: false };
        }) ?? [];

      setSubSectorOptions((prevArr) => {
        return [{ text: "All", checked: false }, ...tempSector];
      });
    });

    optionSelectHandler(
      receivedOptionArr,
      chosenSector,
      setChosenSector,
      "sector"
    );
  };

  return (
    <div className="filter-section-container">
      <div className="x-axis-selection-container">
        <div className="x-axis-selection-container-title">Select X-Axis</div>
        <div className="radio-btns-section">
          {radioBtnArr &&
            radioBtnArr.length > 0 &&
            radioBtnArr.map((radioBtn) => (
              <div>
                <input
                  type="radio"
                  checked={radioBtn.checked}
                  name={radioBtn.text}
                  value={radioBtn.text}
                  onChange={radioBtnClickHandler(radioBtn)}
                />
                <label>{radioBtn.text}</label>
              </div>
            ))}
        </div>
      </div>
      <div className="partitioner-line"></div>
      <div className="filter-section-body">
        <div className="filter-section-body-title">
          <b>Select Filter</b>
        </div>
        <div className="filter-section-body-fitler-container hide-scrollbar">
          <div className="filter-section-body-fitler">
            <div className="filter-section-body-fitler-title">Asset Class</div>
            <MultiSelect
              options={chosenAssetClass}
              onSelectHandler={assetClassSelectHandler}
            />
          </div>
          {subAssetClassOptions && subAssetClassOptions.length > 0 && (
            <div className="filter-section-body-fitler">
              <div className="filter-section-body-fitler-title">
                Sub Asset Class
              </div>
              <MultiSelect
                options={subAssetClassOptions}
                onSelectHandler={(receivedOptionArr) => {
                  optionSelectHandler(
                    receivedOptionArr,
                    subAssetClassOptions,
                    setSubAssetClassOptions,
                    "sub_asset_class"
                  );
                }}
              />
            </div>
          )}
          <div className="filter-section-body-fitler">
            <div className="filter-section-body-fitler-title">Sector</div>
            <MultiSelect
              options={chosenSector}
              onSelectHandler={sectorSelectHandler}
            />
          </div>
          {subSectorOptions && subSectorOptions.length > 0 && (
            <div className="filter-section-body-fitler">
              <div className="filter-section-body-fitler-title">Sub Sector</div>
              <MultiSelect
                options={subSectorOptions}
                onSelectHandler={(receivedOptionArr) => {
                  optionSelectHandler(
                    receivedOptionArr,
                    subSectorOptions,
                    setSubSectorOptions,
                    "sub_sector"
                  );
                }}
              />
            </div>
          )}
          <div className="filter-section-body-fitler">
            <div className="filter-section-body-fitler-title">Data Score</div>
            <MultiSelect
              options={chosenDataScore}
              onSelectHandler={(receivedOptionArr) => {
                optionSelectHandler(
                  receivedOptionArr,
                  chosenDataScore,
                  setChosenDataScore,
                  "dq_score"
                );
              }}
            />
          </div>
          <div className="filter-section-body-fitler">
            <div className="filter-section-body-fitler-title">Location</div>
            <MultiSelect
              options={chosenLocation}
              disableSelectAllOption={true}
              onSelectHandler={(receivedOptionArr) => {
                optionSelectHandler(
                  receivedOptionArr,
                  chosenLocation,
                  setChosenLocation,
                  "location"
                );
              }}
            />
          </div>
          <div className="filter-section-body-time-filter">
            <div className="filter-section-body-time-fitler-title">
              Time Period
            </div>
            <div className="time-period-container">
              <DropDown
                optionsList={yearData}
                dropDownContainerClassName="dropdown-container"
                dropDownBodyClassName="dropdown-body"
                placeholderText="From"
                placeholderEnabled={true}
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    initialYear: e,
                    finalYear: finalDate,
                  });
                  setInitialDate(e);
                }}
              />
              <ArrowRight />
              <DropDown
                optionsList={yearData}
                dropDownContainerClassName="dropdown-container"
                dropDownBodyClassName="dropdown-body"
                placeholderText="To"
                placeholderEnabled={true}
                onSelectHandler={(e) => {
                  dateSelectHandler({
                    initialYear: initialDate,
                    finalYear: e,
                  });
                  setFinalDate(e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
