import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "../../../utils";

type payloadType = {
  id?: string;
  pcaf_asset_class?: string;
};

export const updatePcafAssetClassAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "updateAssetClassAction",
    async (payload, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.updatePcafAssetClass(),
          api.http.post(payload)
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.post(payload)
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const updatePcafAssetClassReducer = createSlice({
  name: "updatePcafAssetClassReducer",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(updatePcafAssetClassAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updatePcafAssetClassAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(updatePcafAssetClassAction.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.payload;
        state.isError = true;
      });
  },
});

export default updatePcafAssetClassReducer.reducer;
