// import library components
import { useEffect, useState, ChangeEvent, useId } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// import styles file
import "./datacollectiontable.scss";

// impot custom components
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrowLeft.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus-blue.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download-icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload-icon.svg";
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../Components/Core/Button/Button";
import TextInput from "../../../../Components/Core/TextInput/TextInput";
import { ReactComponent as FilterLine } from "../../../../assets/icons/filters-line-icon.svg";
import { ReactComponent as MagnifyGlass } from "../../../../assets/icons/magnifyingGlass-icon.svg";
import TableEnhanced from "../../../../Components/Core/TableEnhanced/TableEnhanced";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import Pagination from "../../../../Components/Core/Pagination/Pagination";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete-outline-icon.svg";

// import redux related components
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getGHGEmissionDataListAction } from "../../../../Redux/Slices/ghgEmission/ghgEmissionDataList/getGhgEmissionDataList.slice";
import { selectGhgEmissionResponse } from "../../../../Redux/Slices/ghgEmission/ghgEmissionDataList/getGhgEmissionDataList.selector";
import postFilesS3, {
  postFilesS3Action,
} from "../../../../Redux/Slices/Utils/uploadFilesS3/postFilesS3";
import { toastifyAsync } from "../../../../utils/notifications/toastifyAsync";
import { ReactComponent as Close } from "../../../../assets/icons/crossIcon.svg";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { patchGhgEmissionDataListAction } from "../../../../Redux/Slices/ghgEmission/updateGhgEmissionDataList/updateGhgEmissionDataList.slice";
import {  getTableArr } from "../../../../utils/data/tableData";

type Props = {};

const DataCollectionTable = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionResponse);
  const navigate = useNavigate();
  const selectFileId = useId();

  const { table } = useParams();
  const { state } = useLocation();

  // constants
  const [ghgEmissionDataList, setGhgEmissionDataList] = useState<any>(null);

  const handleBackClick = () => {
    navigate("/data-collection/ghg-emission");
  };

  const handleAddNewBtn = () => {
    navigate(`/data-collection/ghg-emission/forms/${table}`, {
      state: {
        ...state,
      },
    });
  };

  //$ to handle the file upload functionality
  const [selectedFile, setSelectedFile] = useState<any>();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setSelectedFile(file);
    }

    dispatch(
      postFilesS3Action({
        objectFolder: "GHGEmissionData",
        bucketName: "bucketinthislife",
        file: selectedFile,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  // search and filters related states
  const [openFilterModal, setFilterModal] = useState<boolean>(false);
  const [location, setLocation] = useState<string>();
  const [facility, setFacility] = useState<string>();
  const [orgUnit, setOrgUnit] = useState<string>();
  const [offset, setOffset] = useState<number>(0);
  const [headerData, setHeaderData] = useState<any>([])

  const [searchData, setSearchData] = useState<string>("");


  useEffect(() => {


    let extractedUrl = window.location.pathname

    let receivedHeaderData = getTableArr(extractedUrl,
          {
      label: "Options",
      key: "option",
      option: "popup",
      popupContent: (receivedFunction: any, index: any, rowData: any) => {
        return (
          <div className="popup-checkbox-container hide-scrollbar">
            <div
              className="asset-inventory-popup-edit"
              onClick={editClickHandler(rowData)}
            >
              <EditIcon />
              Edit
            </div>
            <div
              className="asset-inventory-popup-delete"
              onClick={() => {
                receivedFunction(index);
              }}
            >
              <DeleteIcon /> Delete
            </div>
          </div>
        );
      },
    },


    )

    setHeaderData(receivedHeaderData)

  }, [])

  // @ fetch and update the state
  useEffect(() => {
    const timer = setTimeout(() => {
      let payload = {
        emission_source: state?.scopePath,
      };
      if (state?.scopePath === "purchased_goods_&_services") {
        payload["emission_source"] = "purchased_goods_services";
      }
      if (state?.scopePath === "transportation_&_distribution") {
        payload["emission_source"] = "transportation_distribution";
      }

      dispatch(
        getGHGEmissionDataListAction({
          limit: 10,
          offset,
          scope_type: state?.scope,
          ...payload,
          facility_type: facility,
          location,
          organisation_type: orgUnit,
          search: searchData,
        })
      ).then((res) => {
        if (!res.payload?.data.length) {
        }
      });
    }, 600);
    return () => clearTimeout(timer);
  }, [location, facility, orgUnit, searchData, offset]);

  useEffect(() => {
    setGhgEmissionDataList(ghgEmissionData);
  }, [ghgEmissionData]);

  const editClickHandler = (receivedData: any) => () => {
    navigate(`/data-collection/ghg-emission/forms/${table}/edit`, {
      state: {
        formData: receivedData,
      },
    });
  };

  // const headerData: {
  //   label: string;
  //   key: string | null;
  //   option?: "checkbox" | "menu" | "popup";
  //   customStyles?: boolean;
  //   popupContent?: any;
  // }[] = [
  //   {
  //     label: " ",
  //     key: "",
  //     option: "checkbox",
  //   },
  //   {
  //     label: "Name",
  //     key: "name",
  //   },
  //   {
  //     label: "Location",
  //     key: "location",
  //   },
  //   {
  //     label: "Facility Type",
  //     key: "facility_article",
  //   },
  //   {
  //     label: "Organisation Unit",
  //     key: "organisation_unit",
  //   },
  //   {
  //     label: "Provider",
  //     key: "provider",
  //   },
  //   {
  //     label: "Quantity",
  //     key: "quantity",
  //   },
  //   {
  //     label: "Ashrae refrigerant code",
  //     key: "ashrae_refrigerant_code",
  //   },
  //   {
  //     label: "Installation date",
  //     key: "installation_date",
  //   },
  //   {
  //     label: "Options",
  //     key: "option",
  //     option: "popup",
  //     popupContent: (receivedFunction: any, index: any, rowData: any) => {
  //       return (
  //         <div className="popup-checkbox-container hide-scrollbar">
  //           <div
  //             className="asset-inventory-popup-edit"
  //             onClick={editClickHandler(rowData)}
  //           >
  //             <EditIcon />
  //             Edit
  //           </div>
  //           <div
  //             className="asset-inventory-popup-delete"
  //             onClick={() => {
  //               receivedFunction(index);
  //             }}
  //           >
  //             <DeleteIcon /> Delete
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <MainContentWrapper customClassName="data-collection-container">
      <div id="DataCollectionTableComponent" className="data-collection-tables">
        <div className="header-row-container">
          {openFilterModal && (
            <div className="filter-modal">
              <DropDown
                optionsList={[
                  "Select",
                  ...ghgEmissionDataList?.filters[0]?.values,
                ]}
                dropdownHeader="Location"
                dropDownBodyClassName="location-dropdown-body"
                dropDownContainerClassName="Location-Dropdown-Container"
                selected={location}
                onSelectHandler={(selected) => setLocation(selected)}
              />
              <DropDown
                optionsList={[
                  "Select",
                  ...ghgEmissionDataList?.filters[1]?.values,
                ]}
                dropdownHeader="Facility type"
                dropDownBodyClassName="location-dropdown-body"
                dropDownContainerClassName="Location-Dropdown-Container"
                selected={facility}
                onSelectHandler={(selected) => setFacility(selected)}
              />
              <DropDown
                dropDownContainerClassName="Location-Dropdown-Container"
                dropDownBodyClassName="location-dropdown-body"
                optionsList={[
                  "Select",
                  ...ghgEmissionDataList?.filters[2]?.values,
                ]}
                dropdownHeader="Organisational unit"
                selected={orgUnit}
                onSelectHandler={(selected) => setOrgUnit(selected)}
              />

              <Button
                leftIcon={<Close />}
                label="Close"
                className="light-border-outlined filter-btn dropdown-close-btn"
                onClick={() => setFilterModal(false)}
              />
            </div>
          )}

          <div className="header-row ">
            <div className="back-btn">
              <ArrowLeft onClick={handleBackClick} />
              <div className="back">Back</div>
            </div>

            <div className="action-btns">
              <Button
                label="Add new"
                leftIcon={<PlusIcon className="plus-icon" />}
                className="primary-button"
                onClick={handleAddNewBtn}
              />

              <Button
                label="Export"
                className="primary-no-outline"
                leftIcon={<UploadIcon />}
              />

              <Button
                label="Import"
                className="primary-no-outline"
                leftIcon={<DownloadIcon />}
                onClick={() => {
                  document.getElementById(selectFileId)?.click();
                }}
              />
            </div>
          </div>
          <div className="search-bar-header-h2 ">
            <div className="header">{table}</div>
            <div className="search-filter">
              <div className="search">
                <TextInput
                  placeholder="Search"
                  leftIcon={<MagnifyGlass />}
                  onChangeHandler={setSearchData}
                />
              </div>
              <div className="filter">
                <Button
                  leftIcon={<FilterLine />}
                  label="Filters"
                  className="light-border-outlined filter-btn"
                  onClick={() => setFilterModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-body noScrollBar">
          {ghgEmissionDataList?.data && (
            <TableEnhanced
              tableData={ghgEmissionDataList?.data}
              tableHeader={headerData}
              editedDataList={(receivedData) => {
                return dispatch(
                  patchGhgEmissionDataListAction({
                    emission_source: receivedData.type,
                    action: "edit",
                    scope_type: receivedData.scope,
                    sk: receivedData.sk,
                    data: {
                      ...receivedData,
                    },
                  })
                ).then((res) => {
                  if (res.type.includes("fulfilled")) {
                    return "success" as "success";
                  } else {
                    return "error" as "error";
                  }
                });
              }}
              deletedDataList={(receivedData) => {
                return dispatch(
                  patchGhgEmissionDataListAction({
                    emission_source: receivedData.type,
                    action: "delete",
                    scope_type: receivedData.scope,
                    sk: receivedData.sk,
                  })
                ).then((res) => {
                  if (res.type.includes("fulfilled")) {
                    return "success" as "success";
                  } else {
                    return "error" as "error";
                  }
                });
              }}
            />
          )}
        </div>
        <div className="footer-action-btns">
          <Pagination
            totalNumberOfData={ghgEmissionDataList?.totalItems}
            perPage={10}
            nextClickHandler={(number) => setOffset(Number(number))}
            previousClickHandler={(number) => setOffset(Number(number))}
            pageClickHandler={(number) => setOffset(Number(number))}
            classNames={{
              nextButton: "next-button",
              previousButton: "previous-button",
              pageButton: "page-button",
              pageActiveButton: "page-active",
              buttons: "buttons",
            }}
            components={{
              nextButton: <Chevron />,
              previousButton: <Chevron />,
            }}
          />
        </div>

        <input
          type="file"
          className="d-none"
          id={selectFileId}
          onChange={handleFileUpload}
        />
      </div>
    </MainContentWrapper>
  );
};

export default DataCollectionTable;
