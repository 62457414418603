import React, { ReactNode, useEffect, useState } from "react";
import { Graphs } from "../../../../../Components/graphs";
import { RangeBasedColorAssigner } from "../../../../../utils/helpers/RangeBasedColorAssigner";
import { abbreviatedNumber } from "../../../../../utils/helpers/NumberConverter";

interface IMatrixChartData {
  text?: ReactNode;
  color?: string;
  value: number;
}

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const EmissionBySector = ({ receivedFinancedEmissionData }: Props) => {
  const [matrixChartData, setMatrixChartData] = useState<IMatrixChartData[]>();
  const [minValue, setMinValue] = useState("0");
  const [maxValue, setMaxValue] = useState("0");

  useEffect(() => {
    let formattedMatrixChartData =
      receivedFinancedEmissionData?.emission_by_sector
        ? receivedFinancedEmissionData?.emission_by_sector.map((el: any) => {
            return {
              text: (
                <div className="matrix-chart-text-container">
                  <div className="matrix-chart-text-container-value">
                    {abbreviatedNumber(el.financed_emission)} Mtco2e
                  </div>
                  <div className="matrix-chart-text-container-category">
                    {el.sector}
                  </div>
                </div>
              ),
              value: Math.round(el.financed_emission),
            };
          })
        : [];

    let newArr = RangeBasedColorAssigner(formattedMatrixChartData, 10, [
      "#9DCA5C",
      "#FACE5E",
      "#FA5B5B",
    ]);

    setMaxValue(abbreviatedNumber(newArr[1]));
    setMinValue(abbreviatedNumber(newArr[2]));

    setMatrixChartData(newArr[0]);
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.MatrixChart
      matrixElements={matrixChartData}
      header="Emission by Sector"
      legends={[
        {
          text: (
            <>
              {"<"} {minValue} Mtco2e
            </>
          ),
          color: "#FA5B5B",
        },
        {
          text: (
            <>
              {minValue} - {maxValue} Mtco2e
            </>
          ),
          color: "#FACE5E",
        },
        {
          text: (
            <>
              {">"} {maxValue} Mtco2e
            </>
          ),
          color: "#9DCA5C",
        },
      ]}
    />
  );
};

export default EmissionBySector;
