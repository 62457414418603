import React, { ReactNode } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { colors } from "../../../DesignSystem/Colors/colors";
import annotationPlugin from "chartjs-plugin-annotation";
import { changeOpacityOfRgb } from "../../../utils/helpers/OpacityModifier";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  Filler
);

type Props = {
  className?: string;
  data?: {};
  tooltip?: string;
  labels?: Array<string>;
  chartData: number[][];
  header: ReactNode;
  legends: string[];
  xAxisLabel: string;
  yAxisLabel: string;
  theme?: string;
  legendsPosition?: "left" | "right" | "top" | "bottom";
  annotations?: {};
  tension?: number;
  fill?: boolean;
  legendsDisabled?: boolean;
  maintainAspectRatio?: boolean;
  borderDashArray?: number[][];
  themeColors?: string[];
  pointsRadius?: number;
};

const LineGraph = ({
  header,
  xAxisLabel,
  yAxisLabel,
  legends,
  labels,
  chartData,
  theme = "blue",
  legendsPosition = "right",
  annotations,
  tension = 0,
  fill = false,
  legendsDisabled = false,
  maintainAspectRatio = true,
  borderDashArray = [],
  themeColors = [],
  pointsRadius = 3,
}: Props) => {
  const memoisedChartData = React.useMemo(() => {
    let newarray = [];

    for (let index = 0; index < chartData.length; index++) {
      const element = chartData[index];
      newarray.push({
        label: legends[index],
        data: element,
        // backgroundColor:
        //   theme === "blue"
        //     ? colors.primaryColors[index]
        //     : colors.mixedColors[index],
        borderColor:
          theme === "blue"
            ? colors.primaryColors[index]
            : themeColors?.length > 0
            ? themeColors[index]
            : colors.mixedColors[index],
        tension: tension,
        borderWidth: 2,
        fill: fill,
        borderDash: borderDashArray[index],
        backgroundColor: (ctx: any) => {
          const canvas = ctx.chart.ctx;
          const gradient = canvas.createLinearGradient(-30, -160, 0, 300);

          gradient.addColorStop(
            0,
            `${
              theme === "blue"
                ? colors.primaryColors[index] ?? "red"
                : colors.mixedColors[index] ?? "red"
            }`
          );
          gradient.addColorStop(
            1,
            `${changeOpacityOfRgb(colors.primaryColors[index], 0)}`
          );

          return gradient;
        },
      });
    }
    return newarray;
  }, [chartData]);

  return (
    <>
      <div
        className="chart-header"
        style={{
          margin: "1.2rem",
          fontWeight: 500,
          fontSize: "1.6rem",
        }}
      >
        {header}
      </div>
      <Line
        data={{
          labels: labels,
          datasets: memoisedChartData,
        }}
        options={{
          scales: {
            x: {
              stacked: false,
              title: {
                display: true,
                color: "#7B91B0",
                text: xAxisLabel,
              },
              ticks: {
                color: "#7B91B0",
              },
              grid: {
                display: false,
              },
              border: {},
            },
            y: {
              border: {
                display: false,
              },
              stacked: false,
              title: {
                display: true,
                color: "#7B91B0",
                text: yAxisLabel,
              },
              ticks: {
                labelOffset: 0,
                padding: 14.5,
                color: "#7B91B0",
              },
            },
          },

          responsive: true,
          maintainAspectRatio,
          plugins: {
            annotation: { ...annotations },
            datalabels: {
              display: false,
            },
            legend: {
              display: !legendsDisabled,
              position: legendsPosition,
              labels: {
                boxHeight: 8,
                boxWidth: 8,
                usePointStyle: true,
              },
            },
            title: {
              display: false,
              text: "Chart.js Bar Chart",
            },
          },
          elements: {
            bar: {
              // inflateAmount: -1,
              // borderColor: '#fff',
              // borderWidth: 0,
              // borderSkipped: 'start',
            },
            point: {
              radius: pointsRadius,
            },
          },
        }}
      />
    </>
  );
};

export default LineGraph;
