import React, { useEffect, useState } from "react";
import "./EmissionPerFacilities.scss";
import { Graphs } from "../../../../Components/graphs";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import { AppDispatch } from "../../../../Redux/store";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
  setReceivedGhgEmissionData: any;
}

const EmissionPerFacilities = ({
  receivedGhgEmissionData,
  setReceivedGhgEmissionData,
}: Props) => {
  const [labels, setLabels] = useState<string[]>([
    "Date center",
    "Warehouse",
    "Storage Space",
    "Cafeteria",
    "Office Buldings",
  ]);

  const [energyData, setEnergyData] = useState([]);
  const [emissionData, setEmissionData] = useState([]);

  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionDataResponse);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.emission_per_facilities
    ) {
      setLabels(
        receivedGhgEmissionData.emission_per_facilities.map(
          (el: any) => el.facility_name
        )
      );
      setEnergyData(
        receivedGhgEmissionData.emission_per_facilities.map(
          (el: any) => el.energy_per_facility
        )
      );
      setEmissionData(
        receivedGhgEmissionData.emission_per_facilities.map(
          (el: any) => el.total_emission_per_facility
        )
      );
    }
  }, [receivedGhgEmissionData]);

  useEffect(() => {
    setReceivedGhgEmissionData(ghgEmissionData?.data);
  }, [ghgEmissionData]);

  const yearChangeHandler = (year: string) => {
    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 1",
        emission_year: year,
      })
    );
  };

  return (
    <Graphs.BarGraph
      header={
        <div className="card-header-container">
          <div>Emission per Facilities</div>
          <div className="date-selector">
            <DropDown
              optionsList={["2023", "2022", "2021", "2020"]}
              dropDownContainerClassName="dropdown-container"
              onSelectHandler={(e) => {
                yearChangeHandler(e);
              }}
            />
          </div>
        </div>
      }
      xAxisLabel=""
      yAxisLabel="Emissions (MtCO2e)"
      labels={labels}
      chartData={[
        {
          label: "Energy",
          values: energyData,
        },
        {
          label: "Emission",
          values: emissionData,
        },
      ]}
      xAxisLabelVisibility={false}
      maintainAspectRatio={true}
      datalabels={{
        datalabels: {
          display: true,
          anchor: "end",
          color: "white",
          borderRadius: 14,
          backgroundColor: (context: any) => {
            // Dynamically set the label color based on the bar's background color
            const backgroundColor = context.dataset.backgroundColor;
            return backgroundColor;
          },
          formatter: (value: any) => {
            return "    ";
          },
        },
      }}
      borderWidth={10}
      barThickness={25}
      legendsPosition="top"
      extraAxes={{
        y1: {
          stacked: false,
          position: "right",
          border: {},
          ticks: {
            color: "#7B91B0",
          },
          title: {
            display: true,
            color: "#7B91B0",
            text: "Energy (KWh)",
          },
          grid: {
            drawOnChartArea: false,
          },
        },
      }}
      xGridVisibility={false}
    />
  );
};

export default EmissionPerFacilities;
