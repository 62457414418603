import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../../Components/graphs";

type financedEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedFinancedEmissionData?: financedEmissionDataObj;
}

const AumCoverage = ({ receivedFinancedEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [outstandingAmount, setOutstandingAmount] = useState();

  useEffect(() => {
    setValues([
      receivedFinancedEmissionData?.aum_coverage?.mapped_percentage ?? 100,
      100 - receivedFinancedEmissionData?.aum_coverage?.mapped_percentage ??
        100,
    ]);
    setOutstandingAmount(
      receivedFinancedEmissionData?.aum_coverage?.total_outstanding_amount ?? ""
    );
  }, [receivedFinancedEmissionData]);

  return (
    <Graphs.PieChart
      legendsPosition="bottom"
      doughNut="80%"
      header="Asset Under Management Coverage"
      tooltip="Asset"
      labels={["Calulated", "Uncalculated"]}
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      graphColors={["#14C99E", "#EFEFEF"]}
      annotations={{
        annotations: {
          label1: {
            type: "label",
            xValue: 2.5,
            yValue: 60,
            backgroundColor: "rgba(0,0,0,0)",
            content: [outstandingAmount],
            font: {
              size: 30,
            },
          },
        },
      }}
    />
  );
};

export default AumCoverage;
