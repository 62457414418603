// import library components
import React, { useEffect, useState } from "react";

//  import styles file
import "./PartnerIntegration.scss";
//  import custom components
import MainContentWrapper from "../../../../Components/Core/MainContent/ContentWrapper";
import { ReactComponent as Chevron } from "../../../../assets/icons/chevron.svg";
import dataCollectionJson from "../../../../utils/data/dataCollectionPartner.json";

// import redux related components
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { selectAssetClassResponse } from "../../../../Redux/Slices/ghgEmission/partnerIntegrationList/partnerIntegrationList.selector";

// type definition
type Props = {};

const PartnerIntegration = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const partnerIntegrationDataSelector = useSelector(selectAssetClassResponse);
  const [partnerIntegrationData, setPartnerIntegrationData] = useState<any>();

  useEffect(() => {
    setPartnerIntegrationData(partnerIntegrationDataSelector?.data);
  }, [partnerIntegrationDataSelector]);

  const scopeHeaders = [
    "Direct  emissions from company-owned or controlled sources.",
    "Indirect  emissions from purchased electricity, heat, or steam.",
    "Emissions generated by the supply chain and transportation.",
    "Emissions generated from product use and disposal.",
  ];

  return (
    <MainContentWrapper>
      <div
        id="partnerIntegrationComponent"
        className="partner-integration-container"
      >
        {partnerIntegrationData?.map((data: any, index: number) => {
          return (
            <div key={data.scope_type} className="scope-container">
              <div className="header">
                <div className="bolded">{data.scope_type}</div>
                <div className="light">{scopeHeaders[index]}</div>
              </div>

              <div className="section-row">
                {data.scope_data?.map((subsection: any) => {
                  return (
                    <div key={subsection.url} className="section cursorPointer">
                      <div className="left">
                        <img src={subsection.url} alt="" />
                      </div>
                      <div className="icon">{subsection.logo_name}</div>
                    </div>
                  );
                })}
              </div>
              <br />
              <br />
            </div>
          );
        })}

        {/* {
                    dataCollectionJson.map(data => {
                        return (
                            <div key={data.section_name} className="scope-container">
                                <div className="header">
                                    <div className="bolded">
                                        {data.section_name}
                                    </div>
                                    <div className="light">
                                        {data.section_subtitle}
                                    </div>
                                </div>

                                <div className="section-row">
                                    {
                                        data.sub_sections.map(subsection => {
                                            return (
                                                <div key={subsection.sub_section_name} className="section cursorPointer" >
                                                    <div className="left">
                                                        <img src={subsection.img_url} alt="" />
                                                    </div>
                                                    <div className="icon">
                                                        {subsection.sub_section_name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                                <br />
                                <br />
                            </div>
                        )
                    })

                } */}
      </div>
    </MainContentWrapper>
  );
};

export default PartnerIntegration;
