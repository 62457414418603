// import library components
import React from "react";

// improt styles file
import "./ListChart.scss";

// type definitions

type countryData = {
  supplier_name: string;
  image?: string;
  total_emission_co2e: number;
};

type Props = {
  listArray: countryData[];
  header?: string;
  tableHeaders: string[];
  interactive?: boolean;
  onClickHandler?: (path: string) => void;
  customRowClassname?: string;
  dividerVisibility?: boolean;
};

const ListChart = ({
  listArray,
  header,
  tableHeaders,
  interactive = false,
  onClickHandler,
  customRowClassname = "",
  dividerVisibility = false,
}: Props) => {
  return (
    <div className="list-data">
      <div
        className="header"
        style={{ margin: "1.2rem", fontWeight: 500, fontSize: "1.6rem" }}
      >
        {header}
      </div>

      <div className="data-table">
        <div className="data-table-header">
          {tableHeaders?.map((element) => {
            return (
              <div
                className="cell"
                style={{
                  width: `${100 / tableHeaders.length}%`,
                }}
              >
                {element}
              </div>
            );
          })}
        </div>

        <div className="data-table-body hide-scrollbar">
          {listArray.map((element, index) => {
            return (
              <>
                <div
                  className={`table-row ${
                    interactive && "table-row-interact"
                  } ${customRowClassname}`}
                  key={index}
                  onClick={() => onClickHandler?.(element.supplier_name)}
                >
                  <div
                    className="cell main-cell"
                    style={{ width: `${100 / tableHeaders.length}%` }}
                  >
                    {element.image && <img src={element.image} alt="" />}
                    <span>{element.supplier_name}</span>
                  </div>
                  <div
                    className="cell"
                    style={{
                      width: `${100 / tableHeaders.length}%`,
                    }}
                  >
                    {element.total_emission_co2e}
                  </div>
                </div>
                {dividerVisibility && listArray.length - 1 !== index && (
                  <div className="list-chart-row-divider"></div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ListChart;
