// import library components
import api from "../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../utils";


// payload type definition
type payloadType = {
    investeeEmailList: string[];
    action_type: "INVESTEE_ONBOARDING" | "DATA_UPLOAD"
};

export const postInviteInvesteeAction: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'onboardStatus/inviteInvestee',
        async ({ investeeEmailList, action_type }, { rejectWithValue }) => {
            try {

                const response = await fetch(
                    api.config.postInvesteeInvite(),
                    api.http.post({ investee_email_ids: investeeEmailList, action_type })
                )

                const result = await api.afterFetchHandlers.parseContent(response, api.http.post())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const postInvitInvestee = createSlice({
    name: 'postInvitInvestee',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(postInviteInvesteeAction.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(postInviteInvesteeAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(postInviteInvesteeAction.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorResponse = action.error;
            })
    },
})

export default postInvitInvestee.reducer;