// import library components
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import styles file
import "./Homepage.scss";

// import custom components
import { scienceBasedTarget } from "../../../../assets/images/images";
import ToggleSwitch from "../../../../Components/Core/ToggleSwitch/ToggleSwitch";
import Button from "../../../../Components/Core/Button/Button";
import TargetCard from "../Card/TargetCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { getTargetHomepageAction } from "../../../../Redux/Slices/InvesteePlatform/getTargetHomepage/getTargetHomepage.slice";
import { selectTargetHomepageResponse } from "../../../../Redux/Slices/InvesteePlatform/getTargetHomepage/getTargetHomepage.selector";
import { selectInvestorTargetHomepageResponse } from "../../../../Redux/Slices/dashboard/targetSetting/investorTargetHomepage/getInvestorTargetHomepage.selector";
import { getInvestorTargetHomepageAction } from "../../../../Redux/Slices/dashboard/targetSetting/investorTargetHomepage/getInvestorTargetHomepage.slice";

type Props = {};

const Homepage = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const apiResponse = useSelector(selectTargetHomepageResponse);
  const investorapiResponse = useSelector(selectInvestorTargetHomepageResponse);

  const location = useLocation();
  const isInvestor = location.pathname?.includes("investor");

  const [targetHomepageResponse, setTargetHomepageResponse] = useState<{
    [key: string]: any;
  } | null>(null);

  const switchState = isInvestor
    ? ["ALL", "Scope 1", "Scope 2", "Scope 3", "Financed Emission"]
    : ["ALL", "Scope 1", "Scope 2", "Scope 3"];
  const [selectedToggle, setSelectedToggle] = useState<number>(0);
  const [forceReRender, setForceReRender] = useState<number>(0);

  const onSetClickHandler = () => {
    isInvestor
      ? navigate("/dashboard/investor/set-target-form")
      : navigate("/investee/set-target-form");
  };

  useEffect(() => {
    isInvestor
      ? dispatch(
          getInvestorTargetHomepageAction({
            targetScope: switchState[selectedToggle].toUpperCase(),
          })
        )
      : dispatch(
          getTargetHomepageAction({
            targetScope: switchState[selectedToggle].toUpperCase(),
          })
        );
  }, [selectedToggle, forceReRender]);

  useEffect(() => {
    isInvestor
      ? setTargetHomepageResponse(investorapiResponse?.data)
      : setTargetHomepageResponse(apiResponse?.data);
  }, [apiResponse, investorapiResponse]);

  return (
    <div id="InvesteeTargetComponent" className="investee-target-container">
      <div className="header-row-1">
        <div className="section-name">Your Targets</div>
        <div className="poweredby-details">
          <div className="label">Powered by:</div>
          <div className="logo">
            <img
              alt="powered by science based targets"
              src={scienceBasedTarget}
            />
          </div>
        </div>
      </div>
      <div className="header-row-2">
        <ToggleSwitch
          toggleSwitchClassnameExtra="switch"
          switchState={switchState}
          selectedValue={selectedToggle}
          onSwitchChange={setSelectedToggle}
        />

        <Button
          label="Set Target"
          className="primary-button"
          onClick={onSetClickHandler}
        />
      </div>

      <div className="content-body">
        <div className="scope-section">
          <div className="header">
            {switchState[selectedToggle] === "Scope 1" && <div>Scope 1</div>}
            {switchState[selectedToggle] === "Scope 2" && <div>Scope 2</div>}
            {switchState[selectedToggle] === "Scope 3" && <div> Scope 3</div>}
            {switchState[selectedToggle] === "Financed Emission" && (
              <div> Financed Emission</div>
            )}
          </div>
          <div className="grid-container ">
            {targetHomepageResponse?.map((item: any) => {
              if (item?.scope_type === "S1") {
                return (
                  <TargetCard
                    cardData={item}
                    key={item.sk}
                    setForceReRender={setForceReRender}
                  />
                );
              }
            })}
            {targetHomepageResponse?.map((item: any) => {
              if (item?.scope_type === "S2") {
                return (
                  <TargetCard
                    cardData={item}
                    key={item.sk}
                    setForceReRender={setForceReRender}
                  />
                );
              }
            })}
            {targetHomepageResponse?.map((item: any) => {
              if (item?.scope_type === "S3") {
                return (
                  <TargetCard
                    cardData={item}
                    key={item.sk}
                    setForceReRender={setForceReRender}
                  />
                );
              }
            })}
             {targetHomepageResponse?.map((item: any) => {
              if (item?.scope_type === "Financed Emission") {
                return (
                  <TargetCard
                    cardData={item}
                    key={item.sk}
                    setForceReRender={setForceReRender}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
