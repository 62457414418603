// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";


// create base selector of the reducer
const selectGhgEmissionDataList = (state: RootState) => state.getGhgEmissionDataList;

export const selectGhgEmissionResponse = createSelector(
    [selectGhgEmissionDataList],
    (ghgEmissionDataList) => ghgEmissionDataList.response
)