// import library components
import api from "../../../../../Api";
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";

// import initial state
import { initialStateApi } from "../../../../utils";

// payload type definition
type payloadType = {
  url: string;
  id: string;
};

export const deleteTargetAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "deleteTargetAction",
    async ({ url, id }, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.deleteTarget(url, id),
          api.http.delete()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.delete()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const deleteTargetReducer = createSlice({
  name: "deleteTargetReducer",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteTargetAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(deleteTargetAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(deleteTargetAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.error;
      });
  },
});

export default deleteTargetReducer.reducer;
