//  improt library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

//  create base selector for reducer
const selectAssetClassDetailsStore = (state: RootState) => state.assetClassDetails;

export const selectAssetClassResponse = createSelector(
    [selectAssetClassDetailsStore],
    (assetClassDetailsStore) => assetClassDetailsStore.response
)
export const selectAssetClassLoading = createSelector(
    [selectAssetClassDetailsStore],
    (assetClassDetailsStore) => assetClassDetailsStore.isLoading
)