//import library components
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

// import initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
    id?: string;
    asset_type?: string
}


export const getAssetFormDataAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'investee/getAssetFormData',
    async ({ id, asset_type }, { rejectWithValue }) => {
        try {
            const response = await fetch(
                api.config.getAssetFormData(asset_type, id),
                api.http.get()
            )

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
            return result;

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            rejectWithValue(error)
        }
    }
)

const investeeAssetFormData = createSlice({
    name: 'investeeAssetFormData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAssetFormDataAction.pending, (state, _) => {
                state.isLoading = true
            })
            .addCase(getAssetFormDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getAssetFormDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
            })
    },
})

export default investeeAssetFormData.reducer