import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payloadType = {
  location?: string;
};

export const getFinancedEmissionLocationDetails: AsyncThunk<
  any,
  payloadType,
  {}
> = createAsyncThunk(
  "getFinancedEmissionLocationDetails",
  async ({ location }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        api.config.getFinancedEmissionLocationDetails(location),
        api.http.get()
      );

      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );

      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      rejectWithValue(error);
    }
  }
);

export const financedEmissionLocationDetails = createSlice({
  name: "financedEmissionLocationDetails",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFinancedEmissionLocationDetails.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(
        getFinancedEmissionLocationDetails.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.response = action.payload;
        }
      )
      .addCase(getFinancedEmissionLocationDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default financedEmissionLocationDetails.reducer;
