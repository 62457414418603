import React, { useEffect, useState } from "react";
import ColoredRowList from "../../ColoredRowList/ColoredRowList";
import { colors } from "../../../../../DesignSystem/Colors/colors";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionByGas = ({ receivedGhgEmissionData }: Props) => {
  const [colorRowListData, setColorRowListData] = useState([]);

  useEffect(() => {
    if (receivedGhgEmissionData && receivedGhgEmissionData.emission_by_gas) {
      let coloredListEntries = Object.entries(
        receivedGhgEmissionData.emission_by_gas
      );

      let coloredRowListTemp = [] as any;

      coloredListEntries.forEach((el: any) => {
        coloredRowListTemp.push({
          text: el[0],
          value: `${el[1] ?? 0} Mtco2e`,
        });
      });
      setColorRowListData(coloredRowListTemp);
    }
  }, [receivedGhgEmissionData]);

  return (
    <>
      <div className="chart-header">Emission by Gas</div>
      <ColoredRowList
        rowArr={colorRowListData}
        colorArr={colors.primaryColors}
      />
    </>
  );
};

export default EmissionByGas;
