import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";
import api from "../../../../../Api";

type payloadType = {};

export const createBenchmarkAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk("createBenchmark", async (payload, { rejectWithValue }) => {
    try {
      const reponse = await fetch(
        api.config.postBenchmark(),
        api.http.post(payload)
      );

      const result = await api.afterFetchHandlers.parseContent(
        reponse,
        api.http.post(payload)
      );
      return result;
    } catch (error: any) {
      if (!error.reponse) {
        throw error;
      }
      rejectWithValue(error);
    }
  });

const createBenchmark = createSlice({
  name: "createBenchmark",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createBenchmarkAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createBenchmarkAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(createBenchmarkAction.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorResponse = action.payload;
      });
  },
});

export default createBenchmark.reducer;
