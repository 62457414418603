import { useState, useEffect } from "react";
import { Graphs } from "../../../../../Components/graphs";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionPerYear = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    if (
      receivedGhgEmissionData &&
      receivedGhgEmissionData.downstream_category_emission
    ) {
      setValues(
        receivedGhgEmissionData.emission_per_year.map(
          (el: any) => el.total_emission_co2e
        )
      );

      setLabels(
        receivedGhgEmissionData.emission_per_year.map((el: any) => el.year)
      );
    }
  }, [receivedGhgEmissionData]);

  return (
    <Graphs.BarGraph
      header={<>Emission Per Year</>}
      yAxisLabel="Energy (KWh)"
      labels={labels}
      legendsDisabled
      chartData={[
        {
          label: "tCO2e",
          values: values,
        },
      ]}
      datalabels={{
        datalabels: {
          display: true,
          anchor: "end",
          color: "white",
          borderRadius: 14,
          backgroundColor: "#14C99E",
          formatter: (value: any) => {
            return "    ";
          },
        },
      }}
      borderWidth={10}
      barThickness={25}
      xAxisLabelVisibility={false}
      maintainAspectRatio={false}
      barColor="rgba(20, 201, 158, 0.4)"
      xGridVisibility={false}
      yAxisVisibility={false}
      legendsPosition="bottom"
    />
  );
};

export default EmissionPerYear;
