// import library components
import { useState, useEffect } from "react";

// import custom components
import { ReactComponent as GraphVector } from "../../../../assets/icons/graphVector.svg";
import { Graphs } from "../../../../Components/graphs/index";
import FilterCard from "../FilterCard/FilterCard";
import DateRangeSelector from "../../../../Components/Core/DateRangeSelector/DateRangeSelector";
import "./Scope2-Component.scss";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import EmissionBreakDown from "../Scope1/EmissionBreakdown";
import EmissionPerFacilities from "../Scope1/EmissionPerFacilities";
import EmissionByOrgUnit from "../Scope1/EmissionByOrgUnit";
import EmissionByPeriod from "../Scope1/EmissionByPeriod";
import EmissionPerYear from "../Scope1/EmissionPerYear";
import EnergyConsumePerYear from "../Scope1/EnergyConsumePerYear";

type Props = {};

type emissionBreakdownObj = {
  [key: string]: any;
};

type ebsObj = {
  supplier_name: string;
  total_emission_co2e: number;
};

type eboObj = {
  organisation_unit: string;
  total_emission_co2e: number;
};

type receivedGhgEmissionDataType = {
  total_emission?: number;
  energy_consumed?: number;
  fuel_consumed?: number;
  last_year_total_emission?: number;
  last_year_energy_consumed?: number;
  renewables?: number;
  last_year_renewables?: number;
  emission_breakdown?: emissionBreakdownObj;
  total_emission_by_market?: number;
  last_year_total_emission_by_market?: number;
  total_emission_by_location?: number;
  last_year_total_emission_by_location?: number;
  emission_by_suppliers?: ebsObj[];
  emission_by_organisation_unit?: eboObj[];
  is_market_emission_decreased?: boolean;
  is_location_emission_decreased?: boolean;
  is_energy_consumed_decreased?: boolean;
  is_renewables_decreased?: boolean;
};

const Scope2 = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionDataResponse);

  const [categoryFiltersSelected, setCategoryFiltersSelected] = useState([
    { value: "Purchased Electricity", checked: false },
    { value: "Purchased Stream", checked: false },
    { value: "Purchased Heat", checked: false },
    { value: "Purchased Cooling", checked: false },
  ]);
  const [orgUnitFiltersSelected, setOrgUnitFiltersSelected] = useState([]);
  const [receivedGhgEmissionData, setReceivedGhgEmissionData] =
    useState<receivedGhgEmissionDataType>({ last_year_total_emission: 0 });
  const [orgUnitList, setOrgUnitList] = useState([]);

  useEffect(() => {
    let filteredCategoryList = categoryFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    let filteredOrgUnitList = orgUnitFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 2",
        category_list: filteredCategoryList,
        organisation_unit_list: filteredOrgUnitList,
        emission_year: "2023",
      })
    );
  }, [dispatch, categoryFiltersSelected, orgUnitFiltersSelected]);

  useEffect(() => {
    if (
      ghgEmissionData?.data &&
      ghgEmissionData?.data?.emission_by_organisation_unit
    ) {
      setOrgUnitList(
        ghgEmissionData?.data?.emission_by_organisation_unit.map((el: any) => {
          return { value: el.organisation_unit, checked: false };
        })
      );
    }

    setReceivedGhgEmissionData(ghgEmissionData?.data);
  }, [ghgEmissionData]);

  return (
    <>
      <div className="stats-filter-container d-grid-c6">
        <div className="quickstats-container col-3 d-grid-c6">
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">
              Scope 2 Emission (Market Based)
            </div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.total_emission_by_market ?? 0}{" "}
              <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_market_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_market_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_total_emission_by_market}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">
              Scope 2 Emission (Location Based)
            </div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.total_emission_by_location ?? 0}{" "}
              <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_location_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_location_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {
                    receivedGhgEmissionData?.last_year_total_emission_by_location
                  }
                  %
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Energy Consumed</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.energy_consumed || 0} <sub>KWh</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_energy_consumed_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_energy_consumed_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_energy_consumed}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Renewables</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.renewables || 0} <sub>KWh</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_renewables_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_renewables_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span> {receivedGhgEmissionData?.last_year_renewables}%</span>
                <GraphVector />
              </span>
            </div>
          </div>
        </div>

        <div className="filters col-3 d-grid-c6">
          <FilterCard
            header="Category"
            checkboxList={ categoryFiltersSelected}
            applyBtnHandler={(e) => {
              setCategoryFiltersSelected(e);
            }}
          />
          <FilterCard
            header="Organisation unit"
            checkboxList={orgUnitList}
            applyBtnHandler={(e) => {
              setOrgUnitFiltersSelected(e);
            }}
          />
        </div>
      </div>
      <div className="demographics-container d-grid-c6">
        <div className="demographic-tile col-2">
          <EmissionBreakDown
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div className="demographic-tile col-4">
          <EmissionPerFacilities
            receivedGhgEmissionData={receivedGhgEmissionData}
            setReceivedGhgEmissionData={setReceivedGhgEmissionData}
          />
        </div>
        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "500px", paddingBottom: "7rem" }}
        >
          <EmissionByOrgUnit
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>
        <div className="demographic-tile col-4">
          <EmissionByPeriod receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>

        <div className="demographic-tile col-2">
          <Graphs.ListChart
            listArray={
              receivedGhgEmissionData?.emission_by_suppliers ?? [
                { supplier_name: "", total_emission_co2e: 0 },
              ]
            }
            header="Emission by Suppliers"
            tableHeaders={["Suppliers", "Emission (MtCO2e)"]}
          />
        </div>

        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "438.6px", paddingBottom: "7rem" }}
        >
          <EmissionPerYear receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>
        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "438.6px", paddingBottom: "7rem" }}
        >
          <EnergyConsumePerYear
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>
      </div>
    </>
  );
};

export default Scope2;

const highEmittingCountries = [
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
  {
    supplier_name: "USA",
    total_emission_co2e: "100 MtCO2e",
  },
];
