// import library components
import React from "react";

// import styles file
import "./TrapezoidGraph.scss";
import { colors } from "../../../DesignSystem/Colors/colors";

// import custom components

// type definition
type valueStructure = {
  value: number;
  name: string;
};

type Props = {
  chartData?: valueStructure[];
  header: string;
  cssPosition?: "unset" | "absolute";
  yAxisLabel?: string;
};

const TrapezoidGraph = ({
  header,
  chartData,
  yAxisLabel = "",
  cssPosition = "unset",
}: Props) => {
  return (
    <div className="trapezoid-graph-component">
      <div className="chart-header" style={{ position: cssPosition }}>
        {header}
      </div>

      <div className="graph-container">
        <div className="y-axis-label">{yAxisLabel}</div>
        <div className="graph">
          <div className="trapezoid-container">
            {chartData?.map((item, index) => {
              return (
                <div key={item.name} className="trapezoid-section">
                  <div
                    className="trapezoid-bar"
                    style={{
                      backgroundColor: `${colors.primaryColors[index]}`,
                    }}
                  >
                    {item.value}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="legends">
            {chartData?.map((item, index) => {
              return (
                <div
                  key={item.name}
                  className="pointer-label"
                  title={item.name}
                >
                  <hr className="pointer-row" />
                  <div
                    className="pointer-dot"
                    style={{
                      backgroundColor: `${colors.primaryColors[index]}`,
                    }}
                  ></div>
                  <div className="label">{item.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrapezoidGraph;
