import React, { useEffect, useId, useState } from "react";
import "./checkbox.scss";
import { Flex } from "../Flex";
import { ReactComponent as UncheckIcon } from "../../../assets/icons/checkbox-uncheck.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/checkbox-checked.svg";

export interface ICheckBoxProops {
  name?: string;
  checked?: boolean;
  toggleChecked?: (value: boolean) => void;
  svgType?: boolean;
}

const CheckBox = ({
  name,
  checked,
  toggleChecked,
  svgType,
  ...props
}: ICheckBoxProops) => {
  const checkBoxId = useId();
  const [checkboxState, setCheckboxState] = useState<boolean>(checked || false);

  const handleCheckBox = () => {
    toggleChecked?.(!checked);
    setCheckboxState((prev) => !prev);
  };

  useEffect(() => {
    setCheckboxState(checked || false);
  }, [checked]);

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="flex-start"
      gap={10}
      {...props}
    >
      {!svgType ? (
        <input
          type="checkbox"
          name={name}
          value={name}
          checked={checkboxState}
          onChange={handleCheckBox}
          id={checkBoxId}
        />
      ) : (
        <label>
          {checkboxState ? (
            <CheckIcon onClick={handleCheckBox} />
          ) : (
            <UncheckIcon onClick={handleCheckBox} />
          )}
        </label>
      )}

      <label className="disableSelect" htmlFor={checkBoxId}>
        {name}
      </label>
    </Flex>
  );
};

export default CheckBox;
