import { Children, useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import RadioInput from "../../../../../../Components/Core/RadioInput/RadioInput";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";

type Props = {};

const TransportationDistribution = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const activityArray = ["Upstream", "Unladen Blakhaul", "Distribution"];
  const [selectedActivity, setSelectedActivity] = useState<number>(0);

  const metricsArray = ["Fuel Based", "Distance Based", "Spend Based"];
  const [metrics, setMetrics] = useState<number>(0);

  const DistributionMetricsArray = ["Site Specific", "Average Data Method"];
  const [distributionMetrics, setDistributionMetrics] = useState<number>(0);
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>({
    name: "",
    location: "",
    organisation_unit: "",
    supplier_name: "",
    fuel_type: "",
    fuel_consumed: "",
    emission_factor: "",
    mass_of_goods_purchased: "",
    distance_travelled: "",
    amount_spent: "",
    refridgerant_emission_factor: "",
    electricity_consumed: "",
    refridgerant_leakage: "",
    vol_of_goods_stored: "",
    avg_no_of_days: "",
    activity_type: "upstream",
  });

  useEffect(() => {
    activityArray.forEach((el, index) => {
      if (el.toLocaleLowerCase() === state?.formData?.method) {
        setSelectedActivity(index);
      }
    });

    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        organisation_unit: "",
        supplier_name: "",
        fuel_type: "",
        fuel_consumed: "",
        emission_factor: "",
        mass_of_goods_purchased: "",
        distance_travelled: "",
        amount_spent: "",
        refridgerant_emission_factor: "",
        electricity_consumed: "",
        refridgerant_leakage: "",
        vol_of_goods_stored: "",
        avg_no_of_days: "",
        activity_type: "upstream",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3U", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    if (state.scopePath === "transportation_&_distribution") {
      payload["emission_source"] = "transportation_distribution";
    }
    if (payload.activity_type === "upstream") {
      delete payload.method;
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(
          `/data-collection/ghg-emission/tables/Transportation & Distribution`,
          {
            state: {
              scope: "S3U",
              scopePath: "transportation_distribution",
            },
          }
        );
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organisation</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({
                  name: item,
                })
              )}
              width="46%"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <>
            <div className="radio-questions-container">
              <div className="header">Select Activity Type</div>

              <div className="radio-container">
                {Children.toArray(
                  activityArray.map((item: string, index: number) => (
                    <RadioInput
                      label={item}
                      onClickHandler={() => {
                        handleChanges(
                          item.toLowerCase().split(" ").join("_"),
                          "activity_type"
                        );
                        setSelectedActivity(index);
                      }}
                      checked={index === selectedActivity}
                    />
                  ))
                )}
              </div>
            </div>
            <hr className="horizontal-row" />
          </>

          {selectedActivity === 0 && (
            <>
              <div className="section-header">Emission Data</div>
              <br />
              <div className="questions-container">
                <Dropdown
                  options={dropDownData?.fuel_type?.values?.map(
                    (item: any) => ({
                      name: item?.fuel_type,
                    })
                  )}
                  width="46%"
                  header="Fuel Type"
                  selectedCallBack={(selectedOption) =>
                    handleChanges(selectedOption[0].name, "fuel_type")
                  }
                  searchIncluded
                  heightInput="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                  defaultOption={[{ name: state?.formData?.fuel_type ?? "" }]}
                />
              </div>
              <hr className="horizontal-row" />

              <div className="section-header">Activity Data</div>
              <br />
              <div className="questions-container">
                <Dropdown
                  options={[
                    { name: "fuel1" },
                    { name: "fuel2" },
                    { name: "fuel3" },
                  ]}
                  width="46%"
                  header="Quantity of Fuel Used"
                  selectedCallBack={(selectedOption) =>
                    handleChanges(selectedOption[0].name, "fuel_consumed")
                  }
                  searchIncluded
                  heightInput="5.2rem"
                  headerStyles={{ fontSize: "1.4rem" }}
                  defaultOption={[
                    { name: state?.formData?.fuel_consumed ?? "" },
                  ]}
                />
              </div>
              <hr className="horizontal-row" />
            </>
          )}

          {selectedActivity === 1 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Method</div>

                  <div className="radio-container">
                    {Children.toArray(
                      metricsArray.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "method"
                            );
                            setMetrics(index);
                          }}
                          checked={index === metrics}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {metrics === 0 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={dropDownData?.fuel_type?.values?.map(
                        (item: any) => ({
                          name: item?.fuel_type,
                        })
                      )}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Quantity of Fuel Consumed From Blackhaul"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_consumed")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_consumed ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {metrics === 1 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Emission factor of Vehicle type"
                      width="46%"
                      theme="theme-gray"
                      value={data.emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Mass of Goods Purchased"
                      width="46%"
                      theme="theme-gray"
                      value={data.mass_of_goods_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "mass_of_goods_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Distance Travelled"
                      width="46%"
                      theme="theme-gray"
                      value={data.distance_travelled}
                      onChangeHandler={(value) =>
                        handleChanges(value, "distance_travelled")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {metrics === 2 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Product Type"
                      width="46%"
                      theme="theme-gray"
                      value={data.emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Amount Spend"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "amount_spent")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.amount_spent ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}

          {selectedActivity === 2 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Method</div>

                  <div className="radio-container">
                    {Children.toArray(
                      DistributionMetricsArray.map(
                        (item: string, index: number) => (
                          <RadioInput
                            label={item}
                            onClickHandler={() => {
                              setDistributionMetrics(index);
                            }}
                            checked={index === distributionMetrics}
                          />
                        )
                      )
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {distributionMetrics === 0 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={dropDownData?.fuel_type?.values?.map(
                        (item: any) => ({
                          name: item?.fuel_type,
                        })
                      )}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />

                    <TextInput
                      header="Electricity Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />

                    <TextInput
                      header="Refrigeration Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.refridgerant_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refridgerant_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Fuel Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.fuel_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "fuel_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Electricity Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Refrigerant Leakage"
                      width="46%"
                      theme="theme-gray"
                      value={data.refridgerant_leakage}
                      onChangeHandler={(value) =>
                        handleChanges(value, "refridgerant_leakage")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {distributionMetrics === 1 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "fuel1" },
                        { name: "fuel2" },
                        { name: "fuel3" },
                      ]}
                      width="46%"
                      header="Emission factor (tco2e/pallet or m3)"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "emission_factor")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.emission_factor ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Volume of Goods stored (m3 or pallet)"
                      width="46%"
                      theme="theme-gray"
                      value={data.vol_of_goods_stored}
                      onChangeHandler={(value) =>
                        handleChanges(value, "vol_of_goods_stored")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      header="Average number of days"
                      width="46%"
                      theme="theme-gray"
                      value={data.avg_no_of_days}
                      onChangeHandler={(value) =>
                        handleChanges(value, "avg_no_of_days")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default TransportationDistribution;
