// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

// create base selector for reducer
const selectPcafMappingStore = (state: RootState) => state.pcafMappingData;

export const selectPcafMappingResponse = createSelector(
  [selectPcafMappingStore],
  (selectPcafMappingStore) => selectPcafMappingStore.response
);
