import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payLoadType = {
  investor_id?: string;
  analysis_type?: string;
  asset_class?: string[];
  sub_asset_class?: string[];
  sector?: string[];
  sub_sector?: string[];
  dq_score?: number[];
  location?: string[];
  from_date?: string;
  to_date?: string;
};

export const getFinancedEmissionPortfolioAnalysisData: AsyncThunk<
  any,
  payLoadType,
  {}
> = createAsyncThunk(
  "getFinancedEmissionPortfolioAnalysisData",
  async (
    {
      investor_id,
      analysis_type,
      asset_class,
      sub_asset_class,
      sector,
      sub_sector,
      dq_score,
      location,
      from_date,
      to_date,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        api.config.getFinancedEmissionPortfolioAnalysisData(
          investor_id,
          analysis_type,
          asset_class,
          sub_asset_class,
          sector,
          sub_sector,
          dq_score,
          location,
          from_date,
          to_date
        ),
        api.http.get()
      );
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );

      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      rejectWithValue(error);
    }
  }
);

export const financedEmissionPortfolioAnalysisData = createSlice({
  name: "financedEmissionPortfolioAnalysisData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFinancedEmissionPortfolioAnalysisData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(
        getFinancedEmissionPortfolioAnalysisData.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.response = action.payload;
        }
      )
      .addCase(
        getFinancedEmissionPortfolioAnalysisData.rejected,
        (state, action) => {
          state.isLoading = false;
          state.errorResponse = action.error;
        }
      );
  },
});

export default financedEmissionPortfolioAnalysisData.reducer;
