import "./button.scss";
import { ReactNode } from "react";

const buttonSizes = {
  small: { px: "12px", width: "48px", height: "30px" },
  medium: { px: "16px", width: "61px", height: "44px" },
  large: { px: "20px", width: "68px", height: "48px" },
  default: { px: "", width: "", height: "" },
};
type BUTTON_SIZES = "small" | "medium" | "large" | "default";

interface IButtonProps {
  size?: BUTTON_SIZES;
  color?: string;
  bg?: string;
  borderColor?: string;
  label: string;
  onClick?: (value: any) => any;
  className?:
    | "action-button"
    | "menu-button"
    | "primary-button"
    | "primary-no-outline"
    | "light-border-outlined"
    | "primary-border-outlined"
    | "search-button"
    | string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
}

const Button = ({
  size = "default",
  color,
  bg,
  borderColor,
  label,
  onClick,
  className = "",
  leftIcon,
  rightIcon,
  disabled,
}: IButtonProps) => {
  const buttonStyles = {
    background: bg,
    borderColor: borderColor,
    color: color,
    paddingX: buttonSizes[size]?.px,
    width: buttonSizes[size]?.width,
    height: buttonSizes[size]?.height,
    fontSize: buttonSizes[size]?.px,
  };
  return (
    <button
      className={`${className} action-btn-reusable`}
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
    >
      {leftIcon && leftIcon}
      {label}
      {rightIcon && rightIcon}
    </button>
  );
};

export default Button;
