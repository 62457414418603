// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../store";

// create base selector for reducer
const selectOverviewDataStore = (state: RootState) => state.overviewData;

export const selectOverviewDataResponse = createSelector(
  [selectOverviewDataStore],
  (selectOverviewDataStore) => selectOverviewDataStore.response
);
