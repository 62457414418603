import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
// import "./defaultStyles.scss";
// import { persistor, store } from "./Redux/Store/configureStore";
// import { PersistGate } from "redux-persist/integration/react";

// don't remove persistReducer import, it is needed for bundler to look into the package
import { persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

serviceWorkerRegistration.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// serviceWorkerRegistration.register();
