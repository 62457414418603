// import library components
import api from '../../../../Api';
import { createSlice, createAsyncThunk, AsyncThunk, } from '@reduxjs/toolkit';

// iniitial state
import { initialStateApi } from '../../../utils';

//  payload type definition
type payloadType = {
    investorName?: string
}

export const fetchAssetClassDetails: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'fetchAssetClassDetails',
        async ({ investorName }, { rejectWithValue }) => {
            try {
                const response = await fetch(
                    api.config.getAssetDetails(investorName),
                    api.http.get()
                )


                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const assetClassDetailsSlice = createSlice({
    name: 'assetClassDetails',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAssetClassDetails.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchAssetClassDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(fetchAssetClassDetails.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    }
})

export default assetClassDetailsSlice.reducer;