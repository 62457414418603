// import library components
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import styles file
import "./SidebarInvestee.scss";

// import custom components
import { logoColoured } from "../../../assets/images/images";
import { ReactComponent as Chevron } from "../../../assets/icons/chevron.svg";

// import utils
import {
  sidebarJsonDataInvestee,
  SidebarItem,
} from "../../../utils/data/sidebarData";
import { sidebarData } from "../../../utils/data/sidebar_investee_data";
import { ReactComponent as Check } from "../../../assets/icons/check-line.svg";

// import redux component
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { selectAssetFormDataResponse } from "../../../Redux/Slices/InvesteePlatform/getAssetFormData/getAssetFormData.selector";
import { getAssetFormDataAction } from "../../../Redux/Slices/InvesteePlatform/getAssetFormData/getAssetFormData.slice";
import { SelectDataCollectionProgressState } from "../../../Redux/Slices/InvesteePlatform/Utils/storeDataCollectionProgress/dataCollectionPrgress.selector";
import { updateDataCollectionProgressAction } from "../../../Redux/Slices/InvesteePlatform/Utils/storeDataCollectionProgress/dataCollectionProgress.slice";

type Props = {};

const SidebarInvestee = ({}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userCurrentState = useSelector(selectAssetFormDataResponse);
  const userPRogressFromForm = useSelector(SelectDataCollectionProgressState);

  const [selectedSideBar, setSelectedSideBar] = useState<SidebarItem>(
    sidebarJsonDataInvestee[1]
  );
  const [unSelectedSideBar, setUnSelectedSideBar] = useState<SidebarItem[]>();
  const [activeSideBarOption, setActiveSideBarOption] = useState<{
    grandParent: string;
    parent?: string;
    child?: string;
  }>({ grandParent: "Dashboard", parent: "Overview", child: "" });

  const handleOptionClick = (
    path: any,
    grandParent: string,
    parent?: string,
    child?: string
  ) => {
    setActiveSideBarOption({ grandParent, parent, child });

    if (!parent && !child) {
      const indexOfItem = sidebarJsonDataInvestee.findIndex(
        (item) => item.title === grandParent
      );
      setSelectedSideBar(sidebarJsonDataInvestee[indexOfItem]);
    }

    if (parent && !child) {
      const tempCopy = selectedSideBar;
      const indexOfItem = tempCopy.children.findIndex(
        (item) => item.title === parent
      );
      tempCopy.children[indexOfItem].childVisible =
        !tempCopy.children[indexOfItem].childVisible;
      setSelectedSideBar(tempCopy);
    }
    navigate(path);
  };

  useEffect(() => {
    const filterUnselectedData = sidebarJsonDataInvestee.filter(
      (item) => item.title !== selectedSideBar.title
    );
    setUnSelectedSideBar(filterUnselectedData);
  }, [selectedSideBar]);

  //^ handle the the progress of the user

  const [currentIndexDataSource, setCurrentIndexDataSource] =
    useState<number>(0);
  const [completedDataSource, setCompletedDataSource] = useState<string[]>([]);
  const [userProgressDataSource, setUserProgressDataSource] = useState<
    string[]
  >([]);

  const handleProgressBar = (index: number) => {
    // dispatch(getAssetFormDataAction({ asset_type: userProgressDataSource[index], id: '' }))
    //     .then(res => {
    //         if (res.type.includes('fulfilled')) {
    //             setCurrentIndexDataSource(index)
    //             setCompletedDataSource([...userProgressDataSource.slice(0, index)])
    //         }
    //     })
    dispatch(updateDataCollectionProgressAction({ index }));
  };

  useEffect(() => {
    const { index } = userPRogressFromForm;

    if (index) {
      setCurrentIndexDataSource(index);
      setCompletedDataSource([...userProgressDataSource.slice(0, index)]);
      dispatch(
        getAssetFormDataAction({
          asset_type: userProgressDataSource[index],
          id: "",
        })
      ).then((res) => {
        if (res.type.includes("fulfilled")) {
          setCurrentIndexDataSource(index);
          setCompletedDataSource([...userProgressDataSource.slice(0, index)]);
        }
      });
    }
  }, [userPRogressFromForm]);

  //^ useEffect for updating the source of the data
  useEffect(() => {
    setUserProgressDataSource(() => userCurrentState?.sideBarList);
  }, [userCurrentState]);

  return (
    <div id="SidebarComponentV2" className="SidebarComponentV2">
      <div className="se-logo-container">
        <img src={logoColoured} alt="se logo colored" />
      </div>
      <div className="sidebar-item-container">
        {[selectedSideBar].map((item: any) => {
          return (
            <div
              key={item.title}
              className="sidebar-content-level-one level-one-active"
            >
              <div
                className="level-one-tile"
                onClick={() => handleOptionClick(item.route, item.title)}
              >
                {item.icon}
                <div className="level-one-tile-text">{item.title}</div>
              </div>

              {item.title !== "Data Management" ? (
                item.children.map((children: any, index: number) => {
                  return (
                    <div
                      key={children.title + index}
                      className="sidebar-content-level-one-two "
                    >
                      <div
                        className={`level-two-tile ${
                          children.title === activeSideBarOption.parent &&
                          "tile-active"
                        }`}
                        onClick={() =>
                          handleOptionClick(
                            children.route,
                            item.title,
                            children.title
                          )
                        }
                      >
                        {children.icon}
                        <div className="level-two-tile-text">
                          {children.title}
                        </div>
                        {children.children.length > 0 && (
                          <Chevron className="chevron-icon" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="progress-tile-container">
                  {userProgressDataSource &&
                    userProgressDataSource?.map((item: any, index: number) => {
                      return (
                        <div
                          key={item + index}
                          onClick={() => handleProgressBar(index)}
                          className={`progress-tile ${
                            completedDataSource?.includes(item) && "completed"
                          }  ${currentIndexDataSource < index && "inactive"}  ${
                            currentIndexDataSource === index && "active"
                          }`}
                        >
                          <div className="indicator">
                            <Check />
                          </div>
                          <div className="tile-header">{item}</div>
                          <div className="active-indicator" />
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          );
        })}

        <div className="rest-container">
          {unSelectedSideBar?.map((item: any, index: number) => {
            return (
              <div
                key={item.title + index}
                className="sidebar-content-level-one "
              >
                <div
                  className="level-one-tile level-one-inactive"
                  onClick={() => handleOptionClick(item.route, item.title)}
                >
                  {item.icon}
                  <div className="level-one-tile-text">{item.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SidebarInvestee;
