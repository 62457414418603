// import library components
import { ChangeEvent, useEffect, useState, ReactNode } from "react";
import toast from "react-hot-toast";

// import Pagination from 'react-light-pagination';

// import styles file
import "./AssetInventory.scss";

// import custom components
import DialogueModal from "../../../../../Components/Core/DialogueModal/DialogueModal";
import MainContentWrapper from "../../../../../Components/Core/MainContent/ContentWrapper";
import Button from "../../../../../Components/Core/Button/Button";
import { ReactComponent as ReloadIcon } from "../../../../../assets/icons/reload-icon.svg";
import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/download.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/icons/server-icon.svg";
import { ReactComponent as CheckList } from "../../../../../assets/icons/checkedList-icon.svg";
import { ReactComponent as SubCheckList } from "../../../../../assets/icons/subchecklist-icon.svg";
import { ReactComponent as MagnifyGlass } from "../../../../../assets/icons/magnifyingGlass-icon.svg";
import { ReactComponent as UploadCloud } from "../../../../../assets/icons/upload-cloud-icon.svg";
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";
import { ReactComponent as Close } from "../../../../../assets/icons/crossIcon.svg";
import { ReactComponent as FilterLine } from "../../../../../assets/icons/filters-line-icon.svg";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/plus-blue.svg";
import TextInput from "../../../../../Components/Core/TextInput/TextInput";
import Pagination from "../../../../../Components/Core/Pagination/Pagination";
import DropDown from "../../../../../Components/Core/DropDown/DropDown";
import TableEnhanced from "../../../../../Components/Core/TableEnhanced/TableEnhanced";
import countryData from "../../../../../utils/data/countries.json";

// import redux related components
import { useSelector, useDispatch } from "react-redux";
import { selectAssetInventoryListResponse } from "../../../../../Redux/Slices/financedEmission/assetInventory/getAssetInventory.selector";
import { getAssetInventoryList } from "../../../../../Redux/Slices/financedEmission/assetInventory/getAssetInventory.slice";
import { AppDispatch } from "../../../../../Redux/store";
import { postFilesS3Action } from "../../../../../Redux/Slices/Utils/uploadFilesS3/postFilesS3";
import { patchAssetInventoryDataAction } from "../../../../../Redux/Slices/financedEmission/updateAssetInventory/patchAssetInventory.slice";
import { toastifyAsync } from "../../../../../utils/notifications/toastifyAsync";
import AddInvesteeModal from "./AddAssetInventory/AddInvesteeModal";
import CheckBox from "../../../../../Components/Core/CheckBox/CheckBox";
import { useForceUpdate } from "../../../../../Hooks/useForceUpdate";
import { useLocation, useNavigate } from "react-router-dom";

// type definition for props
type Props = {
  updateNavigationTab: (index: number) => void;
};

const AssetInventory = ({ updateNavigationTab }: Props) => {
  // constants
  const dispatch = useDispatch<AppDispatch>();
  const assetInventoryList = useSelector(selectAssetInventoryListResponse);
  const navigate = useNavigate();
  const {state}=useLocation();


  //^  to handle the state and functionality of the ackowledge modal
  const [showAcknowledgeModal, setShowAcknowledgeModal] = useState(false);
  const handleConfirmation = () => {
   // setShowAcknowledgeModal(false);
    updateNavigationTab(1);
  };

  //^ dispatch the action to fetch the inventory list from api and store it from redux to state
  const [assetInventoryData, setAssetInventoryData] = useState<any>();
  
  const [paginationKeys, setPaginationKeys] = useState<any>();
  const [assetStats, setAssetStats] = useState<any>();


  useEffect(() => {
    setAssetInventoryData(assetInventoryList);
    assetInventoryList?.static_data &&
      setPaginationKeys(assetInventoryList?.static_data?.next_token_list);
    assetInventoryList?.static_data &&
      setAssetStats(assetInventoryList?.static_data);
  }, [assetInventoryList]);

  //^ to handle the state and functionality of the upload investee modal
  const [showUploadInvesteeModal, setShowUploadInvesteeModal] = useState(false);

  //^ to handle the pagination navigations
  const [nextPageToken, setNextPageToken] = useState("");

  const onNextClick = (offset: number | string) => {
    setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token);
  };

  const onPreviousClick = (offset: number | string) => {
    setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token);
  };

  const onPageClick = (offset: number | string) => {
    setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token);
  };

  //^ filter and modal related states

  const defaultFilterState = {
    asset_class: "",
    sub_asset_class: "",
    sector: "",
    sub_sector: "",
    // region: "",
    country: "",
    data_score: "",
  };

  const  filterDropdownData = {
  
    data_score: ["Select", "One", "two", "three","four","five"],
  };

  const [appliedFilters, setAppliedFilters] = useState(defaultFilterState);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleFilterchange = (
    source: string | number,
    value: string | number
  ) => {
    const filterObject = {
      [source]: value,
    };
    setAppliedFilters((prev) => ({ ...prev, ...filterObject }));
  };

  //^ to handle the search functionality for investee list
  const [searchFeildInput, setSearchFeildInput] = useState("");

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(
        getAssetInventoryList({
          limit: 10,
          search_key: searchFeildInput,
          asset_class: appliedFilters.asset_class,
          sub_asset_class: appliedFilters.sub_asset_class,
          sector: appliedFilters.sector,
          sub_sector: appliedFilters.sub_sector,
          country: appliedFilters.country,
          data_score: appliedFilters.data_score,
          next_token: nextPageToken,
        })
      );
    }, 600);

    return () => clearTimeout(getData);
  }, [searchFeildInput, appliedFilters, nextPageToken]);

  //$ to handle the file upload functionality
  const [assetFile, setAssetFile] = useState<any>();

  const handleAssetFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setAssetFile(file);
    }
  };

  const [investeeFile, setInvesteeFile] = useState<any>();

  const handleInvesteeFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      const file = event.target.files[0];
      setInvesteeFile(file);
    }
  };

  const onSubmitFileUpload = () => {
    dispatch(
      postFilesS3Action({
        bucketName: "bucketinthislife",
        objectFolder: "accountingTest",
        file: assetFile,
      })
    );
  };

  // $ to handle the table edited and deleted data

  const handleEditedData = async (data: any): Promise<"success" | "error"> => {
    let tempPayload = { ...data };

    delete tempPayload["sl_no"];
    delete tempPayload["investee_uid"];
    delete tempPayload["investor_name"];
    delete tempPayload["editEnabled"];

    return dispatch(
      patchAssetInventoryDataAction({
        action_type: "EDIT",
        ...tempPayload,
      })
    )
      .then((res) => {
        toastifyAsync(res);
        if (res.type.includes("fulfilled")) {
          return "success" as "success";
        } else {
          return "error" as "error";
        }
      })
      .catch((error) => {
        return "error" as "error";
      });
  };

  const handleDeletedData = async (data: any): Promise<"success" | "error"> => {
    let tempPayload = { ...data };

    delete tempPayload["sl_no"];
    delete tempPayload["investee_uid"];
    delete tempPayload["investor_name"];
    delete tempPayload["outstanding_amount"];

    return dispatch(
      patchAssetInventoryDataAction({
        action_type: "DELETE",
        ...tempPayload,
      })
    )
      .then((res) => {
        toastifyAsync(res);
        if (res.type.includes("fulfilled")) {
          return "success" as "success";
        } else {
          return "error" as "error";
        }
      })
      .catch((error) => {
        return "error" as "error";
      });
  };

  //^ handling the modal for the adding the investee
  const [showAddInvesteeModal, setShowAddInvesteeModal] = useState(false);

  const headerData: {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu" | "popup";
    width?: string;
    customStyles?: boolean;
    popupContent?: any;
  }[] = [
    {
      label: "Sl no",
      key: "sl_no",
      width: "7rem",
    },
    {
      label: "ISIN No",
      key: "isin_no",
    },
    {
      label: "Investee name",
      key: "investee_name",
    },
    {
      label: "Country",
      key: "country",
    },
    {
      label: "Asset class",
      key: "asset_class",
    },
    {
      label: "Sub asset class",
      key: "investor_asset_class",
    },
    {
      label: "Asset Allocation",
      key: "investor_asset_class",
    },
    {
      label: "Sector",
      key: "sector",
    },
    {
      label: "Sub sector",
      key: "sub_sector",
    },
    // {
    //   label: "Options",
    //   key: "option",
    //   option: "popup",
    //   popupContent: (receivedFunction: any, index: any) => {
    //     return (
    //       <div className="popup-checkbox-container hide-scrollbar">
    //         <div
    //           className="asset-inventory-popup-edit"
    //           onClick={editClickHandler}
    //         >
    //           <EditIcon />
    //           Edit
    //         </div>
    //         <div
    //           className="asset-inventory-popup-delete"
    //           onClick={() => {
    //             receivedFunction(index);
    //           }}
    //         >
    //           <DeleteIcon /> Delete
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      label: "Options",
      key: "",
      option: "menu",
    },
  ];

  return (
    <div id="investeeListComponent" className="investee-component-container">
      <MainContentWrapper customClassName="wrapper-in-data-validation">
        <div className="data-validation-body">
          <div className="header">
            {showFilterModal && (
              <div className="filter-modal">
                <DropDown
                  optionsList={assetInventoryList?.static_data?.assest_class_list}
                  dropdownHeader="Asset class"
                  onSelectHandler={(selected) =>
                    handleFilterchange("asset_class", selected)
                  }
                />
                <DropDown
                  optionsList={assetInventoryList?.static_data?.sub_assest_class_list}
                  dropdownHeader="Sub asset class"
                  onSelectHandler={(selected) =>
                    handleFilterchange("sub_asset_class", selected)
                  }
                />
                <DropDown
                  optionsList={assetInventoryList?.static_data?.sector_list }
                  dropdownHeader="Sector"
                  onSelectHandler={(selected) =>
                    handleFilterchange("sector", selected)
                  }
                />
                <DropDown
                  optionsList={assetInventoryList?.static_data?.sub_assest_class_list}
                  dropdownHeader="Sub sector"
                  onSelectHandler={(selected) =>
                    handleFilterchange("sub_sector", selected)
                  }
                />
                <DropDown
                  optionsList={countryData.map((el)=>el?.name).sort() ?? []}
                  dropdownHeader="Country"
                  dropDownBodyClassName="dropDown-assetInventoryClass hide-scrollbar"
                  onSelectHandler={(selected) =>
                    {
                      
                      let tempShortName = countryData.filter((country) => country.name === selected)[0].shortName

                      handleFilterchange("country", tempShortName)}
                  }
                  searchEnabled={true}
                />
                <DropDown
                  optionsList={filterDropdownData.data_score}
                  dropdownHeader="Data score"
                  onSelectHandler={(selected) =>
                    handleFilterchange("data_score", selected)
                  }
                />

                <Button
                  leftIcon={<Close />}
                  label="Close"
                  className="light-border-outlined filter-btn dropdown-close-btn"
                  onClick={() => setShowFilterModal(false)}
                />
              </div>
            )}

            <div className="header-section">
              <div className="section-left">
                <div className="section-headline">Asset details summary</div>
                <div className="section-headline-2">
                  Investee list
                  <span>{assetStats?.total_data_count}</span>
                </div>
              </div>

              <div className="section-right">
                <div className="duration-section">
                  Reporting period: <span>{assetStats?.reporting_date}</span>
                </div>

                <Button
                  leftIcon={<ReloadIcon />}
                  className="primary-no-outline"
                  label="Update"
                  onClick={() => setShowUploadInvesteeModal(true)}
                />

                <Button
                  leftIcon={<DownloadIcon />}
                  className="primary-no-outline"
                  label="Download"
                />

                <Button
                  onClick={() => setShowAddInvesteeModal(true)}
                  leftIcon={<CrossIcon className="plus-icon" />}
                  className="primary-button"
                  label="Add Investee"
                />
              </div>
            </div>
            <div className="header-section-sub">
              <div className="stats">
                <div className="stat-tile stats-yellow">
                  <div className="header">Total Asset Allocation</div>
                  <div className="content">
                    <div className="icon-container">
                      <FileIcon />
                    </div>
                    <span>{assetStats?.assest_allocation}</span>
                  </div>
                </div>

                <div className="stat-tile stats-green">
                  <div className="header">Asset Classes Covered</div>
                  <div className="content">
                    <div className="icon-container">
                      <CheckList />
                    </div>
                    <span>{assetStats?.assest_class_count} of {state}</span>
                  </div>
                </div>

                <div className="stat-tile stats-blue">
                  <div className="header">Sub-Asset Classes Covered</div>
                  <div className="content">
                    <div className="icon-container">
                      <SubCheckList />
                    </div>
                    <span>{assetStats?.sub_assest_allocation}</span>
                  </div>
                </div>
              </div>

              <div className="search-inputs">
                <TextInput
                  placeholder="Search"
                  leftIcon={<MagnifyGlass />}
                  value={searchFeildInput}
                  onChangeHandler={setSearchFeildInput}
                />

                <Button
                  leftIcon={<FilterLine />}
                  label="Filters"
                  className="light-border-outlined filter-btn"
                  onClick={() => setShowFilterModal(true)}
                />
              </div>
            </div>
          </div>

          <div className="table-container noScrollBar">
            {assetInventoryData?.data && (
              <TableEnhanced
                tableData={assetInventoryData?.data}
                tableHeader={headerData}
                editedDataList={handleEditedData}
                deletedDataList={handleDeletedData}
              />
            )}
          </div>
        </div>
      </MainContentWrapper>

      <div className="footer-action-btns">
        <Pagination
          totalNumberOfData={paginationKeys?.length}
          perPage={1}
          nextClickHandler={onNextClick}
          previousClickHandler={onPreviousClick}
          pageClickHandler={onPageClick}
          classNames={{
            container: "container",
            nextButton: "next-button",
            previousButton: "previous-button",
            pageButton: "page-button",
            pageActiveButton: "page-active",
            buttons: "buttons",
          }}
          components={{
            nextButton: <Chevron />,
            previousButton: <Chevron />,
          }}
        />

        <Button
          label="Confirm"
          className="primary-button"
          onClick={handleConfirmation}
        />
      </div>
{/* 
      {showAcknowledgeModal && (
        <DialogueModal
          header="Acknowledge before you move"
          callback={setShowAcknowledgeModal}
          onSubmit={handleConfirmation}
        ></DialogueModal>
      )} */}

      {showUploadInvesteeModal && (
        <DialogueModal
          header="Kindly provide Asset Class & Investee Details"
          callback={setShowUploadInvesteeModal}
          onSubmit={onSubmitFileUpload}
        >
          <div className="upload-file-investee-modal">
            <div className="file-upload-tile">
              {/* <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div> */}

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Asset class details</div>
                </div>
                <hr />
                <div className="sub-header">
                  Asset Classes / Sub Asset Classes /Asset Allocation Amount /
                  Funds
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal2"
                    onChange={handleAssetFileUpload}
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal2"> Browse</label>
                  <br />
                  <br />
                  {assetFile?.name && (
                    <span>Selected File: {assetFile?.name}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="file-upload-tile">
              {/* <div className="sample-content">
                <InfoIcon />
                <div>Download sample template</div>
              </div> */}

              <div className="drag-drop-zone">
                <div className="header">
                  <UploadCloud />
                  <div>Investee / Portfolio</div>
                </div>
                <hr />
                <div className="sub-header">
                  List of Investee / Portfolio / Location of Investment /
                  Sectors and Sub - Sector of Investee / Asset Allocation Amount
                </div>
                <div className="links">
                  <input
                    type="file"
                    className="d-none"
                    id="upload-file-modal1"
                    onChange={handleInvesteeFileUpload}
                  />
                  Drop file here or{" "}
                  <label htmlFor="upload-file-modal1"> Browse</label>
                  <br />
                  <br />
                  {investeeFile?.name && (
                    <span>Selected File: {investeeFile?.name}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="tile-divider">
              <hr />
              <span>or</span>
              <hr />
            </div>

            <div>
              <TextInput header="Import from URL" placeholder="Enter URL" />
            </div>
          </div>
        </DialogueModal>
      )}

      {showAddInvesteeModal && (
        <AddInvesteeModal toggleModal={setShowAddInvesteeModal} />
      )}
    </div>
  );
};

export default AssetInventory;
