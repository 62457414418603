import { Children, useState, useEffect } from "react";
import Button from "../../../../../../Components/Core/Button/Button";
import TextInput from "../../../../../../Components/Core/TextInput/TextInput";
import Dropdown from "../../../../../../Components/Core/DropdownV2/Dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { toastifyAsync } from "../../../../../../utils/notifications/toastifyAsync";
import { postGHGEmissionDataAction } from "../../../../../../Redux/Slices/ghgEmission/dataCollection/postGhgDataCollection.slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../Redux/store";
import countriesData from "../../../../../../utils/data/countries.json";
import RadioInput from "../../../../../../Components/Core/RadioInput/RadioInput";
import { selectDropdownContentResponse } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.selector";
import { getDropdowncontentsAction } from "../../../../../../Redux/Slices/ghgEmission/dropdownContents/getDropdownContents.slice";
import { patchDropdownListAction } from "../../../../../../Redux/Slices/financedEmission/updateDropdownList/updateDropdownList.slice";

type Props = {};

const FuelEnergy = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();

  const activityArray = [
    "Upstream Emissions of Purchased Fuel",
    "Upstream emission of Purchased Electricity",
    "Transmission & Distribution Losses",
    "Purchased and Sold Power",
  ];
  const [selectedActivity, setSelectedActivity] = useState<number>(0);

  const firstMethod = ["Supplier Specific", "Average Data Method"];

  const [selectedFirstMethod, setSelectedFirstMethod] = useState<number>(0);
  const [selectedsecondMethod, setSelectedsecondMethod] = useState<number>(0);
  const [selectedfourthMethod, setSelectedfourthMethod] = useState<number>(0);
  const [selectedthirdMethod, setSelectedthirdMethod] = useState<number>(0);
  const [dropDownData, setDropDownData] = useState<any>();
  const dropdownContentList = useSelector(selectDropdownContentResponse);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<{ [key: string]: any }>({
    name: "",
    location: "",
    supplier_name: "",
    organisation_unit: "",

    activity_type: "upstream_emissions_of_purchased_fuel",
    method: "supplier_specific",
  });

  useEffect(() => {
    activityArray.forEach((el, index) => {
      if (el.toLocaleLowerCase() === state?.formData?.method) {
        setSelectedActivity(index);
      }
    });

    setEditMode(window.location.pathname.includes("/edit"));
    setData(
      state?.formData ?? {
        name: "",
        location: "",
        facility_article: "",
        organisation_unit: "",
        source_type: "",
        leak_rate: "",
        provider: "",
        quantity: "",
        ashrae_refrigerant_code: "",
        installation_date: "",
        operation: "",
        disposed_date: "",
        recovered_date: "",
      }
    );
  }, []);

  //^ fetch the dropdown data if there is not data available
  useEffect(() => {
    !dropdownContentList && dispatch(getDropdowncontentsAction());
  }, []);

  //^ update the state for dropdown content
  useEffect(() => {
    setDropDownData(dropdownContentList?.data);
  }, [dropdownContentList]);

  const handleChanges = (value: string, key: string): void => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const submitHandler = () => {
    let payload: { [key: string]: any };

    if (editMode) {
      delete data["pk"];
      delete data["sk"];
      delete data["created_by"];
      delete data["created_on"];
      delete data["modified_by"];
      delete data["modified_on"];
      payload = {
        emission_source: state.scopePath, // mention source
        action: "edit", // delete, edit
        scope_type: "S3U", // mention the scopes here
        sk: state?.formData?.sk,
        data: data,
      };
    } else {
      payload = {
        emission_source: state.scopePath, // mention source
        action: "add", // delete, edit
        scope_type: state.scope, // mention the scopes here
        data: data,
      };
    }

    if (state.scopePath === "fuel_&_energy_related") {
      payload["emission_source"] = "fuel_energy_related";
    }

    dispatch(postGHGEmissionDataAction(payload)).then((res) => {
      toastifyAsync(res);
      if (res.type.includes("fulfilled")) {
        navigate(`/data-collection/ghg-emission/tables/Fuel & Energy Related`, {
          state: {
            scope: "S3U",
            scopePath: "fuel_energy",
          },
        });
      }
    });
  };

  const updatDropdownListHandler = (
    enteredOption: string,
    inputType: string
  ) => {
    dispatch(
      patchDropdownListAction({
        name: inputType,
        value: enteredOption,
      })
    ).then((res) => {
      toastifyAsync(res);
    });
  };

  return (
    <div className="datacollection-forms-container">
      <div className="question-section">
        <div className="section-container-org">
          <div className="section-header">Organization</div>
          <br />
          <div className="questions-container">
            <TextInput
              header="Name"
              width="46%"
              theme="theme-gray"
              value={data.name}
              onChangeHandler={(value) => handleChanges(value, "name")}
              height="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
            />
            <Dropdown
              options={countriesData.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }

                return 0;
              })}
              width="46%"
              header="Location"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "location")
              }
              searchIncluded
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              defaultOption={[{ name: state?.formData?.location ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.supplier_name?.values?.map(
                (item: string) => ({
                  name: item,
                })
              )}
              width="46%"
              header="Supplier name"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "supplier_name")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "supplier_name");
              }}
              defaultOption={[{ name: state?.formData?.supplier_name ?? "" }]}
            />
            <Dropdown
              options={dropDownData?.organisation_unit?.values?.map(
                (item: string) => ({ name: item })
              )}
              width="46%"
              header="Organisational unit"
              selectedCallBack={(selectedOption) =>
                handleChanges(selectedOption[0].name, "organisation_unit")
              }
              heightInput="5.2rem"
              headerStyles={{ fontSize: "1.4rem" }}
              addButtonIncluded
              addClickHandler={(enteredOption) => {
                updatDropdownListHandler(enteredOption, "organisation_unit");
              }}
              defaultOption={[
                { name: state?.formData?.organisation_unit ?? "" },
              ]}
            />
          </div>
          <hr className="horizontal-row" />
        </div>

        <div className="section-container-source">
          <>
            <div className="radio-questions-container">
              <div className="header">Select Activity Type</div>

              <div className="radio-container">
                {Children.toArray(
                  activityArray.map((item: string, index: number) => (
                    <RadioInput
                      label={item}
                      onClickHandler={() => {
                        handleChanges(
                          item.toLowerCase().split(" ").join("_"),
                          "activity_type"
                        );
                        setSelectedActivity(index);
                      }}
                      checked={index === selectedActivity}
                    />
                  ))
                )}
              </div>
            </div>
            <hr className="horizontal-row" />
          </>

          {selectedActivity === 0 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Method</div>

                  <div className="radio-container">
                    {Children.toArray(
                      firstMethod.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "method"
                            );
                            setSelectedFirstMethod(index);
                          }}
                          checked={index === selectedFirstMethod}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {selectedFirstMethod === 0 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={countriesData}
                      width="46%"
                      header="Lifecycle Emission factor (Country wise)"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "life_cycle_emission_factor"
                        )
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        {
                          name:
                            state?.formData?.life_cycle_emission_factor ?? "",
                        },
                      ]}
                    />
                    <TextInput
                      header="Supplier Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_emisson_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_emisson_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />

                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Fuel Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.fuel_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "fuel_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {selectedFirstMethod === 1 && (
                <>
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      header="Lifecycle Emission factor (Country wise)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <Dropdown
                      options={dropDownData?.fuel_type?.values?.map(
                        (item: any) => ({
                          name: item?.fuel_type,
                        })
                      )}
                      width="46%"
                      header="Fuel Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(selectedOption[0].name, "fuel_type")
                      }
                      searchIncluded
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.fuel_type ?? "" },
                      ]}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Fuel Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.fuel_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "fuel_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}

          {selectedActivity === 1 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Allocated Emission</div>

                  <div className="radio-container">
                    {Children.toArray(
                      firstMethod.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "method"
                            );
                            setSelectedfourthMethod(index);
                          }}
                          checked={index === selectedfourthMethod}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {selectedfourthMethod === 0 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "one" },
                        { name: "two" },
                        { name: "three" },
                      ]}
                      width="46%"
                      header="Energy Purchased Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "energy_purchased_type"
                        )
                      }
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.energy_purchased_type ?? "" },
                      ]}
                    />
                    <TextInput
                      type="number"
                      header="Lifecycle Emission Factor (Country wise)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="Supplier Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.supplier_emisson_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "supplier_emisson_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Energy Consumed (kw)"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
              {selectedfourthMethod === 1 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <Dropdown
                      options={[
                        { name: "one" },
                        { name: "two" },
                        { name: "three" },
                      ]}
                      width="46%"
                      header="Energy Purchased Type"
                      selectedCallBack={(selectedOption) =>
                        handleChanges(
                          selectedOption[0].name,
                          "energy_purchased_type"
                        )
                      }
                      heightInput="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                      defaultOption={[
                        { name: state?.formData?.energy_purchased_type ?? "" },
                      ]}
                    />
                    <TextInput
                      type="number"
                      header="Lifecycle Emission Factor (Country wise)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="Average Emission Factor"
                      width="46%"
                      theme="theme-gray"
                      value={data.average_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "average_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Energy Consumed (kw)"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}
          {selectedActivity === 2 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Allocated Emission</div>

                  <div className="radio-container">
                    {Children.toArray(
                      firstMethod.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "method"
                            );
                            setSelectedthirdMethod(index);
                          }}
                          checked={index === selectedthirdMethod}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>
              {selectedthirdMethod === 0 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Lifecycle Fuel Emission Factor (Supplier Specific)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="T&D Loss Rate"
                      width="46%"
                      theme="theme-gray"
                      value={data.td_loss_rate}
                      onChangeHandler={(value) =>
                        handleChanges(value, "td_loss_rate")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Electricity Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {selectedthirdMethod === 1 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Lifecycle Fuel Emission Factor (Supplier Specific)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                    <TextInput
                      type="number"
                      header="T&D Loss Rate"
                      width="46%"
                      theme="theme-gray"
                      value={data.td_loss_rate}
                      onChangeHandler={(value) =>
                        handleChanges(value, "td_loss_rate")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Electricity Consumed"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_consumed}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_consumed")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}
          {selectedActivity === 3 && (
            <>
              <>
                <div className="radio-questions-container">
                  <div className="header">Select Allocated Emission</div>

                  <div className="radio-container">
                    {Children.toArray(
                      firstMethod.map((item: string, index: number) => (
                        <RadioInput
                          label={item}
                          onClickHandler={() => {
                            handleChanges(
                              item.toLowerCase().split(" ").join("_"),
                              "method"
                            );
                            setSelectedsecondMethod(index);
                          }}
                          checked={index === selectedsecondMethod}
                        />
                      ))
                    )}
                  </div>
                </div>
                <hr className="horizontal-row" />
              </>

              {selectedsecondMethod === 0 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Lifecycle Fuel Emission Factor (Supplier Specific)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Electricity Purchased for Resale"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}

              {selectedsecondMethod === 1 && (
                <>
                  <br />
                  <div className="section-header">Emission Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Lifecycle Fuel Emission Factor (Country wise)"
                      width="46%"
                      theme="theme-gray"
                      value={data.life_cycle_emission_factor}
                      onChangeHandler={(value) =>
                        handleChanges(value, "life_cycle_emission_factor")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                  <div className="section-header">Activity Data</div>
                  <br />
                  <div className="questions-container">
                    <TextInput
                      type="number"
                      header="Electricity Purchased for Resale"
                      width="46%"
                      theme="theme-gray"
                      value={data.electricity_purchased}
                      onChangeHandler={(value) =>
                        handleChanges(value, "electricity_purchased")
                      }
                      height="5.2rem"
                      headerStyles={{ fontSize: "1.4rem" }}
                    />
                  </div>
                  <hr className="horizontal-row" />
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Button
        label="Save"
        className="primary-button save-btn"
        onClick={submitHandler}
      />
    </div>
  );
};

export default FuelEnergy;
