// import library components
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';



// import styles file
import './InvesteeOnboarding.scss'


// import custom components
import MainContentWrapper from '../../../../../Components/Core/MainContent/ContentWrapper';
import Button from '../../../../../Components/Core/Button/Button';
import { ReactComponent as NotificationIcon } from "../../../../../assets/icons/notification-bell-fill.svg";
import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/download.svg";
import { ReactComponent as Chevron } from '../../../../../assets/icons/chevron.svg';
import TextInput from '../../../../../Components/Core/TextInput/TextInput';
import Pagination from '../../../../../Components/Core/Pagination/Pagination';


// import redux related components
import { useDispatch } from 'react-redux';
import { getOnboardedInvesteeList } from '../../../../../Redux/Slices/financedEmission/onboardedInvesteeList/onboardedInvesteeList.slice';
import { AppDispatch } from '../../../../../Redux/store';
import { useSelector } from 'react-redux';
import { selectOnboardedInvesteeList } from '../../../../../Redux/Slices/financedEmission/onboardedInvesteeList/onboardedInvesteeList.selector';
import TableEnhanced from '../../../../../Components/Core/TableEnhanced/TableEnhanced';
import { ReactComponent as MagnifyGlass } from '../../../../../assets/icons/magnifyingGlass-icon.svg';
import { postInviteInvesteeAction } from '../../../../../Redux/Slices/financedEmission/inviteInvestee/postInviteInvestee.slice';
import { toastifyAsync } from '../../../../../utils/notifications/toastifyAsync';


// 
type Props = {
    updateNavigationTab: (index: number) => void;
}

const InvesteeOnboarding = ({updateNavigationTab }: Props) => {

    // ^ default constants
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    const onboardedInvesteeListData = useSelector(selectOnboardedInvesteeList)

    const [searchField, setSearchField] = useState("")


    const [onboardedInvesteeList, setOnboardedInvesteeList] = useState<any[]>()
    const [paginationKeys, setPaginationKeys] = useState<any>();

    const [showUploadInvesteeModal, setShowUploadInvesteeModal] = useState(false);

    const handleConfirmation = () => {
        
        updateNavigationTab (3);
    };

    //^ to handle the pagination navigations
    const [nextPageToken, setNextPageToken] = useState("");

    const onNextClick = (offset: number | string) => {
        setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token)
    }

    const onPreviousClick = (offset: number | string) => {
        setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token)
    }

    const onPageClick = (offset: number | string) => {
        setNextPageToken(paginationKeys[Number(offset) - 1]?.next_token)
    }

    //^ setter function for updating the states
    useEffect(() => {
        setOnboardedInvesteeList(onboardedInvesteeListData?.data)
        onboardedInvesteeListData?.next_token_list && setPaginationKeys(onboardedInvesteeListData?.next_token_list)
    }, [onboardedInvesteeListData])

    //^ to fetch the api, based on debouncing
    //investor_id, limit, search_key, next_token
    useEffect(() => {
        const getData = setTimeout(() => {
            dispatch(getOnboardedInvesteeList({
                investee_id: "599e40c7-a271-4f5d-a70c-b9fb34b94bd0",
                limit: 10,
                search_key: searchField,
                next_token: nextPageToken
            }))
        }, 600)

        return () => clearTimeout(getData)
    }, [searchField, nextPageToken])


    //^ selected list of entries from table
    const [selectedList, setSelectedList] = useState<string[]>([])

    const handleSelectEntries = (selectedData: any) => {
        const emailList = selectedData.filter((item: any) => item.selected).map((item: any) => item.investee_email)
        setSelectedList(emailList)
    }

    const onNotifyClick = () => {
        if (selectedList.length === 0) {
            toast.error('Select at least one!');
            return;
        }

        toast.loading('Sending Invite to Investors')

        dispatch(postInviteInvesteeAction({ investeeEmailList: selectedList, action_type: 'DATA_UPLOAD' }))
            .then((res: any) => toastifyAsync(res))
    }



    // $ to handle the table edited and deleted data
    // const handleEditedData = (data: any): 'success' | 'error' => {
    //     toast.error("Oops! Error occured,Your changes have not been saved yet")

    //     return 'error'
    // }
    // const handleDeletedData = (data: any): 'success' | 'error' => {

    //     toast.error("Oops! Error occured, your data has not deleted yet!")
    //     return 'error'
    // }

    return (
        <div id='investeeOnboardingComponent' className="investee-onboarding-component-container">

            <MainContentWrapper customClassName="wrapper-in-data-validation">
                <div className="data-validation-body">

                    <div className="header-section">
                        <div className="section-left">
                            <div className="section-headline-2">
                                Investee list
                                <span>{onboardedInvesteeListData?.total_data_count || 0}</span>
                            </div>
                            <div className="section-headline">Track investee onboarding and data upload status </div>
                        </div>

                        <div className="section-right">
                            {/* <Button
                                onClick={() => setShowUploadInvesteeModal(true)}
                                label='Upload'
                                leftIcon={
                                    <div className='download-icon'>
                                        <DownloadIcon />
                                    </div>
                                }
                                className='primary-no-outline '
                            /> */}
                            <Button
                                label='Download'
                                leftIcon={<DownloadIcon />}
                                className='primary-no-outline'
                            />
                        </div>

                    </div>
                    <br />
                    <div className='header-section-1'>

                        <div className="invite-btn">
                            <Button
                                onClick={onNotifyClick}
                                leftIcon={<NotificationIcon />}
                                label='Notify'
                                className='primary-button'
                            />
                        </div>

                        <div className="search-bar">
                            <TextInput
                                placeholder="Search"
                                leftIcon={<MagnifyGlass />}
                                value={searchField}
                                onChangeHandler={setSearchField}
                            />
                        </div>
                    </div>

                    <div className="table-container noScrollBar">
                        {/* <TableComponent
                            tableHeaders={tableHeader}
                            tableBodyContent={onboardedInvesteeList}
                        /> */}
                        {
                            onboardedInvesteeList &&
                            <TableEnhanced
                                tableHeader={headerData}
                                tableData={onboardedInvesteeList}
                                selectedDataList={handleSelectEntries}
                            // editedDataList={handleEditedData}
                            // deletedDataList={handleDeletedData}
                            />
                        }
                    </div>
                </div>
            </MainContentWrapper>

            <div className="footer-action-btns">
                <Pagination
                    totalNumberOfData={paginationKeys?.length || 0}
                    perPage={1}
                    nextClickHandler={onNextClick}
                    previousClickHandler={onPreviousClick}
                    pageClickHandler={onPageClick}
                    classNames={{
                        nextButton: 'next-button',
                        previousButton: 'previous-button',
                        pageButton: 'page-button',
                        pageActiveButton: 'page-active',
                        buttons: 'buttons',
                    }}
                    components={{
                        nextButton: <Chevron />,
                        previousButton: <Chevron />,
                    }}

                />
                <Button
                    label="Confirm"
                    className="primary-button"
                    onClick={handleConfirmation}

                />
            </div>

        </div>
    )
}

export default InvesteeOnboarding

const headerData: {
    label: string;
    key: string | null;
    option?: "checkbox" | "menu";
    width?: string;
    customStyles?: boolean
}[] = [
        {
            label: 'select',
            key: 'selected',
            option: "checkbox",
        },
        {
            label: 'Investee name',
            key: 'investee_company_name',
        },
        {
            label: 'Country',
            key: 'country',
        },
        {
            label: 'Email address',
            key: 'investee_email',
        },
        {
            label: 'Onboarding status',
            key: 'onboarding_status',
            width: '10rem',
            customStyles: true
        },
        {
            label: 'Data upload status',
            key: 'data_upload_status',
            width: '10rem',
            customStyles: true
        },
    ]
