// import library components
import React, { useEffect, useState } from "react";

// import styles file
import "../GhgEmission.scss";

// import custom components
import { ReactComponent as GraphVector } from "../../../../assets/icons/graphVector.svg";
import FilterCard from "../FilterCard/FilterCard";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";
import EmissionByCategoryUpstream from "./EmissionByCategoryUpstream/EmissionByCategoryUpstream";
import EmissionByCategoryDownstream from "./EmissionByCategoryDownstream/EmissionByCategoryDownstream";
import EmissionPerYear from "./EmissionPerYear/EmissionPerYear";
import EmissionPerFacilities from "./EmissionPerFacilities/EmissionPerFacilities";
import EmissionByOrgUnit from "../Scope1/EmissionByOrgUnit";
import EmissionByPeriod from "../Scope1/EmissionByPeriod";
import EmissionBySupplier from "./EmissionBySupplier/EmissionBySupplier";
import CategoryFilterCard from "./CategoryFilterCard/CategoryFilterCard";

type Props = {};

type receivedGhgEmissionDataType = {
  total_emission?: number;
  upstream_emission?: number;
  downstream_emission?: number;
  last_year_total_emission?: number;
  last_year_upstream_emission?: number;
  last_year_downstream_emission?: number;
  renewables?: number;
  last_year_renewables?: number;
  is_total_emission_decreased?: boolean;
  is_upstream_emission_decreased?: boolean;
  is_downstream_emission_decreased?: boolean;
};

interface ICheckboxList {
  value?: string;
  checked?: boolean;
  type?: string;
}

const Scope3 = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const ghgEmissionData = useSelector(selectGhgEmissionDataResponse);
  const [selectedDateEbp, setSelectedDateEbp] = useState({});
  const [receivedGhgEmissionData, setReceivedGhgEmissionData] =
    useState<receivedGhgEmissionDataType>({ last_year_total_emission: 0 });
  const [categoryFiltersSelected, setCategoryFiltersSelected] = useState([]);
  const [orgUnitFiltersSelected, setOrgUnitFiltersSelected] = useState([]);
  const [orgUnitList, setOrgUnitList] = useState([]);
  const [categoryList, setCategoryList] = useState<ICheckboxList[]>([]);

  useEffect(() => {
    let filteredCategoryList = categoryFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    let filteredOrgUnitList = orgUnitFiltersSelected
      .filter((el: any) => el.checked)
      .map((el: any) => el.value);

    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 3",
        category_list: filteredCategoryList,
        organisation_unit_list: filteredOrgUnitList,
        emission_year: "2023",
      })
    );
  }, [dispatch, categoryFiltersSelected, orgUnitFiltersSelected]);

  useEffect(() => {
    if (ghgEmissionData?.static_data) {
      let tempOrgUnitList =
        ghgEmissionData?.static_data?.organisation_unit_list.map((el: any) => {
          return { value: el, checked: false };
        }) ?? [];

      let tempCategoryListUpstream =
        ghgEmissionData?.static_data?.scope3_upstream_category_list.map(
          (el: any) => {
            return { value: el, checked: false, type: "upstream" };
          }
        ) ?? [];
      let tempCategoryListDownstream =
        ghgEmissionData?.static_data?.scope3_downstream_category_list.map(
          (el: any) => {
            return { value: el, checked: false, type: "downstream" };
          }
        ) ?? [];

      setCategoryList([
        ...tempCategoryListUpstream,
        ...tempCategoryListDownstream,
      ]);
      setOrgUnitList(tempOrgUnitList);
    }
    setReceivedGhgEmissionData(ghgEmissionData?.data);
  }, [ghgEmissionData]);

  return (
    <>
      <div className="stats-filter-container d-grid-c6">
        <div className="quickstats-container col-3 d-grid-c6">
          <div className="stats-tile col-6">
            <div className="stats-tile-headline">Scope 3 Emission</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.total_emission ?? 0} <sub>MtCO2e</sub>{" "}
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_total_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_total_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_total_emission}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Upstream Emission</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.upstream_emission ?? 0}{" "}
              <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator ${
                  receivedGhgEmissionData?.is_upstream_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_upstream_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_upstream_emission}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
          <div className="stats-tile col-3">
            <div className="stats-tile-headline">Downstream Emission</div>

            <div className="stats-tile-values">
              {receivedGhgEmissionData?.downstream_emission ?? 0}{" "}
              <sub>MtCO2e</sub>
            </div>

            <div className="stats-tile-progress">
              <span
                className={`indicator  ${
                  receivedGhgEmissionData?.is_downstream_emission_decreased
                    ? "indicator-success"
                    : "indicator-error"
                }`}
              ></span>
              <span>from Last year</span>

              <span
                className={`graph-section ${
                  receivedGhgEmissionData?.is_downstream_emission_decreased
                    ? "graph-section-success"
                    : "graph-section-error"
                }`}
              >
                <span>
                  {" "}
                  {receivedGhgEmissionData?.last_year_downstream_emission}%
                </span>
                <GraphVector />
              </span>
            </div>
          </div>
        </div>

        <div className="filters col-3 d-grid-c6">
          <CategoryFilterCard
            checkboxList={categoryList}
            applyBtnHandler={(e) => {
              setCategoryFiltersSelected(e);
            }}
          />
          <FilterCard
            header="Organisation unit"
            checkboxList={orgUnitList}
            applyBtnHandler={(e) => {
              setOrgUnitFiltersSelected(e);
            }}
          />
        </div>
      </div>

      <div className="demographics-container d-grid-c6">
        <div className="demographic-tile col-3">
          <EmissionByCategoryUpstream
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div className="demographic-tile col-3">
          <EmissionByCategoryDownstream
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div
          className="demographic-tile col-2"
          style={{
            maxHeight: "456px",
            paddingBottom: "7rem",
          }}
        >
          <EmissionPerYear receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>

        <div className="demographic-tile col-4">
          <EmissionPerFacilities
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div
          className="demographic-tile col-2"
          style={{ maxHeight: "473px", paddingBottom: "7rem" }}
        >
          <EmissionByOrgUnit
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>

        <div className="demographic-tile col-4">
          <EmissionByPeriod receivedGhgEmissionData={receivedGhgEmissionData} />
        </div>
        <div className="demographic-tile col-6">
          <EmissionBySupplier
            receivedGhgEmissionData={receivedGhgEmissionData}
          />
        </div>
      </div>
    </>
  );
};

export default Scope3;
