import { createSlice } from "@reduxjs/toolkit";


const INITIAL_STATE = {
    selectedSwitch: 0
}

const dataFillMode = createSlice({
    name: 'utils/selectedDataFillMode',
    initialState: INITIAL_STATE,
    reducers: {
        setSelectedSwitchAction(state, { payload }) {
            return {
                ...state,
                selectedSwitch: payload
            }
        }
    }
})

export const { setSelectedSwitchAction } = dataFillMode.actions;
export const dataFillModeSwitchSlice = dataFillMode.reducer;