import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";
import { colors } from "../../../../DesignSystem/Colors/colors";
import "./EmissionBreakdown.scss";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData: dynamicObj;
}

const EmissionBreakDown = ({ dashboardOverviewData }: Props) => {
  const [labels, setLabels] = useState<string[]>([]);
  const [values, setValues] = useState<number[]>([]);

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.emission_breakdown) {
      setLabels(Object.keys(dashboardOverviewData.emission_breakdown));
      setValues(Object.values(dashboardOverviewData.emission_breakdown));
    }
  }, [dashboardOverviewData]);

  return (
    <div className="emission-breakdown-chart-container">
      <div className="emission-breakdown-chart-header">Emission Breakdown</div>
      <div className="emission-breakdown-pie-chart-container">
        <Graphs.PieChart
          header=""
          tooltip="Emission by Scope"
          labels={labels}
          legendsDisabled={true}
          chartData={[
            {
              label: "tCO2e",
              values: values,
            },
          ]}
          dataLabels={false}
        />
      </div>
      <div className="emission-breakdown-label-container hide-scrollbar">
        <div className="labels-container-table-header-container">
          <div className="labels-container-table-cell labels-header-container-table-cell-column-1">
            Label
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-2">
            Emission
          </div>
          <div className="labels-container-table-cell labels-header-container-table-cell-column-3">
            %
          </div>
        </div>
        {labels.map((el, index) => (
          <div className="labels-container-table-row">
            <div className="labels-container-table-cell labels-body-container-table-cell-column-1">
              <div
                className="ebp-label-point"
                style={{ backgroundColor: `${colors.primaryColors[index]}` }}
              ></div>
              <div>{labels[index]}</div>
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-2">
              {values[index]}
            </div>
            <div className="labels-container-table-cell labels-body-container-table-cell-column-3">
              {values[index]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmissionBreakDown;
