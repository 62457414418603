// import library components
import { createSelector } from "reselect";
import { RootState } from "../../../../store";

// create base selector for reducer
const selectFinancedEmissionDetailsStore = (state: RootState) =>
  state.financedEmissionLocationDetails;

export const selectFinancedEmissionLocationDetailsResponse = createSelector(
  [selectFinancedEmissionDetailsStore],
  (selectFinancedEmissionDetailsStore) =>
    selectFinancedEmissionDetailsStore.response
);
