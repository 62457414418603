import { createSelector } from "reselect";
import { RootState } from "../../../../store";

const selectBenchmarkingComparedDataStore = (state: RootState) =>
  state.benchmarkingComparedData;

export const selectBenchmarkingComparedDataResponse = createSelector(
  [selectBenchmarkingComparedDataStore],
  (selectBenchmarkingComparedDataStore) =>
    selectBenchmarkingComparedDataStore.response
);
