//import library components
import { createSlice, AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../Api";

// import initial state
import { initialStateApi } from "../../../utils";

type payloadType = {
    module: string;
    user_token: string
}


export const getAuthTokenVerified: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'investor/utils/getFilterBasedDataAction',
    async ({ module, user_token }, { rejectWithValue }) => {
        try {
            const response = await fetch(
                api.config.tokenVerifyUrl(module),
                api.http.get({ 'Authorization': `Bearer ${user_token}` })
            )

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
            return result;

        } catch (error: any) {
            if (!error.response) {
                throw error
            }
            rejectWithValue(error)
        }
    }
)

const utilsVerifyToken = createSlice({
    name: 'utilsVerifyToken',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAuthTokenVerified.pending, (state, _) => {
                state.isLoading = true
            })
            .addCase(getAuthTokenVerified.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getAuthTokenVerified.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
            })
    },
})

export default utilsVerifyToken.reducer