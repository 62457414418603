// import library components
import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../../utils";

//  payload type definition
type payloadType = {
  filter?: string;
  filter_value?: string;
  page?: number;
};

export const getPcafMappingData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getPcafMappingData",
    async ({ filter, filter_value, page }, { rejectWithValue }) => {
      // order fo the parameters in the url
      // investor_name, limit, search_key, asset_class, sub_asset_class, sector, sub_sector, country, data_score, next_token

      try {
        const response = await fetch(
          api.config.getPcafMappingData(filter, filter_value, page),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const pcafMappingData = createSlice({
  name: "pcafMappingData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPcafMappingData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getPcafMappingData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getPcafMappingData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default pcafMappingData.reducer;
