import React from 'react'

import './UnAuthorized.scss'

import { ReactComponent as SecurityIcon } from '../../../assets/icons/Security-Icon.svg'

type Props = {}

const UnAuthorized = (props: Props) => {

    const redirect = () => window.location.href = `${process.env.REACT_APP_MARKETPLACE_URL}`

    return (
        <div id='UnAutherisedComponent' className='unautherised-screen' >
            <SecurityIcon className='icon' />
            <div className='text-container'>
                <div className="header1">Sorry!</div>
                <div className="header2">No authorization found</div>
                <div className="header3">We're sorry, You are not authorized to use this application, Please go back </div>
                <div onClick={redirect} className="button">Go back Marketplace</div>
            </div>
        </div>
    )
}

export default UnAuthorized