import React, { useEffect, useState } from "react";
import { Graphs } from "../../../../Components/graphs";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { ReactComponent as ArrowRightExtendedTail } from "../../../../assets/icons/arrowRightExtendedTail.svg";
import {
  getMonthNumber,
  getAbbreviatedMonthName,
} from "../../../../utils/helpers/MonthMapper";
import "./EmissionByPeriod.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectOverviewDataResponse } from "../../../../Redux/Slices/dashboard/overview/getOverviewData.selector";
import { AppDispatch } from "../../../../Redux/store";
import { getOverviewData } from "../../../../Redux/Slices/dashboard/overview/getOverviewData.slice";
import { yearData } from "../../../../utils/data/yearData";

interface dynamicObj {
  [key: string]: any;
}

interface Props {
  dashboardOverviewData: dynamicObj;
}

const EmissionByPeriod = ({ dashboardOverviewData }: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  const [labelsArr, setLabelsArr] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>("2022");
  const [selectedInitialMonth, setSelectedInitalMonth] =
    useState<string>("January");
  const [selectedFinalMonth, setSelectedFinalMonth] =
    useState<string>("December");

  const regex = /-(.*?)-/;

  useEffect(() => {
    if (dashboardOverviewData && dashboardOverviewData.emission_by_period) {
      setFilteredData(
        dashboardOverviewData.emission_by_period.map(
          (el: any) => el.emission_value
        )
      );

      const mappedData = dashboardOverviewData.emission_by_period.map(
        (item: any) =>
          getAbbreviatedMonthName(Number(item.emission_date.slice(5)))
      );

      setLabelsArr(mappedData);

      // setLabelsArr(
      //   dashboardOverviewData.emission_by_period.map((el: any) =>
      //     getAbbreviatedMonthName(+el.emission_date.match(regex)?.[1])
      //   )
      // );
    }
  }, [dashboardOverviewData]);

  const dateSelectHandler = (mode: string, value: string) => {
    if (mode === "year") {
      let fromDate = `${value}-${getMonthNumber(
        selectedInitialMonth
      )}-01T00:00:18.000Z`;
      let toDate = `${value}-${getMonthNumber(
        selectedFinalMonth
      )}-31T23:58:18.000Z`;

      dispatch(getOverviewData({ from_date: fromDate, to_date: toDate }));
    } else if (mode === "initialMonth") {
      let fromDate = `${selectedYear}-${getMonthNumber(
        value
      )}-01T00:00:18.000Z`;
      let toDate = `${selectedYear}-${getMonthNumber(
        selectedFinalMonth
      )}-31T23:58:18.000Z`;

      dispatch(getOverviewData({ from_date: fromDate, to_date: toDate }));
    } else {
      let fromDate = `${selectedYear}-${getMonthNumber(
        selectedInitialMonth
      )}-01T00:00:18.000Z`;
      let toDate = `${selectedYear}-${getMonthNumber(value)}-31T23:58:18.000Z`;

      dispatch(getOverviewData({ from_date: fromDate, to_date: toDate }));
    }
  };

  return (
    <Graphs.LineGraph
      chartData={[filteredData]}
      labels={labelsArr}
      header={
        <div className="ebp-date-selector-container">
          <div>Emission By Period</div>
          <div className="ebp-date-selector">
            <DropDown
              optionsList={yearData}
              dropDownContainerClassName="dropdown-container"
              dropDownBodyClassName="dropdown-body"
              onSelectHandler={(e) => {
                setSelectedYear(e);
                dateSelectHandler("year", e);
              }}
              selected="2022"
            />
            <div className="ebp-partitioner"></div>
            <DropDown
              optionsList={[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ]}
              dropDownContainerClassName="dropdown-container dropdown-container-months"
              dropDownBodyClassName="dropdown-body"
              onSelectHandler={(e) => {
                setSelectedInitalMonth(e);
                dateSelectHandler("initialMonth", e);
              }}
            />
            <div className="ebp-right-arrow">
              <ArrowRightExtendedTail />
            </div>
            <DropDown
              optionsList={[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ]}
              dropDownContainerClassName="dropdown-container dropdown-container-months"
              dropDownBodyClassName="dropdown-body"
              selected="December"
              onSelectHandler={(e) => {
                setSelectedFinalMonth(e);
                dateSelectHandler("finalMonth", e);
              }}
            />
          </div>
        </div>
      }
      legends={["Emission"]}
      xAxisLabel=""
      yAxisLabel="Emissions(MtCO2e)"
      legendsPosition="top"
      tension={0.3}
      theme="blue"
      fill={true}
    />
  );
};

export default EmissionByPeriod;
