import api from "../../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { initialStateApi } from "../../../../utils";

type payLoadType = {
  investor_id?: string;
  sector?: string;
  sub_sector?: string;
  from_date?: string;
  to_date?: string;
};

export const getSectorData: AsyncThunk<any, payLoadType, {}> = createAsyncThunk(
  "getSectorData",
  async (
    { investor_id, sector, sub_sector, from_date, to_date },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetch(
        api.config.getSectorData(
          investor_id,
          sector,
          sub_sector,
          from_date,
          to_date
        ),
        api.http.get()
      );
      const result = await api.afterFetchHandlers.parseContent(
        response,
        api.http.get()
      );

      return result;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      rejectWithValue(error);
    }
  }
);

export const sectorData = createSlice({
  name: "sectorData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getSectorData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getSectorData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getSectorData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default sectorData.reducer;
