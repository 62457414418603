// import library components
import api from '../../../../Api/index';
import { createSlice, createAsyncThunk, AsyncThunk, } from '@reduxjs/toolkit';

// iniitial state
import { initialStateApi } from '../../../utils';

//  payload type definition
type payloadType = undefined

export const fetchPartnerIntegrationList: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'fetchPartnerIntegrationList',
        async (_, { rejectWithValue }) => {
            try {
                const response = await fetch(
                    api.config.getPartnerIntegrationList(),
                    api.http.get()
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.get())
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )


const partnerIntegrationList = createSlice({
    name: 'fetchPartnerIntegrationList',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchPartnerIntegrationList.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchPartnerIntegrationList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(fetchPartnerIntegrationList.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    }
})

export default partnerIntegrationList.reducer;