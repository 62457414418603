import { useState, useEffect } from "react";
import { Graphs } from "../../../../Components/graphs";
import {
  getAbbreviatedMonthName,
  getMonthNumber,
} from "../../../../utils/helpers/MonthMapper";
import DropDown from "../../../../Components/Core/DropDown/DropDown";
import { ReactComponent as ArrowRightExtendedTail } from "../../../../assets/icons/arrowRightExtendedTail.svg";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Redux/store";
import { useSelector } from "react-redux";
import { selectGhgEmissionDataResponse } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.selector";
import { getGhgEmissionData } from "../../../../Redux/Slices/dashboard/ghgEmission/getGhgEmissionData.slice";

type ghgEmissionDataObj = {
  [key: string]: any;
};

interface Props {
  receivedGhgEmissionData?: ghgEmissionDataObj;
}

const EmissionByPeriod = ({ receivedGhgEmissionData }: Props) => {
  const [values, setValues] = useState([]);
  const [labelsArr, setLabelsArr] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>("2022");
  const [selectedInitialMonth, setSelectedInitalMonth] =
    useState<string>("January");
  const [selectedFinalMonth, setSelectedFinalMonth] =
    useState<string>("December");
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (receivedGhgEmissionData && receivedGhgEmissionData.emission_by_period) {
      setValues(
        receivedGhgEmissionData.emission_by_period.map(
          (el: any) => el.total_emission_co2e
        )
      );

      setLabelsArr(
        receivedGhgEmissionData.emission_by_period.map((el: any) =>
          getAbbreviatedMonthName(+el.month.split("-")[1])
        )
      );
    }
  }, [receivedGhgEmissionData]);

  const dateSelectHandler = ({
    year = "2022",
    initialMonth = "January",
    finalMonth = "December",
  }: any) => {
    let fromDate = `${year}-${getMonthNumber(initialMonth)}-01T00:00:18.000Z`;
    let toDate = `${year}-${getMonthNumber(finalMonth)}-31T23:58:18.000Z`;

    dispatch(
      getGhgEmissionData({
        scope_type: "SCOPE 1",
        from_date: fromDate,
        to_date: toDate,
      })
    );
  };

  return (
    <Graphs.LineGraph
      chartData={[values]}
      labels={
        labelsArr ?? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      }
      header={
        <div className="ebp-date-selector-container">
          <div>Emission By Period</div>
          <div className="ebp-date-selector">
            <DropDown
              optionsList={[
                "2023",
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
              ]}
              dropDownContainerClassName="dropdown-container"
              dropDownBodyClassName="dropdown-body"
              onSelectHandler={(e) => {
                dateSelectHandler({
                  year: e,
                  initialMonth: selectedInitialMonth,
                  finalMonth: selectedFinalMonth,
                });
                setSelectedYear(e);
              }}
              selected="2022"
            />
            <div className="ebp-partitioner"></div>
            <DropDown
              optionsList={[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ]}
              dropDownContainerClassName="dropdown-container dropdown-container-months"
              dropDownBodyClassName="dropdown-body"
              onSelectHandler={(e) => {
                dateSelectHandler({
                  year: selectedYear,
                  initialMonth: e,
                  finalMonth: selectedFinalMonth,
                });
                setSelectedInitalMonth(e);
              }}
            />
            <div className="ebp-right-arrow">
              <ArrowRightExtendedTail />
            </div>
            <DropDown
              optionsList={[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ]}
              dropDownContainerClassName="dropdown-container dropdown-container-months"
              dropDownBodyClassName="dropdown-body"
              selected="December"
              onSelectHandler={(e) => {
                dateSelectHandler({
                  year: selectedYear,
                  initialMonth: selectedInitialMonth,
                  finalMonth: e,
                });
                setSelectedFinalMonth(e);
              }}
            />
          </div>
        </div>
      }
      legends={["CO2"]}
      xAxisLabel="Months"
      yAxisLabel="Emissions(MtCO2e)"
      tension={0.3}
      theme="blue"
      fill={true}
      legendsPosition="bottom"
    />
  );
};

export default EmissionByPeriod;
