import { useState, useEffect, ReactNode, useRef } from "react";
import "./MultiSelect.scss";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/chevron.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/crossIcon.svg";

interface IOptions {
  text?: string;
  checked?: boolean;
}

export interface IMultiSelectProps {
  placeholder?: string;
  selectDropdownIcon?: ReactNode;
  options?: IOptions[];
  onSelectHandler?: (index: IOptions[]) => void;
  disableSelectAllOption?: boolean;
}

const MultiSelect = ({
  placeholder = "-Select-",
  selectDropdownIcon = <DropdownIcon />,
  options = [],
  onSelectHandler,
  disableSelectAllOption = false,
}: IMultiSelectProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [optionsArr, setOptionsArr] = useState<IOptions[]>([...options]);
  const [selectedOptions, setSelectedOptions] = useState<IOptions[]>([]);
  const autoScroll = useRef<HTMLDivElement>(null);
  const prevOptions = useRef<IOptions[]>(options);

  useEffect(() => {
    // Update optionsArr only if options have changed
    if (prevOptions.current !== options) {
      setOptionsArr(() => [...options]);
      prevOptions.current = options;
    }
  }, [options]);

  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    if (autoScroll.current) {
      autoScroll.current.scrollTop = autoScroll.current.scrollHeight;
    }
  };

  const optionClickHandler = (option: IOptions) => () => {
    let optionsArrCopy = optionsArr.map((a) => {
      return { ...a };
    });

    if (option.text === "All") {
      optionsArrCopy = optionsArrCopy.map((el) => ({
        ...el,
        checked: !option.checked,
      }));
    } else {
      const updatedArr = optionsArrCopy.map((el) =>
        el.text === option.text ? { ...el, checked: !el.checked } : el
      );
      if (!disableSelectAllOption) {
        // Change "All" option checked state based on the checked status of other options
        const allChecked = updatedArr
          .filter((el) => el.text !== "All")
          .every((el) => el.checked); // returns a boolean
        updatedArr[0].checked = allChecked;
      }

      optionsArrCopy = updatedArr;
    }

    setOptionsArr(optionsArrCopy);

    // Call auto scroll function
    scrollToBottom();

    // Send back the selected options to parent component
    const newSelectedOptionsArr = optionsArrCopy.filter((el) => el.checked);
    setSelectedOptions(newSelectedOptionsArr);
    onSelectHandler?.(newSelectedOptionsArr);
  };

  return (
    <div className="multi-select-container">
      <div
        className="selector-dropdown"
        onClick={() => setOpen((prevState) => !prevState)}
      >
        {(selectedOptions && selectedOptions.length) > 0 ? (
          <div
            className="selected-options-container hide-scrollbar"
            ref={autoScroll}
          >
            {selectedOptions.map(
              (el) =>
                el.text !== "All" && (
                  <div className="selected-option">
                    {el.text}
                    <CrossIcon onClick={optionClickHandler(el)} />
                  </div>
                )
            )}
          </div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
        <div
          className={
            open ? "selectDropdownIcon rotate-icon" : "selectDropdownIcon"
          }
        >
          {selectDropdownIcon}
        </div>
      </div>
      {open && (
        <div className="selector-dropdown-body">
          {optionsArr.map((option) => (
            <div
              className="selector-dropdown-body-element"
              onClick={optionClickHandler(option)}
            >
              <div className="selector-dropdown-body-element-checkbox">
                <input type="checkbox" checked={option.checked} />
              </div>
              <div className="selector-dropdown-body-element-option">
                {option.text}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
