// import library components
import api from "../../../../Api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../../utils";

// payload type definition
type payloadType = {
    objectFolder: string;
    bucketName: string;
    file: File | Blob;
};

export const postFilesS3Action: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        'AWS_S3/postFilesS3',
        async ({ objectFolder, bucketName, file }, { rejectWithValue }) => {
            try {
                const response = await fetch(
                    api.config.getPRreSignUrlS3(),
                    api.http.postNoAuth({ "object_folder": objectFolder, "bucket_name": bucketName })
                )
                const result = await api.afterFetchHandlers.parseContent(response, api.http.postNoAuth())
                const { presignedUrl: { url, fields } } = result;

                let formData = new FormData()

                formData.append("key", fields.key)
                formData.append("x-amz-algorithm", fields['x-amz-algorithm'])
                formData.append("x-amz-credential", fields['x-amz-credential'])
                formData.append("x-amz-date", fields['x-amz-date'])
                formData.append("x-amz-security-token", fields['x-amz-security-token'])
                formData.append("policy", fields.policy)
                formData.append("x-amz-signature", fields['x-amz-signature'])
                formData.append("file", file)

                const finalResponse = await fetch(
                    url,
                    api.http.postNoAuthFormData(formData)
                )
                const parsedFinalResponse = await api.afterFetchHandlers.parseContent(finalResponse, api.http.postNoAuthFormData())

                return parsedFinalResponse;
            } catch (error: any) {
                if (!error.response) {
                    throw error
                }
                rejectWithValue(error)
            }
        }
    )




const postFilesS3Slice = createSlice({
    name: "postFilesS3Slice",
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(postFilesS3Action.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(postFilesS3Action.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(postFilesS3Action.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error
            })
    },
})

export default postFilesS3Slice.reducer;