// import library components
import { useState, useEffect } from 'react';

// import styles file
import './DataCollection.scss'
import ToggleSwitch from '../../../../Components/Core/ToggleSwitch/ToggleSwitch';
import PartnerIntegration from '../PartnerIntegration/PartnerIntegration';
import DataCollectionManual from '../DataCollectionManual/DataCollectionManual';

// improt custom components


// import redux related components
import { setSelectedSwitchAction } from '../../../../Redux/Slices/ghgEmission/utils/dataFillModeSwitchGHG/switch.dataFillModeGHG.slice'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../Redux/store';
import { useSelector } from 'react-redux';
import { selectDataFillingModeSwitch } from '../../../../Redux/Slices/ghgEmission/utils/dataFillModeSwitchGHG/switch.dataFillModeGHG.selector';
import { fetchPartnerIntegrationList } from '../../../../Redux/Slices/ghgEmission/partnerIntegrationList/partnerIntegrationList.slice';
import MainContentWrapper from '../../../../Components/Core/MainContent/ContentWrapper';
import { selectAssetClassResponse } from '../../../../Redux/Slices/ghgEmission/partnerIntegrationList/partnerIntegrationList.selector';

// type definition
type Props = {}

const DataCollection = () => {
    // constants
    const dispatch = useDispatch<AppDispatch>()
    const switchMode = useSelector(selectDataFillingModeSwitch)
    const partnerIntegrationDetails = useSelector(selectAssetClassResponse)


    //  some default state and constants
    const dataCollectionModes = ['Access from partner integration', 'Fill manually']

    const [selectedMode, setSelectedMode] = useState(switchMode || 0);

    const handleSwitchChange = (mode: number) => {
        dispatch(setSelectedSwitchAction(mode))
        setSelectedMode(mode)
    }

    useEffect(() => {
        !partnerIntegrationDetails && dispatch(fetchPartnerIntegrationList())
    }, [])

    return (
        <>
            <div id='dataCollectionComponent' className='dataCollectionComponent'>
                <div className="section-header">
                    Green House Gas Emission
                    
                </div>
                <div className='dataCollectionComponent noScrollBar'>

                    <ToggleSwitch
                        selectedValue={selectedMode || 0}
                        switchState={dataCollectionModes}
                        btnTheme='gray'
                        onSwitchChange={handleSwitchChange}
                    />

                    {
                        selectedMode === 0 ?
                            <PartnerIntegration />
                            :
                            <DataCollectionManual />
                    }


                </div>
            </div>
        </>

    )
}

export default DataCollection