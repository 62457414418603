// import library components
import React, { useEffect, useId, useState } from 'react'

// import styles file
import './RadioInput.scss'

// import custom components
import { ReactComponent as Checked } from '../../../assets/icons/radio-checked.svg'
import { ReactComponent as Unchecked } from '../../../assets/icons/radio-unchecked.svg'

type Props = {
    label?: string;
    checked?: boolean;
    onClickHandler?: (value: boolean) => void;
}

const RadioInput = ({ label, onClickHandler, checked }: Props) => {



    const radioCheckClick = () => {
        onClickHandler?.(!checked)
    }




    return (
        <div className='radio-input-container'>
            {
                checked ?
                    <Checked onClick={radioCheckClick} />
                    :
                    <Unchecked onClick={radioCheckClick} />
            }

            <label onClick={radioCheckClick} >{label}</label>
        </div>
    )
}

export default RadioInput