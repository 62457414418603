// import library components
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// import styles file
import "./LocationDetails.scss";

// import custom components
import StatsTile from "../../../../../Components/Core/StatsTile/StatsTile.component";
import { ReactComponent as Chevron } from "../../../../../assets/icons/chevron.svg";
import { Graphs } from "../../../../../Components/graphs/index";
import { ReactComponent as Arrow } from "../../../../../assets/icons/backArrow.svg";
import EmissionByAssetClass from "./EmissionByAssetClass";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../Redux/store";
import { selectFinancedEmissionLocationDetailsResponse } from "../../../../../Redux/Slices/dashboard/financedEmission/locationDetails/getFinancedEmissionLocationDetails.selector";
import { useSelector } from "react-redux";
import { getFinancedEmissionLocationDetails } from "../../../../../Redux/Slices/dashboard/financedEmission/locationDetails/getFinancedEmissionLocationDetails.slice";
import EmissionBySector from "../../Overview/EmissionBySector/EmissionBySector";
import countriesData from "../../../../../utils/data/countries.json";
import { pcaf } from "../../../../../assets/images/images";

//type definition
type Props = {};

type receivedFinancedEmissionDetailsType = {
  waci_investment?: number;
  waci_revenue?: number;
  average_data_quality_score?: number;
  last_year_waci_investment?: number;
  last_year_waci_revenue?: number;
  last_year_average_data_quality_score?: number;
  country_img_url?: string;
};

const LocationDetails = (props: Props) => {
  const { country } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const financedEmissionLocationDetails = useSelector(
    selectFinancedEmissionLocationDetailsResponse
  );
  const [
    receivedFinancedEmissionLocationDetails,
    setReceivedFinancedEmissionLocationDetails,
  ] = useState<receivedFinancedEmissionDetailsType>({});
  const [highEmittingInvestees, setHighEmittingInvestees] = useState<
    [string, number][]
  >([]);

  const backNavigationHandler = (): void => {
    window.history.go(-1);
  };

  useEffect(() => {
    dispatch(getFinancedEmissionLocationDetails({ location: country }));
  }, []);

  useEffect(() => {
    let tempHeInvestees =
      financedEmissionLocationDetails?.data?.high_emitting_investees.map(
        (el: any) => [el.investee_name, Math.round(el.financed_emission)]
      );
    setHighEmittingInvestees(tempHeInvestees ?? []);
    setReceivedFinancedEmissionLocationDetails(
      financedEmissionLocationDetails?.data
    );
  }, [financedEmissionLocationDetails]);

  return (
    <div className="financed-emission-locationDetails-component noScrollBar">
      <div className="header-filter-row">
        <div className="header">
          <div className="header-text">
            <Arrow onClick={backNavigationHandler} />
            Country <Chevron className="chevron-icon" />{" "}
            {countriesData.find((el) => el.shortName === country)?.name}
          </div>
          <div className="poweredby-details">
            <div className="label">Powered by:</div>
            <div className="logo">
              <img alt="powered by science based targets" src={pcaf} />
            </div>
          </div>
        </div>
      </div>
      <div className="demographic-container d-grid-c8">
        <div className="quick-stats-row col-8 d-grid-c8">
          <div className="demographic-tile col-2 country-placeholder">
            <img
              src={receivedFinancedEmissionLocationDetails?.country_img_url}
              alt=""
            />
            <div className="text">
              {" "}
              {countriesData.find((el) => el.shortName === country)?.name}
            </div>
          </div>

          <div className="col-2">
            <StatsTile
              header="WACI /$M Investment"
              value={
                `${receivedFinancedEmissionLocationDetails?.waci_investment}` ??
                "0"
              }
              unit="MtCO2e"
              icon="success"
              progress={`${
                receivedFinancedEmissionLocationDetails?.last_year_waci_investment ??
                0
              }%`}
            />
          </div>
          <div className="col-2">
            <StatsTile
              header="WACI /$M Revenue "
              value={
                `${receivedFinancedEmissionLocationDetails?.waci_revenue}` ??
                "0"
              }
              unit="MtCO2e"
              icon="success"
              progress={`${
                receivedFinancedEmissionLocationDetails?.last_year_waci_revenue ??
                0
              }%`}
            />
          </div>
          <div className="col-2">
            <StatsTile
              header="Average Data Quality Score"
              value={
                `${receivedFinancedEmissionLocationDetails?.average_data_quality_score}` ??
                "0"
              }
              unit="MtCO2e"
              icon="success"
              progress={`${
                receivedFinancedEmissionLocationDetails?.last_year_average_data_quality_score ??
                0
              }%`}
            />
          </div>
        </div>

        <div className="demographic-tile col-4">
          <EmissionByAssetClass
            receivedFinancedEmissionLocationDetails={
              receivedFinancedEmissionLocationDetails
            }
          />
        </div>

        <div className="demographic-tile col-4">
          <Graphs.MultiBarGraph
            header="High Emitting Investees"
            chartData={highEmittingInvestees}
            extraStyles={{
              height: "400px",
              overflowY: "scroll",
            }}
          />
        </div>
        <div
          className="demographic-tile col-8 p-rel"
          style={{ height: "590px" }}
        >
          <EmissionBySector
            receivedFinancedEmissionData={
              receivedFinancedEmissionLocationDetails
            }
          />
        </div>
      </div>
    </div>
  );
};

export default LocationDetails;
