const primaryColors = [
  "rgba(12, 129, 168, 1)",
  "rgba(20, 201, 158, 1)",
  "rgba(20, 201, 201, 1)",
  "rgba(157, 202, 92, 1)",
  "rgba(159, 132, 186, 1)",
  "rgba(250, 204, 21, 1)",
  "rgba(151, 153, 231, 1)",
  "rgba(183, 113, 147, 1)",
  "rgba(209, 230, 235, 1)",
  "rgba(232, 243, 245, 1)",
];

const errorColors = [
  "#FFCCCC",
  "#FF9999",
  "#FF6666",
  "#FF3333",
  "#FF0000",
  "#CC0000",
  "#990000",
  "#660000",
  "#330000",
  "#1A0000",
];

const mixedColors = [
  "rgba(247, 93, 95, 1)",
  "rgba(15, 202, 122, 1)",
  "rgba(0, 199, 242, 1)",
  "rgba(251, 198, 47, 1)",
  "rgba(224, 169, 238, 1)",
  "rgba(0, 199, 242, 1)",
  "rgba(251, 198, 47, 1)",
  "rgba(224, 169, 238, 1)",
  "rgba(0, 199, 242, 1)",
  "rgba(251, 198, 47, 1)",
  "rgba(224, 169, 238, 1)",
];

const staticColorsMultiBarGraph = ["#14C99E", "#9799E7", "#9DCA5C"];

const colorPalette = [
  "#2CA6E0",
  "#3F70F5",
  "#6A4FF7",
  "#9346E8",
  "#9136B8",
  "#CB63D6",
  "#D75599",
  "#FC8BA8",
  "#C52954",
  "#D84C3D",
  "#EA9246",
  "#EECF52",
  "#9DC929",
  "#80CF7A",
  "#64C2AC",
  "#8A969E",
];

export const colors = {
  primaryColors,
  errorColors,
  mixedColors,
  staticColorsMultiBarGraph,
  colorPalette,
};
